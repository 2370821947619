import { api } from 'app/api';
import type { IPlayablePing } from '../types';

export const playablePingApi = api.injectEndpoints({
  endpoints: (build) => ({
    pingPlayable: build.query<IPlayablePing, null>({
      query: () => `plyable/ping/`,
      providesTags: ['playablePing'],
    }),
    plyableAccessLink: build.mutation<{ link: string }, { referenceId: string }>({
      query: (payload) => ({
        url: `/plyable/access-link/${payload.referenceId}/`,
        method: 'GET',
      }),
    }),
  }),
});

export const { usePingPlayableQuery, usePlyableAccessLinkMutation } = playablePingApi;
