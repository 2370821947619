import React from 'react';
import colors from 'theme/colors';
import { Button, Group, Stack, Text } from 'ui';
import { handleApiCall } from 'utils/helpers';
import { NOTIFICATION } from '../../alert/constants';
import { showSuccessMessage, showUncaughtErrorMessage } from '../../alert/utils';
import { useModal } from '../../modals/ModalProvider';
import { useDeleteEventMutation } from '../eventsApi';

interface IDeleteEvent {
  id: number;
}

const DeleteEvent: React.FC<IDeleteEvent> = ({ id }) => {
  const [deleteEvent, { isLoading }] = useDeleteEventMutation();
  const { close } = useModal();
  const handleDeleteMessage = async () => {
    const res = await deleteEvent(id);
    handleApiCall(
      res,
      () => showUncaughtErrorMessage(),
      () => {
        close();
        showSuccessMessage(NOTIFICATION.EVENT_DELETED);
      }
    );
  };

  return (
    <Stack gap="20px">
      <Text size="lg" color={colors.gray2}>
        Are you sure you want to delete this event?
      </Text>
      <Group justify="end" gap="15px">
        <Button
          color={colors.dark2}
          onClick={() => close()}
          cypressAttribute="modal-delete-event-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          color={colors.red2}
          onClick={handleDeleteMessage}
          cypressAttribute="modal-delete-event-delete-btn"
        >
          {isLoading ? 'Deleting...' : 'Delete'}
        </Button>
      </Group>
    </Stack>
  );
};

export default DeleteEvent;
