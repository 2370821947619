import { ReactComponent as PlayableEventLogo } from 'assets/icons/playable-logo.svg';
import { AnnotationItemWrapper } from 'modules/annotations/components/AnnotationLayout';
import { AnnotationsSkeleton } from 'modules/annotations/components/AnnotationsSkeleton';
import type { IPlyableReply } from 'modules/playable/types';
import React from 'react';
import { IUserMinimize } from 'types/user';
import * as Layout from '../../annotations/components/AnnotationLayout';
import { useLazyPlyableAnnotationByIdQuery } from '../api';
import PlyableAnnotationContent from './PlyableAnnotationContent';

interface IPlyableAnnotationItemProps {
  /**
   * Unique identifier of the annotation.
   */
  id: string;
  author: IUserMinimize;
  /**
   * Date of the annotation creation. ISO 8601 format.
   * @example "2021-01-01T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * Unique identifier of the file that the annotation is attached to.
   */
  fileId: number;
  /**
   * Name of the file that the annotation is attached to.
   */
  fileName: string;
  /**
   * Version of the file that the annotation is attached to.
   * @example "1.0.0"
   */
  fileVersion: string;
  /**
   * URL of the file that the annotation is attached to.
   */
  fileUrl: string;
  /**
   * List of comments for the annotation.
   */
  comments: IPlyableReply[];
}

const PlyableAnnotationItem: React.FC<IPlyableAnnotationItemProps> = (props) => {
  const { id, author, createdAt, fileId, fileName, fileVersion, fileUrl, comments = [] } = props;

  const [fetchPlyableAnnotation, { isFetching: isRefreshing, isLoading }] =
    useLazyPlyableAnnotationByIdQuery();

  const annotationRefreshClickHandler = (annotationId: string) => {
    fetchPlyableAnnotation({ id: annotationId });
  };

  if (isLoading) {
    <AnnotationsSkeleton />;
  }

  return (
    <AnnotationItemWrapper
      fluid
      gap="12px"
      justify="flex-start"
      align="flex-start"
      data-cy="annotation-item-wrapper"
    >
      <PlyableAnnotationContent
        key={id}
        id={id}
        authorName={`${author.firstName} ${author.lastName}`}
        createdAt={createdAt}
        fileId={fileId}
        fileName={fileName}
        fileVersion={fileVersion}
        fileUrl={fileUrl}
        comments={comments}
        onRefreshClick={annotationRefreshClickHandler}
        isRefreshing={isRefreshing}
      />
      <Layout.LogoWrapper data-cy="playable-annotations-avatar">
        <PlayableEventLogo />
      </Layout.LogoWrapper>
    </AnnotationItemWrapper>
  );
};

export default PlyableAnnotationItem;
