import { yupResolver } from '@hookform/resolvers/yup';
import { PLYABLE_ALLOWED_FILES, THREADS_3D_ALLOWED_FILES } from 'constants/index';
import { EReferenceIntegrations } from 'constants/integrations';
import { NOTIFICATION } from 'modules/alert/constants';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { useModal } from 'modules/modals/ModalProvider';
import { usePlayableStatus } from 'modules/playable';
import FileVersionForm from 'modules/references/components/FileVersionForm';
import IntegrationSection from 'modules/references/components/IntegrationSection';
import { Threads3dIntegrationDetails } from 'modules/references/components/threads3d/Threads3dIntegrationDetails';
import { Threads3dIntegrationToggle } from 'modules/references/components/threads3d/Threads3dIntegrationToggle';
import { useGetReferenceQuery, useUpdateReferenceMutation } from 'modules/threads/threadsApi';
import { IEditReferenceMutation, IReferences, REFERENCE_TYPE } from 'modules/threads/types';
import { useThreads3dStatus } from 'modules/threads3d/hooks/status';
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import colors from 'theme/colors';
import { Button, Group, Icon, RadioButton, Spinner, Stack, Text, TextArea, TextInput } from 'ui';
import { fileTypeMatches } from 'utils/helpers';
import { validationSchema } from 'utils/validation';
import * as yup from 'yup';
import * as Layout from './Layout';

interface IEditReference {
  reference?: IReferences;
  referenceId?: number;
}

const validation = yup
  .object({
    name: validationSchema.stringRequired(),
    description: validationSchema.textArea().nullable(),
    url: validationSchema.url(),
  })
  .required();

const EditReference: React.FC<IEditReference> = ({ reference, referenceId }) => {
  const currentRefId = reference?.id || referenceId;
  const {
    data,
    isLoading: isReferenceLoading,
    isFetching: isReferenceFetching,
  } = useGetReferenceQuery(currentRefId as number, {
    refetchOnMountOrArgChange: true,
  });

  const [updateReference, { isError, isLoading, isSuccess, error: updateError }] =
    useUpdateReferenceMutation();
  const [isPlayableError, setIsPlayableError] = useState<boolean>(false);
  const { close } = useModal();

  const { isPlayableFunctionalAvailable } = usePlayableStatus();
  const { active: threads3dEnabled } = useThreads3dStatus();

  const [preview, setPreview] = useState<string | null>();
  const [attach, setAttach] = useState<File | null>(null);
  const [error, setError] = useState<boolean>(false);

  const isLink = useMemo(() => data?.referenceType === REFERENCE_TYPE.LINK, [data?.referenceType]);
  const isFile = useMemo(() => data?.referenceType === REFERENCE_TYPE.FILE, [data?.referenceType]);
  const isPlayableFile = useMemo(
    () => data?.referenceType === REFERENCE_TYPE.PLAYABLE,
    [data?.referenceType]
  );
  const [selectedVersion, setSelectedVersion] = useState(0);
  const selectedFile = data?.fileVersions[selectedVersion];

  const shouldShowFileVersionSection =
    isFile || isPlayableFile || data?.referenceType === REFERENCE_TYPE.THREADS_3D;

  const isGoogleIntegrationReference = useMemo(
    () => reference && reference.icons,
    [reference?.icons]
  );

  const hasAttachedFile = useMemo(() => {
    return attach !== null;
  }, [attach]);

  // For whatever reason, the one previous file version is a constraint here
  const canEnablePlyable = useMemo(
    () =>
      isFile &&
      data?.fileVersions.length === 1 &&
      fileTypeMatches(data?.fileVersions[0].file, PLYABLE_ALLOWED_FILES) &&
      attach === null,
    [data, attach]
  );

  // Edge case where multiple files are uploaded with varying file endings
  const canEnableThreads3D = isFile && attach && fileTypeMatches(attach, THREADS_3D_ALLOWED_FILES);

  // Refers to either the uploaded files integration if already selected
  // Or a plain FILEs newly selected integration type
  const [selectedIntegration, setSelectedIntegration] = useState<EReferenceIntegrations>();
  const isPlyableReference = selectedIntegration === EReferenceIntegrations.plyable;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IEditReferenceMutation>({
    mode: 'onSubmit',
    shouldFocusError: false,
    resolver: yupResolver(validation),
  });

  const onFileChange = (uploadFile: File) => {
    setError(false);
    setAttach(uploadFile);
  };

  const resetUpload = () => {
    setValue('file', null);
    setAttach(null);
    setError(false);
  };

  const onSubmit: SubmitHandler<IEditReferenceMutation> = (payload, event) => {
    event?.preventDefault();

    if (attach !== null) {
      payload.file = attach;
    }

    if (typeof preview === 'string') {
      delete payload.file;
    }

    if (isPlayableFile && !payload.plyableQuoteFor && attach !== null) {
      setIsPlayableError(true);
      return;
    }
    if (canEnablePlyable && !payload.plyableQuoteFor && isPlyableReference) {
      setIsPlayableError(true);
      return;
    }

    if (canEnablePlyable && isPlyableReference) {
      payload.referenceType = REFERENCE_TYPE.PLAYABLE;
    }
    if (canEnableThreads3D && selectedIntegration === EReferenceIntegrations.threads3d) {
      payload.referenceType = REFERENCE_TYPE.THREADS_3D;
    }

    updateReference({ ...payload, id: currentRefId as number });
  };

  const handlePlayableManufactureChange = (option: { label: string; value: string }) => {
    setIsPlayableError(false);
    setValue('plyableQuoteFor', option.value);
  };

  useEffect(() => {
    if (isSuccess) {
      showSuccessMessage(NOTIFICATION.REFERENCE_UPDATED);
      close();
    }

    if (isError) {
      const fileExtensionError = updateError as { data: { file: string[] } };
      showErrorMessage(fileExtensionError?.data?.file[0]);
    }
  }, [isLoading]);

  useEffect(() => {
    if (data) {
      setValue('name', data?.name);
      setValue('description', data?.description as string);
    }
  }, [data]);

  useEffect(() => {
    switch (data?.referenceType) {
      case REFERENCE_TYPE.PLAYABLE:
        setSelectedIntegration(EReferenceIntegrations.plyable);
        break;
      case REFERENCE_TYPE.THREADS_3D:
        setSelectedIntegration(EReferenceIntegrations.threads3d);
        break;
      default:
        break;
    }
  }, [data?.referenceType]);

  if (isReferenceLoading || isReferenceFetching) {
    return (
      <Stack style={{ height: '100%', width: '100%' }} align="center" justify="center">
        <Spinner />
      </Stack>
    );
  }

  return (
    <Layout.Form onSubmit={handleSubmit(onSubmit)} data-cy="modal-edit-reference-form">
      <Stack fluid gap="25px" style={{ marginBottom: '20px' }}>
        <TextInput
          label="Name"
          name="name"
          placeholder="Name"
          type="text"
          defaultValue={data?.name}
          register={register}
          required
          error={errors.name}
          data-cy="modal-edit-reference-name"
        />
        <TextArea
          label="Description"
          name="description"
          placeholder="Description"
          resize="none"
          defaultValue={data?.description}
          register={register}
          error={errors.description}
          data-cy="modal-edit-reference-description"
        />
        <Group gap="12px" role="radiogroup">
          {isLink && (
            <RadioButton
              id="edit-link"
              value="link"
              text="Link"
              icon={<Icon icon="ReferenceLinkIcon" size="medium" stroke="none" />}
              name={REFERENCE_TYPE.LINK}
              checked={isLink}
              aria-checked={isLink}
              onChange={() => null}
              data-cy="modal-edit-reference-radio-link"
            />
          )}
          {(isFile || isPlayableFile) && (
            <RadioButton
              id="edit-file"
              value="file"
              text="File"
              icon={<Icon icon="ReferenceDocumentIcon" size="medium" stroke="none" />}
              name={REFERENCE_TYPE.FILE}
              checked={isFile || isPlayableFile}
              aria-checked={isFile || isPlayableFile}
              onChange={() => null}
              data-cy="modal-edit-reference-radio-file"
            />
          )}
        </Group>

        {isLink && (
          <TextInput
            register={register}
            required={isLink}
            error={errors.url}
            defaultValue={data?.url || ''}
            label="Paste link"
            disabled={isGoogleIntegrationReference}
            name="url"
            type="text"
            icon={<Icon icon="LinkIcon" />}
            placeholder="Paste your link here"
            style={{ border: `1px solid ${colors.blue}` }}
            data-cy="modal-edit-reference-paste-link"
          />
        )}

        {shouldShowFileVersionSection && (
          <FileVersionForm
            versionFiles={data?.fileVersions || []}
            fileName={data?.name as string}
            isLoading={isLoading}
            selectVersion={(version) => setSelectedVersion(version)}
            attach={attach}
            register={register}
            uploadFile={onFileChange}
            resetUpload={resetUpload}
            fileType={data?.referenceType as (typeof REFERENCE_TYPE)[keyof typeof REFERENCE_TYPE]}
            cypressAttribute="modal-edit-reference-file-version"
          />
        )}
        {selectedFile && (
          <>
            {(canEnablePlyable || isPlayableFile) && isPlayableFunctionalAvailable && (
              <IntegrationSection
                file={selectedFile}
                fileName={data?.name}
                type={EReferenceIntegrations.plyable}
                isEdit
                isSelectDisabled={isLoading}
                isInfoSection={isPlayableFile}
                isCreateSection={canEnablePlyable || hasAttachedFile}
                active={isPlyableReference}
                toggleIntegration={(enabled) =>
                  setSelectedIntegration(enabled ? EReferenceIntegrations.plyable : undefined)
                }
                dataLabels={data?.labels}
                onChange={handlePlayableManufactureChange}
                isPlayableError={isPlayableError}
              />
            )}
            {canEnableThreads3D && threads3dEnabled && (
              <Threads3dIntegrationToggle
                isSelected={selectedIntegration === EReferenceIntegrations.threads3d}
                toggleSelection={() =>
                  setSelectedIntegration((prev) =>
                    prev === EReferenceIntegrations.threads3d
                      ? undefined
                      : EReferenceIntegrations.threads3d
                  )
                }
              />
            )}
            {threads3dEnabled && data?.referenceType === REFERENCE_TYPE.THREADS_3D && (
              <Threads3dIntegrationDetails file={selectedFile} />
            )}
          </>
        )}
        {error && <Text color={colors.red}>Should contain at least one reference</Text>}
      </Stack>
      <Group justify="end" gap="15px" style={{ marginTop: 'auto' }}>
        <Button
          color={colors.dark2}
          onClick={close}
          cypressAttribute="modal-edit-reference-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={isLoading}
          cypressAttribute="modal-edit-reference-cancel-update-btn"
        >
          {isLoading ? <Spinner color="white" size="small" /> : 'Update and Save'}
        </Button>
      </Group>
    </Layout.Form>
  );
};

export default EditReference;
