import {
  EReferenceIntegrations,
  INTEGRATION_COLORS,
  INTEGRATION_LOGOS,
  PLAYABLE_MOLD_TYPES_OPTIONS,
  PLAYABLE_QUOTE_FOR,
} from 'constants/integrations';
import { MODAL_STATUS_LABELS_TEXT } from 'constants/labels';
import _capitalize from 'lodash/capitalize';
import { IFileVersions } from 'modules/threads/types';
import React, { FC, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import { Group, Icon, Spinner, Stack, Text } from 'ui';
import Tooltip from 'components/ui/tooltip';
import PlayableIntegrationSelect from 'ui/PlayableSelect';
import EditPlayableSection from './EditPlayableSection';
import * as Layout from './Layout';

/**
 * 60px dropdown header height + 30px padding + 10px margin-bottom
 */
const baseContentHeight = 60 + 30 + 10;

const SlideDownContainer = styled(Stack)<{
  expanded: boolean;
  maxHeight: number;
  active: boolean;
  type: EReferenceIntegrations;
}>`
  overflow: hidden;
  min-height: 60px;
  padding: 15px;
  border: ${({ active, type }) =>
    `${active ? 2 : 1}px solid ${active ? INTEGRATION_COLORS[type] : '#737D9B'}`};
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  max-height: ${({ expanded, maxHeight }) => (expanded ? maxHeight + baseContentHeight : 0)}px;
`;

const Content = styled.div<{ expanded: boolean }>`
  opacity: ${({ expanded }) => (expanded ? 1 : 0)};
`;

const ImageContainer = styled.div<{ expanded: boolean }>`
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
  ${({ expanded }) => expanded && 'transform: rotate(0deg);'}
`;

// TODO: Break this into add and edit components
interface IProps {
  file?: IFileVersions;
  fileName?: string;
  type: EReferenceIntegrations;
  active: boolean;
  isInfoSection?: boolean;
  isCreateSection?: boolean;
  isEdit?: boolean;
  isPlayableError: boolean;
  isSelectDisabled: boolean;
  dataLabels?: (typeof MODAL_STATUS_LABELS_TEXT)[keyof typeof MODAL_STATUS_LABELS_TEXT][];
  onChange: (option: { label: string; value: string }) => void;
  toggleIntegration?: React.Dispatch<React.SetStateAction<boolean>>;
}

const IntegrationSection: FC<IProps> = ({
  file: currentFile,
  fileName: currentFileName,
  type,
  active,
  dataLabels,
  isPlayableError,
  isInfoSection,
  isCreateSection,
  isEdit = false,
  onChange,
  toggleIntegration,
  isSelectDisabled,
}) => {
  const [maxHeight, setMaxHeight] = useState(0);
  const [isQuoteLoading, setIsQuoteLoading] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const QUOTE_DOWNLOAD_URL = currentFile?.plyableDownloadUrl;
  const PLAYABLE_QUOTE_LINK = currentFile?.plyableUrl;

  const isPlyableIntegrations = useMemo(() => type === EReferenceIntegrations.plyable, [type]);

  useLayoutEffect(() => {
    if (contentRef.current) {
      setMaxHeight(contentRef.current.scrollHeight);
    }
  }, [active, isCreateSection, isInfoSection, isEdit, PLAYABLE_QUOTE_LINK]);

  const toggleExpand = () => {
    if (isInfoSection) return;
    toggleIntegration?.(!active);
  };

  return (
    <SlideDownContainer expanded={active} maxHeight={maxHeight} active={active} type={type}>
      <Group
        justify="space-between"
        align="center"
        style={{
          marginBottom: '10px',
          cursor: isInfoSection ? 'default' : 'pointer',
        }}
        onClick={toggleExpand}
      >
        <Group gap="10px" align="center">
          {INTEGRATION_LOGOS[type]}
          <Text size="lg">{_capitalize(type)}</Text>
          {isEdit && currentFile?.plyableQuoteFor && !isCreateSection && (
            <Layout.PlayableQuoteForContainer>
              <Text size="xs" weight="500" color={colors.dark4}>
                {currentFile ? PLAYABLE_QUOTE_FOR[currentFile.plyableQuoteFor] : 'N/A'}
              </Text>
            </Layout.PlayableQuoteForContainer>
          )}
        </Group>
        <Group gap="8px">
          {isEdit &&
            currentFile?.plyableDownloadUrl &&
            !isCreateSection &&
            (isQuoteLoading ? (
              <Spinner size="small" color="white" />
            ) : (
              <Tooltip content="Download Quote" side="left">
                <a href={QUOTE_DOWNLOAD_URL ?? ''} target="_blank" rel="noreferrer">
                  <Icon icon="DownloadPlayableIcon" size="medium" />
                </a>
              </Tooltip>
            ))}
          <ImageContainer expanded={active}>
            <Icon icon="ArrowUpIcon" />
          </ImageContainer>
        </Group>
      </Group>
      <Content ref={contentRef} expanded={active}>
        {isPlyableIntegrations && (isCreateSection || !isEdit) && (
          <Stack gap="10px">
            <PlayableIntegrationSelect
              options={PLAYABLE_MOLD_TYPES_OPTIONS}
              onChange={onChange}
              isDisabled={isSelectDisabled}
            />
            {isPlayableError && (
              <Group gap="5px" align="center">
                <Icon icon="InfoIcon" path={colors.red} size="normal" />
                <Text weight="500" color={colors.red}>
                  Please select manufacture type
                </Text>
              </Group>
            )}
          </Stack>
        )}
        {currentFile &&
          currentFileName &&
          isPlyableIntegrations &&
          isInfoSection &&
          !isCreateSection && (
            <EditPlayableSection
              dataLabels={dataLabels}
              file={currentFile}
              fileName={currentFileName}
            />
          )}
      </Content>
    </SlideDownContainer>
  );
};

export default IntegrationSection;
