import useSubscriptionPermissions from 'hooks/useSubscriptionPermissions';
import { showSuccessMessage } from 'modules/alert/utils';
import React, { useCallback } from 'react';
import colors from 'theme/colors';
import { Avatar, Group, Icon, Spinner, Stack, Text } from 'ui';
import { handleApiCall } from 'utils/helpers';
import { useContributorInviteUpdateMutation } from '../contributorsApi';
import type { IThreadJoinRequest } from '../types';
import * as Layout from './Layout';

const ContributorRequestCard: React.FC<IThreadJoinRequest> = (props) => {
  const {
    id,
    user: { first_name: firstName, last_name: lastName, email, avatar },
  } = props;
  const { handleSubscriptionPermissionErrors } = useSubscriptionPermissions();
  const [contributorMutation, { isLoading }] = useContributorInviteUpdateMutation();

  const userFullName = !!firstName && !!lastName;

  const handleApproveRequest = useCallback(async () => {
    const response = await contributorMutation({
      id,
      accept: true,
    });
    handleApiCall(
      response,
      (error) => {
        handleSubscriptionPermissionErrors(error, 'Error occurred while approving request.');
      },
      () => showSuccessMessage('Request approved.')
    );
  }, [id]);

  const handleRejectRequest = useCallback(async () => {
    try {
      await contributorMutation({
        id,
        accept: false,
      }).unwrap();
      showSuccessMessage('Request rejected.');
    } catch (error) {
      handleSubscriptionPermissionErrors(error, 'Error occurred while rejecting request.');
    }
  }, [id]);
  return (
    <Layout.Card
      align="center"
      justify="space-between"
      data-cy="contributor-join-request-card"
      fluid
    >
      <Group gap="12px" align="center">
        <Avatar src={avatar} cypressAttribute="contributor-join-request-card-avatar" />
        <Stack gap="8px">
          <Text
            weight="700"
            color={colors.orange}
            cypressAttribute="contributor-join-request-card-label"
          >
            Request to join
          </Text>
          {userFullName ? (
            <Text weight="500" cypressAttribute="contributor-join-request-card-full-name">
              {firstName} {lastName}
            </Text>
          ) : (
            <Text cypressAttribute="contributor-join-request-card-email">{email}</Text>
          )}
        </Stack>
      </Group>
      {isLoading ? (
        <Spinner size="medium" color={colors.orange} />
      ) : (
        <Group align="center" gap="15px">
          <Icon
            icon="RemoveUserIcon"
            style={{ cursor: 'pointer' }}
            onClick={handleRejectRequest}
            cypressAttribute="contributor-join-request-card-decline-request-btn"
          />
          <Icon
            icon="AddUserIcon"
            path={colors.orange}
            style={{ cursor: 'pointer' }}
            cypressAttribute="contributor-join-request-card-approve-request-btn"
            onClick={handleApproveRequest}
          />
        </Group>
      )}
    </Layout.Card>
  );
};

export default ContributorRequestCard;
