import { FormattedDate } from 'components/dates';
import { MODAL_STATUS_LABELS_COLORS, MODAL_STATUS_LABELS_TEXT } from 'constants/labels';
import _capitalize from 'lodash/capitalize';
import { showUncaughtErrorMessage } from 'modules/alert/utils';
import { usePlyableAccessLinkMutation } from 'modules/playable/api';
import { IFileVersions } from 'modules/threads/types';
import React from 'react';
import colors from 'theme/colors';
import { Group, Icon, Stack, TagBadge, Text } from 'ui';
import Tooltip from 'components/ui/tooltip';
import { convertCentsToDollars } from 'utils/helpers';
import * as Layout from './Layout';

interface IProps {
  file: IFileVersions;
  fileName: string;
  dataLabels?: (typeof MODAL_STATUS_LABELS_TEXT)[keyof typeof MODAL_STATUS_LABELS_TEXT][];
}

const EditPlyableSection: React.FC<IProps> = ({
  dataLabels,
  file: currentFile,
  fileName: currentFileName,
}) => {
  const [getPlyableLink] = usePlyableAccessLinkMutation();

  // This link might be outdated (not having invite code) so use fresh one
  const PLYABLE_QUOTE_LINK = currentFile?.plyableUrl;
  const PLYABLE_UPLOAD_ERROR_MESSAGE = currentFile?.plyableUploadError;
  const QUOTE_DELIVERY_DATE = currentFile?.plyableEstimatedDeliveryDate;
  const QUOTE_PRICE_IN_CENTS = currentFile?.plyablePriceInCents;
  const QUOTE_PLYABLE_CURRENCY = currentFile?.plyablePriceCurrency;

  const navigateToPlyable = async () => {
    if (!currentFile?.id) return;
    try {
      const response = await getPlyableLink({ referenceId: `${currentFile.id}` }).unwrap();
      window.open(response.link);
    } catch (error) {
      if ((error as any).status_code === 400) {
        return;
      }
      showUncaughtErrorMessage(error);
    }
  };
  return (
    <Stack gap="8px">
      <Group gap="10px" align="center">
        <Icon icon="ReferenceDocumentIcon" style={{ width: 32, height: 32 }} stroke="none" />
        <Text>{currentFileName}</Text>
        <TagBadge>
          <Text size="xs" weight="400" noWrap>{`Version ${currentFile?.version}`}</Text>
        </TagBadge>
      </Group>

      <Text color={colors.gray3}>
        Quote total: {convertCentsToDollars(QUOTE_PRICE_IN_CENTS, QUOTE_PLYABLE_CURRENCY) || 'N/A'}
      </Text>
      <Text color={colors.gray3}>
        Order Status: {_capitalize(currentFile?.plyableOrderStatus) || 'N/A'}
      </Text>
      <Group align="center" justify="space-between">
        <Text color={colors.gray3}>
          Est. delivery date: <FormattedDate date={QUOTE_DELIVERY_DATE} />
        </Text>
        <Group gap="15px" align="center">
          {currentFile?.isPlyableDfmCheckFailed && (
            <Tooltip
              content={<Text size="sm">Your mold design has failed one or more DFM checks.</Text>}
              side="left"
            >
              <Icon icon="InfoIcon" path={colors.red} size="medium" />
            </Tooltip>
          )}
          {PLYABLE_QUOTE_LINK && (
            <Group
              style={{
                borderRadius: 4,
                backgroundColor: '#F583FF',
                padding: 2,
                cursor: 'pointer',
              }}
              align="center"
              onClick={navigateToPlyable}
            >
              <Layout.IntegrationImageWrapper>
                <Icon icon="PlyableLogo" stroke="none" aria-label="Plyable logo" />
              </Layout.IntegrationImageWrapper>

              <Icon icon="ArrowRightIcon" size="medium" />
            </Group>
          )}
        </Group>
      </Group>
      {dataLabels && PLYABLE_QUOTE_LINK && (
        <Group align="center" gap="25px">
          {dataLabels.map((label) => (
            <TagBadge color={MODAL_STATUS_LABELS_COLORS[label]} key={label}>
              {_capitalize(label)}
            </TagBadge>
          ))}
        </Group>
      )}
      {PLYABLE_UPLOAD_ERROR_MESSAGE && (
        <Group gap="10px" style={{ marginTop: 10 }} align="center">
          <Icon icon="InfoIcon" path={colors.red} size="normal" />
          <Text style={{ lineHeight: 'normal' }} color={colors.red}>
            {PLYABLE_UPLOAD_ERROR_MESSAGE}
          </Text>
        </Group>
      )}
    </Stack>
  );
};

export default EditPlyableSection;
