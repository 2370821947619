import { MentionData } from '@draft-js-plugins/mention';
import { useAppSelector } from 'app/hooks';
import { selectUserInfo } from 'modules/auth/userSlice';
import { useGetContributorsQuery } from 'modules/contributors/contributorsApi';
import { selectOwner } from 'modules/threads/threadsSlice';
import { useMemo } from 'react';
import { getMention } from 'utils/mappers';

export const useContributors = (threadId: string) => {
  const { data: contributors, isLoading } = useGetContributorsQuery({ id: threadId });

  const { id: currentUserId } = useAppSelector(selectUserInfo);
  const threadOwner = useAppSelector(selectOwner(threadId));

  const isUserAContributor = (userId: string) =>
    contributors?.results.some(({ user }) => user.id === userId) ?? false;

  const isCurrentUserContributor = useMemo(
    () => isUserAContributor(currentUserId),
    [currentUserId, contributors]
  );

  const contributorMentions: MentionData[] = useMemo(() => {
    const mentions =
      contributors?.results
        .filter(({ user }) => user.id !== threadOwner.id)
        .map(({ user }) => getMention(user)) ?? [];
    return [...mentions, getMention(threadOwner)];
  }, [contributors, threadOwner]);

  return {
    contributors: contributors?.results,
    isLoading,
    isCurrentUserContributor,
    contributorMentions,
    isUserAContributor,
  };
};
