import { yupResolver } from '@hookform/resolvers/yup';
import { EEventStatus, EEvents, EVENTS_OPTIONS, EVENTS_STATUS_OPTIONS } from 'constants/events';
import _map from 'lodash/map';
import { NOTIFICATION } from 'modules/alert/constants';
import {
  showErrorMessage,
  showSuccessMessage,
  showUncaughtErrorMessage,
} from 'modules/alert/utils';
import { useActionSuggestionMutation } from 'modules/copilot/copilotApi';
import {
  ECopilotSuggestionTypes,
  ICopilotSuggestion,
  SuggestionAction,
} from 'modules/copilot/types';
import { useModal } from 'modules/modals/ModalProvider';
import moment from 'moment';
import React, { SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import colors from 'theme/colors';
import { TReactSelectOption } from 'types';
import { Button, Dropdown, Group, ReactSelect, Spinner, Stack, TextArea, TextInput } from 'ui';
import CalendarInput from 'ui/calendar';
import { getFullName, getOption, handleApiCall } from 'utils/helpers';
import { MESSAGE, validationSchema } from 'utils/validation';
import * as yup from 'yup';
import {
  useCreateEventMutation,
  useEditEventMutation,
  useGetEventAssigneesQuery,
  useGetEventQuery,
} from '../eventsApi';
import { IEvent, IEventResponse } from '../types';

const validation = yup.object({
  name: validationSchema.stringRequired(),
  type: validationSchema.stringRequired(),
  description: validationSchema.string().max(1000, MESSAGE.INVALID_TEXTAREA),
});

interface IProps {
  eventId?: number;
  threadId: number;
  messageId?: number;
  name?: string;
  type?: EEvents;
  description?: string;
  dueDate?: Date;
  status?: EEventStatus;
  copilotSuggestion?: ICopilotSuggestion;
}

const CreateEvent: React.FC<IProps> = ({
  eventId,
  threadId,
  messageId,
  name,
  type,
  description,
  status,
  dueDate = new Date(),
  copilotSuggestion,
}) => {
  const {
    data: eventData,
    isLoading: isEventDataLoading,
    error: eventDataFetchError,
    isFetching,
  } = useGetEventQuery(
    { id: eventId?.toString() as string },
    { skip: !eventId, refetchOnMountOrArgChange: true }
  );

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm<IEvent>({
    resolver: yupResolver(validation),
    defaultValues: { name, type, description, status, dueDate },
  });

  const updatedFormValues = watch();
  const { data: eventPossibleAssignees } = useGetEventAssigneesQuery({ id: threadId });

  const { close, setOptions } = useModal();
  const [createEvent, createOptions] = useCreateEventMutation();
  const [editEvent, editOptions] = useEditEventMutation();
  const [dismissSuggestion] = useActionSuggestionMutation();

  const changeEventType = (value: string) => setValue('type', value as EEvents);
  const changeStatus = (value: string) => setValue('status', value as EEventStatus);
  const changeDate = (value: Date) => {
    setValue('dueDate', value);
  };

  const formValuesEqualToInitial = useMemo(() => {
    if (eventData) {
      return (
        !isDirty &&
        eventData?.type === updatedFormValues.type &&
        eventData?.status === (updatedFormValues.status || EVENTS_STATUS_OPTIONS[0].value) &&
        eventData?.assignees.length === (updatedFormValues?.assignees || []).length &&
        moment(eventData?.dueDate).isSame(
          updatedFormValues.dueDate || moment().startOf('day').toDate()
        )
      );
    }

    return (
      !isDirty &&
      ![updatedFormValues.type, updatedFormValues.status, updatedFormValues.assignees].some(Boolean)
    );
  }, [eventData, isDirty, updatedFormValues]);

  const isLoading = createOptions.isLoading || editOptions.isLoading;

  const changeAssignee = (value: TReactSelectOption[]) => {
    setValue(
      'assignees',
      value.map((item) => item.value)
    );
  };

  const assignedToOptions: TReactSelectOption[] = useMemo(
    () =>
      eventPossibleAssignees?.results.map((contributor) => ({
        // Add email here to be able to search by email
        label: `${getFullName(contributor)} | ${contributor.email}`,
        value: contributor.id.toString(),
      })) || [{ label: '', value: '' }],
    [eventPossibleAssignees]
  );

  const getDefaultAssignees = useCallback(() => {
    if (eventData) {
      return eventData?.assignees?.map((assignee) => ({
        // Add email here to be able to search by email
        label: `${getFullName(assignee)} | ${assignee.email}`,
        value: assignee.id.toString(),
      }));
    }
    return [];
  }, [eventData]);

  const onCreate = async (values: Omit<IEvent, 'thread'>) => {
    const payload = { ...values, thread: threadId };

    if (messageId) {
      Object.assign(payload, { fromMessage: messageId });
    }

    const res = await createEvent(payload);
    handleApiCall<IEventResponse>(
      res,
      ({ error }) => showUncaughtErrorMessage(error),
      () => {
        showSuccessMessage(NOTIFICATION.EVENT_CREATED);
        close();
      }
    );
  };
  const onEdit = async (values: Omit<IEvent, 'thread'>) => {
    const payload = { ...values, thread: threadId, id: eventId };
    const res = await editEvent(payload);
    handleApiCall<IEventResponse>(
      res,
      () => showUncaughtErrorMessage(),
      () => {
        showSuccessMessage(NOTIFICATION.EVENT_UPDATED);
        close();
      }
    );
  };

  useEffect(() => {
    if (
      eventDataFetchError &&
      'status' in eventDataFetchError &&
      eventDataFetchError?.status === 404
    ) {
      close();
      showErrorMessage(NOTIFICATION.EVENT_404);
    }
  }, [eventDataFetchError]);

  useEffect(() => {
    setOptions((previous) => ({
      ...previous,
      showWarningBeforeClose: !formValuesEqualToInitial,
    }));
  }, [formValuesEqualToInitial]);

  useEffect(() => {
    // If editing, manually set the existing values here.
    if (eventData) {
      setValue('description', eventData.description);
      setValue('name', eventData.name);
      setValue('type', eventData.type);
      setValue('assignees', _map(eventData.assignees, 'id'));
      setValue('status', eventData.status);
      setValue('dueDate', moment(eventData.dueDate).toDate());
    }
  }, [eventData]);

  if (isEventDataLoading || isFetching) {
    return (
      <Stack style={{ height: '100%', width: '100%' }} align="center" justify="center">
        <Spinner color="white" size="small" />
      </Stack>
    );
  }

  /**
   * @description Determine whether request should be regular onEdit, onCreate, or if it is a Copilot suggestion.
   */
  const getEventSubmitType = async (values: IEvent) => {
    // There is a copilot suggestion and its suggestionType is MODIFY: Call its edit function from `copilot/utils.ts`
    if (copilotSuggestion && copilotSuggestion.suggestionType === ECopilotSuggestionTypes.MODIFY) {
      // Badly named - eventUuid is a number not a uuid
      const payload = {
        ...values,
        thread: threadId,
        id: Number(copilotSuggestion.eventUuid) ?? undefined,
      };
      try {
        await editEvent(payload).unwrap();
        // Not unwrapping since we don't really care if an error occurs
        await dismissSuggestion({
          suggestionId: copilotSuggestion.id,
          payload: {
            event: copilotSuggestion.eventUuid ?? undefined,
            action: SuggestionAction.EDIT,
          },
        });
        showSuccessMessage(NOTIFICATION.EVENT_UPDATED);
        close();
      } catch {
        showErrorMessage(NOTIFICATION.SOMETHING_WRONG);
      }
      return;
    }

    // There is existing data: edit this event.
    if (eventData) {
      onEdit(values);
    } else {
      // There is no existing data: create new event.
      onCreate(values);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(getEventSubmitType)}
      style={{ height: '100%' }}
      data-cy="modal-event-form"
    >
      <Stack justify="space-between" fullHeight>
        <Stack gap="25px">
          <Dropdown
            label="Event Type"
            name="type"
            badge="event"
            placeholder="Choose type"
            required
            defaultValue={
              (eventData && getOption(EVENTS_OPTIONS, eventData.type)) ||
              (type && getOption(EVENTS_OPTIONS, type))
            }
            bg={colors.dark2}
            options={EVENTS_OPTIONS}
            onClick={changeEventType}
            error={errors.type}
            cypressAttribute="modal-event-type"
          />
          <TextInput
            name="name"
            required
            register={register}
            label="Event Name"
            placeholder="Event Name"
            defaultValue={eventData?.name}
            error={errors.name}
            data-cy="modal-event-name"
          />
          <TextArea
            name="description"
            register={register}
            label="Description"
            placeholder="Description"
            resize="none"
            error={errors.description}
            data-cy="modal-event-description"
          />
          <ReactSelect
            label="Assigned to"
            id="event-assignee-select"
            options={assignedToOptions}
            defaultValue={getDefaultAssignees()}
            placeholder="Select assignees"
            isCreatable={false}
            isMulti
            onChange={changeAssignee}
          />
          <Dropdown
            label="Status"
            name="status"
            badge="status"
            placeholder="Choose status"
            bg={colors.dark2}
            defaultValue={
              eventData
                ? getOption(EVENTS_STATUS_OPTIONS, eventData.status)
                : EVENTS_STATUS_OPTIONS[0]
            }
            options={EVENTS_STATUS_OPTIONS}
            onClick={changeStatus}
            error={errors.status}
            cypressAttribute="modal-event-status"
          />
          <CalendarInput
            value={eventData?.dueDate}
            onChange={changeDate}
            label="Due Date"
            cypressAttribute="modal-event-calendar"
          />
        </Stack>
        <Group justify="flex-end" gap="15px">
          <Button
            onClick={(event: SyntheticEvent) => close(event, !formValuesEqualToInitial)}
            color={colors.dark2}
          >
            {formValuesEqualToInitial ? 'Close' : 'Cancel'}
          </Button>
          <Button type="submit" disabled={isLoading || formValuesEqualToInitial}>
            {isLoading ? <Spinner color="white" size="small" /> : 'Save'}
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default CreateEvent;
