import { Group, Stack, Text } from 'ui';
import { Button } from 'components/ui/button';
import { useModal } from 'modules/modals/ModalProvider';
import React from 'react';
import colors from 'theme/colors';

interface IProps {
  text?: string;
  onConfirm: () => void;
  onAbort: () => void;
}

const OnCloseSaveWarningModal: React.FC<IProps> = ({ text, onConfirm, onAbort }) => {
  return (
    <Stack gap="20px">
      <Text size="lg" color={colors.gray2} cypressAttribute="modal-relate-event-warning-text">
        {text || 'Are you sure you wish to close this window? You will lose any unsaved changes.'}
      </Text>
      <Group justify="end" gap="15px">
        <Button variant="secondary" onClick={onConfirm}>
          Close without saving
        </Button>
        <Button variant="default" onClick={onAbort}>
          Cancel
        </Button>
      </Group>
    </Stack>
  );
};

export default OnCloseSaveWarningModal;
