import { api } from 'app/api';
import { IUser } from 'types/user';
import { IPaginatedResponse } from 'types';
import {
  IGetUsersParams,
  IUserGroups,
  IUserProfileMutation,
  IUserMemberRoleMutation,
  IUserProfileNotifications,
  IUserPreferences,
  IUserPreferencesMutation,
  IUserPreferencesResponse,
} from 'modules/user/types';
import { toFormData } from 'utils/toFormData';

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserInfo: build.query<IUser, void>({
      query: () => '/user-info/',
      keepUnusedDataFor: 0,
      extraOptions: {
        maxRetries: 1,
      },
      providesTags: ['user-info'],
    }),
    getUserByEmail: build.mutation<void, string>({
      query: (search: string) => `/users/?limit=1&search=${search || ''}`,
    }),
    getUsers: build.query<IPaginatedResponse<IUser>, IGetUsersParams>({
      query: ({ limit, offset, search }) =>
        `/users/?limit=${limit}&offset=${offset}&search=${search || ''}`,
      providesTags: ['users'],
    }),
    getUpgradePlanUrl: build.query<{ url: string }, void>({
      query: () => '/upgrade-now-url/',
    }),
    deleteUser: build.mutation<void, string>({
      query: (id) => ({
        url: `/users/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['users'],
    }),
    updateProfile: build.mutation<IUserProfileMutation, IUserProfileMutation>({
      query: (payload) => ({
        url: `/user/me/`,
        method: 'PATCH',
        body: payload.avatar ? toFormData(payload) : payload,
      }),
      invalidatesTags: ['user-info'],
    }),
    getUserGroups: build.query<IUserGroups, void>({
      query: () => '/user/groups/',
      keepUnusedDataFor: 0,
      providesTags: ['user-groups'],
    }),
    getUserProfileNotifications: build.query<IUserProfileNotifications, void>({
      query: () => 'user/notification-settings/',
      keepUnusedDataFor: 0,
      providesTags: ['user-notification-settings'],
    }),
    updateUserProfileNotifications: build.mutation<
      IUserProfileNotifications,
      IUserProfileNotifications
    >({
      query: (payload: object) => ({
        url: '/user/notification-settings/',
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['user-notification-settings'],
    }),
    updateMemberRole: build.mutation<IUserMemberRoleMutation, IUserMemberRoleMutation>({
      query: ({ id, ...payload }) => ({
        url: `users/${id}/update-role/`,
        method: 'POST',
        body: payload,
        invalidatesTags: ['users'],
      }),
    }),
    getUserPreferences: build.query<IUserPreferences, void>({
      query: () => '/user/preferences/',
    }),
    updateUserPreferences: build.mutation<IUserPreferencesResponse, IUserPreferencesMutation>({
      query: (payload) => ({
        url: `/user/preferences/`,
        method: 'PUT',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetUserInfoQuery,
  useLazyGetUserInfoQuery,
  useGetUsersQuery,
  useDeleteUserMutation,
  useUpdateProfileMutation,
  useGetUserGroupsQuery,
  useGetUserProfileNotificationsQuery,
  useUpdateUserProfileNotificationsMutation,
  useUpdateMemberRoleMutation,
  useLazyGetUpgradePlanUrlQuery,
  useGetUserByEmailMutation,
  useGetUserPreferencesQuery,
  useUpdateUserPreferencesMutation,
} = userApi;
