import { api } from 'app/api';
import { IPaginatedResponse } from 'types';
import {
  ICreateThreadMutation,
  IRelatedThread,
  ISearchRelatedThread,
  ISearchThreadsParams,
} from './types';

export const relatedThreads = api.injectEndpoints({
  endpoints: (build) => ({
    searchRelatedThreads: build.query<
      IPaginatedResponse<ISearchRelatedThread>,
      ISearchThreadsParams
    >({
      query: ({ id, name, relatedThreadId, limit, offset }) => ({
        // eslint-disable-next-line max-len
        url: `/threads/${id}/related-threads-choices/`,
        params: {
          limit,
          offset,
          search: name,
          id: relatedThreadId,
        },
      }),
      providesTags: ['searchRelatedThreads'],
    }),
    relatedThreads: build.query<IPaginatedResponse<IRelatedThread>, string>({
      query: (id) => ({
        url: `/threads/${id}/related-threads/`,
        params: {
          limit: Number.MAX_SAFE_INTEGER,
          offset: 0,
        },
      }),
      providesTags: ['relatedThreads'],
    }),
    createRelatedThread: build.mutation<void, { payload: ICreateThreadMutation; threadId: string }>(
      {
        query: ({ threadId, payload }) => ({
          url: `/threads/${threadId}/related-threads/`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['relatedThreads', 'searchRelatedThreads'],
      }
    ),
    removeRelatedThread: build.mutation<void, { threadId: string; relatedThreadId: number }>({
      query: ({ relatedThreadId, threadId }) => ({
        url: `/threads/${threadId}/related-threads/${relatedThreadId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['relatedThreads', 'searchRelatedThreads'],
    }),
  }),
});

export const {
  useSearchRelatedThreadsQuery,
  useRelatedThreadsQuery,
  useCreateRelatedThreadMutation,
  useRemoveRelatedThreadMutation,
} = relatedThreads;
