import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export const initialState = {
  showSuggestions: false,
};

const copilotSlice = createSlice({
  name: 'copilot',
  initialState,
  reducers: {
    setShowSuggestions: (state, { payload }) => ({
      ...state,
      showSuggestions: payload,
    }),
  },
});

export const selectShowSuggestions = (state: RootState) => state.suggestions.showSuggestions;

export const { setShowSuggestions } = copilotSlice.actions;

export default copilotSlice.reducer;
