import { api } from 'app/api';
import { IId, IPaginatedResponse } from 'types';
import { toFormData } from 'utils/toFormData';
import {
  ICreateReferenceMutation,
  ICreateThreadMutation,
  ICreateThreadResponse,
  IEditReferenceMutation,
  IGanttResult,
  IGetThreadsParams,
  IReferences,
  ISearchThreadsParams,
  ISearchThreadsResult,
  IStatusRequest,
  ITagsResponse,
  IThreadsResult,
  IUpdateThreadMutation,
  TStatusResponse,
} from './types';

interface IMeta {
  request: Request;
  response: Response;
}

export const threadsListApi = api.injectEndpoints({
  endpoints: (build) => ({
    getThreadsList: build.query<IPaginatedResponse<IThreadsResult>, IGetThreadsParams>({
      query: ({ url, ...args }) => ({
        url,
        params: { ...args },
      }),
      providesTags: ['threadList'],
    }),

    getGantt: build.query<IPaginatedResponse<IGanttResult>, IGetThreadsParams>({
      query: ({ url, ...args }) => ({
        url,
        params: { ...args },
      }),
    }),

    createThread: build.mutation<ICreateThreadResponse, ICreateThreadMutation>({
      query: (payload) => ({
        url: `/threads/`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['threadList', 'statistic'],
    }),

    deleteThread: build.mutation<void, string>({
      query: (id) => ({
        url: `/threads/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['threadList', 'statistic'],
    }),

    updateThread: build.mutation<ICreateThreadMutation, IUpdateThreadMutation>({
      query: ({ id, ...payload }) => ({
        url: `/threads/${id}/`,
        method: 'PATCH',
        body: { ...payload },
      }),
      invalidatesTags: ['threadList', 'statistic'],
    }),

    // useGetThreadQuery references this
    getThread: build.query<IThreadsResult, IId>({
      query: ({ id }) => `/threads/${id}/user-view/`,
      providesTags: (arg) => [
        { type: 'threadList', id: arg ? JSON.stringify(arg) : '0' },
        'threadList',
      ],
    }),

    getSearchThreads: build.query<IPaginatedResponse<ISearchThreadsResult>, ISearchThreadsParams>({
      query: (args) => ({
        url: '/threads-search/',
        params: { ...args },
      }),
    }),

    getThreadTags: build.query({
      query: () => '/threads/tags-list/',
      transformResponse(response: ITagsResponse) {
        return response.tags;
      },
    }),

    createReference: build.mutation<ICreateReferenceMutation, ICreateReferenceMutation>({
      query: (payload) => ({
        url: '/references/',
        method: 'POST',
        body: payload.file !== null ? toFormData(payload) : payload,
      }),
      invalidatesTags: ['references'],
    }),

    getReference: build.query<IReferences, number>({
      query: (id) => `/references/${id}/`,
      providesTags: ['references'],
    }),

    references: build.query<
      IPaginatedResponse<IReferences>,
      { id: string | undefined; limit: number }
    >({
      query: ({ id, limit = 10 }) => `threads/${id}/references/?limit=${limit}`,
      providesTags: ['references'],
    }),

    updateReference: build.mutation<IEditReferenceMutation, IEditReferenceMutation>({
      query: (payload) => ({
        url: `/references/${payload.id}/`,
        method: 'PATCH',
        body: !payload.file ? payload : toFormData(payload),
      }),
      invalidatesTags: ['references'],
    }),

    deleteReference: build.mutation<void, string>({
      query: (id) => ({
        url: `/references/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['references'],
    }),

    getContributorsThreads: build.query<IPaginatedResponse<IThreadsResult>, IGetThreadsParams>({
      query: ({ url, ...args }) => ({
        url,
        params: { ...args },
      }),
    }),
    getViewabilityOfThreads: build.query<{ guestsCanViewPublicThreads: boolean }, void>({
      query: () => '/site-settings/',
      providesTags: ['threadViewability'],
    }),
    updateViewabilityOfThreads: build.mutation<void, { guestsCanViewPublicThreads: boolean }>({
      query: (payload) => ({
        url: '/site-settings/',
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['threadViewability'],
    }),
    getStatistic: build.query<TStatusResponse, IStatusRequest>({
      query: ({ dateFrom, type }) => ({
        url: `/threads/statistic/?type=${type}&updated_at__gte=${dateFrom}`,
      }),
      providesTags: ['statistic'],
      transformResponse: (response) => {
        return response as TStatusResponse;
      },
    }),
  }),
});

export const {
  useGetThreadsListQuery,
  useGetGanttQuery,
  useGetThreadQuery,
  useCreateThreadMutation,
  useUpdateThreadMutation,
  useDeleteThreadMutation,
  useGetThreadTagsQuery,
  useCreateReferenceMutation,
  useUpdateReferenceMutation,
  useDeleteReferenceMutation,
  useGetContributorsThreadsQuery,
  useGetStatisticQuery,
  useGetSearchThreadsQuery,
  useGetReferenceQuery,
  useReferencesQuery,
  useGetViewabilityOfThreadsQuery,
  useUpdateViewabilityOfThreadsMutation,
} = threadsListApi;
