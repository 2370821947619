/**
 * App feature flags.
 */
export enum EFeatureFlags {
  COPILOT = 'copilot',
  THREADS_3D = 'threads-3d',
  UI_LIBRARY = 'ui-library',
  NAUTILUS_INTEGRATION = 'nautilus-integration',
}

export const FEATURE_FLAGS = [
  EFeatureFlags.COPILOT,
  EFeatureFlags.THREADS_3D,
  EFeatureFlags.UI_LIBRARY,
  EFeatureFlags.NAUTILUS_INTEGRATION,
];
