import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'app/hooks';
import { useUserWalkthrough } from 'hooks/useUserWalkthrough';
import { selectUserWalkthroughs } from 'modules/userWalkthrough/userWalkthroughSlice';
import { Button, Title } from 'ui';
import { EPosition } from 'modules/userWalkthrough/types';
import colors from 'theme/colors';
import { EUserWalkthroughs } from 'modules/userWalkthrough/constants';
import { useWindowSize } from 'hooks/useWindowSize';
import devices, { mediaSize } from 'theme/devices';
import { waitForElement } from 'utils/DOM';
import { DarkOverlay, UserWalkthroughBox, UserWalkthroughDialog } from '../Layout';

interface IProps {
  id?: string;
}

/**
 * User walk-through for onboarding first-time users.
 * Walk-through for user thread details.
 */
const ThreadDetailsUserWalkthrough = ({ id }: IProps) => {
  const { markWalkthroughAsCompleted } = useUserWalkthrough(EUserWalkthroughs.THREAD_DETAILS);
  const { width, height } = useWindowSize();
  const isViewportWidthTabletOrBelow = width <= mediaSize.tablet;

  const commentsBtn = document.getElementById('chat-filter-comments');
  const contextFilterDropdown = document.getElementById('react-select');
  const addThreadMemberBtn = document.getElementById('add-thread-member-btn');
  const [copilotLightbulb, setCopilotLightbulb] = useState<HTMLElement | null>(null);

  const initializeDOMElements = async () => {
    // Wait for copilot-lightbulb as it loads late.
    setTimeout(async () => {
      const result = (await waitForElement('copilot-lightbulb')) as HTMLElement;
      setCopilotLightbulb(result);
    }, 1000);
  };

  useEffect(() => {
    initializeDOMElements();
  }, []);

  return (
    <>
      <DarkOverlay height={height} id="dark-overlay" />
      <UserWalkthroughDialog.Body id="user-walkthrough-dismissable-dialog">
        <Title weight="700" heading="h6">
          First Thread
        </Title>
        Congratulations, you’re in your first thread!
        <UserWalkthroughDialog.Footer id="user-walkthrough-dialog-footer">
          <Button onClick={markWalkthroughAsCompleted} color={colors.orange2}>
            Got it!
          </Button>
        </UserWalkthroughDialog.Footer>
      </UserWalkthroughDialog.Body>
      <UserWalkthroughBox
        topOffset={-50}
        leftOffset={-20}
        id="user-walkthrough-box"
        position={EPosition.RIGHT}
        anchoredComponent={commentsBtn}
        width={200}
      >
        <Title weight="700" heading="h6">
          Filters
        </Title>
        Filter the thread’s content to view the most relevant information quickly.
      </UserWalkthroughBox>
      <UserWalkthroughBox
        topOffset={-50}
        leftOffset={-20}
        id="user-walkthrough-box"
        position={isViewportWidthTabletOrBelow ? EPosition.LEFT : EPosition.RIGHT}
        anchoredComponent={contextFilterDropdown}
        width={200}
      >
        <Title weight="700" heading="h6">
          Resources
        </Title>
        Filter between events, contributors, references, resources and related Threads. To add new
        ones, just click the ‘+’ button!
      </UserWalkthroughBox>
      <UserWalkthroughBox
        topOffset={-50}
        leftOffset={-10}
        id="user-walkthrough-box"
        position={EPosition.RIGHT}
        anchoredComponent={addThreadMemberBtn}
        width={200}
      >
        <Title weight="700" heading="h6">
          Contributors
        </Title>
        Collaborate with other thread contributors.
      </UserWalkthroughBox>
      {copilotLightbulb && (
        <UserWalkthroughBox
          leftOffset={20}
          topOffset={-60}
          id="user-walkthrough-box"
          position={isViewportWidthTabletOrBelow ? EPosition.LEFT : EPosition.RIGHT}
          anchoredComponent={copilotLightbulb}
          width={isViewportWidthTabletOrBelow ? 200 : 350}
        >
          <Title weight="700" heading="h6">
            Suggestions
          </Title>
          Click the glowing light bulb to view event suggestions based on your conversation.
        </UserWalkthroughBox>
      )}
    </>
  );
};

export default ThreadDetailsUserWalkthrough;
