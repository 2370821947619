import { useAppSelector } from 'app/hooks';
import { useGetUserInfoQuery } from 'modules/user/userApi';
import { selectUser } from './userSlice';

/**
 * WARNING: Do NOT use in unauthenticated pages
 *
 * We determine whether a user is logged in or not by checking the
 * result of the user info hook. If this returns a 403, this means the user
 * is unauthenticated.
 *
 * However, RTKQuery works in the following way:
 * 1. A hook will send a request if the cache is empty. The cache is not populated
 *    until a request returns successfully.
 * 2. If a user isn't signed in, the cache will never populate. This means every
 *    time the hook re-renders, another fetch is made.
 * 3. The isLoading param is true whenever the hook is fetching if the cache is not
 *    populated.
 * 4. If this hook is used in an unauthenticated component, the hook will refetch. This
 *    means the top-level hook will re-render and return isLoading = true.
 * 5. This will cause an infinite loop as each hook triggers the other to re-render
 *
 * Until we come up with a better solution this will have to do
 */
export const useAuth = () => {
  const { data: user, isLoading } = useGetUserInfoQuery();

  return {
    isAuthenticated: !!user,
    isLoading,
    user,
  };
};
