import React, { FC, useRef, useState, useMemo } from 'react';
import { useGetSearchThreadsQuery } from 'modules/threads/threadsApi';
import { useOnClickOutside, useDebounce } from 'hooks';
import { isValidUrl, getThreadIdFromUrl } from 'utils/helpers';
import { SEARCH_THREADS_PARAMS } from 'modules/threads/constants';
import { Icon } from 'ui';
import SearchResults from 'modules/header/components/SearchResults';
import * as Layout from 'modules/header/components/Layout';
import colors from 'theme/colors';

interface ISearchProps {
  name?: string;
  type?: string;
  placeholder?: string;
}

export const Search: FC<ISearchProps> = ({ name, type, placeholder, ...props }) => {
  const [searchValue, setSearchValue] = useState<string>(SEARCH_THREADS_PARAMS.SEARCH);
  const [searchThreadById, setSearchThreadById] = useState(SEARCH_THREADS_PARAMS.ID);
  const [showMenu, setShowMenu] = useState<boolean>(true);

  const searchContainer = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const avoidRequest = useMemo(
    () =>
      searchValue === SEARCH_THREADS_PARAMS.SEARCH && searchThreadById === SEARCH_THREADS_PARAMS.ID,
    [searchValue, searchThreadById]
  );

  const { data: threads } = useGetSearchThreadsQuery(
    {
      limit: SEARCH_THREADS_PARAMS.LIMIT,
      offset: SEARCH_THREADS_PARAMS.OFFSET,
      id: searchThreadById,
      search: debouncedSearchValue,
    },
    { skip: avoidRequest }
  );

  const searchResults = threads?.results;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const isUrl = isValidUrl(value);
    const search = isUrl ? '' : value.trim().toLowerCase();
    const threadId = isUrl ? getThreadIdFromUrl(value) : '';

    setSearchValue(search);
    setSearchThreadById(threadId);
    setShowMenu(true);
  };

  const handleInputFocus = () => {
    setShowMenu(true);
  };

  const setActiveFocus = () => {
    inputRef.current?.focus();
  };

  useOnClickOutside(searchContainer, () => setShowMenu(false));

  return (
    <Layout.SearchBar ref={searchContainer}>
      <Layout.SearchIcon aria-hidden="true" onClick={setActiveFocus}>
        <Icon fill={colors.gray1} icon="SearchIcon" size="medium" />
      </Layout.SearchIcon>
      <Layout.Input
        autoComplete="off"
        ref={inputRef}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={handleInputFocus}
        maxLength={100}
        {...props}
      />

      {showMenu && searchResults && (
        <SearchResults searchResults={searchResults} searchValue={debouncedSearchValue} />
      )}
    </Layout.SearchBar>
  );
};

export default Search;
