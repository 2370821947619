import { EVENTS_OPTIONS } from 'constants/events';
import { ECopilotSuggestionTypes, ICopilotSuggestion } from 'modules/copilot/types';
import { getModifiedEventFields } from 'modules/copilot/utils';
import { useGetEventsQuery } from 'modules/events/eventsApi';
import { IEventsResult } from 'modules/events/types';
import React from 'react';
import { getOption } from 'utils/helpers';
import { CopilotHighlightedText } from '../CopilotSuggestion/Layout';

interface IProps {
  copilotSuggestion: ICopilotSuggestion;
  threadId: string;
}

const CopilotSuggestionMessageContent: React.FC<IProps> = ({ copilotSuggestion, threadId }) => {
  const { description, type, name, eventUuid, suggestionType: suggestion_type } = copilotSuggestion;

  const { data } = useGetEventsQuery(
    {
      id: threadId,
      eventId: eventUuid || '',
    },
    { skip: eventUuid === undefined }
  );

  const event = data?.results[0];

  switch (suggestion_type) {
    case ECopilotSuggestionTypes.MODIFY: {
      const modifiedEventFields = getModifiedEventFields(event, copilotSuggestion);

      const modifiedFields = modifiedEventFields.map((field: string) => {
        const currentValue = event?.[field as keyof IEventsResult] as string;
        const newValue = copilotSuggestion[field as keyof ICopilotSuggestion] as string;

        return {
          field,
          currentValue,
          newValue,
        };
      });

      return (
        <>
          Do you want to update{' '}
          <>
            <CopilotHighlightedText fontStyle="italic">{event?.name}</CopilotHighlightedText>
            &apos;s
          </>
          {modifiedFields.length > 0 && (
            <>
              {' '}
              {modifiedFields.map((modifiedField, index) => {
                const commaAffix = index > 0 && index < modifiedFields.length ? ', ' : '';
                return (
                  <>
                    {commaAffix}
                    {modifiedField.field} from{' '}
                    <CopilotHighlightedText fontStyle="italic">
                      &ldquo;{modifiedField.currentValue}&rdquo;
                    </CopilotHighlightedText>{' '}
                    to{' '}
                    <CopilotHighlightedText fontStyle="italic">
                      &ldquo;{modifiedField.newValue}&rdquo;
                    </CopilotHighlightedText>
                  </>
                );
              })}
            </>
          )}
          ?
        </>
      );
    }
    case ECopilotSuggestionTypes.CREATE:
      return (
        <>
          Do you want to create a new{' '}
          <CopilotHighlightedText fontStyle="italic">
            {getOption(EVENTS_OPTIONS, type)?.optionLabel}
          </CopilotHighlightedText>{' '}
          event called{' '}
          <CopilotHighlightedText fontStyle="italic">&ldquo;{name}&rdquo;</CopilotHighlightedText>{' '}
          with description{' '}
          <CopilotHighlightedText fontStyle="italic">
            &ldquo;{description}&rdquo;
          </CopilotHighlightedText>{' '}
          ?
        </>
      );
    default:
  }
  return <p>Unexpected Action.</p>;
};

export default CopilotSuggestionMessageContent;
