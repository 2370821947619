import React from 'react';
import * as yup from 'yup';
import colors from 'theme/colors';
import { Stack, Group, TextInput, TextArea, Button } from 'ui';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from 'utils/validation';
import { useModal } from 'modules/modals/ModalProvider';
import { handleApiCall } from 'utils/helpers';
import {
  showErrorMessage,
  showSuccessMessage,
  showUncaughtErrorMessage,
} from 'modules/alert/utils';
import { NOTIFICATION } from 'modules/alert/constants';
import { IResourcesResponse } from 'modules/resources/types';
import { useCreateResourceMutation, useEditResourceMutation } from 'modules/resources/resourcesApi';

const defaultValues = {
  name: '',
  description: '',
  unit: '',
  amount: '',
  thread: '',
  location: '',
};

const validation = yup.object({
  name: validationSchema.stringRequired(),
  description: validationSchema.textArea(),
  unit: validationSchema.stringRequired(),
  amount: validationSchema.amount(),
  location: validationSchema.string(),
});

interface IProps {
  threadId: number;
  initialValue: IResourcesResponse;
}

const CreateResource = ({ threadId, initialValue }: IProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialValue || defaultValues,
    resolver: yupResolver(validation),
  });
  const { close } = useModal();
  const [createResource] = useCreateResourceMutation();
  const [editResource] = useEditResourceMutation();

  const onCreate = async (values: Omit<IResourcesResponse, 'thread'>) => {
    const res = await createResource({ ...values, thread: threadId });
    handleApiCall<IResourcesResponse>(
      res,
      () => showUncaughtErrorMessage(),
      () => {
        showSuccessMessage(NOTIFICATION.RESOURCE_CREATED);
        close();
      }
    );
  };

  const onEdit = async (values: IResourcesResponse) => {
    const res = await editResource({ ...values, thread: threadId });
    handleApiCall<IResourcesResponse>(
      res,
      () => showUncaughtErrorMessage(),
      () => {
        showSuccessMessage(NOTIFICATION.RESOURCE_CREATED);
        close();
      }
    );
  };

  return (
    <form
      onSubmit={handleSubmit(initialValue ? onEdit : onCreate)}
      style={{ height: '100%' }}
      data-cy="modal-resource-form"
    >
      <Stack justify="space-between" fullHeight>
        <Stack gap="25px">
          <TextInput
            name="name"
            required
            register={register}
            label="Name"
            placeholder="Name"
            error={errors.name}
            data-cy="modal-resource-name"
          />
          <TextArea
            register={register}
            name="description"
            placeholder="Description"
            label="Description"
            resize="none"
            error={errors.description}
            data-cy="modal-resource-description"
          />
          <Group gap="10px">
            <TextInput
              register={register}
              name="amount"
              placeholder="0"
              type="text"
              label="Amount"
              required
              error={errors.amount}
              data-cy="modal-resource-amount"
            />
            <TextInput
              register={register}
              name="unit"
              placeholder="cm"
              type="text"
              label="Units"
              required
              error={errors.unit}
              data-cy="modal-resource-units"
            />
          </Group>
          <TextInput
            register={register}
            name="location"
            placeholder="Location"
            type="text"
            label="Location"
            error={errors.location}
            data-cy="modal-resource-location"
          />
        </Stack>

        <Group justify="flex-end" gap="15px">
          <Button onClick={close} color={colors.dark2} cypressAttribute="modal-resource-close-btn">
            Close
          </Button>
          <Button type="submit" cypressAttribute="modal-resource-save-btn">
            Save
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default CreateResource;
