import { useAppSelector } from 'app/hooks';
import _has from 'lodash/has';
import { setModalState } from 'modules/app/appSlice';
import { selectUserInfo } from 'modules/auth/userSlice';
import React, { useState } from 'react';
import { FaArrowRight, FaChevronDown, FaChevronUp } from 'react-icons/fa6';
import { useDispatch } from 'react-redux';
import styled, { CSSProperties } from 'styled-components';
import colors from 'theme/colors';
import TextButton from 'ui/textButton';

interface IProps {
  errorData?: unknown;
}

const GenericErrorToasterWrapper = styled.div<{
  backgroundColor?: string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor || colors.error_red};
  padding: 10px;
  margin: 0;
  color: white;
`;

const ErrorParagraphText = styled.p<{
  fontFamily?: CSSProperties['fontFamily'];
}>`
  margin-top: 5px;
  font-family: ${({ fontFamily }) => fontFamily || 'inherit'};
  font-size: 12px;
`;

const ButtonWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
`;

/**
 * A custom toaster that intends to provide more context and insight
 * when uncaught system errors occur.
 */
const GenericErrorToaster: React.FC<IProps> = ({ errorData }) => {
  const dispatch = useDispatch();
  const userInfo = useAppSelector(selectUserInfo);

  const [showErrorDetails, setShowErrorDetails] = useState(false);

  const handleReportError = () =>
    dispatch(
      setModalState({
        modal: 'reportError',
        show: true,
        context: {
          errorData,
          userInfo,
          timestamp: new Date(),
        },
      })
    );

  return (
    <GenericErrorToasterWrapper>
      <h1>Error</h1>
      <ErrorParagraphText>An unexpected exception has occurred.</ErrorParagraphText>
      <ButtonWrapper>
        <TextButton
          onClick={() => setShowErrorDetails((previous) => !previous)}
          color={colors.white}
        >
          {showErrorDetails ? 'Hide error details' : 'View error details'}{' '}
          <span style={{ marginLeft: 5 }}>
            {showErrorDetails ? <FaChevronUp /> : <FaChevronDown />}
          </span>
        </TextButton>
        <TextButton onClick={handleReportError} color={colors.white}>
          Report Error <FaArrowRight style={{ marginLeft: 5 }} />
        </TextButton>
      </ButtonWrapper>
      {showErrorDetails && (
        <div>
          <ErrorParagraphText fontFamily="monospace">
            {(_has(errorData, 'message')
              ? JSON.stringify((errorData as Error)?.message)
              : JSON.stringify(errorData)) || "We're sorry, but no raw error data could be found."}
          </ErrorParagraphText>
        </div>
      )}
    </GenericErrorToasterWrapper>
  );
};

export default GenericErrorToaster;
