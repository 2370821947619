import { ReactComponent as GoogleDriveIcon } from 'assets/icons/google-drive.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/success-icon.svg';
import { ReactComponent as SyncIcon } from 'assets/icons/sync-icon.svg';
import React from 'react';
import colors from 'theme/colors';
import { Group, Stack, Text } from 'ui';
import * as Layout from './Layout';

export const IntegrationSuccessful: React.FC = () => {
  return (
    <Layout.SuccessIntegrationsMenuContainer gap="20px" data-cy="integration-successful-container">
      <Group
        gap="20px"
        align="center"
        justify="center"
        fluid
        data-cy="integration-successful-icon-group"
      >
        <GoogleDriveIcon width={88} height={75} />
        <SyncIcon width={44} height={44} />
        <SuccessIcon width={100} height={100} />
      </Group>
      <Stack gap="12px">
        <Text
          weight="600"
          align="center"
          size="lg"
          cypressAttribute="integration-successful-text-1"
        >
          Integrated successfully
        </Text>
        <Text
          weight="400"
          align="left"
          size="sm"
          style={{ lineHeight: 1.3, letterSpacing: 0.4 }}
          cypressAttribute="integration-successful-text-2"
        >
          Your files are now integrated with Google Workspace. For more information on usage, please
          see our{' '}
          <a
            href="https://authentise.zendesk.com/hc/en-us/sections/19807393261716-Integrations"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none', color: colors.brandSecondaryLight }}
            data-cy="integration-successful-text-link"
          >
            Help Section
          </a>
          .
        </Text>
      </Stack>
    </Layout.SuccessIntegrationsMenuContainer>
  );
};
