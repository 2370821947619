import { useAppDispatch } from 'app/hooks';
import { ThreadTable, ThreadsFilter } from 'modules/threads/components';
import { FilterButton } from 'modules/threads/components/filter/Layout';
import { resetFilter } from 'modules/threads/filterSlice';
import { EDefaultSortOption, IThreadView } from 'modules/threads/types';
import React, { useEffect, useState } from 'react';
import colors from 'theme/colors';
import { EThreadParams } from 'types';
import { Button, Group, Icon, Spinner, Stack, Text } from 'ui';
import { useThreadsList } from './hooks';

const DEFAULT_SORT_DSC = EDefaultSortOption.UPDATED_AT_DSC;

const TableView: React.FC<IThreadView> = ({ url, title, cypressAttribute, isAllThreads }) => {
  const [ordering, setOrdering] = useState<EThreadParams | string>(DEFAULT_SORT_DSC);
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { data, isLoading, isFetching, onFetchMore } = useThreadsList({
    url,
    ordering,
    shouldSkipRequest: false,
    limit: 50,
    resultsPerRequestLimit: 20,
  });

  const threadsList = data?.results;
  const totalCount = data?.count;

  const toggleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  useEffect(() => {
    return () => {
      dispatch(resetFilter());
    };
  }, []);

  if (isLoading) {
    return (
      <Stack align="center" fullHeight style={{ margin: '5rem auto' }}>
        <Spinner />
      </Stack>
    );
  }

  return (
    <>
      <Group
        fluid
        align="center"
        justify="space-between"
        gap="25px"
        style={{ padding: '16px 0' }}
        data-cy={cypressAttribute && `${cypressAttribute}-header`}
      >
        <Text cypressAttribute={cypressAttribute && `${cypressAttribute}-header-text`}>
          {totalCount} {title}
        </Text>

        <div>
          <FilterButton variant="plain" onClick={toggleFilter}>
            <Icon icon="FilterIcon" size="normal" stroke={colors.gray2} />
          </FilterButton>
          {showFilter && <ThreadsFilter toggleFilter={toggleFilter} />}
        </div>
      </Group>

      {threadsList?.length ? (
        <>
          <ThreadTable
            sortOption={ordering}
            list={threadsList}
            isAllThreads={isAllThreads}
            setSortOption={setOrdering}
            isLoading={isFetching}
            cypressAttribute="thread-table"
          />
          {totalCount && threadsList.length <= totalCount && (
            <div
              id="infinite-scroll-actions-container"
              className="mt-2 flex flex-col items-center gap-3"
            >
              {!isFetching && (
                <Text color={colors.gray1} size="xs">
                  You have viewed {threadsList.length} of {totalCount} results.
                </Text>
              )}
              <Button
                disabled={isFetching}
                color={colors.dark2}
                variant="filled"
                onClick={onFetchMore}
              >
                Load more
              </Button>
            </div>
          )}
        </>
      ) : (
        <Stack align="center" justify="center" style={{ height: '370px' }} data-cy="no-threads">
          <Text size="lg">No Threads...</Text>
        </Stack>
      )}
    </>
  );
};

export default TableView;
