import {
  Action,
  Middleware,
  PreloadedState,
  ThunkAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import appReducer from 'modules/app/appSlice';
import userReducer from 'modules/auth/userSlice';
import chatReducer from 'modules/chat/chatSlice';
import copilotReducer from 'modules/copilot/copilotSlice';
import notificationReducer from 'modules/notification/slice';
import { playableReducer } from 'modules/playable';
import streamReducer from 'modules/stream/streamSlice';
import subscriptionPlansReducer from 'modules/subscriptionsPlans/subscriptionsPlansSlice';
import filterReducer from 'modules/threads/filterSlice';
import threadsReducer from 'modules/threads/threadsSlice';
import usersReducer from 'modules/user/userSlice';
import userWalkthroughReducer from 'modules/userWalkthrough/userWalkthroughSlice';
import { saveReduxStateToLocalStorage } from 'utils/localStorage';
import { api } from './api';

const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  [api.reducerPath]: api.reducer,
  threads: threadsReducer,
  subscriptionPlans: subscriptionPlansReducer,
  chat: chatReducer,
  stream: streamReducer,
  threadsFilter: filterReducer,
  notifications: notificationReducer,
  users: usersReducer,
  playable: playableReducer,
  suggestions: copilotReducer,
  userWalkthrough: userWalkthroughReducer,
});

/**
 * Middleware to determine whether to write the redux state to localStorage.
 */
const writeToLocalStorageMiddleware: Middleware = (store) => (next) => (action) => {
  const previousState = store.getState();
  const result = next(action); // Calling next(...) ensures store.getState() returns future values.
  const nextState = store.getState();

  if (previousState.app.debugMode !== nextState.app.debugMode) {
    saveReduxStateToLocalStorage(nextState);
  }

  return result;
};

export const createStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false })
        .concat(api.middleware)
        .concat(writeToLocalStorageMiddleware),
  });

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof createStore>;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
