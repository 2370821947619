import React from 'react';
import TermsOfService from 'pages/termsOfService';
import { useAuth } from 'modules/auth/useAuth';

interface IProps {
  children: React.ReactElement;
}

export const TermsOfServiceProvider: React.FC<IProps> = ({ children }) => {
  const { user } = useAuth();
  const hasAcceptedTermsAndConditions = !!user?.termsAndConditions;

  if (!hasAcceptedTermsAndConditions) {
    return <TermsOfService />;
  }

  return children;
};
