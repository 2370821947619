// @ts-nocheck
import { DEVELOPMENT, ENV, PRODUCTION } from 'constants/env';
import React, { useCallback, useEffect, useMemo } from 'react';

interface IProps {
  children: React.ReactNode | React.ReactNode[];
}

/**
 * Wrapper component for Microsoft Clarity integration.
 */
const MicrosoftClarityProvider = ({ children }: IProps) => {
  useEffect(() => {
    if (ENV.NODE_ENV === PRODUCTION) {
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            // eslint-disable-next-line prefer-rest-params
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = `https://www.clarity.ms/tag/${i}`;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, 'clarity', 'script', ENV.REACT_APP_MICROSOFT_CLARITY_APP_KEY);
    }
  }, [ENV.REACT_APP_MICROSOFT_CLARITY_APP_KEY]);

  return <div>{children}</div>;
};

export default MicrosoftClarityProvider;
