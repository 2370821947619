import React, { KeyboardEvent, forwardRef, MutableRefObject, ForwardedRef } from 'react';
import styled from 'styled-components';
import makeAnimated from 'react-select/animated';
import CreatableSelect from 'react-select/creatable';
import Select, { FormatOptionLabelMeta, OnChangeValue } from 'react-select';
import { Stack, Text } from 'ui';
import colors from 'theme/colors';
import { TReactSelectOption } from 'types/index';
import { selectStyles } from './styles';
import { IReactSelected } from './types';

const SelectLabel = styled.label`
  span {
    margin-left: 5px;
    color: ${colors.red};
  }
`;

const animatedComponents = makeAnimated();

const customOptionLabel: (
  data: unknown,
  meta: FormatOptionLabelMeta<unknown>
) => React.ReactNode = (data, meta) => {
  const selectOptionData = data as TReactSelectOption;

  if (typeof selectOptionData?.label === 'string') {
    return (
      <div>
        <strong>{selectOptionData?.label?.split('|')[0]}</strong>
      </div>
    );
  }

  return selectOptionData.label;
};

const ReactSelect = forwardRef(
  (
    {
      id,
      label,
      error,
      placeholder,
      options,
      value,
      defaultValue,
      menuIsOpen,
      isSearchable,
      autoFocus,
      isLoading,
      required,
      isMulti = true,
      isCreatable = true,
      onChange,
      onKeyDown,
      onInputChange,
      onMenuOpen,
      disabled = false,
      menuPortalTarget = document.body,
      isValidNewOption,
    }: IReactSelected,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: ForwardedRef<HTMLDivElement | null> | MutableRefObject<HTMLInputElement> | any
  ) => {
    const handleChange = (
      option: OnChangeValue<TReactSelectOption | TReactSelectOption[] | unknown, boolean>
    ) => {
      onChange(option);
    };

    const handleKeyDown = (e: KeyboardEvent) => onKeyDown && onKeyDown(e);
    const handleInputChange = (val: string) => onInputChange && onInputChange(val);
    const handleMenuOpen = () => onMenuOpen && onMenuOpen();

    return (
      <Stack gap="8px">
        {label && (
          <SelectLabel htmlFor={id}>
            <Text>
              {label}
              {required && <span>*</span>}
            </Text>
          </SelectLabel>
        )}
        {isCreatable ? (
          <CreatableSelect
            id="react-select"
            ref={ref}
            isMulti={isMulti}
            inputId={id}
            isSearchable={isSearchable}
            autoFocus={autoFocus}
            isLoading={isLoading}
            value={value}
            defaultValue={defaultValue}
            options={options}
            required={required}
            styles={selectStyles}
            menuPortalTarget={menuPortalTarget}
            menuIsOpen={menuIsOpen}
            closeMenuOnSelect={!isMulti}
            placeholder={placeholder}
            formatOptionLabel={customOptionLabel}
            noOptionsMessage={() => null}
            components={animatedComponents}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            classNamePrefix="react-select"
            isDisabled={disabled}
            onInputChange={handleInputChange}
            isValidNewOption={isValidNewOption}
          />
        ) : (
          <Select
            id="react-select"
            ref={ref}
            isMulti={isMulti}
            inputId={id}
            isSearchable={isSearchable}
            autoFocus={autoFocus}
            isLoading={isLoading}
            value={value}
            defaultValue={defaultValue}
            options={options}
            styles={selectStyles}
            menuIsOpen={menuIsOpen}
            closeMenuOnSelect={!isMulti}
            placeholder={placeholder}
            menuPortalTarget={menuPortalTarget}
            noOptionsMessage={() => null}
            components={animatedComponents}
            formatOptionLabel={customOptionLabel}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onInputChange={handleInputChange}
            onMenuOpen={handleMenuOpen}
            classNamePrefix="react-select"
            isDisabled={disabled}
          />
        )}
        {error && (
          <Text size="xs" color="red">
            {error.message}
          </Text>
        )}
      </Stack>
    );
  }
);

export default ReactSelect;
