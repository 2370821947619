import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Button } from 'components/ui/button';
import routes from 'constants/routes';
import { useFeatureFlags } from 'hooks';
import { Container } from 'layout/App';
import { CircleHelp, Plus } from 'lucide-react';
import { setModalState } from 'modules/app/appSlice';
import { selectUserInfo, selectUserRole } from 'modules/auth/userSlice';
import * as Layout from 'modules/header/components/Layout';
import { Search } from 'modules/header/components/Search';
import { UserMenu } from 'modules/header/components/UserMenu';
import { useModal } from 'modules/modals/ModalProvider';
import {
  isCurrentCustomPaidSubscriptionPlan,
  selectSubscriptionName,
} from 'modules/subscriptionsPlans/subscriptionsPlansSlice';
import { handleOpenCreateThreadModal } from 'modules/threads/utils';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import colors from 'theme/colors';
import { Group, TagBadge, Text } from 'ui';
import { isAdmin, isSiteUser, truncateRawString } from 'utils/helpers';
import NotificationToggle from '../notification';
import { Banner } from './components/Banner';
import ProfileMenu from './components/ProfileMenu';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import Logo from '!file-loader!assets/icons/Logo_header.svg';

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();

  const { open } = useModal();

  const { firstName, lastName, isImpersonate, isSiteOwner } = useAppSelector(selectUserInfo);
  const userRole = useAppSelector(selectUserRole);
  const currentSubscriptionName = useAppSelector(selectSubscriptionName);
  const isCustomPaidSubscription = useAppSelector(isCurrentCustomPaidSubscriptionPlan);
  const { features } = useFeatureFlags();

  const handleUpgradeClick = () => {
    navigation(`${routes.subscriptionsPlans}/dashboard`);
  };

  const handleShowSupportModal = () => {
    dispatch(
      setModalState({
        modal: 'customerSupport',
        show: true,
      })
    );
  };

  return (
    <Layout.Header id="app-header">
      <Banner />
      <Container>
        <Group align="center" justify="space-between" gap="20px" wrap="wrap">
          <Group align="center" gap="20px" style={{ flexBasis: '480px' }}>
            <NavLink to="/">
              <img src={Logo} alt="logo" width="48" height="48" />
            </NavLink>
            <Search
              data-cy="header-search"
              name="search"
              placeholder="Search..."
              aria-label="search"
            />
            {(isAdmin(userRole) || isSiteUser(userRole)) && (
              <Group align="center" gap="16px">
                <Button
                  onClick={() => handleOpenCreateThreadModal(open)}
                  data-cy="dashboard-new-thread"
                >
                  <Plus className="mr-2 h-4 w-4" />
                  New thread
                </Button>
              </Group>
            )}
          </Group>
          <div id="header-content-container" className="flex flex-1 justify-end">
            <Group align="center" gap="8px">
              <Button onClick={handleShowSupportModal} variant="ghost" size="icon">
                <CircleHelp size={20} />
              </Button>
              {isSiteOwner && currentSubscriptionName && !isCustomPaidSubscription && (
                <Layout.UpgradeButton onClick={handleUpgradeClick}>Upgrade</Layout.UpgradeButton>
              )}
              <NotificationToggle />
            </Group>
            {features['ui-library'] ? (
              <UserMenu />
            ) : (
              <Group align="center" gap="8px" className="relative">
                <Text
                  aria-labelledby="accessible-user-info"
                  size="md"
                  className="ml-5 mr-1 flex max-h-[50px] max-w-48 justify-start truncate"
                  data-cy="header-user-info"
                >
                  {isImpersonate && (
                    <TagBadge size="xs" color={colors.orange}>
                      Impersonating
                    </TagBadge>
                  )}
                  {firstName} {lastName}
                </Text>

                <ProfileMenu />
              </Group>
            )}
          </div>
        </Group>
      </Container>
    </Layout.Header>
  );
};

export default Header;
