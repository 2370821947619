import styled from 'styled-components';
import colors from 'theme/colors';
import { Group, Stack } from 'ui';

export const TabContentWrap = styled(Stack)`
  height: 100%;
  overflow-y: auto;
  gap: 25px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const NoItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  font-size: 18px;
`;

export const Form = styled.form`
  height: 100%;
  display: flex;
  gap: 25px;
  justify-content: space-between;
  flex-direction: column;
`;

export const FilePreview = styled(Group)`
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid ${colors.blue};
`;

export const ContributorForm = styled.form`
  height: 100%;
  display: flex;
  gap: 10vw;
  justify-content: space-between;
  flex-direction: column;

  .react-select__dropdown-indicator {
    display: none;
  }
  .react-select__placeholder {
    max-width: 100%;
  }

  .react-select__control {
    width: 100%;
    padding-left: 40px;
  }
`;

export const InputWrap = styled.div`
  position: relative;
`;

export const SearchWrap = styled.i`
  position: absolute;
  left: 17px;
  top: 11px;
  z-index: 1;
`;

export const AddTagsModal = styled.form`
  position: absolute;
  top: 100%;
  right: 0;
  width: 500px;
  padding: 12px;
  box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.08);
  border-radius: 6px;
  background-color: ${colors.dark2};
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
`;

export const AddReferenceIntegrationWarningContainer = styled(Group)`
  margin-top: 16px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.blue};
  background-color: ${({ theme }) => theme.colors.dark2};
`;
