import { useModal } from 'modules/modals/ModalProvider';
import ThreadCard from 'modules/relatedThreads/components/ThreadCard';
import { useRelatedThreadsQuery } from 'modules/relatedThreads/relatedThreadsApi';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Spinner, Stack } from 'ui';

const RelatedThreads: React.FC = () => {
  const { id } = useParams();
  const { open } = useModal();
  const { data, isLoading } = useRelatedThreadsQuery(id as string);
  const relatedThreads = data?.results;

  const openRelatedThreadsModal = () => {
    open({
      variant: 'fullRight',
      contentLabel: 'add related thread',
      name: 'Add Related Thread',
      modal: 'addRelatedThread',
      context: {
        threadId: id,
      },
      id: 'modal-add-related-thread',
    });
  };

  if (isLoading) {
    return (
      <Stack justify="center" align="center" fluid fullHeight>
        <Spinner />
      </Stack>
    );
  }

  if (!relatedThreads?.length) {
    return (
      <Stack align="center" justify="center" style={{ height: '50%' }}>
        <Button onClick={openRelatedThreadsModal}>Add Related Threads</Button>
      </Stack>
    );
  }
  return (
    <Stack gap="12px">
      {relatedThreads.map((thread) => (
        <ThreadCard key={thread.id} {...thread} />
      ))}
    </Stack>
  );
};

export default RelatedThreads;
