import { useAppSelector } from 'app/hooks';
import { selectUserRole } from 'modules/auth/userSlice';
import { useGetViewabilityOfThreadsQuery } from 'modules/threads/threadsApi';
import { isGuest } from 'utils/helpers';

/**
 * Handles all cases for guest viewability.
 */
export const useGuestViewability = () => {
  const userRole = useAppSelector(selectUserRole);
  const { data: guestViewability } = useGetViewabilityOfThreadsQuery(undefined, {
    skip: !isGuest(userRole),
  });

  /**
   * User is guest and guestViewability settings do not permit visibility of public threads.
   */
  const isGuestUserCannotViewPublicThreads =
    isGuest(userRole) && !guestViewability?.guestsCanViewPublicThreads;

  return {
    isGuestUserCannotViewPublicThreads,
    guestViewability,
  };
};
