import { TooltipProvider } from 'components/ui/tooltip';
import ThreadsAppErrorBoundary from 'pages/errorBoundary';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import MicrosoftClarityProvider from 'modules/app/components/MicrosoftClarityProvider';
import { useAuth } from 'modules/auth/useAuth';
import { ScreenLoader } from 'ui';
import { AuthenticatedRoutes } from './AuthenticatedRoutes';
import { UnauthenticatedRoutes } from './UnauthenticatedRoutes';

const App: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) return <ScreenLoader />;

  return (
    <MicrosoftClarityProvider>
      <ThreadsAppErrorBoundary>
        <TooltipProvider>
          <BrowserRouter>
            {isAuthenticated ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />}
          </BrowserRouter>
        </TooltipProvider>
      </ThreadsAppErrorBoundary>
    </MicrosoftClarityProvider>
  );
};

export default App;
