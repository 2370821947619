import styled, { CSSProperties } from 'styled-components';
import colors from 'theme/colors';
import { EPosition } from '../types';
import { ARROW_STYLE_MAP } from '../constants';

/**
 * Calculate element's position anchored to subsequent component.
 */
const calculateElementPosition = (
  element: HTMLElement | null,
  position: EPosition,
  boxWidth = 200
) => {
  if (!element) {
    return { top: 0, left: 0 };
  }

  const POSITION_MAP = {
    [EPosition.TOP]: {
      top: element.getBoundingClientRect().top,
      left: element.getBoundingClientRect().left,
    },
    [EPosition.LEFT]: {
      top: element.getBoundingClientRect().top,
      left: element.getBoundingClientRect().left - boxWidth,
    },
    [EPosition.RIGHT]: {
      top: element.getBoundingClientRect().top,
      left: element.getBoundingClientRect().right,
    },
    [EPosition.BOTTOM]: {
      top: element.getBoundingClientRect().bottom,
      left: element.getBoundingClientRect().left,
    },
  };

  return POSITION_MAP[position];
};

export const DarkOverlay = styled.div<{ height?: number }>`
  left: 0;
  top: 0;
  position: absolute;
  background: black;
  opacity: 0.4;
  height: ${({ height }) => `${height}px` || '100vh'};
  width: 100vw;
  z-index: 3;
`;

export const UserWalkthroughDialog = {
  Body: styled.div<{ focused?: boolean }>`
    box-shadow: 0 0 40px 0 rgba(240, 157, 35, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    padding: 20px;
    position: absolute;
    z-index: ${({ focused }) => (focused ? 999 : 10)};
    background: ${colors.orange};
    width: 100%;
    max-width: 400px;
    bottom: 60px;
    left: 60px;
    font-size: 14px;
    line-height: 1.2;
  `,
  Footer: styled.div`
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: end;
  `,
};

export const UserWalkthroughBox = styled.div<{
  focused?: boolean;
  position?: EPosition;
  anchoredComponent: HTMLElement | null;
  width: number;
  height?: number;
  topOffset?: number;
  leftOffset?: number;
}>`
  &:after {
    content: ' ';
    position: absolute;
    top: 15px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;

    ${({ position = EPosition.LEFT }) => ARROW_STYLE_MAP[position]}
  }

  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 12px;
  z-index: ${({ focused }) => (focused ? 999 : 99)};
  width: 100%;
  max-width: ${({ width }) => `${width}px`};
  background-color: ${colors.orange};
  border-radius: 6px;
  color: #fff;
  padding: 20px;
  position: absolute;
  margin: 40px;
  float: left;
  top: ${({
    anchoredComponent: focusedComponent,
    position = EPosition.LEFT,
    width,
    topOffset = 0,
  }) => `${calculateElementPosition(focusedComponent, position, width).top + topOffset}px`};
  left: ${({
    anchoredComponent: focusedComponent,
    position = EPosition.LEFT,
    width,
    leftOffset = 0,
  }) => `${calculateElementPosition(focusedComponent, position, width).left + leftOffset}px`};
`;
