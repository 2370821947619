import React, { FC } from 'react';
import { Button, Group, Stack, Text } from 'ui';
import colors from 'theme/colors';
import { handleApiCall } from 'utils/helpers';
import { useAppDispatch } from 'app/hooks';
import { useDeleteMessageMutation } from '../chatApi';
import { showErrorMessage, showSuccessMessage, showUncaughtErrorMessage } from '../../alert/utils';
import { NOTIFICATION } from '../../alert/constants';
import { deleteMessage as deleteFromState } from '../chatSlice';
import { useModal } from '../../modals/ModalProvider';

interface IDeleteMessage {
  id: number;
}

const DeleteMessage: FC<IDeleteMessage> = ({ id }) => {
  const [deleteMessage, { isLoading }] = useDeleteMessageMutation();
  const { close } = useModal();
  const dispatch = useAppDispatch();
  const handleDeleteMessage = async () => {
    const res = await deleteMessage({ id });
    handleApiCall(
      res,
      () => showUncaughtErrorMessage(),
      () => {
        dispatch(deleteFromState({ id }));
        close();
        showSuccessMessage(NOTIFICATION.MESSAGE_DELETED);
      }
    );
  };

  return (
    <Stack gap="20px">
      <Text size="lg" color={colors.gray2}>
        Are you sure you want to delete this message?
      </Text>
      <Group justify="end" gap="15px">
        <Button
          color={colors.dark2}
          onClick={() => close()}
          cypressAttribute="modal-delete-message-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          color={colors.red2}
          onClick={handleDeleteMessage}
          cypressAttribute="modal-delete-message-delete-btn"
        >
          {isLoading ? 'Deleting...' : 'Delete'}
        </Button>
      </Group>
    </Stack>
  );
};

export default DeleteMessage;
