import React, { useMemo } from 'react';

export interface Props extends React.ButtonHTMLAttributes<HTMLSpanElement> {
  date: string | Date | undefined | null;
}

const isValidDate = (date: Date) => !Number.isNaN(date.getTime());

// Helper to retreive a valid date
const getValidatedDate = (date: Date | string | undefined | null) => {
  if (!date) return undefined;
  if (date instanceof Date) return date;
  const parsed = new Date(date);
  if (!isValidDate(parsed)) return undefined;
  return parsed;
};

export const FormattedDate: React.FC<Props> = ({ date, ...rest }) => {
  const validated = useMemo(() => getValidatedDate(date), [date]);

  if (!validated) return <span {...rest}>N/A</span>;
  return <span {...rest}>{validated.toLocaleDateString()}</span>;
};

export const FormattedDateTime: React.FC<Props> = ({ date, ...rest }) => {
  const validated = useMemo(() => getValidatedDate(date), [date]);

  if (!validated) return <span {...rest}>N/A</span>;
  return <span {...rest}>{validated.toLocaleString()}</span>;
};
