import { useAppSelector } from 'app/hooks';
import { Button } from 'components/ui/button';
import { BellDot, Blocks, Building2, CircleUserRound, SettingsIcon } from 'lucide-react';
import { selectUserRole } from 'modules/auth/userSlice';
import { Account } from 'pages/settings/tabs/account';
import { Integrations } from 'pages/settings/tabs/integrations';
import { Notifications } from 'pages/settings/tabs/notifications';
import { Profile } from 'pages/settings/tabs/profile';
import { Workspace } from 'pages/settings/tabs/workspace/index';
import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isAdmin } from 'utils/helpers';

enum Tab {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Profile = 'profile',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Workspace = 'workspace',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Integrations = 'integrations',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Notifications = 'notifications',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Account = 'account',
}

export const Settings: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const role = useAppSelector(selectUserRole);

  const changeTab = (tab: Tab) => {
    setSearchParams({ tab });
  };

  const tabs = useMemo(() => {
    const userTabs = [
      {
        name: 'Profile',
        value: Tab.Profile,
        icon: <CircleUserRound />,
      },
      { name: 'Notifications', value: Tab.Notifications, icon: <BellDot /> },
      { name: 'Integrations', value: Tab.Integrations, icon: <Blocks /> },
      { name: 'Account', value: Tab.Account, icon: <SettingsIcon /> },
    ];
    if (isAdmin(role)) {
      userTabs.push({ name: 'Workspace', value: Tab.Workspace, icon: <Building2 /> });
    }
    return userTabs;
  }, [role]);

  const currentTab = tabs.find((tab) => tab.value === searchParams.get('tab') ?? '') ?? tabs[0];
  return (
    // Height hack while we still have weird layout with navbar
    <div className="flex w-full flex-col sm:h-[calc(100vh-180px)] sm:pl-8">
      <div className="flex flex-row justify-between">
        <h1 className="pb-2 text-2xl font-bold">Settings</h1>
      </div>
      <span className="hidden text-sm text-neutral-500 sm:block">
        Manage your profile, account and workspace preferences
      </span>
      <div className="my-1 h-[1px] w-full bg-secondary sm:my-4" />
      <div className="flex flex-1 flex-row gap-1 overflow-x-auto overflow-y-visible py-2 sm:hidden">
        {tabs.map((tab) => (
          <Button
            variant={tab.value === currentTab.value ? 'secondary' : 'ghost'}
            onClick={() => changeTab(tab.value)}
            className="flex flex-row"
            key={tab.value}
          >
            {tab.name}
          </Button>
        ))}
      </div>
      <div className="flex flex-row gap-8 sm:h-full">
        <nav className="hidden flex-col gap-4 sm:flex">
          {tabs.map((tab) => (
            <Button
              variant={tab.value === currentTab.value ? 'secondary' : 'ghost'}
              onClick={() => changeTab(tab.value)}
              className="flex flex-row justify-start pr-16"
              key={tab.value}
            >
              <span className="mr-2">{tab.icon}</span>
              {tab.name}
            </Button>
          ))}
        </nav>
        <div className="w-full pb-12 sm:h-full sm:overflow-auto">
          <div className="max-w-[800px]">
            {currentTab.value === Tab.Profile && <Profile />}
            {currentTab.value === Tab.Notifications && <Notifications />}
            {currentTab.value === Tab.Integrations && <Integrations />}
            {currentTab.value === Tab.Account && <Account />}
            {currentTab.value === Tab.Workspace && <Workspace />}
          </div>
        </div>
      </div>
    </div>
  );
};
