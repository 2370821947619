import { AVAILABLE_INTEGRATIONS } from 'constants/integrations';
import { MODAL_STATUS_LABELS_TEXT } from 'constants/labels';
import { Task, ViewMode } from 'gantt-task-react';
import { CSSProperties } from 'styled-components';
import { EThreadPriority, EThreadStatuses } from 'types';
import { IUser, IUserMinimize } from 'types/user';

export interface IThreadsResponse {
  count: number | null;
  next?: string | null;
  previous?: string | null;
  results: IThreadsResult[];
}

export interface IThreadsResult {
  id: number;
  name: string;
  description: string;
  creator: IUserMinimize;
  references?: IReferences[];
  site?: number;
  status: EThreadStatuses;
  priority: EThreadPriority;
  tags: Array<string> | null;
  createdAt: string;
  updatedAt: string;
  isPrivate: boolean;
  isRequestedToJoin?: boolean;
  isContributor?: boolean;
}

export interface ITagsResponse {
  tags: string[];
}

export interface ICreateThreadResponse {
  id: string;
  creator: string;
  description: string | null;
  name: string;
  priority: EThreadPriority;
  status: EThreadStatuses;
  tags: Array<string> | null;
  updated_at: string;
  created_at: string;
}

export interface ICreateThreadMutation {
  name: string;
  description: string;
  status: EThreadStatuses;
  priority: EThreadPriority;
  tags: Array<string> | null;
  isPrivate: boolean;
}

export interface IUpdateThreadMutation extends ICreateThreadMutation {
  id: string;
}

export interface IFilterState {
  search: string;
  status: string | EThreadStatuses;
  group: string[];
  tags: string[];
  date: Date[];
}

/**
 * @param { string } updatedAt_Gte greater than or equal date (start date YYYY-MM-DD 00:00:00)".
 * @param { string } updatedAt_Lte less than or equal date (end date YYYY-MM-DD 00:00:00)".
 */
export interface IGetThreadsParams {
  url: string;
  ordering: string;
  limit: number;
  offset: number;
  search?: string;
  status?: string | EThreadStatuses;
  group?: string[];
  tags?: string[];
  updatedAt_Gte?: string;
  updatedAt_Lte?: string;
}

export interface IRows {
  rows: IThreadsResult[];
  cypressAttribute?: string;
  isAllThreads?: boolean;
}

export interface IBadgeProps {
  label?: string;
  color?: string;
  radius?: string;
  backgroundColor?: CSSProperties['backgroundColor'];
}

export enum EDefaultSortOption {
  UPDATED_AT = 'updated_at',
  UPDATED_AT_DSC = '-updated_at',
}

export const REFERENCE_TYPE = {
  LINK: 'LINK',
  FILE: 'FILE',
  ENCUBE: 'ENCUBE_FILE',
  PLAYABLE: 'PLYABLE_FILE',
  THREADS_3D: 'THREADS_3D_FILE',
} as const;

export const PLAYABLE_AVAILABLE_QUOTE_FOR = {
  TOOL: 'TOOL',
  COMPONENT: 'COMPONENT',
} as const;

export interface IFileVersions {
  createdAt: string;
  updatedAt: string;
  file: string;
  fileSize: string;
  id: number;
  version: number;
  isPlyable: boolean;
  isPlyableDfmCheckFailed: boolean;
  plyableDownloadUrl: string | null;
  plyableEstimatedDeliveryDate: string | null;
  plyableOrderStatus: string;
  plyablePriceInCents: number | null;
  plyablePriceCurrency: string;
  plyableQuoteFor: (typeof PLAYABLE_AVAILABLE_QUOTE_FOR)[keyof typeof PLAYABLE_AVAILABLE_QUOTE_FOR];
  plyableUrl: string | null;
  plyableUploadError: string;
  syncedAt: string | null;
  // Threads 3d fields
  isThreads3d: boolean;
  modelId: string | null;
  modelStatus: EModelStatus | null;
  threads3dErrorMessage: string | null;
  threads3dUrl: string | null;
}

export enum EModelStatus {
  NEW = 'new',
  UPLOADED = 'uploaded',
  PENDING = 'pending',
  PROCESSED = 'processed',
  ERROR = 'error',
}

export interface IReferences {
  id: number | string;
  name: string;
  description?: string;
  url: string;
  icons: [keyof typeof AVAILABLE_INTEGRATIONS] | null;
  fileVersions: IFileVersions[];
  fileSize: string | null;
  thread: number;
  labels: (typeof MODAL_STATUS_LABELS_TEXT)[keyof typeof MODAL_STATUS_LABELS_TEXT][];
  referenceType: (typeof REFERENCE_TYPE)[keyof typeof REFERENCE_TYPE];
  creator: IUser;
  createdAt: string;
  updatedAt: string;
}

export interface ICreateReferenceMutation {
  name: string;
  description: string | null;
  url: string;
  thread: string | number;
  file?: File | null;
  fileSize?: string | null;
  referenceType: (typeof REFERENCE_TYPE)[keyof typeof REFERENCE_TYPE];
  plyableQuoteFor?: string;
}

export interface IEditReferenceMutation {
  id: string | number;
  name: string;
  description: string | null;
  url: string;
  thread: number;
  file?: File | null | string;
  fileSize?: string | null;
  plyableQuoteFor: string;
  referenceType?: (typeof REFERENCE_TYPE)[keyof typeof REFERENCE_TYPE];
}

export enum EChartType {
  MY_THREADS = 'my_threads',
  CONTRIBUTION = 'my_contribution',
  ALL_THREADS = 'all_threads',
}

export type TStatusResponse = {
  [key in EThreadStatuses | 'all']: number;
};

export interface IStatusRequest {
  dateFrom: string | Date;
  type: EChartType;
}

export interface IChartProps {
  labels: string[];
  title: string;
  data: number[];
  colors: string[];
}

export interface ISearchThreadsParams {
  search: string;
  limit?: number;
  offset: number;
  fields?: string;
  id?: string | number;
}

export interface ISearchThreadsResult {
  id: number;
  name: string;
}

export interface IThreadView {
  url: string;
  title: string;
  isAllThreads?: boolean;
  cypressAttribute?: string;
}

export interface IGanttResult {
  id: number;
  name: string;
  description: string;
  creator: IUserMinimize;
  status: EThreadStatuses;
  priority: EThreadPriority;
  createdAt: Date;
  updatedAt: Date;
  relatedThreads: number[];
}

export interface IGanttTasks extends Task {
  description?: string;
  status?: EThreadStatuses;
  priority?: EThreadPriority;
  creator?: IUserMinimize;
  updatedAt?: Date;
}

export interface IGanttTaskHeader {
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
}

export interface IGanttTaskList extends Omit<IGanttTaskHeader, 'headerHeight'> {
  rowHeight: number;
  locale: string;
  tasks: IGanttTasks[];
  selectedTaskId: string;
  setSelectedTask: (taskId: string) => void;
}

export interface ITooltipProps {
  task: IGanttTasks;
  fontSize: string;
  fontFamily: string;
}

export interface IGanttSwitcher {
  isChecked: boolean;
  viewMode: ViewMode;
  onViewModeChange: (mode: ViewMode) => void;
  onViewListChange: (checked: boolean) => void;
  ganttViewType: EGanttViewType;
}

export enum EGanttViewType {
  GANTT_BAR_VIEW = 'gantt_bar_view',
  GANTT_EVENT_VIEW = 'gantt_event_view',
}

export type TNumericallyKeyedThreadsResult = {
  [key: number]: IThreadsResult;
};

export interface IThreadMinimized {
  id: number;
  name: string;
}
