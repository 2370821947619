import { useAppSelector } from 'app/hooks';
import { EPermissions } from 'app/permissions/constants';
import { FormattedDate } from 'components/dates';
import routes from 'constants/routes';
import { useWindowSize } from 'hooks/useWindowSize';
import { NOTIFICATION } from 'modules/alert/constants';
import {
  showErrorMessage,
  showSuccessMessage,
  showUncaughtErrorMessage,
} from 'modules/alert/utils';
import { selectUserInfo, selectUserRole } from 'modules/auth/userSlice';
import {
  useAddContributorsMutation,
  useRevokeRequestToJoinThreadMutation,
} from 'modules/contributors/contributorsApi';
import { useModal } from 'modules/modals/ModalProvider';
import StatusBadgeWithCircleIcon from 'modules/threads/components/badge/StatusBadgeWithCircleIcon';
import { IRows, IThreadsResult } from 'modules/threads/types';
import PrivateThreadIndicator from 'pages/thread/components/privateThreadIndicator';
import React, { useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled, { CSSProperties } from 'styled-components';
import colors from 'theme/colors';
import { DropDownAction, Group, Icon, Stack, Tags, Text } from 'ui';
import Tooltip from 'components/ui/tooltip';
import { IAction } from 'ui/dropDownAction/types';
import { isAdmin } from 'utils/helpers';

export const Tr = styled.tr`
  border-radius: 8px;

  &:nth-child(odd) {
    background-color: ${colors.dark2};
  }
`;

export const Td = styled.td<{ padding?: CSSProperties['padding'] }>`
  position: relative;
  font-size: 14px;
  color: ${colors.gray};
  padding: ${({ padding }) => padding || '18px 8px 18px 12px'};
  text-wrap: balance;

  .link {
    color: inherit;
    display: inline;
    text-decoration: none;

    :hover {
      opacity: 0.8;
      text-decoration: underline;
    }
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    text-align: right;
    padding-right: 22px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const TBody: React.FC<IRows> = ({ rows, cypressAttribute, isAllThreads }) => {
  const { open } = useModal();
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { id: userId, email } = useAppSelector(selectUserInfo);
  const userRole = useAppSelector(selectUserRole);
  const [addContributors, { isLoading }] = useAddContributorsMutation();
  const [revokeThreadJoinRequest] = useRevokeRequestToJoinThreadMutation();

  const tagsOffsetForViewportWidth = useMemo(
    () => Math.min(3, Math.max(0, Math.ceil((width - 900) / 256))),
    [width]
  );

  const handleCopyEventLink = async (id: number) => {
    try {
      await navigator.clipboard.writeText(`${window.location.origin}/thread/${id}`);
      showSuccessMessage('Thread link copied');
    } catch (error) {
      showErrorMessage(NOTIFICATION.CLIPBOARD_COPY_FAILURE);
    }
  };

  const handleEditThread = (id: number) => {
    open({
      variant: 'fullRight',
      contentLabel: 'Edit thread properties',
      name: 'Edit Thread',
      modal: 'editThread',
      context: { threadId: id },
      id: 'modal-edit-thread',
    });
  };

  const handleJoinThread = async (id: number, name: string) => {
    if (isAdmin(userRole)) {
      // If user is admin, auto-join the thread.
      try {
        await addContributors([
          {
            user: userId,
            email,
            thread: String(id),
          },
        ]).unwrap();
        navigate(`${routes.thread}/${id}`);
      } catch (error) {
        showUncaughtErrorMessage(error);
      }
    } else {
      open({
        variant: 'center',
        contentLabel: 'Join thread',
        name: 'Join Thread',
        modal: 'joinThread',
        context: { threadName: name, threadId: id },
        id: 'modal-join-thread',
      });
    }
  };

  const handleDeleteThread = (id: number, name: string) => {
    open({
      variant: 'center',
      contentLabel: 'Delete thread',
      name: 'Delete Thread',
      modal: 'deleteThread',
      context: { threadName: name, threadId: id },
      id: 'modal-remove-thread',
    });
  };

  const handleRevokeThreadJoinRequest = async (id: number) => {
    try {
      await revokeThreadJoinRequest({ thread: id }).unwrap();
      showSuccessMessage('Thread join request cancelled');
    } catch (error) {
      showErrorMessage('Failed to cancel thread join request');
    }
  };

  const getThreadActions = useCallback(
    (thread: IThreadsResult) => {
      const { id, name, isContributor, isPrivate, isRequestedToJoin } = thread;
      const isThreadCreator = userId === thread.creator?.id;

      const threadCanBeJoined = isAllThreads && !isContributor && !isThreadCreator && !isPrivate;

      const actions: IAction[] = [
        {
          id: '1',
          title: 'Copy link',
          action: () => handleCopyEventLink(id),
          icon: <Icon size="small" icon="CopyLinkIcon" stroke="white" />,
        },
        {
          id: '2',
          title: 'Edit',
          action: () => handleEditThread(id),
          icon: <Icon size="small" icon="PencilIcon" stroke="white" />,
          permissions: [EPermissions.EDIT_THREAD],
        },
        {
          id: '3',
          title: 'Delete',
          action: () => handleDeleteThread(id, name),
          icon: <Icon size="small" icon="BinIcon" />,
          permissions: [EPermissions.DELETE_THREAD],
        },
      ];

      if (threadCanBeJoined) {
        if (isRequestedToJoin) {
          actions.push({
            id: '0',
            title: 'Cancel Join Request',
            action: () => handleRevokeThreadJoinRequest(id),
            icon: <Icon size="small" icon="RemoveUserIcon" stroke="white" />,
          });
        } else {
          actions.push({
            id: '0',
            title: 'Join Thread',
            action: () => handleJoinThread(id, name),
            icon: <Icon size="small" icon="UserWithPlus" stroke="white" />,
          });
        }
      }
      return actions;
    },
    [isAllThreads]
  );

  return (
    <tbody data-cy={cypressAttribute}>
      {rows.map((thread) => (
        <Tr key={thread.id}>
          <Td>
            <StatusBadgeWithCircleIcon text={false} status={thread.status} />
          </Td>

          <Td padding="18px 0 18px 0">
            <Tooltip content={thread.name} side="top" isVisible={thread.name.length >= 10}>
              <Group gap="5px" align="center">
                <div>
                  {thread.isRequestedToJoin && !isAdmin(userRole) && (
                    <Tooltip
                      side="right"
                      content={
                        <Stack gap="6px">
                          <Text size="xs">You have requested to join this thread.</Text>
                          <Text size="xs">Awaiting contributor approval</Text>
                        </Stack>
                      }
                    >
                      <Icon icon="PendingRequestIcon" size="small" />
                    </Tooltip>
                  )}
                </div>
                <Link to={`/thread/${thread.id}`} className="link max-h-16 truncate text-balance">
                  {thread.name}
                </Link>
              </Group>
            </Tooltip>
          </Td>

          <Td>
            <Tags tags={thread.tags} offset={tagsOffsetForViewportWidth} />
          </Td>

          <Td>
            <Tooltip content={<PrivateThreadIndicator isPrivate={thread.isPrivate} />}>
              {thread.isPrivate ? (
                <Icon icon="StrikeEyeIcon" size="medium" stroke="none" />
              ) : (
                <Icon icon="EyeIcon" size="medium" stroke="none" />
              )}
            </Tooltip>
          </Td>

          <Td>
            <Group justify="start" align="center" fluid>
              <FormattedDate
                className="max-h-16 overflow-hidden break-all "
                date={thread.updatedAt}
              />
            </Group>
          </Td>

          <Td id="dropdown-menu-header-cell">
            <DropDownAction
              position="vertical"
              actions={getThreadActions(thread)}
              cypressAttribute="thread-card-dropdown-actions"
            />
          </Td>
        </Tr>
      ))}
    </tbody>
  );
};

export default TBody;
