import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { IPaginationParams } from 'types';
import { notificationApi } from './notificationApi';
import { INotificationResponse, INotifications } from './types';
import { markAllAsRead } from './utils';

export const LIMIT = 10;

export interface IState {
  list: INotificationResponse[];
  unread: number;
  total: number;
  isLoading: boolean;
}

export const initialState: IState = {
  list: [],
  unread: 0,
  total: 0,
  isLoading: false,
};

export const selectNotificationsState = (state: RootState) => state.notifications;
export const selectNotifications = (state: RootState): INotificationResponse[] =>
  state.notifications.list;
export const selectUnreadCount = (state: RootState): number => state.notifications.unread;
export const selectTotal = (state: RootState): number => state.notifications.total;

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    updateNotifications: (state, { payload }: PayloadAction<INotificationResponse[]>) => {
      state.list = payload;
      return state;
    },
    clearNotifications: (state) => {
      state.list = [];
      return state;
    },
    markAsRead: (state, { payload }: PayloadAction<number>) => {
      state.list.forEach((message) => {
        if (message.id === payload) {
          message.isRead = true;
        }
      });
      state.unread--;
      return state;
    },
    markAllNotificationsAsRead: (state) => {
      state.list.forEach((message) => {
        message.isRead = true;
      });
      return state;
    },
    addMessage: (state, { payload }: PayloadAction<INotificationResponse>) => {
      state.list.unshift(payload);
      state.unread++;
      state.total++;
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        notificationApi.endpoints.getNotifications.matchFulfilled,
        (state, { payload }: PayloadAction<INotifications>) => {
          state.isLoading = false;
          state.list.push(...payload.results);
          state.total = payload.count;
          state.unread = payload.unread;
        }
      )
      .addMatcher(notificationApi.endpoints.getNotifications.matchPending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addMatcher(notificationApi.endpoints.markAllAsRead.matchFulfilled, (state) => {
        state.list = state.list.map(markAllAsRead);
        state.unread = 0;
        return state;
      }),
});

export const {
  clearNotifications,
  markAsRead,
  addMessage,
  updateNotifications,
  markAllNotificationsAsRead,
} = notificationSlice.actions;
export default notificationSlice.reducer;

export const loadNotifications = createAsyncThunk(
  'notification/loadNotifications',
  async ({ offset, limit }: IPaginationParams, { dispatch }) => {
    const result = dispatch(
      notificationApi.endpoints.getNotifications.initiate({ offset, limit }, { forceRefetch: true })
    );
    result.unsubscribe();
    const response = await result;

    if ('data' in response) {
      return response.data as INotifications;
    }

    return null;
  }
);
