import { Button } from 'components/ui/button';
import { CardContent } from 'components/ui/card';
import { showSuccessMessage, showUncaughtErrorMessage } from 'modules/alert/utils';
import {
  useGetNonActiveIntegrationsQuery,
  useUpdateIntegrationsMutation,
} from 'modules/integrations/integrationsApi';
import React from 'react';

export const GoogleIntegrationCard: React.FC = () => {
  // Integrations were previously individually toggle-able
  const [updateIntegrations, { isLoading: isEnablingGoogle }] = useUpdateIntegrationsMutation();
  const { data: inactiveIntegrations, isLoading } = useGetNonActiveIntegrationsQuery();
  // Need to ensure that all of possible Google integrations are enabled
  const isEnabled = inactiveIntegrations && Object.keys(inactiveIntegrations).length === 0;

  const enableGoogle = async () => {
    try {
      await updateIntegrations({
        document: true,
        drive: true,
        spreadsheets: true,
        presentation: true,
      }).unwrap();
      showSuccessMessage('Google integration enabled');
    } catch (error) {
      showUncaughtErrorMessage(error);
    }
  };
  return (
    <CardContent className="items:start flex flex-col justify-between gap-2 pt-6 sm:flex-row sm:items-center">
      <div className="flex flex-col space-y-0.5">
        <span className="text-base font-semibold">Google Integration</span>
        <span className="text-sm text-neutral-500">
          Allow users to link their Threads and Google accounts
        </span>
      </div>

      <div className="flex flex-row justify-end gap-2 sm:justify-start">
        <Button onClick={enableGoogle} disabled={isLoading || isEnablingGoogle || isEnabled}>
          {isEnabled ? 'Enabled' : 'Enable'}
        </Button>
      </div>
    </CardContent>
  );
};
