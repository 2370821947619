import OnCloseSaveWarningModal from 'modules/app/modals/OnCloseWarningModal';
import React, {
  createContext,
  FC,
  PropsWithChildren,
  SyntheticEvent,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Modal } from 'ui';
import { modals } from './modals';
import { IModalOptions } from './types';

const defaultOptions: IModalOptions = {
  variant: 'fullRight',
  contentLabel: '',
  name: '',
  context: {},
};

interface IModalContext {
  /** Current modal state. */
  options: IModalOptions;
  setOptions: React.Dispatch<React.SetStateAction<IModalOptions>>;

  /** Open a modal with given configuration. */
  open: (options: IModalOptions) => void;

  /** Close the current modal. */
  close: (
    event?: SyntheticEvent | null,
    /** Provides option to force show save warning at caller level. */
    showWarning?: boolean
  ) => void;
  isOpen: boolean;
}

const ModalContext = createContext<IModalContext>({
  options: defaultOptions,
  setOptions: () => null,
  open: (option) => null,
  close: () => null,
  isOpen: false,
});

const NulComponent: FC = () => null;

const ModalProvider = ({ children }: PropsWithChildren) => {
  const [options, setOptions] = useState<IModalOptions>(defaultOptions);
  const [showWarning, setShowWarning] = useState<boolean>(false);

  const {
    variant,
    name,
    context,
    contentLabel,
    modal,
    showWarningBeforeClose = false,
    warningText,
  } = options;

  const handleWarningConfirmation = () => {
    setShowWarning(false);
    setOptions(defaultOptions);
  };

  const close = (event?: SyntheticEvent | null, _showWarning = false) => {
    if (showWarningBeforeClose || _showWarning) {
      setShowWarning(true);
    } else {
      setOptions(defaultOptions);
    }
  };

  const open = (option: IModalOptions) => {
    setOptions(option);
  };

  const ModalComponent = modal ? modals[modal] : NulComponent;
  const isOpen = !!modal;
  const store = useMemo(
    () => ({
      options,
      setOptions,
      close,
      open,
      isOpen,
    }),
    [modal]
  );

  /**
   * Render warning modal on close if set in modal options.
   */
  const renderWarningModal = () => (
    <Modal
      isOpen={showWarning}
      onRequestClose={() => setShowWarning(false)}
      variant="center"
      contentLabel=""
      name="Warning"
    >
      <OnCloseSaveWarningModal
        text={warningText}
        onAbort={() => setShowWarning(false)}
        onConfirm={handleWarningConfirmation}
      />
    </Modal>
  );

  return (
    <ModalContext.Provider value={store}>
      {children}
      {renderWarningModal()}
      <Modal
        isOpen={isOpen}
        onRequestClose={close}
        variant={variant}
        contentLabel={contentLabel}
        name={name}
        id={options?.id}
      >
        {isOpen && <ModalComponent {...(context as any)} />}
      </Modal>
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);

export default ModalProvider;
