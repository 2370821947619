import styled, { css, keyframes } from 'styled-components';

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const sizeMap = {
  extraTiny: '6px',
  tiny: '10px',
  extraSmall: '14px',
  small: '16px',
  normal: '20px',
  medium: '24px',
  large: '36px',
};

export type TSize = 'extraTiny' | 'tiny' | 'extraSmall' | 'small' | 'normal' | 'medium' | 'large';

export interface IIconWrapperProps {
  size: TSize;
  fill?: string;
  stroke?: string;
  path?: string;
  strokeWidth?: number;
  hoverColor?: string;
  color?: string;
  onClick?: () => void;
  rotate?: '0deg' | '45deg' | '90deg' | '180deg' | '360deg';
  spin?: boolean;
}

const IconWrapper = styled.div<IIconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ size }) => sizeMap[size]};
  width: ${({ size }) => sizeMap[size]};
  cursor: ${({ onClick }) => onClick && 'pointer'};
  transform: rotate(${({ rotate }) => rotate || 0});
  transition: 0.4s ease-in-out;
  animation: ${({ spin }) =>
    spin
      ? css`
          ${spinAnimation} 1s linear infinite
        `
      : ''};

  & > svg {
    width: 100%;
    height: 100%;
    fill: ${({ fill, color }) => !color && fill};
    stroke: ${({ stroke }) => stroke};
    strokewidth: ${({ strokeWidth }) => strokeWidth};

    & path {
      fill: ${({ color }) => color};
      stroke: ${({ path }) => path};
    }
    &:hover {
      fill: ${({ fill, hoverColor }) => (fill ? hoverColor : '')};
      fill: ${({ stroke, hoverColor }) => (stroke ? hoverColor : '')};
    }
  }
`;

export default IconWrapper;
