import { AnnotationComment } from 'modules/annotations/components/AnnotationComment';
import { AnnotationContentSidebar } from 'modules/annotations/components/AnnotationContentSidebar';
import { IAnnotation } from 'modules/annotations/types';
import React from 'react';
import {
  AnnotationAvatar,
  AnnotationCommentList,
  AnnotationCommentListScroller,
  AnnotationContentHolder,
  AnnotationItemWrapper,
} from './AnnotationLayout';

interface IAnnotationItemProps {
  annotation: IAnnotation;
  refetch?: () => void;
}

export const AnnotationItem: React.FC<IAnnotationItemProps> = ({ annotation, refetch }) => {
  return (
    <AnnotationItemWrapper
      fluid
      gap="12px"
      justify="flex-start"
      align="flex-start"
      data-cy="annotation-item-wrapper"
    >
      <AnnotationContentHolder
        fluid
        justify="space-between"
        align="stretch"
        gap="10px"
        data-cy="annotation-content-holder"
      >
        <AnnotationCommentListScroller
          fullHeight
          justify="flex-start"
          data-cy="annotation-comments-list-scroller"
        >
          <AnnotationCommentList
            fluid
            fullHeight
            gap="8px"
            justify="flex-start"
            align="flex-start"
            data-cy="annotation-comments-list"
          >
            {annotation.threads3dComments.map((comment, index) => (
              <AnnotationComment
                // eslint-disable-next-line react/no-array-index-key
                key={`${comment.modelId}-${index}`}
                avatarSrc={comment.creator.avatar as string}
                content={comment.message}
                date={comment.createdAt}
                referenceId={annotation.versionedFile.id}
              />
            ))}
          </AnnotationCommentList>
        </AnnotationCommentListScroller>
        <AnnotationContentSidebar
          author={`${annotation.creator.firstName} ${annotation.creator.lastName}`}
          date={annotation.createdAt}
          id={annotation.id.toString()}
          referenceId={annotation.versionedFile.id}
          onRefreshClick={() => refetch?.()}
        />
      </AnnotationContentHolder>
      <AnnotationAvatar
        size="mediumSmall"
        src={annotation.creator.avatar}
        data-cy="annotation-avatar"
      />
    </AnnotationItemWrapper>
  );
};
