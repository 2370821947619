import { extractLinks } from '@draft-js-plugins/linkify';
import { ContentState, RawDraftContentState } from 'draft-js';
import * as qs from 'qs';
import { switchCase } from 'utils/match';
import { IUser, IUserMinimize } from 'types/user';
import { EChatFilter, EMessageType, IChatQuery, IMessageResponse, IMessageCreate } from './types';

const MAIL_SCHEMA = 'mailto:';
const MAX_NAME_LENGTH = 98;

export const getReferences = (files: File[]) =>
  files.map((file) => ({
    name: file.name,
    file,
  }));

const formatLink = (link: { name: string; url: string }) =>
  `${link.url.split(',')[0].replaceAll('"', '')}`;

const getLink = (link: { name: string; url: string }) => ({
  name: link.name || formatLink(link).substring(0, MAX_NAME_LENGTH),
  url: formatLink(link),
});

export const getLinks = (state: RawDraftContentState) => {
  const excludeLinks = Object.values(state.entityMap).map(({ data }: any) => data?.mention?.link);
  const result = extractLinks(JSON.stringify(state.blocks))?.reduce((links, link) => {
    if (!excludeLinks.includes(link.url) && link.schema !== MAIL_SCHEMA) links.push(getLink(link));
    return links;
  }, []);
  return result || [];
};

const isMatch = (msg: IMessageResponse, resMsg: IMessageResponse) =>
  msg.id === resMsg.id || (msg.hash && msg.hash === resMsg.hash);

export const updateMessage = (state: IMessageResponse[], payload: IMessageResponse) =>
  state.map((message) => {
    return switchCase(true)
      .on(isMatch(message, payload), () => ({ ...message, ...payload }))
      .on(message.id === payload.replyTo, () => {
        message.replies = updateMessage(message.replies, payload);
        return message;
      })
      .otherwise(() => message);
  });

export const pushMessage = (state: IMessageResponse[], payload: IMessageResponse) => {
  const messages = state.map((message) => {
    if (message.id === payload.replyTo) {
      return { ...message, replies: [...message.replies, payload] };
    }
    return message;
  });
  return payload.replyTo ? messages : [...messages, { ...payload, replies: [] }];
};

export const isLiked = (users: IUserMinimize[], userId: string) =>
  !!users.find(({ id }) => id === userId);

export const hasText = (editor: ContentState) => !!editor.getLastBlock().getText().trim();
export const addQuery = (url: string, query: IChatQuery) => {
  const { type, ...rest } = query;
  return type === EChatFilter.ALL
    ? `${url}?${qs.stringify(rest)}`
    : `${url}?${qs.stringify({ ...rest, type })}`;
};

export const checkFiltration = (messageType: EMessageType, filter: EChatFilter) => {
  switch (filter) {
    case EChatFilter.ALL:
      return true;
    case EChatFilter.COMMENTS:
      return [EMessageType.USER_MESSAGE, EMessageType.FLOWS_MESSAGE].includes(
        messageType.toUpperCase() as EMessageType
      );
    default:
      return messageType.toUpperCase() === filter.toUpperCase();
  }
};

export const messageToStateData = (
  message: IMessageCreate,
  user: IUser,
  attaches: File[]
): IMessageResponse => ({
  ...message,
  creator: user,
  // @ts-ignore
  id: message.hash,
  references: [],
  preloadFiles: attaches,
  replies: [],
  createdAt: new Date().toUTCString(),
  updatedAt: new Date().toUTCString(),
  numberOfLikes: 0,
  likes: [],
  type: EMessageType.USER_MESSAGE,
  isLoading: true,
});

/**
 * Find virtuoso index by message id.
 */
export const findByMsgIndexById = (messages: IMessageResponse[], msgid: string) => {
  // if return -1 find in nested array
  const index = messages.findIndex((msg) => msg.id === Number(msgid));
  if (index !== -1) {
    return index;
  }
  return messages.findIndex((msg) => {
    if (msg.replies) {
      return msg.replies.findIndex((reply) => reply.id === Number(msgid)) !== -1;
    }
    return false;
  });
};
