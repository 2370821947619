import { AddContributors, RemoveContributor } from 'modules/contributors/modals';
import { AddReference, EditReference, RemoveReference } from 'modules/references/modals';
import { DeleteMember, InviteMembers } from 'modules/inviteUsers/modals';
import { CreateResource, DeleteResource } from 'modules/resources/modals';
import { AddRelatedThread, DeleteRelatedThread } from 'modules/relatedThreads/modals';
import { CreateEvent, DeleteEvent, RelateEventWarning } from 'modules/events/modals';
import { ArchiveThread, CreateThread, EditThread, RemoveThread } from 'modules/threads/modals';
import LeaveThread from 'modules/relatedThreads/modals/leaveThread';
import JoinThreadModal from 'modules/threads/modals/joinThread';
import RemoveIntegration from 'modules/header/components/modals/RemoveIntegration';
import { AddIntegration } from 'modules/header/components/modals/AddIntegration';
import ContributorInviteActions from 'modules/notification/modals/ContributorInviteActions';
import ChooseIntegration from 'modules/header/components/modals/ChooseIntegration';
import SubscriptionLimits from 'modules/subscriptionsPlans/modals/SubscriptionLimits';
import CustomerSupportModal from 'modules/app/modals/CustomerSupportModal';
import ReportErrorModal from 'modules/app/modals/ReportErrorModal';
import ContributorPendingActions from 'modules/notification/modals/ContributorPendingActions';
import OnCloseSaveWarningModal from 'modules/app/modals/OnCloseWarningModal';
import DeleteMessage from '../chat/modals/DeleteMessage';
import DeleteInvite from '../inviteUsers/modals/removeInvite';

/**
 * Modals that are global, shown app-wide.
 */
export const appModals = {
  customerSupport: CustomerSupportModal,
  reportError: ReportErrorModal,
};

/**
 * Regular module-specific modals.
 */
export const modals = {
  createEvent: CreateEvent,
  onCloseSaveWarningModal: OnCloseSaveWarningModal,
  relateEventWarning: RelateEventWarning,
  deleteMessage: DeleteMessage,
  deleteEvent: DeleteEvent,
  choseIntegration: ChooseIntegration,
  addReference: AddReference,
  editReference: EditReference,
  removeReference: RemoveReference,
  addContributors: AddContributors,
  removeContributor: RemoveContributor,
  inviteMembers: InviteMembers,
  deleteMember: DeleteMember,
  deleteInvite: DeleteInvite,
  joinThread: JoinThreadModal,
  editThread: EditThread,
  deleteThread: RemoveThread,
  createResource: CreateResource,
  deleteResource: DeleteResource,
  addRelatedThread: AddRelatedThread,
  deleteRelatedThread: DeleteRelatedThread,
  leaveThread: LeaveThread,
  createThread: CreateThread,
  archiveThread: ArchiveThread,
  removeIntegration: RemoveIntegration,
  addIntegration: AddIntegration,
  subscriptionLimits: SubscriptionLimits,
  contributorInviteActions: ContributorInviteActions,
  contributorPendingActions: ContributorPendingActions,
  ...appModals,
};
