import { FormattedDateTime } from 'components/dates';
import {
  AnnotationFileLink,
  AnnotationRefreshButton,
} from 'modules/annotations/components/AnnotationLayout';
import { getFileNameFromUrl } from 'modules/annotations/helpers';
import React, { useCallback } from 'react';
import colors from 'theme/colors';
import { Group, Icon, Stack, Text } from 'ui';

interface IPlyableAnnotationContentSidebarProps {
  /**
   * Unique identifier of the annotation.
   */
  id: string;
  /**
   * Author of the annotation.
   */
  author: string;
  /**
   * Date of the annotation creation. ISO 8601 format.
   * @example "2021-01-01T00:00:00.000Z"
   */
  date: string;
  /**
   * Unique identifier of the file that the annotation is attached to.
   */
  fileId: number;
  /**
   * Name of the file that the annotation is attached to.
   */
  fileName: string;
  /**
   * Version of the file that the annotation is attached to.
   * @example "1.0.0"
   */
  fileVersion: string;
  /**
   * URL of the file that the annotation is attached to.
   */
  fileUrl: string;
  /**
   * Callback that is called when the refresh button is clicked.
   * @param id - Unique identifier of the annotation.
   */
  onRefreshClick?: (id: string) => void;
  isRefreshing: boolean;
}

const PlyableAnnotationContentSidebar: React.FC<IPlyableAnnotationContentSidebarProps> = (
  props
) => {
  const {
    id,
    author,
    date,
    fileId,
    fileName,
    fileUrl,
    fileVersion,
    onRefreshClick,
    isRefreshing = false,
  } = props;

  const refreshAnnotationClickHandler = () => {
    onRefreshClick?.(id);
  };

  const navigateToPlyableQuote = useCallback(() => {
    window.open(fileUrl, '_blank');
  }, [fileUrl]);

  return (
    <Stack gap="16px" justify="space-between" align="flex-end" data-cy="annotation-content-sidebar">
      <Stack gap="24px" align="flex-end" justify="flex-start" fluid>
        <Stack
          gap="8px"
          align="flex-end"
          justify="flex-start"
          fluid
          data-cy="annotation-content-sidebar-stack"
        >
          <Text color={colors.white} size="sm" weight="700" align="right">
            Plyable Comments
          </Text>
          <Text
            color={colors.gray3}
            size="xs"
            weight="400"
            align="right"
            noWrap
            cypressAttribute="annotation-content-sidebar-date-text"
          >
            <FormattedDateTime date={date} />
          </Text>
        </Stack>
        <Group gap="10px" align="center">
          <Stack>
            <Text color={colors.white} size="sm" weight="700" align="right" noWrap>
              Reference
            </Text>
            <AnnotationFileLink
              style={{ cursor: 'pointer' }}
              target="_target"
              data-cy="annotation-content-sidebar-file-link"
              onClick={navigateToPlyableQuote}
            >
              {getFileNameFromUrl(fileName)} (Version {fileVersion})
            </AnnotationFileLink>
          </Stack>
          <Icon
            icon="ExternalLinkIcon"
            path="rgba(98, 170, 254, 1)"
            size="medium"
            onClick={navigateToPlyableQuote}
          />
        </Group>
      </Stack>
      <AnnotationRefreshButton
        isRefreshing={isRefreshing}
        disabled={isRefreshing}
        type="button"
        rightIcon={<Icon spin={isRefreshing} icon="RefreshAnnotationIcon" size="small" />}
        onClick={refreshAnnotationClickHandler}
        data-cy="annotation-refresh-btn"
      >
        {isRefreshing ? 'Refreshing...' : 'Refresh'}
      </AnnotationRefreshButton>
    </Stack>
  );
};
export default PlyableAnnotationContentSidebar;
