import React, { FC, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, DropDownAction, Icon } from 'ui';
import { useParams } from 'react-router-dom';
import { useModal } from 'modules/modals/ModalProvider';
import { IUserMinimize } from 'types/user';
import { useAppSelector } from 'app/hooks';
import { selectUserInfo } from 'modules/auth/userSlice';
import { EPermissions } from 'app/permissions/constants';
import AccessControl from 'app/permissions/AccessControl';
import { convertFromRaw, EditorState } from 'draft-js';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { NOTIFICATION } from 'modules/alert/constants';
import Like from './Like';
import { ActionWrapper } from '../Layout';
import { MessageContext } from './index';
import { useLikeMessagesMutation } from '../../chatApi';
import { isLiked } from '../../utils';

interface IActionsProps {
  isActionVisible: boolean;
  likes: number;
  isOwner: boolean;
  isReplied: boolean;
  messageId: number;
  usersLike: IUserMinimize[];
  text?: string;

  onEdit(): void;
}

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.blue8};
`;
const Actions: FC<IActionsProps> = ({
  isActionVisible,
  likes,
  onEdit,
  isOwner,
  messageId,
  usersLike: messageLikers,
  text,
}) => {
  const { open } = useModal();
  const { id: threadId } = useParams();
  const description = text
    ? EditorState.createWithContent(convertFromRaw(JSON.parse(text)))
        .getCurrentContent()
        .getPlainText()
    : '';
  const onDelete = () =>
    open({
      variant: 'center',
      contentLabel: 'Delete message',
      name: 'Delete Message',
      modal: 'deleteMessage',
      context: {
        id: messageId,
      },
      id: 'modal-delete-message',
    });

  const handleCopyMessageLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `${window.location.origin}/thread/${threadId}/messages/${messageId}`
      );

      showSuccessMessage('Message link copied');
    } catch (error) {
      showErrorMessage(NOTIFICATION.CLIPBOARD_COPY_FAILURE);
    }
  };
  const ownerActions = useMemo(
    () => [
      {
        id: 'Copy link',
        title: 'Copy link',
        action: handleCopyMessageLink,
        icon: <Icon size="small" icon="CopyLinkIcon" />,
      },
      {
        id: 'Edit',
        title: 'Edit',
        action: onEdit,
        icon: <Icon size="small" icon="PencilIcon" />,
        permissions: [EPermissions.EDIT_OWN_CHAT_MESSAGE],
      },
      {
        id: 'Delete',
        title: 'Delete',
        action: onDelete,
        icon: <Icon size="small" icon="BinIcon" />,
        permissions: [EPermissions.DELETE_OWN_CHAT_MESSAGE],
      },
    ],
    []
  );

  // destructuring to get only copyLink action as not owner actions
  const [copyLink] = ownerActions;

  const [toggleLike] = useLikeMessagesMutation();
  const { enableReplyMode } = useContext(MessageContext);
  const { id } = useParams();
  const { id: userId } = useAppSelector(selectUserInfo);
  const isLike = isLiked(messageLikers, userId);

  const openEventModal = () => {
    open({
      variant: 'fullRight',
      contentLabel: 'event-type',
      name: 'Create an Event',
      modal: 'createEvent',
      context: {
        threadId: id,
        description,
        messageId,
      },
      id: 'modal-create-event',
    });
  };

  return (
    <ActionWrapper isActionVisible={isActionVisible} owner={isOwner} gap="20px" align="center">
      <Like
        users={messageLikers}
        isActive={isLike}
        count={likes}
        onClick={() => toggleLike(messageId)}
      />
      <AccessControl permissions={[EPermissions.SEND_CHAT_MESSAGE]} threadId={id as string}>
        <StyledButton
          onClick={() => enableReplyMode(messageId)}
          variant="plain"
          leftIcon={<Icon icon="ReplyIcon" />}
          data-cy="action-reply-btn"
        >
          Reply
        </StyledButton>
      </AccessControl>
      <AccessControl permissions={[EPermissions.ADD_EVENT]} threadId={id as string}>
        <StyledButton
          onClick={openEventModal}
          variant="plain"
          leftIcon={<Icon icon="BranchIcon" />}
          data-cy="action-create-event-btn"
        >
          Create an Event
        </StyledButton>
      </AccessControl>

      <DropDownAction
        actions={isOwner ? ownerActions : [copyLink]}
        cypressAttribute="actions-dropdown-action"
      />
    </ActionWrapper>
  );
};

export default Actions;
