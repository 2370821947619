import { THREAD_STATUS_COLORS, THREAD_STATUS_NAMES } from 'constants/index';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { EThreadStatuses } from 'types';
import Text from 'ui/text';
import { EEventStatus } from '../../constants/events';

interface IProps {
  status: EThreadStatuses | EEventStatus;
  text?: string | ReactElement;
  cypressAttribute?: string;
}

const Wrapper = styled.div<IProps>`
  padding: 4px 8px;
  background-color: ${({ status }) => THREAD_STATUS_COLORS[status]};
  border-radius: 4px;
  display: inline-block;
`;
const StyledText = styled(Text)`
  line-height: inherit;
  white-space: nowrap;
`;

const StatusBadge: React.FC<IProps> = ({ status, text = '', cypressAttribute }) => {
  return (
    <Wrapper status={status} data-cy={cypressAttribute}>
      <StyledText weight="500">
        {THREAD_STATUS_NAMES[status]} <span className="hidden lg:inline-block">{text}</span>
      </StyledText>
    </Wrapper>
  );
};

export default StatusBadge;
