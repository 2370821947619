import React from 'react';
import { MenuItem } from 'modules/header/components/Layout';
import { Link } from 'react-router-dom';
import colors from 'theme/colors';

interface ISearchItemProps {
  id: number;
  name: string;
  search: string;
}

const SearchItem: React.FC<ISearchItemProps> = ({ id, name, search }) => {
  const threadName = name.toLowerCase();
  const isMatch = threadName.includes(search);

  const toParts = (): string[] => {
    const re = new RegExp(`(${search})`);
    return [...threadName.split(re)];
  };

  const parts = isMatch ? toParts() : [threadName];

  return (
    <MenuItem>
      <Link to={`/thread/${id}`} aria-label="thread link">
        <>
          {parts.map((part) => (
            <span style={{ color: part === search ? colors.brandSecondaryLight : colors.gray }}>
              {part}
            </span>
          ))}
        </>
      </Link>
    </MenuItem>
  );
};

export default SearchItem;
