import { useInfinitePagination } from 'hooks/usePagination';
import { useThreads3dAnnotationsQuery } from 'modules/threads3d/api';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Icon } from 'ui';
import { AnnotationItem } from './AnnotationItem';
import {
  AnnotationListScroller,
  AnnotationRefreshButton,
  AnnotationsContainer,
  LoadButtonContainer,
} from './AnnotationLayout';
import { AnnotationsEmpty } from './AnnotationsEmpty';
import { AnnotationsSkeleton } from './AnnotationsSkeleton';

export const Threads3dAnnotations: React.FC = () => {
  const { id } = useParams();
  const { offset, limit, next } = useInfinitePagination({ pageSize: 10 });
  const { data, isLoading, isFetching, refetch } = useThreads3dAnnotationsQuery(
    { threadId: id!, limit, offset },
    { refetchOnMountOrArgChange: true }
  );
  const annotations = data?.results;
  const canLoadMore = !!data?.next;
  return (
    <AnnotationListScroller fullHeight justify="flex-start" data-cy="annotation-list-scroller">
      <AnnotationsContainer gap="35px" fluid fullHeight data-cy="annotations-container">
        {isLoading && (
          <>
            <AnnotationsSkeleton />
            <AnnotationsSkeleton />
          </>
        )}
        {annotations?.length === 0 && !isLoading && (
          <AnnotationsEmpty isRefreshing={isFetching} onRefreshClick={refetch} />
        )}
        {annotations?.map((annotation) => (
          <AnnotationItem key={annotation.id} annotation={annotation} refetch={refetch} />
        ))}
        {canLoadMore && !isLoading && (
          <LoadButtonContainer isBottomButton data-cy="annotation-load-btn-container">
            <AnnotationRefreshButton
              type="button"
              rightIcon={<Icon spin={isFetching} icon="RefreshAnnotationIcon" size="small" />}
              onClick={next}
              data-cy="annotation-load-more-btn"
            >
              {isFetching ? 'Loading...' : 'Load More'}
            </AnnotationRefreshButton>
          </LoadButtonContainer>
        )}
      </AnnotationsContainer>
    </AnnotationListScroller>
  );
};
