import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { api } from 'app/api';
import { RootState } from 'app/store';
import _reject from 'lodash/reject';
import { getMinimizedUser } from 'modules/user/utils';
import { IId, IPaginatedResponse } from 'types';
import { deepUnderscoreKeys } from 'utils/mappers';
import { toFormData } from 'utils/toFormData';
import { updateMessage } from './chatSlice';
import { MESSAGE_PER_PAGE } from './constants';
import {
  IGetMessageParams,
  IMessageCreate,
  IMessageEdit,
  IMessageLoad,
  IMessageResponse,
} from './types';
import { addQuery } from './utils';

export const chatApi = api.injectEndpoints({
  endpoints: (build) => ({
    sendMessages: build.mutation<IMessageResponse, IMessageCreate>({
      query: (payload) => ({
        url: '/messages/',
        method: 'POST',
        body: payload.references.length ? toFormData(deepUnderscoreKeys(payload)) : payload,
      }),
      invalidatesTags: (_, __, payload) => (payload.references.length ? ['references'] : []),
    }),
    editMessages: build.mutation<IMessageResponse, IMessageEdit>({
      query: ({ id, references, ...payload }) => ({
        url: `/messages/${id}/`,
        method: 'PATCH',
        body: references.length ? toFormData({ ...payload, references }) : payload,
      }),
    }),
    likeMessages: build.mutation<IMessageResponse, number>({
      query: (id) => ({
        url: `/messages/${id}/toggle-like/`,
        method: 'PATCH',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        // Optimistically handle message likes.
        const state = getState() as RootState;

        const user = state.user.userInfo;
        const minimizedUserInfo = getMinimizedUser(user);

        const messagesState = [...state.chat.messages] as IMessageResponse[];
        const targetMessage = messagesState.find((message) => message.id === id);

        // Determine like/unlike.
        const isLiking = targetMessage?.likes.every((liker) => liker.id !== user.id);

        if (targetMessage) {
          const updatedLikes = isLiking
            ? [...targetMessage.likes, minimizedUserInfo]
            : _reject([...targetMessage.likes], (liker) => liker.id === user.id);

          const updatedMessage = {
            ...targetMessage,
            likes: updatedLikes,
            numberOfLikes: updatedLikes.length,
          };
          dispatch(updateMessage(updatedMessage));

          try {
            const response = await queryFulfilled;
            dispatch(updateMessage(response.data));
          } catch {
            dispatch(updateMessage(targetMessage));
          }
        }
      },
    }),
    deleteMessage: build.mutation<void, IId>({
      query: ({ id }) => ({
        url: `/messages/${id}/`,
        method: 'DELETE',
      }),
    }),
    getMessages: build.query<IPaginatedResponse<IMessageResponse>, Partial<IGetMessageParams>>({
      query: ({ id, link, ...query }) => ({
        url: link || addQuery(`/threads/${id}/messages/`, query),
      }),
      providesTags: ['chatMessages'],
    }),
    loadInitialMessage: build.query<IPaginatedResponse<IMessageResponse>, IMessageLoad>({
      async queryFn({ id, filter, msgId }, _queryApi, _extraOptions, fetchWithBQ) {
        const url = `/threads/${id}/messages/`;
        const randomResult = await fetchWithBQ(addQuery(url, { type: filter, message_id: msgId }));
        if (randomResult.error) return { error: randomResult.error as FetchBaseQueryError };
        // @ts-ignore
        const offset = randomResult.data.count as number;
        const lastIndex = offset > MESSAGE_PER_PAGE ? offset - MESSAGE_PER_PAGE : 0;
        const query = {
          limit: MESSAGE_PER_PAGE,
          offset: lastIndex,
          type: filter,
          message_id: msgId,
        };
        const result = await fetchWithBQ(addQuery(url, query));
        return result.data
          ? { data: result.data as IPaginatedResponse<IMessageResponse> }
          : { error: result.error as FetchBaseQueryError };
      },
      keepUnusedDataFor: 2,
    }),
  }),
});

export const {
  useSendMessagesMutation,
  useGetMessagesQuery,
  useEditMessagesMutation,
  useDeleteMessageMutation,
  useLikeMessagesMutation,
} = chatApi;
