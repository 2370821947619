import DashboardUserWalkthrough from './components/walkthroughs/dashboardUserWalkthrough';
import ThreadDetailsUserWalkthrough from './components/walkthroughs/threadDetailsUserWalkthrough';

/**
 * User walk-throughs wrapper for onboarding first-time users.
 */
const UserWalkthrough = {
  Dashboard: DashboardUserWalkthrough,
  ThreadDetails: ThreadDetailsUserWalkthrough,
};

export default UserWalkthrough;
