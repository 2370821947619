import { AVAILABLE_INTEGRATIONS, INTEGRATION_LIST } from 'constants/integrations';
import { IIntegration } from 'modules/integrations/types';
import React, { useMemo } from 'react';
import colors from 'theme/colors';
import { Icon, Text } from 'ui';
import * as Layout from './Layout';

interface IProps {
  integration: IIntegration;
  isAdminPanel?: boolean;
  onClick?: (integrationName: keyof typeof AVAILABLE_INTEGRATIONS) => void;
}

const IntegrationListItem: React.FC<IProps> = ({ integration, onClick, isAdminPanel = false }) => {
  const { name, connected } = integration;

  const { title, icon } = INTEGRATION_LIST[name];

  const handleItemClick = () => {
    if (isAdminPanel && onClick) {
      onClick(name);
    }
  };

  const activeIntegrationText = useMemo(() => (connected ? 'Connected' : ' '), [connected]);

  return (
    <Layout.IntegrationItem
      connected={isAdminPanel ? false : connected}
      data-cy="integration-list-item"
    >
      <Layout.IntegrationItemButton
        connected={isAdminPanel ? connected : true}
        disabled={isAdminPanel ? connected : true}
        type="button"
        onClick={handleItemClick}
        data-cy="integration-list-item-btn"
      >
        {icon}
        <Text
          size="sm"
          weight="500"
          align="center"
          noWrap
          color={colors.white}
          cypressAttribute="integration-list-item-title"
        >
          {title}
        </Text>
      </Layout.IntegrationItemButton>
      {!isAdminPanel && (
        <Text
          size="sm"
          weight="500"
          align="center"
          color={colors.green1}
          cypressAttribute="integration-list-item-text"
        >
          {activeIntegrationText}
        </Text>
      )}
      {isAdminPanel && (
        <Icon
          icon="CloseIcon"
          path={colors.white}
          style={{
            width: 20,
            height: 20,
            position: 'absolute',
            top: '8px',
            right: '8px',
            cursor: 'pointer',
          }}
          onClick={() => onClick && onClick(name)}
        />
      )}
    </Layout.IntegrationItem>
  );
};

export default IntegrationListItem;
