import useSubscriptionPermissions from 'hooks/useSubscriptionPermissions';
import _isEmpty from 'lodash/isEmpty';
import { NOTIFICATION } from 'modules/alert/constants';
import { showSuccessMessage } from 'modules/alert/utils';
import { useInviteUsersMutation } from 'modules/inviteUsers/inviteUsersApi';
import { useModal } from 'modules/modals/ModalProvider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import colors from 'theme/colors';
import { IErrorMessages, TReactSelectOption } from 'types';
import { Button, Group, ReactSelect, Spinner, Stack, Text } from 'ui';
import { EMAIL_REGEX, MESSAGE } from 'utils/validation';
import { EUserRole } from 'types/user';
import { displayErrorMessages } from 'utils/helpers';

export interface IFormValues {
  emails: string[];
  userRole: EUserRole;
}

const Form = styled.form`
  height: 100%;
  display: flex;
  gap: 10vh;
  justify-content: space-between;
  flex-direction: column;

  .react-select__dropdown-indicator {
    display: none;
  }
`;

const INVITE_ROLE_OPTIONS = [
  { value: EUserRole.ADMIN, label: 'Admin' },
  { value: EUserRole.USER, label: 'User' },
];

export const InviteMembers: React.FC = () => {
  const { close } = useModal();
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);

  const { handleSubscriptionPermissionErrors } = useSubscriptionPermissions();
  const [inviteUsers, { isLoading }] = useInviteUsersMutation();

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitted },
  } = useForm<IFormValues>({
    mode: 'onChange',
    resolver: undefined,
  });

  const handleChange = (options: TReactSelectOption[]) => {
    setInvalidEmail(false);
    const isValid = options.every((option) => option.value.trim().match(EMAIL_REGEX));

    if (!isValid) {
      setInvalidEmail(true);
    }
    setValue(
      'emails',
      options.map((item) => item.value)
    );
  };

  const handleRoleChange = (option: TReactSelectOption) => {
    setValue('userRole', option.value as EUserRole);
  };

  const onSubmit = async (data: IFormValues) => {
    const isFormValid = data.emails?.length > 0 && data.userRole && !invalidEmail;
    if (!isFormValid) return;

    const payload = data.emails.reduce(
      (acc, curr) => {
        const payloadOption = { email: curr, userRole: data.userRole };
        acc.push(payloadOption);
        return acc;
      },
      [] as { email: string; userRole: EUserRole }[]
    );
    try {
      await inviteUsers(payload).unwrap();
      showSuccessMessage(NOTIFICATION.USER_INVITE_SUCCESS);
      close();
    } catch (error: unknown) {
      const errorMessages = error as { data: IErrorMessages[] | null };
      if (errorMessages.data?.length) {
        errorMessages.data.forEach(displayErrorMessages);
      } else {
        handleSubscriptionPermissionErrors(error, 'Could not invite user. Please try again.');
      }
    }
  };

  useEffect(() => {
    setValue('userRole', INVITE_ROLE_OPTIONS[0].value);
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} data-cy="modal-invite-members-form">
      <Stack gap="20px">
        <ReactSelect
          label="Email"
          autoFocus
          id="invite-users-select"
          placeholder="Enter user email"
          menuIsOpen
          isSearchable
          onChange={handleChange}
        />
        {isSubmitted && _isEmpty(getValues().emails) && (
          <Text color={colors.red}>Please enter an email.</Text>
        )}
        {invalidEmail && <Text color={colors.red}>{MESSAGE.INVALID_EMAIL}</Text>}
        <ReactSelect
          label="User Role"
          id="invite-user-role-select"
          defaultValue={[{ value: 'admin', label: 'Admin' }]}
          options={INVITE_ROLE_OPTIONS}
          isCreatable={false}
          isMulti={false}
          isSearchable={false}
          onChange={handleRoleChange}
        />
      </Stack>
      <Group justify="end" gap="15px" style={{ marginTop: 'auto' }} fluid>
        <Button
          color={colors.dark2}
          onClick={close}
          fluid
          disabled={isLoading}
          cypressAttribute="modal-invite-members-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          fluid
          disabled={isLoading}
          cypressAttribute="modal-invite-members-send-btn"
        >
          {isLoading ? <Spinner size="small" /> : 'Invite'}
        </Button>
      </Group>
    </Form>
  );
};

export default InviteMembers;
