import { useAppDispatch } from 'app/hooks';
import { useStream } from 'modules/stream/useStream';
import { ReactElement } from 'react';
import { eventHandler } from './handlers';
import { ISocketPayload } from './types';

const Stream = ({ children }: { children: ReactElement }) => {
  const dispatch = useAppDispatch();

  const handler = (message: ISocketPayload) => {
    dispatch(eventHandler(message));
  };

  useStream(handler);
  return children;
};
export default Stream;
