import { useAppSelector } from 'app/hooks';
import { THREAD_STATUS_COLORS, THREAD_STATUS_NAMES } from 'constants/index';
import { selectDashboardThreadsFilterOptionId } from 'modules/app/appSlice';
import moment from 'moment';
import { EThreadsListOptionIds, EThreadsStatisticOptionIds } from 'pages/dashboard/constants';
import React, { useEffect, useState } from 'react';
import pallet from 'theme/colors';
import { EThreadStatuses } from 'types';
import { Divider, Stack, Text, Title } from 'ui';
import { useGetStatisticQuery } from '../../threadsApi';
import { IChartProps } from '../../types';
import Chart from '../chart';
import Label from './Label';
import * as Layout from './Layout';
import { FILTER_OPTION_MAP } from './constants';
import { getOptions } from './utils';

interface IProps {
  title: string;
  dateFrom: Date;
  activeOptionId?: number | string;
  cypressAttribute?: string;
}

const initChatData: Omit<IChartProps, 'title'> = {
  labels: [],
  data: [],
  colors: [],
};

type TActiveOptionTextMapping = {
  [k: string]: string;
};

const StatisticCard = ({ dateFrom, cypressAttribute, activeOptionId }: IProps) => {
  const dashboardThreadsFilterOptionId = useAppSelector(selectDashboardThreadsFilterOptionId);
  const threadType = FILTER_OPTION_MAP[dashboardThreadsFilterOptionId as EThreadsListOptionIds];
  const { data } = useGetStatisticQuery(
    {
      dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
      type: threadType.value,
    },
    { refetchOnMountOrArgChange: true }
  );
  const [chartData, setChatData] = useState(initChatData);

  const activeOptionTextMapping: TActiveOptionTextMapping = {
    [EThreadsStatisticOptionIds.LAST_7_DAYS]: '7 days',
    [EThreadsStatisticOptionIds.LAST_30_DAYS]: '30 days',
  };

  useEffect(() => {
    if (data) {
      const { all, ...rest } = data;
      const threadTypes = Object.keys(rest);
      const labels = getOptions(threadTypes as EThreadStatuses[], THREAD_STATUS_NAMES);
      const colors = getOptions(threadTypes as EThreadStatuses[], THREAD_STATUS_COLORS);
      const values = Object.values(rest);
      setChatData({ labels, data: values, colors });
    }
  }, [data]);

  if (!data) return null;

  return (
    <Layout.Container gap="32px" data-cy={cypressAttribute}>
      {data.all === 0 ? (
        <Stack fullHeight justify="center" align="center">
          <Text color={pallet.gray1}>
            No changes to thread status have been made in the last{' '}
            {activeOptionId && activeOptionTextMapping[activeOptionId]}.
          </Text>
        </Stack>
      ) : (
        <Layout.Content gap="30px" data-cy={cypressAttribute && `${cypressAttribute}-data`}>
          <Layout.ChartWrapper>
            <Chart title="threads" {...chartData} />
          </Layout.ChartWrapper>
          <div style={{ flex: 3 }}>
            <Stack gap="16px">
              <Title
                cypressAttribute={cypressAttribute && `${cypressAttribute}-data-threads`}
                heading="h1"
              >
                {data?.all}
              </Title>
              <Divider />
              <Layout.Labels data-cy={cypressAttribute && `${cypressAttribute}-data-labels`}>
                {[
                  EThreadStatuses.ON_TARGET,
                  EThreadStatuses.COMPLETE,
                  EThreadStatuses.NEEDS_ATTENTION,
                ].map((status) => (
                  <Label
                    key={status}
                    type={status}
                    count={data[status]}
                    dateFrom={dateFrom}
                    cypressAttribute={cypressAttribute && `${cypressAttribute}-data-label-item`}
                  />
                ))}
              </Layout.Labels>
              <Layout.Labels data-cy={cypressAttribute && `${cypressAttribute}-data-labels`}>
                {[
                  EThreadStatuses.AHEAD_OF_SCHEDULE,
                  EThreadStatuses.AT_RISK,
                  EThreadStatuses.ARCHIVE,
                ].map((status) => (
                  <Label
                    key={status}
                    type={status}
                    count={data[status]}
                    dateFrom={dateFrom}
                    cypressAttribute={cypressAttribute && `${cypressAttribute}-data-label-item`}
                  />
                ))}
              </Layout.Labels>
            </Stack>
          </div>
        </Layout.Content>
      )}
    </Layout.Container>
  );
};

export default StatisticCard;
