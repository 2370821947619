import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Avatar, Group } from 'ui';
import { formatAvatarSrcURI, getFullName } from 'utils/helpers';
import Tooltip from 'components/ui/tooltip';
import RestMembers from './Restmembers';
import { IMember, IMemberListProps } from './types';

const StyledAvatar = styled(Avatar)<{ offset: number }>`
  margin-left: -${({ offset }) => offset && 10}px;
`;

const MemberList: React.FC<IMemberListProps> = ({ show = 4, list, disabled }) => {
  const filteredList = list.reduce((acc, member) => {
    if (!acc.find((m) => m.id === member.id)) acc.push(member);
    return acc;
  }, [] as IMember[]);
  const data = useMemo(() => filteredList.slice(0, show), [show, list]);
  const restMembers = filteredList.slice(show);

  return (
    <Group gap="0" data-cy="members-list-group">
      {data.map((member, index) => (
        <Tooltip content={getFullName(member)}>
          <StyledAvatar
            size="mediumSmall"
            key={member.id}
            offset={index}
            src={formatAvatarSrcURI(member.avatar)}
            data-cy="members-list-avatar"
          />
        </Tooltip>
      ))}
      {restMembers.length > 0 && <RestMembers disabled={disabled} list={list.slice(show)} />}
    </Group>
  );
};

export default MemberList;
