import { useSendCopilotChatMessageMutation } from 'modules/copilot/copilotApi';
import { EEventType, ISocketPayload } from 'modules/stream/types';
import { useStream } from 'modules/stream/useStream';
import {
  EThreadsBotMessageSenderTypes,
  EThreadsBotMessageTypes,
  IThreadsBotChatResponse,
  TThreadsBotMessage,
} from 'modules/threadsBot/types';
import { useState } from 'react';
import { generateShortUUID } from 'utils/helpers';
import { deepCamelcaseKeys } from 'utils/mappers';

export const useThreadsBotChat = () => {
  const [messages, setMessages] = useState<TThreadsBotMessage[]>([]);
  const [isResponding, setIsResponding] = useState(false);
  const [sendChatMessage] = useSendCopilotChatMessageMutation();
  const [chatHistory, setChatHistory] = useState('First Message');

  const pushMessage = (payload: {
    message: string;
    type: EThreadsBotMessageTypes;
    senderType: EThreadsBotMessageSenderTypes;
  }) => {
    setMessages((prev) => [
      ...prev,
      {
        ...payload,
        id: generateShortUUID(),
      },
    ]);
  };

  const sendMessage = async (message: string) => {
    pushMessage({
      message,
      type: EThreadsBotMessageTypes.MESSAGE,
      senderType: EThreadsBotMessageSenderTypes.USER,
    });
    setIsResponding(true);
    try {
      await sendChatMessage({ message, chatHistory }).unwrap();
    } catch (error) {
      pushMessage({
        message: 'ThreadsBot was unable to generate a response. Please try again.',
        type: EThreadsBotMessageTypes.ERROR,
        senderType: EThreadsBotMessageSenderTypes.THREADS_BOT,
      });
      console.error(error);
      setIsResponding(false);
    }
  };

  const errorMessage = (type: EThreadsBotMessageTypes) => {
    switch (type) {
      case EThreadsBotMessageTypes.ERROR:
        return 'ThreadsBot was unable to generate a response. Please try again.';
      case EThreadsBotMessageTypes.UNINITIALIZED_ERROR:
        return 'There is not enough data in your workspace for ThreadsBot to generate a response.';
      default:
        return 'An error occurred in ThreadsBot. Unexpected message received.';
    }
  };

  // On receiving a copilot suggestion notification, refetch from api
  const handler = (payload: ISocketPayload) => {
    if (payload.type === EEventType.COPILOT_CHAT_MESSAGE) {
      setIsResponding(false);
      const context = deepCamelcaseKeys(payload.context) as IThreadsBotChatResponse;
      const message =
        context.type === EThreadsBotMessageTypes.MESSAGE
          ? context.response
          : errorMessage(context.type);
      // TODO: Handle error type for NOT_ENOUGH_DATA
      pushMessage({
        message,
        type: context.type,
        senderType: EThreadsBotMessageSenderTypes.THREADS_BOT,
      });
      // Chat history not returned in errors
      if (context.chatHistory) {
        setChatHistory(context.chatHistory);
      }
    }
  };

  useStream(handler);

  return { messages, sendMessage, isResponding };
};
