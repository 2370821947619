import { api } from 'app/api';
import { DEFAULT_THREADS_3D_CREATOR } from 'modules/annotations/helpers';
import { IAnnotation, IAnnotationRaw } from 'modules/annotations/types';
import { IPaginatedResponse } from 'types';

export const threads3dAnnotationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    threads3dAnnotations: build.query<
      IPaginatedResponse<IAnnotation>,
      { threadId: string; limit: number; offset: number }
    >({
      query: ({ threadId, limit, offset }) => ({
        url: `/threads/${threadId}/threads-3d-annotations/`,
        params: {
          limit,
          offset,
        },
      }),
      providesTags: ['threads3dAnnotations'],
      transformResponse: (
        response: IPaginatedResponse<IAnnotationRaw>
      ): IPaginatedResponse<IAnnotation> => {
        return {
          ...response,
          results: response.results.map((annotation) => ({
            ...annotation,
            creator: annotation.creator || DEFAULT_THREADS_3D_CREATOR,
            threads3dComments: annotation.threads3dComments.map((comment) => ({
              ...comment,
              creator: comment.creator || DEFAULT_THREADS_3D_CREATOR,
            })),
          })),
        };
      },
    }),
    threads3dAccessLink: build.mutation<{ location?: string }, { referenceId: number }>({
      query: (payload) => ({
        url: `/threads-3d/access-link/${payload.referenceId}/`,
        method: 'GET',
      }),
      transformResponse(_response, _meta) {
        const meta = _meta as { response: Response };
        return { location: meta?.response.headers.get('Location') ?? undefined };
      },
    }),
  }),
});

export const { useThreads3dAnnotationsQuery, useThreads3dAccessLinkMutation } =
  threads3dAnnotationsApi;
