import React, { ReactElement, useEffect } from 'react';
import { useAppDispatch } from 'app/hooks';
import _ from 'lodash';
import { markAllUserWalkthroughsAsIncomplete } from 'modules/userWalkthrough/userWalkthroughSlice';
import { useGetUserPreferencesQuery } from '../user/userApi';

interface IProps {
  children: ReactElement;
}

export const UserWalkthroughProvider: React.FC<IProps> = ({ children }: IProps) => {
  const dispatch = useAppDispatch();
  const { data: userPreferencesData } = useGetUserPreferencesQuery();

  const initializeUserWalkthroughs = async () => {
    // If `showNewUserWalkthrough` is true, mark the walk-throughs in Redux as incomplete (false).
    if (userPreferencesData?.showNewUserWalkthrough === true) {
      dispatch(markAllUserWalkthroughsAsIncomplete());
    }
  };

  useEffect(() => {
    initializeUserWalkthroughs();
  }, [userPreferencesData]);

  return children;
};
