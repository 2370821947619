import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import routes from 'constants/routes';
import { ILoginMutation } from 'modules/auth/types';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import colors from 'theme/colors';
import Spinner from 'ui/spinner';
import { validationSchema } from 'utils/validation';
import * as yup from 'yup';
import { Label } from 'components/ui/label';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';

const validation = yup.object({
  email: validationSchema.emailRequired(),
  password: validationSchema.stringRequired(),
});

interface Props {
  email: string;
  onSubmit: (fields: ILoginMutation) => void;
  isSubmitting: boolean;
  handleCancel: () => void;
}

export const EmailPasswordForm: React.FC<Props> = ({
  email,
  onSubmit,
  isSubmitting,
  handleCancel,
}) => {
  const form = useForm<ILoginMutation>({
    defaultValues: {
      email,
      password: '',
    },
    resolver: yupResolver(validation),
    mode: 'onSubmit',
    shouldFocusError: false,
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-8">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input className="border-solid" autoFocus disabled {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex flex-col gap-4">
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input
                      className="border-solid"
                      type="password"
                      placeholder="Enter Password"
                      autoFocus
                      disabled={isSubmitting}
                      data-cy="sign-in-password"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex items-center justify-end">
              <Link to={routes.forgotPassword} className="text-sm text-neutral-300">
                Forgot my password
              </Link>
            </div>
          </div>
          <div className="flex w-full flex-col gap-4">
            <Button type="submit">
              {isSubmitting ? <Spinner size="small" color={colors.dark} /> : 'Login'}
            </Button>
            <Button variant="outline" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};
