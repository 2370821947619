export const NOTIFICATION = {
  INVALID_LOGIN: 'Unable to login with provided credentials!',
  INVALID_THREAD: 'Unable to create new thread',
  THREAD_CREATED: 'Thread successfully created',
  THREAD_UPDATED: 'Thread successfully updated',
  THREAD_DELETED: 'Thread successfully deleted',
  THREAD_ARCHIVED: 'Thread successfully archived',
  REFERENCE_CREATED: 'Reference successfully created',
  REFERENCE_UPDATED: 'Reference successfully updated',
  REFERENCE_DELETED: 'Reference successfully deleted',
  USER_INVITE_SUCCESS: 'User successfully invited',
  USER_INVITE_DELETED: 'User successfully deleted',
  CONTRIBUTOR_CREATED: 'Contributor successfully has been added',
  CONTRIBUTOR_DELETED: 'Contributor successfully deleted',
  RESOURCE_CREATED: 'Resource successfully created',
  RESOURCE_DELETED: 'Resource successfully deleted',
  RELATED_THREAD_CREATED: 'Related thread successfully created',
  RELATED_THREAD_DELETED: 'Related thread successfully deleted',
  ADD_TAGS: 'Tags successfully added',
  SOMETHING_WRONG: 'Oops! Something went wrong. Try again.',
  MAX_SIZE_UPLOAD: 'The limit has been exceeded, the maximum file size is 50 MB',
  ACCOUNT_CREATED: 'Account created successfully',
  INVALID_INVITE_LINK: 'Invalid link! Check your invitation link and try again',
  REQUEST_SENT: 'Request successfully sent',
  PASSWORD_CHANGED: 'Password successfully changed',
  MESSAGE_DELETED: 'Message successfully deleted',
  EVENT_CREATED: 'Event successfully created',
  EVENT_DELETED: 'Event successfully deleted',
  EVENT_UPDATED: 'Event successfully updated',
  EVENT_404: 'Event not found. Verify the event ID and try again.',
  PROFILE_UPDATED: 'Profile successfully updated',
  INVALID_PASSWORD: 'Wrong password',
  THREAD_NOT_FOUND: 'Thread not found',
  EMAIL_NOTIFICATION_PERMISSION: 'Email notification permission successfully updated',
  UPDATE_MEMBER_ROLE: 'Member role successfully updated',
  CLIPBOARD_COPY_FAILURE:
    'Error occurred in trying to copy to clipboard, please check your browser settings and try again.',
};
