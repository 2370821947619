import { usePlayableStatus } from 'modules/playable';
import { IFileVersions, REFERENCE_TYPE } from 'modules/threads/types';
import { useThreads3dStatus } from 'modules/threads3d/hooks/status';
import React from 'react';
import colors from 'theme/colors';
import { Button, Group, Icon, TagBadge, Text } from 'ui';
import { handleDownloadFile } from 'utils/helpers';
import * as Layout from './Layout';

interface IProps {
  file: IFileVersions;
  fileName: string;
  selectVersion: () => void;
  isCurrentVersion: boolean;
  fileType: (typeof REFERENCE_TYPE)[keyof typeof REFERENCE_TYPE];
  cypressAttribute?: string;
}

const FileVersionItem: React.FC<IProps> = ({
  file,
  fileName,
  selectVersion,
  isCurrentVersion,
  fileType,
  cypressAttribute,
}) => {
  const { active: threads3dEnabled } = useThreads3dStatus();
  const { isPlayableFunctionalAvailable } = usePlayableStatus();
  const isCurrent = isCurrentVersion ? '(current)' : '';

  const isFileWithIntegration =
    fileType === REFERENCE_TYPE.THREADS_3D || fileType === REFERENCE_TYPE.PLAYABLE;

  const isThreads3dIntegration = fileType === REFERENCE_TYPE.THREADS_3D && threads3dEnabled;
  const isPlayableIntegration =
    fileType === REFERENCE_TYPE.PLAYABLE && isPlayableFunctionalAvailable;

  const handleSelectVersion = () => {
    if (isThreads3dIntegration || isPlayableIntegration) {
      selectVersion();
    }
  };

  const viewSyncDataText =
    isThreads3dIntegration || isPlayableIntegration ? 'View Sync Data' : 'Integration disabled';

  return (
    <Layout.FilePreviewItem data-cy={cypressAttribute}>
      <Text weight="500" color={colors.gray2}>
        {fileName}
      </Text>
      <Layout.BadgeContainer>
        <TagBadge color={colors.blue9} data-cy={cypressAttribute && `${cypressAttribute}-badge`}>
          <Text weight="400" size="xs" noWrap>
            {`Version ${file.version} ${isCurrent}`}
          </Text>
        </TagBadge>
      </Layout.BadgeContainer>
      {isFileWithIntegration ? (
        <Layout.SyncFileButton
          type="button"
          onClick={handleSelectVersion}
          data-cy={cypressAttribute && `${cypressAttribute}-encube-sync`}
        >
          <Text weight="400" size="xs" noWrap>
            {viewSyncDataText}
          </Text>
          {(isThreads3dIntegration || isPlayableIntegration) && (
            <Icon icon="ViewSyncData" size="small" />
          )}
        </Layout.SyncFileButton>
      ) : (
        <Group align="center" data-cy={cypressAttribute && `${cypressAttribute}-file`}>
          <Icon icon="ReferenceDocumentIcon" size="medium" stroke="none" />
          <Text weight="400" size="sm">
            File
          </Text>
        </Group>
      )}
      <Button
        variant="plain"
        onClick={handleDownloadFile(fileName, file.file)}
        cypressAttribute={cypressAttribute && `${cypressAttribute}-download-btn`}
      >
        <Text weight="400" color={colors.brandSecondaryLight}>
          Download
        </Text>
        <Icon icon="ShareIcon" size="normal" />
      </Button>
    </Layout.FilePreviewItem>
  );
};

export default FileVersionItem;
