import React from 'react';
import styled from 'styled-components';
import { Avatar, Button, Group, Stack, Text } from 'ui';
import { useModal } from 'modules/modals/ModalProvider';
import { useDeleteMemberMutation } from 'modules/inviteUsers/inviteUsersApi';
import {
  showErrorMessage,
  showSuccessMessage,
  showUncaughtErrorMessage,
} from 'modules/alert/utils';
import { NOTIFICATION } from 'modules/alert/constants';
import { handleApiCall } from 'utils/helpers';
import * as Layout from 'pages/members/components/Layout';
import colors from 'theme/colors';
import { IUserInvites } from 'modules/inviteUsers/types';

const Form = styled.form`
  height: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

interface DeleteInviteProps {
  invite: IUserInvites;
}

const DeleteInvite: React.FC<DeleteInviteProps> = ({ invite }) => {
  const [deleteMember, { isLoading }] = useDeleteMemberMutation();
  const { close } = useModal();

  const handleDeleteMember = async () => {
    const res = await deleteMember(invite.id);
    handleApiCall(
      res,
      () => showUncaughtErrorMessage(),
      () => {
        showSuccessMessage(NOTIFICATION.USER_INVITE_DELETED);
        close();
      }
    );
  };

  return (
    <Form>
      <Layout.CardUserContainer align="center">
        <Avatar />
        <Stack gap="10px" data-cy="modal-delete-member-user-info">
          <Text>{invite.email}</Text>
        </Stack>
      </Layout.CardUserContainer>
      <Group justify="end" gap="15px">
        <Button
          color={colors.dark2}
          onClick={close}
          cypressAttribute="modal-delete-member-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          color={colors.red2}
          disabled={isLoading}
          onClick={handleDeleteMember}
          cypressAttribute="modal-delete-member-remove-btn"
        >
          {isLoading ? 'Removing...' : 'Remove'}
        </Button>
      </Group>
    </Form>
  );
};

export default DeleteInvite;
