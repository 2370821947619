import React from 'react';
import { RotatingLines } from 'react-loader-spinner';
import colors from 'theme/colors';
import { LARGE, MEDIUM, SMALL } from '../../constants';
import { sizeMap } from './constants';

export type TSpinnerSize = typeof SMALL | typeof MEDIUM | typeof LARGE;

interface IProps {
  color?: string;
  size?: TSpinnerSize;
  strokeWidth?: string;
  width?: string;
}

const Spinner: React.FC<IProps> = ({ color = colors.gray1, size = MEDIUM, strokeWidth, width }) => {
  return (
    <RotatingLines
      strokeColor={color}
      animationDuration="0.75"
      width={width ?? sizeMap[size].width}
      strokeWidth={strokeWidth ?? sizeMap[size].strokeWidth}
      visible
    />
  );
};

export default Spinner;
