import TextEditor from '@draft-js-plugins/editor';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import '@draft-js-plugins/linkify/lib/plugin.css';
import createMentionPlugin, {
  defaultSuggestionsFilter,
  MentionData,
} from '@draft-js-plugins/mention';
import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  DraftHandleValue,
  EditorState,
  getDefaultKeyBinding,
  RawDraftContentState,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import React, { forwardRef, KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { EFeatureFlags } from 'constants/features';
import { useIsFeatureEnabled } from 'hooks/useFeatures';
import { Suggestions } from 'modules/chat/components/Editor/components/Suggestions';
import { useContributors } from 'modules/contributors/useContributors';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'theme/colors';
import { Button, Group, Icon, Spinner } from 'ui';
import { switchCase } from 'utils/match';
import { hasText } from '../../utils';
import { EditWrapper } from '../Layout';
import CustomMentionContainer from './components/Entry';
import editorStyles from './editor.styles.module.css';
import mentionsStyles from './mention.styles.module.css';

interface IEditorProps {
  onSubmit?: (payload: RawDraftContentState, hasText: boolean) => Promise<void>;
  readonly?: boolean;
  placeholder?: string;
  initialValue?: string;
  onPasteFiles?: (files: File[]) => void;
  toggleEditMode?:
    | {
        editMode?: boolean;
        setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
      }
    | undefined;
  isSubmittingEditMessage?: boolean;
}

const SendButton = styled(Button)`
  transition: 0.2s ease;
  transform: scale(1);
  padding: 2px;

  &:hover {
    transform: scale(1.1);
    background: ${colors.bgSlate50};
  }
`;

const Editor = forwardRef<TextEditor, IEditorProps>(
  (
    {
      onSubmit,
      readonly = false,
      placeholder,
      initialValue,
      onPasteFiles,
      toggleEditMode,
      isSubmittingEditMessage = false,
    },
    ref
  ) => {
    const getInitialState = useCallback(
      () =>
        initialValue
          ? EditorState.createWithContent(convertFromRaw(JSON.parse(initialValue)))
          : EditorState.createEmpty(),
      [initialValue]
    );
    const [editorState, setEditorState] = useState(getInitialState);
    const [open, setOpen] = useState(false);
    const { id } = useParams();
    const isCopilotFeatureEnabled = useIsFeatureEnabled(EFeatureFlags.COPILOT);

    const { contributorMentions: members } = useContributors(id as string);
    const [mentionSuggestions, setMentionSuggestions] = useState(members);

    const resetState = () => {
      const state = EditorState.push(editorState, ContentState.createFromText(''), 'remove-range');
      setEditorState(EditorState.moveFocusToEnd(state));
    };
    // force update
    useEffect(() => setEditorState(getInitialState), [initialValue, readonly]);

    const { MentionSuggestions, plugins } = useMemo(() => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const mentionPlugin = createMentionPlugin({
        theme: mentionsStyles,
        mentionPrefix: '@',
        popperOptions: {
          placement: 'top-start',
        },
      });
      const linkifyPlugin = createLinkifyPlugin({ target: '_blank' });
      // eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
      const { MentionSuggestions } = mentionPlugin;
      // eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
      const plugins = [mentionPlugin, linkifyPlugin];
      return { plugins, MentionSuggestions };
    }, []);

    const handleSubmit = () => {
      const value = editorState.getCurrentContent();
      if (onSubmit) {
        if (!initialValue) {
          resetState();
        }
        onSubmit(convertToRaw(value), hasText(value));
      }
    };

    const handleKeyCommand = (command: string): DraftHandleValue => {
      if (command === 'submit') {
        handleSubmit();
        return 'handled';
      }
      return 'not-handled';
    };

    const keyBindingFn = useCallback(
      (event: KeyboardEvent) =>
        switchCase(true)
          .on(event.code === 'Enter' && event.shiftKey, () => getDefaultKeyBinding(event))
          .on(event.code === 'Enter', () => 'submit')
          .otherwise(() => undefined),
      []
    );

    const onOpenChange = useCallback(
      (_open: boolean) => {
        setOpen(_open);
      },
      [editorState]
    );

    const onSearchChange = useCallback(
      ({ value }: { value: string }) => {
        const mentionContributorsSuggestion = {
          name: 'contributors',
        };

        setMentionSuggestions([
          mentionContributorsSuggestion,
          ...defaultSuggestionsFilter(value, members as MentionData[]),
        ]);
      },
      [members]
    );

    const handlePasteFiles = (files: File[]) => {
      if (onPasteFiles) {
        onPasteFiles(files);
      }
      return 'handled' as DraftHandleValue;
    };

    return (
      <>
        <EditWrapper readOnly={!toggleEditMode?.editMode} data-cy="chat-edit-wrapper">
          <Group align="center" gap="10px" fluid>
            <div className={editorStyles.editor} style={{ maxHeight: readonly ? 'auto' : '200px' }}>
              <TextEditor
                editorKey="editor"
                placeholder={!readonly ? placeholder ?? 'Comment...' : undefined}
                editorState={editorState}
                onChange={setEditorState}
                plugins={plugins}
                handleKeyCommand={handleKeyCommand}
                handlePastedFiles={handlePasteFiles}
                keyBindingFn={keyBindingFn}
                readOnly={readonly}
                // ref={ref}
              />
              <MentionSuggestions
                open={open}
                onOpenChange={onOpenChange}
                suggestions={mentionSuggestions}
                onSearchChange={onSearchChange}
                entryComponent={CustomMentionContainer}
              />
            </div>

            {!toggleEditMode?.editMode && !readonly && (
              <>
                {isCopilotFeatureEnabled && id && (
                  <div id="copilot-lightbulb">
                    <Suggestions threadId={id} />
                  </div>
                )}
                <SendButton variant="plain" onMouseDown={handleSubmit} data-cy="chat-edit-send-btn">
                  <Icon icon="SendIcon" stroke={colors.gray1} />
                </SendButton>
              </>
            )}
          </Group>
        </EditWrapper>
        {toggleEditMode?.editMode && (
          <Group justify="flex-end" fluid>
            <Button
              variant="outlined"
              color={colors.orange_dark}
              size="sm"
              onClick={() => toggleEditMode.setEditMode && toggleEditMode.setEditMode(false)}
              cypressAttribute="chat-edit-cancel-btn"
            >
              Cancel
            </Button>
            <Button
              disabled={isSubmittingEditMessage}
              color={colors.orange}
              size="sm"
              onClick={handleSubmit}
              cypressAttribute="chat-edit-save-btn"
            >
              {isSubmittingEditMessage ? <Spinner color="white" size="small" /> : 'Save'}
            </Button>
          </Group>
        )}
      </>
    );
  }
);

export default Editor;
