import { useAppSelector } from 'app/hooks';
import _ from 'lodash';
import { selectUserInfo } from 'modules/auth/userSlice';
import ContributorCard from 'modules/contributors/components/ContributorCard';
import ContributorInviteCard from 'modules/contributors/components/ContributorInviteCard';
import { IContributor, IContributorInvite } from 'modules/contributors/types';
import { useContributors } from 'modules/contributors/useContributors';
import { isThreadOwner } from 'modules/threads/threadsSlice';
import * as Layout from 'pages/thread/components/Layout';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Divider, Spinner, Stack, Text } from 'ui';
import { isGuest } from 'utils/helpers';
import ContributorRequestCard from './components/ContributorRequestCard';
import {
  useGetContributorsQuery,
  useGetInvitesQuery,
  useGetThreadJoinRequestsQuery,
} from './contributorsApi';

const Contributors: React.FC = () => {
  const { id } = useParams();
  const { userRole, id: currentUserId } = useAppSelector(selectUserInfo);
  const { isCurrentUserContributor: isContributor } = useContributors(id as string);
  const isCurrentUserThreadOwner = useAppSelector(isThreadOwner(id as string, currentUserId));

  const { data, isLoading } = useGetInvitesQuery({ id: _.toNumber(id) });
  const { data: contributorsData, isLoading: isContributorLoading } = useGetContributorsQuery({
    id: id as string,
  });

  const isRequestsToJoinControlsVisible = useMemo(
    () => (!isGuest(userRole) && isContributor) || isCurrentUserThreadOwner,
    [userRole, isContributor, isCurrentUserThreadOwner]
  );
  // We don't need to fetch requests if user is not site owner or admin
  const { data: threadJoinRequestsData, isLoading: isThreadJoinRequestsLoading } =
    useGetThreadJoinRequestsQuery(
      { threadId: id as string },
      { skip: !isRequestsToJoinControlsVisible }
    );

  const contributorInvites = data?.results;
  const isDataLoading = isLoading || isContributorLoading || isThreadJoinRequestsLoading;

  if (isDataLoading) {
    return (
      <Stack align="center" justify="center" style={{ padding: '5rem' }}>
        <Spinner />
      </Stack>
    );
  }

  if (
    _.every([
      !contributorInvites?.length,
      !contributorsData?.results?.length,
      !threadJoinRequestsData?.results?.length,
    ])
  )
    return <Layout.NoItems>No contributors...</Layout.NoItems>;

  return (
    <Layout.TabContentWrap>
      {threadJoinRequestsData?.results?.length
        ? isRequestsToJoinControlsVisible && (
            <Stack gap="15px">
              <Stack gap="8px">
                <Text weight="700" size="lg" style={{ letterSpacing: 0.9 }}>
                  Requests:
                </Text>
                <Divider />
              </Stack>
              {threadJoinRequestsData?.results?.map((request) => (
                <ContributorRequestCard key={request.id} {...request} />
              ))}
            </Stack>
          )
        : null}
      {contributorInvites?.length ? (
        <Stack gap="15px">
          <Stack gap="8px">
            <Text weight="700" size="lg" style={{ letterSpacing: 0.9 }}>
              Invites:
            </Text>
            <Divider />
          </Stack>
          {contributorInvites?.map((inviteContributor: IContributorInvite) => (
            <ContributorInviteCard key={inviteContributor.id} {...inviteContributor} />
          ))}
        </Stack>
      ) : null}

      {contributorsData?.results?.length ? (
        <Stack gap="15px">
          <Stack gap="8px">
            <Text weight="700" size="lg" style={{ letterSpacing: 0.8 }}>
              Contributors:
            </Text>
            <Divider />
          </Stack>
          {contributorsData?.results?.map((contributor: IContributor) => (
            <ContributorCard key={contributor.id} {...contributor} />
          ))}
        </Stack>
      ) : null}
    </Layout.TabContentWrap>
  );
};

export default Contributors;
