import { IUserMinimize } from 'types/user';
import { IPaginatedResponse } from '../../types';
import { IEventResponse } from '../events/types';
import { IThreadsResult } from '../threads/types';

export enum ENotificationTypes {
  MENTION = 'mention',
  REPLY = 'reply',
  REACTION = 'reaction',
  EVENT_CREATED = 'event_created',
  USER_EVENT_CREATED = 'user_event_created',
  CONTRIBUTOR_ADDED = 'contributor_added',
  CONTRIBUTOR_REMOVED = 'contributor_removed',
  THREAD_DELETED = 'thread_deleted',
  THREAD_ARCHIVED = 'thread_archived',
  THREAD_UPDATE_SUMMARY = 'thread_update_summary',
  THREAD_CONTRIBUTOR_INVITE_SUCCESS = 'thread_contributor_invite_success',
  THREAD_CONTRIBUTOR_INVITE_FAILURE = 'thread_contributor_invite_failure',
  THREAD_TOO_MANY_CONTRIBUTOR_INVITE = 'thread_too_many_contributor_invite',
  CONTRIBUTOR_LEAVE_THREAD = 'contributor_leave_thread',
  COPILOT_EVENT_SUGGESTIONS = 'copilot_event_suggestions',
  PLYABLE_QUOTE_READY = 'plyable_quote_ready',
  USER_REQUESTED_TO_JOIN_THREAD = 'user_requested_to_join_thread',
  USER_JOIN_REQUEST_APPROVED = 'user_join_request_approved',
  USER_JOIN_REQUEST_REJECTED = 'user_join_request_rejected',
}

export interface INotificationResponse {
  id: number;
  notificationType: ENotificationTypes;
  thread: number | null;
  data: string;
  createdAt: string;
  isRead: boolean;
  user: IUserMinimize;
  cypressAttribute?: string;
}

export interface INotificationReference {
  id: number;
  fileName: string;
  version: number;
}

export interface INotificationData {
  messageId?: number;
  initialMessage: string;
  repliedMessage?: string;
  contributor?: IUserMinimize;
  sender: IUserMinimize;
  thread: IThreadsResult;
  event?: IEventResponse;
  count?: number;
  reference?: INotificationReference;
  threadInviteId?: number;
  threadUserInviteId?: number;
}

export interface INotifications extends IPaginatedResponse<INotificationResponse> {
  unread: number;
}
