import { useAppSelector } from 'app/hooks';
import { EFeatureFlags } from 'constants/features';
import { AVAILABLE_INTEGRATIONS } from 'constants/integrations';
import routes from 'constants/routes';
import { useFeatureFlags } from 'hooks';
import { useIsFeatureEnabled } from 'hooks/useFeatures';
import { useSubscription } from 'hooks/useSubscription';
import { isEmpty, sortBy } from 'lodash';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { selectUserInfo } from 'modules/auth/userSlice';
import IntegrationListItem from 'modules/header/components/IntegrationListItem';
import { useGetUserAvailableIntegrationsQuery } from 'modules/integrations/integrationsApi';
import { useGetUserInvitesListQuery } from 'modules/inviteUsers/inviteUsersApi';
import { IUserInvites } from 'modules/inviteUsers/types';
import { useModal } from 'modules/modals/ModalProvider';
import { isCurrentCustomPaidSubscriptionPlan } from 'modules/subscriptionsPlans/subscriptionsPlansSlice';
import {
  useGetViewabilityOfThreadsQuery,
  useUpdateViewabilityOfThreadsMutation,
} from 'modules/threads/threadsApi';
import { useGetUsersQuery } from 'modules/user/userApi';
import { selectTotal, selectUsers } from 'modules/user/userSlice';
import { useWorkspaceQuery } from 'modules/workspace/workspaceApi';
import { InviteCard } from 'pages/members/components/InviteCard';
import * as Layout from 'pages/members/components/Layout';
import { MemberCard } from 'pages/members/components/MemberCard';
import { MemberCardSkeleton } from 'pages/members/components/MemberCardSkeleton';
import { QUERY_PARAMS } from 'pages/members/constants';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import colors from 'theme/colors';
import { Button, Divider, Group, Icon, Spinner, Stack, Text, Title } from 'ui';
import CheckBox from 'ui/checkbox';
import { handleApiCall, isAdmin, titleizeString } from 'utils/helpers';

const Members: React.FC = () => {
  const [offset, setOffset] = useState(0);
  const users = useAppSelector(selectUsers);
  const total = useAppSelector(selectTotal);
  const [isThreadGuestEnabled, setIsThreadGuestEnabled] = useState(false);
  const { isFetching, isLoading: isUsersLoading } = useGetUsersQuery({
    limit: QUERY_PARAMS.USERS_LIMIT,
    offset,
  });
  const { open } = useModal();
  const navigate = useNavigate();
  const [query] = useSearchParams();

  const { isSiteOwner, email, userRole } = useAppSelector(selectUserInfo);
  const isCustomPaidSubscription = useAppSelector(isCurrentCustomPaidSubscriptionPlan);

  const subscriptionSuccessQuery = query.get('success');
  const subscriptionType = query.get('type');

  const isSubscriptionSuccess = subscriptionSuccessQuery === 'true';
  const isUserSubscriptionType = subscriptionType === 'user';

  const { data: guestViewabilityAllThreads, isLoading: isAllThreadsLoading } =
    useGetViewabilityOfThreadsQuery();

  const { data: invites, isLoading: isInvitesLoading } = useGetUserInvitesListQuery({
    ordering: QUERY_PARAMS.ORDERING,
    limit: QUERY_PARAMS.INVITES_LIMIT,
    offset: QUERY_PARAMS.OFFSET,
  });

  const { data, isLoading } = useGetUserAvailableIntegrationsQuery();
  const { data: subscriptionInfo, isLoading: isSubscriptionLoading } = useSubscription();
  const [useUpdateThreadViewability, { isLoading: isUpdating }] =
    useUpdateViewabilityOfThreadsMutation();

  const { results: usersInvites } = invites || {};

  const shouldShowLoadMoreBtn = total > users.length;
  const isUserInvitesEmpty = isEmpty(usersInvites);

  const isNautilusIntegration = useIsFeatureEnabled(EFeatureFlags.NAUTILUS_INTEGRATION);

  const handleInviteModal = () => {
    open({
      variant: 'center',
      contentLabel: 'Invite Workspace Members',
      name: 'Invite Workspace Members',
      modal: 'inviteMembers',
      id: 'modal-invite-members',
    });
  };

  const loadNext = () => {
    if (total > users.length) {
      setOffset((prevOffset) => prevOffset + QUERY_PARAMS.USERS_LIMIT);
    }
  };

  const onRemoveIntegration = (integrationName: keyof typeof AVAILABLE_INTEGRATIONS) => {
    open({
      variant: 'center',
      contentLabel: 'Remove Integration',
      name: 'Remove Integration',
      modal: 'removeIntegration',
      context: { integrationName },
    });
  };

  const handleAddIntegration = (integrationName: keyof typeof AVAILABLE_INTEGRATIONS) => {
    open({
      variant: 'center',
      contentLabel: 'Add Integration',
      name: 'Add Integration',
      modal: 'addIntegration',
      context: { integrationName },
    });
  };

  const handleUpgradeSubscriptionPress = useCallback(() => {
    navigate(`${routes.subscriptionsPlans}/dashboard`);
  }, []);

  const handleThreadGuestMode = async () => {
    if (isUpdating) return showErrorMessage('Updating in progress. Please wait until it ends');
    const response = await useUpdateThreadViewability({
      guestsCanViewPublicThreads: !isThreadGuestEnabled,
    });

    return handleApiCall(
      response,
      () => showErrorMessage('Could not update thread guest visibility'),
      () => {
        setIsThreadGuestEnabled(!isThreadGuestEnabled);
        showSuccessMessage('Thread visibility for guest updated');
      }
    );
  };

  // user will be redirected back to settings page from stripe checkout page
  // display success message when user get back from stripe checkout page
  useEffect(() => {
    if (isSubscriptionSuccess && isUserSubscriptionType) {
      showSuccessMessage('Congratulations! You successfully purchased additional user');
    } else if (isSubscriptionSuccess && !isUserSubscriptionType) {
      showSuccessMessage('Congratulations! Your subscription has been activated');
    }
  }, [isSubscriptionSuccess, isUserSubscriptionType]);

  useEffect(() => {
    // redirect user to dashboard if he is not admin
    if (!isAdmin(userRole)) {
      navigate(routes.dashboard);
    }
  }, [userRole]);

  useEffect(() => {
    if (guestViewabilityAllThreads) {
      setIsThreadGuestEnabled(guestViewabilityAllThreads.guestsCanViewPublicThreads);
    }
  }, [guestViewabilityAllThreads]);

  const { data: workspace } = useWorkspaceQuery();

  return (
    <Stack gap="25px">
      <Stack>
        <Title heading="h5" weight="600" cypressAttribute="members-page-title">
          {workspace?.name}
        </Title>
        <Title heading="h6" weight="600" color={colors.gray3} cypressAttribute="members-page-title">
          Workspace Settings
        </Title>
      </Stack>
      <Stack gap="15px">
        <Layout.MainContainer>
          {isSiteOwner && subscriptionInfo?.plan && (
            <Group align="center" gap="20px">
              <Text weight="600" style={{ fontSize: 24, lineHeight: 'normal' }}>
                Current plan:
              </Text>

              {isSubscriptionLoading ? (
                <Spinner size="medium" color={colors.white} />
              ) : (
                <Group gap="10px">
                  <Layout.SiteSubscriptionLabel>
                    {titleizeString(subscriptionInfo.plan)}
                  </Layout.SiteSubscriptionLabel>
                  {!isCustomPaidSubscription && (
                    <Layout.UpgradeButton onClick={handleUpgradeSubscriptionPress}>
                      Upgrade
                    </Layout.UpgradeButton>
                  )}
                </Group>
              )}
            </Group>
          )}
          <Group align="center" justify="space-between">
            <Text weight="600" size="lg">
              Manage Site Members
            </Text>
            {!isNautilusIntegration && (
              <Button
                type="button"
                variant="filled"
                onClick={handleInviteModal}
                cypressAttribute="members-page-invite-site-member-btn"
              >
                Invite
              </Button>
            )}
          </Group>
          <Divider color={colors.blue2} />
          <Layout.MemberListsContainer>
            <Layout.InvitedMembersWrapper>
              <Text weight="600" size="md">
                Workspace Invites
              </Text>
              <Layout.InvitedMembersContainer>
                {isNautilusIntegration ? (
                  <div className="flex h-48 flex-col items-center justify-center text-center">
                    User management can be managed via the Flows Administration page
                  </div>
                ) : (
                  <>
                    {isUserInvitesEmpty && !isInvitesLoading && (
                      <Stack align="center">
                        <Text size="sm" weight="600" style={{ lineHeight: 'normal' }}>
                          No invites yet
                        </Text>
                      </Stack>
                    )}
                    {!isInvitesLoading &&
                      !isUserInvitesEmpty &&
                      usersInvites?.map((invite: IUserInvites) => (
                        <React.Fragment key={invite.id}>
                          <InviteCard invite={invite} />
                        </React.Fragment>
                      ))}
                    {isInvitesLoading &&
                      Array.from({ length: 4 }).map((_, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <MemberCardSkeleton key={index} />
                      ))}
                  </>
                )}
              </Layout.InvitedMembersContainer>
            </Layout.InvitedMembersWrapper>
            <Layout.WorkspaceMembersWrapper>
              <Text weight="600" size="md">
                Workspace Users
              </Text>
              <Layout.WorkspaceMembersContainer>
                {!isUsersLoading &&
                  users &&
                  sortBy(users, (user) => (user.isSiteOwner ? -1 : 1)).map((user) => (
                    <React.Fragment key={user.id}>
                      <MemberCard member={user} />
                    </React.Fragment>
                  ))}
                {isUsersLoading &&
                  Array.from({ length: 4 }).map((_, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MemberCardSkeleton key={index} />
                  ))}
                {shouldShowLoadMoreBtn &&
                  (isFetching ? (
                    <Stack fluid align="center" justify="center">
                      <Spinner size="small" color={colors.white} />
                    </Stack>
                  ) : (
                    <Button variant="plain" onClick={loadNext}>
                      Load More
                    </Button>
                  ))}
              </Layout.WorkspaceMembersContainer>
            </Layout.WorkspaceMembersWrapper>
          </Layout.MemberListsContainer>
          {isSiteOwner && (
            <Stack gap="16px">
              <Text weight="600" size="lg">
                Manage Workspace
              </Text>
              <Divider color={colors.blue2} />
              <Text weight="400" size="sm">
                Threads Settings
              </Text>
              <div
                style={{
                  backgroundColor: colors.dark1,
                  borderRadius: 8,
                  padding: 16,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Text weight="400" size="sm">
                  Guests Can View Public Threads in the Workspace
                </Text>
                {isAllThreadsLoading ? (
                  <Spinner size="small" color={colors.white} />
                ) : (
                  <Group gap="8px">
                    {isUpdating && <Spinner size="small" color={colors.white} />}
                    <CheckBox
                      checked={isThreadGuestEnabled}
                      onChange={handleThreadGuestMode}
                      name="threads-guest-mode"
                      size="xl"
                      cypressAttribute="threads-guest-view"
                    />
                  </Group>
                )}
              </div>
            </Stack>
          )}
        </Layout.MainContainer>
        <Text weight="600" size="lg">
          Active Integrations
        </Text>
        <Layout.IntegrationListContainer>
          {isLoading && <Spinner size="medium" color={colors.white} />}
          {data?.integrations.map((integration) => (
            <IntegrationListItem
              key={integration.name}
              integration={integration}
              isAdminPanel
              onClick={onRemoveIntegration}
            />
          ))}
          {!isLoading && (
            <Layout.AddNewIntegrationButton onClick={handleAddIntegration}>
              <Text weight="600" size="lg">
                +
              </Text>
              <Text weight="500">Add more</Text>
            </Layout.AddNewIntegrationButton>
          )}
        </Layout.IntegrationListContainer>
      </Stack>
      {isSiteOwner && (
        <Button
          variant="outlined"
          color={colors.red}
          leftIcon={<Icon icon="PencilIcon" size="small" path={colors.red} />}
          size="sm"
          style={{ width: 'fit-content' }}
        >
          <a
            // eslint-disable-next-line max-len
            href={`mailto:support@authentise.zendesk.com?subject=Change%20workspace%20ownership&body=Hello,%20I%20would%20like%20to%20change%20the%20owner%20of%20the%20workspace,%20my%20email%20is%20${email}`}
          >
            Change Site Owner
          </a>
        </Button>
      )}
    </Stack>
  );
};

export default Members;
