import React from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { mediaSize } from 'theme/devices';
import { Group, Spinner, Stack, Text, Title } from 'ui';

import { useWindowSize } from 'hooks/useWindowSize';
import { useValidateSignupLinkQuery } from 'modules/auth/authApi';

import Signup from 'modules/signup';
import colors from 'theme/colors';
import AppImg from './assets/App.png';
import RingsImg from './assets/Rings.png';

const RightSide = styled.div`
  background: no-repeat right / cover url('${RingsImg}') ${({ theme }) => theme.colors.dark2};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled.div`
  flex: 1;
  max-width: 650px;
  padding: 20px 32px;
  display: flex;
  align-items: center;
`;

const SignupPage: React.FC = () => {
  const [query] = useSearchParams();
  const size = useWindowSize();
  const { isLoading: isValidatingInviteToken, isError: inviteTokenValidationFailed } =
    useValidateSignupLinkQuery(Object.fromEntries(query));

  if (isValidatingInviteToken) {
    return (
      <Stack justify="center" align="center" fluid style={{ minHeight: '100vh' }}>
        <Spinner />
      </Stack>
    );
  }

  return (
    <Group style={{ minHeight: '100vh' }} fluid>
      <Group justify="center" style={{ flex: 1 }}>
        <FormWrapper>
          {inviteTokenValidationFailed ? (
            <Stack gap="8px">
              <Title heading="h1">Invalid invite token</Title>
              <Text color={colors.gray1}>The link is either incorrect or has expired.</Text>
            </Stack>
          ) : (
            <Signup />
          )}
        </FormWrapper>
      </Group>
      {size.width > mediaSize.laptop && (
        <RightSide>
          <img style={{ width: '90%' }} src={AppImg} alt="app" />
        </RightSide>
      )}
    </Group>
  );
};

export default SignupPage;
