import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from 'components/ui/form';
import { Switch } from 'components/ui/switch';
import { showSuccessMessage, showUncaughtErrorMessage } from 'modules/alert/utils';
import { useUpdateViewabilityOfThreadsMutation } from 'modules/threads/threadsApi';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { InferType, boolean, object } from 'yup';

const validation = object({
  guestVisibility: boolean().required(),
});

export const EditWorkspaceDialog: React.FC<{ visibility: boolean }> = ({ visibility }) => {
  const [open, setOpen] = useState(false);
  const [updateVisibility, { isLoading }] = useUpdateViewabilityOfThreadsMutation();
  const form = useForm({
    defaultValues: { guestVisibility: visibility },
    resolver: yupResolver(validation),
  });

  const onSubmit = async (value: InferType<typeof validation>) => {
    try {
      await updateVisibility({ guestsCanViewPublicThreads: value.guestVisibility }).unwrap();
      showSuccessMessage('Workspace updated');
      setOpen(false);
    } catch (error) {
      showUncaughtErrorMessage(error);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button>Edit</Button>
      </DialogTrigger>
      <DialogContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <DialogHeader>
              <DialogTitle>Edit Workspace</DialogTitle>
            </DialogHeader>

            <FormField
              control={form.control}
              name="guestVisibility"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between pt-6">
                  <div className="space-y-0.5">
                    <FormLabel className="text-base">Guest Visibility</FormLabel>
                    <FormDescription>Allow guests to view all workspace threads</FormDescription>
                  </div>
                  <FormControl>
                    <Switch checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button disabled={isLoading}>Save</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
