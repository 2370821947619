import React from 'react';
import { Button, Group, Stack, Text } from 'ui';
import colors from 'theme/colors';
import { useModal } from 'modules/modals/ModalProvider';

const RelateEventWarning: React.FC = () => {
  const { close } = useModal();

  return (
    <Stack gap="20px">
      <Text size="lg" color={colors.gray2} cypressAttribute="modal-relate-event-warning-text">
        This event is from a related thread. If you want to delete this event, please remove the
        corresponding related thread
      </Text>
      <Group justify="end" gap="15px">
        <Button onClick={close} cypressAttribute="modal-relate-event-warning-cancel-btn">
          Cancel
        </Button>
      </Group>
    </Stack>
  );
};

export default RelateEventWarning;
