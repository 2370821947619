import { useAppSelector } from 'app/hooks';
import path from 'constants/path';
import routes from 'constants/routes';
import { useGuestViewability } from 'hooks/useGuestViewability';
import useLocalStorage from 'hooks/useLocalStorage';
import useSubscriptionPermissions from 'hooks/useSubscriptionPermissions';
import { useUserWalkthrough } from 'hooks/useUserWalkthrough';
import _reject from 'lodash/reject';
import { NOTIFICATION } from 'modules/alert/constants';
import { selectUserRole } from 'modules/auth/userSlice';
import StatisticCard from 'modules/threads/components/StatisticCard';
import { useThreadsList } from 'modules/threads/hooks';
import { useCreateReferenceMutation, useCreateThreadMutation } from 'modules/threads/threadsApi';
import { EDefaultSortOption, REFERENCE_TYPE } from 'modules/threads/types';
import UserWalkthrough from 'modules/userWalkthrough';
import { EUserWalkthroughs } from 'modules/userWalkthrough/constants';
import moment from 'moment';
import * as Layout from 'pages/dashboard/components/Layout';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EThreadPriority, EThreadStatuses } from 'types';
import { Stack, Switch } from 'ui';
import { isGuest } from 'utils/helpers';
import { AllThreads, MyContributions, MyThreads } from './components';
import DashboardEvents from './components/DashboardEvents';
import WrappedDropdownSelector from './components/WrappedDropdownSelector';
import { EThreadsListOptionIds, EThreadsStatisticOptionIds } from './constants';
import { TDropdownSelectorOption } from './types';

const lastWeek = moment().subtract(7, 'days').toDate();
const lastMonth = moment().subtract(30, 'days').toDate();

const Dashboard: React.FC = () => {
  const userRole = useAppSelector(selectUserRole);
  const [showGantt, setShowGantt] = useState(false);
  const { isWalkthroughVisible } = useUserWalkthrough(EUserWalkthroughs.DASHBOARD);
  const { isGuestUserCannotViewPublicThreads, guestViewability } = useGuestViewability();

  const { data: myThreads } = useThreadsList({
    url: path.threadsList,
    ordering: EDefaultSortOption.UPDATED_AT_DSC,
    shouldSkipRequest: isGuest(userRole),
  });

  const { data: myContributions } = useThreadsList({ url: path.contributionsList });
  const { data: allThreads } = useThreadsList({
    url: path.allThreadsList,
    ordering: EDefaultSortOption.UPDATED_AT_DSC,
    shouldSkipRequest: isGuestUserCannotViewPublicThreads,
  });

  /**
   * Stores the previous threads table view mode, to be restorable when user navigates away from the page.
   */
  const [lastSavedThreadsTableViewMode, setLastSavedThreadsTableViewMode] = useLocalStorage(
    'threadsTableViewMode',
    isGuestUserCannotViewPublicThreads
      ? EThreadsListOptionIds.MY_THREADS
      : EThreadsListOptionIds.ALL_THREADS
  );

  const [createThread] = useCreateThreadMutation();
  const [createReference] = useCreateReferenceMutation();

  const { handleSubscriptionPermissionErrors } = useSubscriptionPermissions();

  const location = useLocation();
  const navigate = useNavigate();

  const headerHeight = 140;

  const { pathname, search } = location;
  const searchData = new URLSearchParams(search);

  /**
   * For dropdown listing threads in table.
   */
  const renderThreadsListOptions = () => {
    const threadsListOptions: TDropdownSelectorOption[] = [
      {
        id: EThreadsListOptionIds.MY_THREADS,
        name: `My Threads ${myThreads ? `(${myThreads.count})` : ''}`,
        content: <MyThreads showGantt={showGantt} />,
        permissions: [],
      },
      {
        id: EThreadsListOptionIds.MY_CONTRIBUTIONS,
        name: `My Contributions ${myContributions ? `(${myContributions.count})` : ''}`,
        content: <MyContributions showGantt={showGantt} />,
        permissions: [],
      },
      {
        id: EThreadsListOptionIds.ALL_THREADS,
        name: `All Threads ${allThreads ? `(${allThreads.count})` : ''}`,
        content: <AllThreads showGantt={showGantt} />,
        permissions: [],
      },
    ];

    // `guestsCanViewPublicThreads` is false: User is a guest and cannot view public threads.
    if (isGuestUserCannotViewPublicThreads) {
      return _reject(threadsListOptions, { id: EThreadsListOptionIds.ALL_THREADS });
    }

    return threadsListOptions;
  };

  /**
   * For dropdown listing user's statistics.
   */
  const threadsStatisticOptions: TDropdownSelectorOption[] = [
    {
      id: EThreadsStatisticOptionIds.LAST_7_DAYS,
      name: 'Last 7 Days',
      content: (
        <StatisticCard title="Last 7 days" cypressAttribute="last-7-days" dateFrom={lastWeek} />
      ),
      permissions: [],
    },
    {
      id: EThreadsStatisticOptionIds.LAST_30_DAYS,
      name: 'Last 30 Days',
      content: (
        <StatisticCard title="Last 30 days" cypressAttribute="last-30-days" dateFrom={lastMonth} />
      ),
      permissions: [],
    },
  ];

  const handleCreateThreadFromUrl = async () => {
    const newThreadName = searchData.get('name');
    const newThreadDescription = searchData.get('description');
    const newThreadReferenceUri = searchData.get('reference_uri');

    const isRequiredDataPresent: boolean = [newThreadName].every(Boolean);

    if (pathname === routes.new_thread && isRequiredDataPresent) {
      try {
        const thread = await createThread({
          name: newThreadName as string,
          description:
            newThreadDescription || `Thread about ${newThreadName} ${newThreadReferenceUri}`,
          status: EThreadStatuses.ON_TARGET,
          priority: EThreadPriority.LOW,
          tags: [],
          isPrivate: false,
        }).unwrap();
        const newThreadUri = `${routes.thread}/${thread.id}`;
        navigate(newThreadUri);

        const referencePayload = {
          name: newThreadName as string,
          description: null,
          url: newThreadReferenceUri as string,
          thread: thread.id,
          file: null,
          fileSize: null,
          referenceType: REFERENCE_TYPE.LINK,
          plyableQuoteFor: '',
        };

        if (newThreadReferenceUri) {
          createReference(referencePayload);
        }
      } catch (error) {
        handleSubscriptionPermissionErrors(error, NOTIFICATION.INVALID_THREAD);
      }
    }
  };

  useEffect(() => {
    handleCreateThreadFromUrl();
  }, []);

  return (
    <Layout.DashboardContainer id="dashboard-container">
      {isWalkthroughVisible && <UserWalkthrough.Dashboard id="dashboard-user-walkthrough" />}

      {!showGantt && (
        // Hide all of this content if gantt view is enabled to make space for it.
        <Layout.FlexItem className="w-0" id="left-flex-container">
          <Stack fullHeight maxHeight={`calc(100vh - ${headerHeight}px)`} gap="20px">
            <WrappedDropdownSelector
              id="statistic-options"
              title="Statistics"
              options={threadsStatisticOptions}
              cypressAttribute="statistic-options"
            />

            <Layout.FlexItem id="dashboard-events-flex-container" overflow="hidden">
              <DashboardEvents cypressAttribute="dashboard-events" />
            </Layout.FlexItem>
          </Stack>
        </Layout.FlexItem>
      )}

      <Layout.FlexItem fluidHeight id="right-flex-container" overflow="auto">
        <Stack maxHeight={`calc(100vh - ${headerHeight}px)`} overflowAuto>
          <WrappedDropdownSelector
            id="threads-options"
            defaultValueId={lastSavedThreadsTableViewMode}
            options={renderThreadsListOptions()}
            cypressAttribute="threads-options"
            onChangeActiveOptionEffect={(option) => setLastSavedThreadsTableViewMode(option)}
          >
            <Switch
              leftText="List View"
              rightText="Live View"
              checked={showGantt}
              onChange={() => setShowGantt((prev) => !prev)}
              cypressAttribute="switch-view"
            />
          </WrappedDropdownSelector>
        </Stack>
      </Layout.FlexItem>
    </Layout.DashboardContainer>
  );
};

export default Dashboard;
