import { FormattedDateTime } from 'components/dates';
import { EModelStatus } from 'modules/threads/types';
import { ModelLink } from 'modules/threads3d/Link';
import React from 'react';
import colors from 'theme/colors';
import { Icon, Stack, Text } from 'ui';
import { AnnotationRefreshButton } from './AnnotationLayout';

interface IAnnotationContentSidebarProps {
  id: string;
  author: string;
  date: string;
  referenceId: number;
  onRefreshClick?: (id: string) => void;
}

export const AnnotationContentSidebar: React.FC<IAnnotationContentSidebarProps> = ({
  id,
  author,
  date,
  onRefreshClick,
  referenceId,
}) => {
  return (
    <Stack
      gap="16px"
      justify="space-between"
      align="flex-end"
      fluid
      data-cy="annotation-content-sidebar"
    >
      <Stack gap="24px" align="flex-end" justify="flex-start" fluid>
        <Stack
          gap="8px"
          align="flex-end"
          justify="flex-start"
          fluid
          data-cy="annotation-content-sidebar-stack"
        >
          <Text
            color={colors.white}
            size="sm"
            weight="700"
            align="right"
            cypressAttribute="annotation-content-sidebar-author-text"
          >
            {author}
          </Text>

          <Text
            color={colors.gray3}
            size="xs"
            weight="400"
            align="right"
            noWrap
            cypressAttribute="annotation-content-sidebar-date-text"
          >
            <FormattedDateTime date={date} />
          </Text>
        </Stack>
      </Stack>
      <Stack gap="10px">
        <ModelLink referenceId={referenceId} status={EModelStatus.PROCESSED} />
        <AnnotationRefreshButton
          type="button"
          rightIcon={<Icon icon="RefreshAnnotationIcon" size="small" />}
          onClick={() => onRefreshClick?.(id)}
          data-cy="annotation-refresh-btn"
        >
          Refresh
        </AnnotationRefreshButton>
      </Stack>
    </Stack>
  );
};
