import { IUser } from 'types/user';

export interface IThreadsBotChatResponse {
  response: string;
  type: EThreadsBotMessageTypes;
  chatHistory: string;
}

export enum EThreadsBotMessageSenderTypes {
  USER = 'USER',
  THREADS_BOT = 'THREADS_BOT',
}

export enum EThreadsBotMessageTypes {
  MESSAGE = 'MESSAGE',
  ERROR = 'ERROR',
  UNINITIALIZED_ERROR = 'UNINITIALIZED_ERROR',
}

export type TThreadsBotMessage = {
  chatUserId?: string;
  type: EThreadsBotMessageTypes;
  id?: number | string;
  message: string;
  senderType: EThreadsBotMessageSenderTypes;
  sender?: IUser;
};
