import React, { useState } from 'react';

import { useGoogleLogin } from '@react-oauth/google';
import { Button } from 'components/ui/button';
import { showErrorMessage } from 'modules/alert/utils';
import {
  useGetUserAvailableIntegrationsQuery,
  useSendGoogleAccessTokenMutation,
} from 'modules/integrations/integrationsApi';
import colors from 'theme/colors';
import { Spinner, Text } from 'ui';
import IntegrationListItem from '../IntegrationListItem';
import { IntegrationSuccessful } from '../IntegrationSuccessful';
import * as Layout from '../Layout';

const ChooseIntegration: React.FC = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { data, isLoading } = useGetUserAvailableIntegrationsQuery();

  const [sendGoogleAuthCred, { isLoading: isLoadingGoogleToken }] =
    useSendGoogleAccessTokenMutation();
  const isIntegrationsEmpty = !isLoading && !data?.integrations.length;
  const allEnabled =
    !isIntegrationsEmpty && data?.integrations.every((integration) => integration.connected);

  const googleLogin = useGoogleLogin({
    scope: `https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/documents https://www.googleapis.com/auth/presentations https://www.googleapis.com/auth/drive`,
    flow: 'auth-code',
    redirect_uri: 'postmessage',
    onSuccess: async (codeResponse) => {
      try {
        await sendGoogleAuthCred(codeResponse.code).unwrap();
        setShowSuccessMessage(true);
      } catch {
        showErrorMessage('Could not connect to Google');
      }
    },
    onError: (errorResponse) =>
      showErrorMessage(errorResponse?.error || 'Could not connect to Google'),
  });

  if (isLoading) {
    return (
      <Layout.ListContainer align="center" justify="center" fluid>
        <Spinner />
      </Layout.ListContainer>
    );
  }

  if (showSuccessMessage) {
    return <IntegrationSuccessful />;
  }

  return (
    <Layout.IntegrationsMenuContainer data-cy="modal-choose-integration-menu">
      {isIntegrationsEmpty ? (
        <Layout.ListContainer
          align="center"
          justify="center"
          fluid
          data-cy="modal-choose-integration-container"
        >
          <Text weight="600" size="md" align="center" style={{ lineHeight: 1.3 }}>
            No Integrations enabled. Contact{' '}
            <a href="mailto:support@authentise.zendesk.com" style={{ color: colors.blue }}>
              support@authentise.zendesk.com
            </a>{' '}
            to enable Threads integrations to Google, Sheets, Presentation, Docs etc.
          </Text>
        </Layout.ListContainer>
      ) : (
        <div className="flex w-full flex-col items-center">
          <div className="w-full">
            <Layout.IntegrationsList>
              {data?.integrations.map((integration) => (
                <IntegrationListItem key={integration.name} integration={integration} />
              ))}
            </Layout.IntegrationsList>
          </div>
          <Button onClick={googleLogin} disabled={isLoadingGoogleToken || allEnabled}>
            Enable Google Integration
          </Button>
        </div>
      )}
    </Layout.IntegrationsMenuContainer>
  );
};

export default ChooseIntegration;
