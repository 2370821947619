import * as ChatLayout from 'modules/chat/components/Layout';
import * as Layout from 'modules/chat/components/integrations/Layout';
import { IMessageResponse } from 'modules/chat/types';
import { EModelStatus } from 'modules/threads/types';
import { ModelLink } from 'modules/threads3d/Link';
import { useThreads3dStatus } from 'modules/threads3d/hooks/status';
import React, { useMemo } from 'react';
import Moment from 'react-moment';
import colors from 'theme/colors';
import { Group, Icon, Stack, Text } from 'ui';
import { getFullName } from 'utils/helpers';

interface IMessageData {
  message: string;
  fileName: string | null;
  referenceId?: number;
}

export const Threads3DMessage: React.FC<{ message: IMessageResponse }> = ({ message }) => {
  const { creator, createdAt, text = '' } = message;

  const { active: threads3dEnabled } = useThreads3dStatus();

  const messageData: IMessageData = useMemo(() => {
    const { blocks = [] } = JSON.parse(text) || {};
    const [parsed] = blocks;
    return {
      message: parsed?.text || '',
      fileName: parsed?.reference_versioned_file?.name || null,
      referenceId: parsed?.reference_versioned_file?.reference_versioned_file_id,
    };
  }, [text]);

  return (
    <ChatLayout.MessageContent
      isThreads3dMessage
      owner={false}
      isCopiedMessage={false}
      data-cy="chat-layout-message-content"
    >
      <ChatLayout.MessageHeader owner={false}>
        <Group>
          <Stack data-cy="chat-layout-message-content-stack">
            <Text
              align="left"
              size="sm"
              color={colors.orange}
              cypressAttribute="chat-layout-message-content-full-name"
            >
              {creator && getFullName(creator)}
            </Text>
            {creator?.group && (
              <Text
                align="left"
                size="xs"
                color={colors.gray1}
                cypressAttribute="chat-layout-message-content-group"
              >
                {creator?.group}
              </Text>
            )}
          </Stack>
        </Group>
        <Text size="xs" color={colors.gray1} cypressAttribute="chat-layout-message-content-time">
          <Moment fromNow date={createdAt} />
        </Text>
      </ChatLayout.MessageHeader>
      <Text
        align="left"
        color={colors.white}
        size="sm"
        cypressAttribute="chat-layout-message-content-message"
      >
        {messageData.message}
      </Text>
      <Group justify="space-between" align="end">
        <div>
          {messageData.fileName && (
            <Layout.FileLink>
              <Icon
                icon="AttachIcon"
                size="small"
                path={colors.orange2}
                style={{ width: '20px', height: '20px' }}
              />
              {messageData.fileName}
            </Layout.FileLink>
          )}
        </div>
        {threads3dEnabled && messageData.referenceId && (
          <ModelLink referenceId={messageData.referenceId} status={EModelStatus.PROCESSED} />
        )}
      </Group>
    </ChatLayout.MessageContent>
  );
};
