import React from 'react';
import ReferenceList from 'modules/references';
import Contributors from 'modules/contributors/index';
import Events from 'modules/events';
import Resources from 'modules/resources';
import RelatedThreads from 'modules/relatedThreads';
import { ITab } from 'ui/tabs/types';
import { HiOutlineSparkles } from 'react-icons/hi';
import { MdOutlinePeopleOutline, MdOutlineMultipleStop, MdOutlineFileCopy } from 'react-icons/md';
import { Icon } from 'ui';
import * as Layout from './components/Layout';
import { EPermissions } from '../../app/permissions/constants';

export const THREAD_TABS_GROUP: ITab[] = [
  {
    id: 1,
    name: 'Events',
    content: (
      <Layout.TabContentWrap>
        <Events />
      </Layout.TabContentWrap>
    ),
    permissions: [EPermissions.ADD_EVENT],
    icon: <HiOutlineSparkles />,
  },
  {
    id: 2,
    name: 'Contributors',
    content: <Contributors />,
    permissions: [EPermissions.ADD_CONTRIBUTORS],
    icon: <Icon icon="UserIcon" size="normal" stroke="white" />,
  },
  {
    id: 3,
    name: 'Files and Links',
    content: <ReferenceList />,
    permissions: [EPermissions.ADD_REFERENCES],
    icon: <MdOutlineFileCopy />,
  },
  {
    id: 4,
    name: 'Inventory',
    content: (
      <Layout.TabContentWrap>
        <Resources />
      </Layout.TabContentWrap>
    ),
    permissions: [EPermissions.ADD_RESOURCES],
    icon: <Icon icon="LinkIcon" size="normal" stroke="white" />,
  },
  {
    id: 5,
    name: 'Related Threads',
    content: (
      <Layout.TabContentWrap>
        <RelatedThreads />
      </Layout.TabContentWrap>
    ),
    permissions: [EPermissions.ADD_RELATED_THREADS],
    icon: <MdOutlineMultipleStop />,
  },
];
