import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDeleteThreadMutation } from 'modules/threads/threadsApi';
import { showSuccessMessage, showUncaughtErrorMessage } from 'modules/alert/utils';
import { handleApiCall } from 'utils/helpers';
import { NOTIFICATION } from 'modules/alert/constants';
import { Group, Stack, Button, Text } from 'ui';
import { IThreadMOdalsDefaultProps } from 'types';
import routes from 'constants/routes';
import colors from 'theme/colors';
import { useModal } from 'modules/modals/ModalProvider';

const RemoveThread: React.FC<IThreadMOdalsDefaultProps> = ({ threadId, threadName }) => {
  const { close } = useModal();
  const [deleteThread, { isLoading }] = useDeleteThreadMutation();
  const navigate = useNavigate();

  const handleDeleteThread = async () => {
    const res = await deleteThread(threadId as string);
    handleApiCall(
      res,
      () => showUncaughtErrorMessage(),
      () => {
        close();
        showSuccessMessage(NOTIFICATION.THREAD_DELETED);
        navigate(routes.dashboard);
      }
    );
  };

  return (
    <Stack gap="20px">
      <Text size="lg" color={colors.gray2}>
        Are you sure you want to delete {threadName || 'this'} thread?
      </Text>
      <Group justify="end" gap="15px">
        <Button
          color={colors.dark2}
          onClick={close}
          cypressAttribute="modal-remove-thread-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          color={colors.red2}
          onClick={handleDeleteThread}
          cypressAttribute="modal-remove-thread-delete-btn"
        >
          {isLoading ? 'Deleting...' : 'Delete'}
        </Button>
      </Group>
    </Stack>
  );
};

export default RemoveThread;
