import { useThreadsBotChat } from 'modules/threadsBot/useThreadsBotChat';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoClose, IoWarning } from 'react-icons/io5';
import colors from 'theme/colors';
import { Group, Text } from 'ui';
import Card from 'ui/card';
import Logo from 'ui/logo';
import { ThreadsBotBody, ThreadsBotCard, ThreadsBotHeader } from 'ui/threadsBot/Layout';
import {
  EThreadsBotMessageSenderTypes,
  EThreadsBotMessageTypes,
  TThreadsBotMessage,
} from '../types';
import ThreadsBotCollapsedFloatingCard from './collapsedFloatingCard';
import ThreadsBotMessage, { ThreadsBotError } from './threadsBotMessage';
import ThreadsBotTextInput from './threadsBotTextInput';

const ThreadsBot: React.FC = () => {
  const [show, setShow] = useState(false);
  const { messages, sendMessage, isResponding } = useThreadsBotChat();

  const threadsBotChatFeedRef = useRef<HTMLDivElement>(null);

  const { setValue, handleSubmit, register } = useForm({
    defaultValues: {
      message: '',
    },
  });

  const handleScrollToBottom = () => {
    // Add a short timeout to allow succeeding DOM elements to render.
    setTimeout(() => {
      if (threadsBotChatFeedRef.current) {
        const bottom = threadsBotChatFeedRef.current.scrollHeight;
        threadsBotChatFeedRef.current.scrollTo({
          top: bottom,
          behavior: 'smooth',
        });
      }
    }, 100);
  };

  useEffect(() => {
    handleScrollToBottom();
  }, [messages]);

  const handleSendThreadsBotMessage = async ({ message }: { message: string }) => {
    if (message.trim() === '' || isResponding) {
      return;
    }

    handleScrollToBottom();

    // Clear chat input UI.
    setValue('message', '');

    await sendMessage(message);
  };

  return (
    <>
      {show && (
        <ThreadsBotCard id="threads-bot-wrapper">
          <ThreadsBotHeader id="threads-bot-header">
            <Group fluid align="center" justify="space-between" minWidth={100}>
              <Group gap="10px" align="center">
                <Logo /> ThreadsBot
              </Group>
              <IoClose role="button" color={colors.gray} onClick={() => setShow(false)} size={25} />
            </Group>
          </ThreadsBotHeader>
          <Card padding="10px" height="fit-content" backgroundColor={colors.gray4}>
            <Group align="center">
              <IoWarning size={30} />
              <Text size="xs">
                Although there are safeguards in place, the system may occasionally generate
                incorrect or misleading information. Please be cautious in your use of the system.
              </Text>
            </Group>
          </Card>
          <ThreadsBotBody
            ref={threadsBotChatFeedRef}
            backgroundColor={colors.dark1}
            id="threads-bot-chat-feed"
          >
            {messages.map((data: TThreadsBotMessage) => {
              if (data.type === EThreadsBotMessageTypes.ERROR) {
                return (
                  <ThreadsBotError id={`message-${data.id}`} isMe={false} text={data.message} />
                );
              }

              return (
                <ThreadsBotMessage
                  id={`message-${data.id}`}
                  isMe={data.senderType === EThreadsBotMessageSenderTypes.USER}
                  text={data.message}
                />
              );
            })}
            {isResponding && (
              <ThreadsBotMessage
                id="threads-bot-processing-view"
                noCursor
                textColor={colors.gray5}
                isMe={false}
                text="ThreadsBot is thinking..."
              />
            )}
          </ThreadsBotBody>
          <ThreadsBotTextInput
            isSending={isResponding}
            handleSubmit={handleSubmit}
            register={register}
            handleSendThreadsBotMessage={handleSendThreadsBotMessage}
          />
        </ThreadsBotCard>
      )}
      <ThreadsBotCollapsedFloatingCard toggle={() => setShow((prev) => !prev)} />
    </>
  );
};

export default ThreadsBot;
