import { googleLogout } from '@react-oauth/google';
import { api, resetAll } from 'app/api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ReactComponent as MyPlanIcon } from 'assets/icons/my-plan-icon.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings-icon.svg';
import routes from 'constants/routes';
import { CUSTOM_SUBSCRIPTION_PLANS } from 'constants/subscription';
import { useLogoutMutation } from 'modules/auth/authApi';
import { logout, selectUserInfo } from 'modules/auth/userSlice';
import { copilotApi } from 'modules/copilot/copilotApi';
import { useModal } from 'modules/modals/ModalProvider';
import { notificationApi } from 'modules/notification/notificationApi';
import { useLazyGetStripeDashboardLinkQuery } from 'modules/subscriptionsPlans/subscriptionsPlansApi';
import {
  isCurrentCustomPaidSubscriptionPlan,
  selectSubscriptionName,
} from 'modules/subscriptionsPlans/subscriptionsPlansSlice';
import { userApi } from 'modules/user/userApi';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import colors from 'theme/colors';
import { Avatar, DropDownAction, Icon } from 'ui';
import { isAdmin } from 'utils/helpers';

const ProfileMenu: React.FC = () => {
  const [_, setIsIntegrationsOpen] = useState<boolean>(false);

  const currentSubscriptionName = useAppSelector(selectSubscriptionName);
  const isCustomPaidSubscription = useAppSelector(isCurrentCustomPaidSubscriptionPlan);

  const { open } = useModal();

  const integrationsItemRef = useRef<HTMLDivElement>(null);

  const { avatar, userRole, isSiteOwner } = useAppSelector(selectUserInfo);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onProfile = () => {
    navigate(routes.account);
    setIsIntegrationsOpen(false);
  };

  // Calling the /logout/ endpoint will allow the httpOnly session cookie to be destroyed at the backend.
  const [requestLogoutEndpoint] = useLogoutMutation();

  const [fetchDashBoardUrl] = useLazyGetStripeDashboardLinkQuery();

  const onLogout = () => {
    googleLogout();
    dispatch(logout());
    dispatch(resetAll());

    requestLogoutEndpoint(null).then(() =>
      dispatch(userApi.endpoints.getUserInfo.initiate(undefined, { forceRefetch: true }))
    );
    dispatch(api.util.resetApiState());
    dispatch(userApi.util.resetApiState());
    dispatch(notificationApi.util.resetApiState());
    dispatch(copilotApi.util.resetApiState());

    // Reset document title, because we changing it when user has some unread notifications
    document.title = 'Threads';
    toast.success('Successfully logged out.');
    setIsIntegrationsOpen(false);
  };

  const onIntegrations = () => {
    open({
      variant: 'center',
      contentLabel: 'integrations',
      name: 'Enable Google integration',
      modal: 'choseIntegration',
      id: 'modal-choose-integration',
    });
  };

  const onMenuClose = () => {
    setIsIntegrationsOpen(false);
  };

  const isCurrentSubscriptionFree = useMemo(
    () =>
      currentSubscriptionName &&
      currentSubscriptionName.toLocaleLowerCase() ===
        CUSTOM_SUBSCRIPTION_PLANS.Free.toLocaleLowerCase(),
    [currentSubscriptionName]
  );

  const handleMyPlanClick = async () => {
    if (isCurrentSubscriptionFree) {
      navigate(`${routes.subscriptionsPlans}/dashboard`);
      return;
    }
    fetchDashBoardUrl().then((res) => {
      window.open(res.data?.redirectUrl as string, '_self', 'noopener,noreferrer');
    });
  };

  const actions = useMemo(
    () => [
      {
        id: 'edit',
        title: 'Profile',
        icon: <Icon icon="UserIcon" path={colors.white} />,
        action: onProfile,
      },
      {
        id: 'integrations',
        title: 'Integrations',
        icon: <Icon icon="IntegrationsIcon" path={colors.white} />,
        ref: integrationsItemRef,
        action: onIntegrations,
      },
      {
        id: 'delete',
        title: 'Logout',
        icon: <Icon icon="ExitIcon" path={colors.white} />,
        action: onLogout,
      },
    ],
    []
  );

  useEffect(() => {
    if (isAdmin(userRole)) {
      actions.splice(actions.length - 1, 0, {
        id: 'workspace-settings',
        title: 'Settings',
        icon: <SettingsIcon width={24} height={24} />,
        action: () => navigate(routes.members),
      });
    }
  }, []);

  useEffect(() => {
    if (isSiteOwner && currentSubscriptionName && !isCustomPaidSubscription) {
      actions.splice(actions.length - 1, 0, {
        id: 'subscriptionPortal',
        title: 'My Plan',
        icon: <MyPlanIcon width={24} height={24} />,
        action: handleMyPlanClick,
      });
    }
  }, [currentSubscriptionName]);

  return (
    <DropDownAction
      actions={actions}
      onClose={onMenuClose}
      cypressAttribute="header-profile-dropdown"
    >
      <Avatar src={avatar} style={{ cursor: 'pointer' }} />
    </DropDownAction>
  );
};

export default ProfileMenu;
