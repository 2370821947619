import { CSSProperties } from 'react';

export const fixedBottomRightStyle: CSSProperties = {
  position: 'fixed',
  bottom: 20,
  right: 20,
  zIndex: 99,
};

export enum ThreadsBotErrorCodes {
  NOT_ENOUGH_DATA = 'NOT_ENOUGH_DATA',
}

export const THREADSBOT_ERROR_MESSAGES = {
  ERROR_GENERIC: 'ThreadsBot was unable to generate a response. Please try again.',
  NO_PARSABLE_DATA:
    // eslint-disable-next-line max-len
    'ThreadsBot was unable to generate a response. Class could not be found. Please provide some data in your workspace for the system to use.',
};
