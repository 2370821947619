import { useAppDispatch, useAppSelector } from 'app/hooks';
import _every from 'lodash/every';
import _values from 'lodash/values';
import { useGetUserPreferencesQuery, useUpdateUserPreferencesMutation } from 'modules/user/userApi';
import { EUserWalkthroughs } from 'modules/userWalkthrough/constants';
import {
  selectUserWalkthroughs,
  setWalkthroughState,
} from 'modules/userWalkthrough/userWalkthroughSlice';
import { useEffect } from 'react';

/**
 * Hook with utilities for onboarding user walk-throughs and subsequent components.
 */
export const useUserWalkthrough = (userWalkthroughKey: EUserWalkthroughs) => {
  const dispatch = useAppDispatch();
  const { data: userPreferencesData } = useGetUserPreferencesQuery();
  const [updateUserPreferences] = useUpdateUserPreferencesMutation();
  const completedUserWalkthroughs = useAppSelector(selectUserWalkthroughs);

  useEffect(() => {
    const hasCompletedAllWalkthroughs = _every(
      _values(completedUserWalkthroughs),
      (value) => !!value
    );

    if (userPreferencesData && hasCompletedAllWalkthroughs) {
      // User has completed all walkthroughs, so update their preferences to not show again.
      updateUserPreferences({ showNewUserWalkthrough: false });
    }
  }, [completedUserWalkthroughs]);

  const isWalkthroughVisible = !completedUserWalkthroughs[userWalkthroughKey];

  const markWalkthroughAsCompleted = () => {
    dispatch(setWalkthroughState({ userWalkthroughKey, value: true }));
  };

  return { markWalkthroughAsCompleted, isWalkthroughVisible };
};
