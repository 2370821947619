import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { IUserState } from './types';
import { userApi } from '../user/userApi';
import { IUser, EUserRole } from '../../types/user';
import { resetAll } from '../../app/api';

export const initialState: IUserState = {
  userInfo: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    avatar: '',
    siteId: '',
    description: '',
    phone: '',
    group: '',
    userRole: EUserRole.USER,
    termsAndConditions: null,
    isTrialExpired: false,
    isImpersonate: false,
    isSiteOwner: false,
    impersonatorId: null,
    canImpersonate: false,
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, { payload }: PayloadAction<IUserState>) => ({
      ...state,
      ...payload,
    }),
    logout: () => {
      localStorage.clear();
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetAll, () => ({ ...initialState }))
      .addMatcher(
        userApi.endpoints.getUserInfo.matchFulfilled,
        (state, { payload }: PayloadAction<IUser>) => {
          return {
            ...state,
            userInfo: payload,
            id: payload.id,
          };
        }
      )
      .addMatcher(userApi.endpoints.getUserInfo.matchRejected, (state) => {
        return {
          ...state,
        };
      });
  },
});

export const selectUserInfo = (state: RootState) => state.user.userInfo;
export const selectUser = (state: RootState) => state.user;
export const selectUserRole = (state: RootState) => state.user.userInfo.userRole;

export const { updateUser, logout } = userSlice.actions;
export default userSlice.reducer;
