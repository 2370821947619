import { googleLogout } from '@react-oauth/google';
import { api, resetAll } from 'app/api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/ui/dropdown';
import routes from 'constants/routes';
import { CUSTOM_SUBSCRIPTION_PLANS } from 'constants/subscription';
import { BadgePlus, LogOut, Settings } from 'lucide-react';
import { showSuccessMessage } from 'modules/alert/utils';
import { useLogoutMutation } from 'modules/auth/authApi';
import { useAuth } from 'modules/auth/useAuth';
import { logout, selectUserInfo } from 'modules/auth/userSlice';
import { copilotApi } from 'modules/copilot/copilotApi';
import { notificationApi } from 'modules/notification/notificationApi';
import { useGetStripeDashboardLinkQuery } from 'modules/subscriptionsPlans/subscriptionsPlansApi';
import {
  isCurrentCustomPaidSubscriptionPlan,
  selectSubscriptionName,
} from 'modules/subscriptionsPlans/subscriptionsPlansSlice';
import { userApi } from 'modules/user/userApi';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const SubscriptionButton = () => {
  const { isSiteOwner } = useAppSelector(selectUserInfo);
  const currentSubscriptionName = useAppSelector(selectSubscriptionName);
  const isCustomPaidSubscription = useAppSelector(isCurrentCustomPaidSubscriptionPlan);

  const isCurrentSubscriptionFree =
    currentSubscriptionName?.toLocaleLowerCase() ===
    CUSTOM_SUBSCRIPTION_PLANS.Free.toLocaleLowerCase();
  const { data: stripeLink } = useGetStripeDashboardLinkQuery(undefined, {
    skip: isCurrentSubscriptionFree,
  });

  if (!isSiteOwner || !currentSubscriptionName || isCustomPaidSubscription) {
    return null;
  }

  if (isCurrentSubscriptionFree) {
    return (
      <a href={stripeLink?.redirectUrl ?? ''} target="_self" rel="noopener noreferrer">
        <DropdownMenuItem role="button">
          <BadgePlus className="mr-2 h-4 w-4" /> Subscription
        </DropdownMenuItem>
      </a>
    );
  }
  return (
    <Link to="/subscriptions-plans/dashboard">
      <DropdownMenuItem role="button">
        <BadgePlus className="mr-2 h-4 w-4" /> Subscription
      </DropdownMenuItem>
    </Link>
  );
};

export const UserMenu: React.FC = () => {
  const { firstName, lastName, avatar } = useAppSelector(selectUserInfo);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [removeSession] = useLogoutMutation();

  const handleLogout = () => {
    googleLogout();
    dispatch(logout());
    removeSession(null).then(() =>
      dispatch(userApi.endpoints.getUserInfo.initiate(undefined, { forceRefetch: true }))
    );
    dispatch(resetAll());

    dispatch(api.util.resetApiState());
    dispatch(userApi.util.resetApiState());
    dispatch(notificationApi.util.resetApiState());
    dispatch(copilotApi.util.resetApiState());

    // Reset document title, because we changing it when user has some unread notifications
    document.title = 'Threads';
    showSuccessMessage('Successfully logged out.');
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Avatar>
          <AvatarImage src={avatar ?? undefined} alt={`${firstName} ${lastName}`} />
          <AvatarFallback>{`${firstName[0]}${lastName[0]}`.toUpperCase()}</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mr-6 w-48 border-solid border-card-border">
        <DropdownMenuLabel>Manage Threads</DropdownMenuLabel>
        <Link to="/settings">
          <DropdownMenuItem role="button">
            <Settings className="mr-2 h-4 w-4" /> Settings
          </DropdownMenuItem>
        </Link>
        <SubscriptionButton />
        <DropdownMenuSeparator />
        <DropdownMenuItem role="button" onClick={handleLogout}>
          <LogOut className="mr-2 h-4 w-4" /> Logout
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
