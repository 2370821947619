export const TITLES = {
  OWNER: 'Thread(s) owned by You',
  CONTRIBUTOR: 'Thread(s) you contribute to',
  ALL: 'Thread(s) in Your Workspace',
};

export enum EThreadsListOptionIds {
  MY_THREADS = 'my_threads',
  MY_CONTRIBUTIONS = 'my_contributions',
  ALL_THREADS = 'all_threads',
}

export enum EThreadsStatisticOptionIds {
  LAST_7_DAYS = 'last_7_days',
  LAST_30_DAYS = 'last_30_days',
}
