import { useAppSelector } from 'app/hooks';
import _ from 'lodash';
import { selectUserInfo } from 'modules/auth/userSlice';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import colors from 'theme/colors';
import { IUserMinimize } from 'types/user';
import { Avatar, Button, Group, Icon, MemberList } from 'ui';
import { IMessageResponse } from '../../types';
import Input from '../Input';
import { MembersWrapper, ReplyContent, ReplyWrapper, WithLine } from '../Layout';
import Message from './Message';
import { MessageContext } from './index';

interface IProps {
  messages: IMessageResponse[];
}

/**
 * List of replies to a thread message.
 *
 * @param messages - array of replies
 *
 * Note that on first render, replies are hidden, and can be toggled via a button. Replies received
 * since the first render are *always* shown and are not affected by button.
 */
const ReplyHistory: React.FC<IProps> = ({ messages }) => {
  const { msgId } = useParams();
  const { replyInputRef, replyMode, parentId, isOwner } = useContext(MessageContext);
  const { avatar } = useAppSelector(selectUserInfo);
  const [isOpen, setIsOpen] = useState(false);

  // Memoized list of messages received before page loads
  const initialMessages = useMemo(() => messages, []);

  // List of messages received after page loads
  const newMessages = messages.slice(initialMessages.length);

  const users = initialMessages
    .map((message) => message.creator)
    .filter(Boolean) as IUserMinimize[];
  const replyIncludesCopiedId = !!_.find(initialMessages, { id: Number(msgId) });

  useEffect(() => {
    setIsOpen(replyIncludesCopiedId);
  }, [msgId]);

  return (
    <ReplyWrapper owner={isOwner}>
      <ReplyContent id="reply-content" owner={isOwner}>
        {initialMessages.length > 0 &&
          (isOpen ? (
            <>
              <MembersWrapper owner={isOwner} withBorder>
                <Button
                  onClick={() => setIsOpen(false)}
                  leftIcon={
                    <Icon
                      icon="ChevronDownIcon"
                      size="extraSmall"
                      rotate="180deg"
                      stroke={colors.blue8}
                    />
                  }
                  textColor={colors.blue8}
                  variant="plain"
                >
                  Hide Replies
                </Button>
              </MembersWrapper>
              {initialMessages.map((message, index) => (
                <Message
                  showReplyBorder={replyMode || initialMessages.length - 1 !== index}
                  key={message.id}
                  isReplied
                  {...message}
                />
              ))}
            </>
          ) : (
            <MembersWrapper owner={isOwner}>
              <WithLine owner={isOwner}>
                <Group align="center" gap="10px">
                  <MemberList disabled list={users} />
                  <Button textColor={colors.blue8} variant="plain" onClick={() => setIsOpen(true)}>
                    View {initialMessages.length} More Replies
                  </Button>
                </Group>
              </WithLine>
            </MembersWrapper>
          ))}
        {newMessages.map((message) => (
          <Message showReplyBorder key={message.id} isReplied {...message} />
        ))}
        {replyMode && (
          <Group style={{ padding: '15px 24px 0' }} align="flex-start">
            {isOwner ? (
              <Input ref={replyInputRef} replyTo={parentId} />
            ) : (
              <WithLine style={{ flex: 1 }} owner={isOwner}>
                <Input ref={replyInputRef} replyTo={parentId} />
              </WithLine>
            )}
            {isOwner ? (
              <WithLine owner={isOwner}>
                <Avatar src={avatar} />
              </WithLine>
            ) : (
              <Avatar src={avatar} />
            )}
          </Group>
        )}
      </ReplyContent>
    </ReplyWrapper>
  );
};

export default ReplyHistory;
