import { ViewMode } from 'gantt-task-react';
import _ from 'lodash';
import { EGanttViewType, IGanttSwitcher } from 'modules/threads/types';
import React, { MouseEvent } from 'react';
import colors from 'theme/colors';
import { Checkbox, Group, Text } from 'ui';
import { EVENTS_ICON_MAP } from 'ui/eventBadge/EventBadge';
import { GanttSwitchButton } from './Layout';

const ViewGanttSwitcher: React.FC<IGanttSwitcher> = ({
  onViewModeChange,
  onViewListChange,
  isChecked,
  viewMode,
  ganttViewType,
}) => {
  const handleViewChange = (e: MouseEvent<HTMLElement>) => {
    const view = e.currentTarget.dataset.view;
    onViewModeChange(view as ViewMode);
  };

  return (
    <Group align="center" gap="10px" justify="space-between">
      <Group
        id="action-icons-wrapper"
        justify="space-between"
        align="center"
        gap="10px"
        wrap="wrap"
      >
        {ganttViewType === EGanttViewType.GANTT_EVENT_VIEW &&
          Object.entries(EVENTS_ICON_MAP).map(([eventTypeName, iconComponent]) => (
            <Group justify="space-between" align="center">
              {iconComponent}
              <Text size="xs" color={colors.gray}>
                {_.capitalize(eventTypeName)}
              </Text>
            </Group>
          ))}
      </Group>
      <Group align="center" gap="10px">
        <GanttSwitchButton
          size="sm"
          variant="outlined"
          color={colors.dark4}
          data-view={ViewMode.Day}
          onClick={handleViewChange}
          isActive={viewMode === ViewMode.Day}
        >
          Day
        </GanttSwitchButton>
        <GanttSwitchButton
          size="sm"
          variant="outlined"
          color={colors.dark4}
          data-view={ViewMode.Week}
          onClick={handleViewChange}
          isActive={viewMode === ViewMode.Week}
        >
          Week
        </GanttSwitchButton>
        <GanttSwitchButton
          size="sm"
          variant="outlined"
          color={colors.dark4}
          onClick={handleViewChange}
          data-view={ViewMode.Month}
          isActive={viewMode === ViewMode.Month}
        >
          Month
        </GanttSwitchButton>
      </Group>
    </Group>
  );
};

export default ViewGanttSwitcher;
