import React, { useCallback, useDeferredValue } from 'react';
import { Stack, Spinner, Switch } from 'ui';
import { THREAD_TABLE_OPTIONS } from 'constants/sorting';
import { THead, TBody } from 'modules/threads/components';
import { EDefaultSortOption, IThreadsResult } from 'modules/threads/types';

const DEFAULT_SORT_DSC = EDefaultSortOption.UPDATED_AT_DSC;

interface ITableParams {
  sortOption: string;
  list: IThreadsResult[];
  isLoading: boolean;
  setSortOption: (option: string) => void;
  cypressAttribute?: string;
  isAllThreads?: boolean;
}

const ThreadTable = ({
  sortOption,
  list,
  isLoading,
  setSortOption,
  cypressAttribute,
  isAllThreads,
}: ITableParams) => {
  const deferredList = useDeferredValue(list);

  const sorting = useCallback(
    (sortName: string) => {
      const resetSort = sortOption.indexOf('-') !== -1;
      if (resetSort && sortOption !== DEFAULT_SORT_DSC) {
        return setSortOption(DEFAULT_SORT_DSC);
      }
      if (sortName === sortOption) {
        return setSortOption(`-${sortName}`);
      }
      return setSortOption(sortName);
    },
    [sortOption]
  );

  return (
    <div id="threads-table-wrapper">
      {isLoading ? (
        <Stack align="center" fullHeight>
          <Spinner />
        </Stack>
      ) : (
        <table id="threads-table" data-cy={cypressAttribute}>
          <THead
            rows={THREAD_TABLE_OPTIONS}
            onSort={sorting}
            cypressAttribute={cypressAttribute && `${cypressAttribute}-head`}
          />
          <TBody
            rows={deferredList}
            cypressAttribute={cypressAttribute && `${cypressAttribute}-body`}
            isAllThreads={isAllThreads}
          />
        </table>
      )}
    </div>
  );
};

export default ThreadTable;
