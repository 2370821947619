import { EChartType } from 'modules/threads/types';
import { EThreadsListOptionIds } from 'pages/dashboard/constants';

export const FILTER_OPTION_MAP = {
  [EThreadsListOptionIds.MY_THREADS]: {
    label: 'My Threads',
    value: EChartType.MY_THREADS,
  },
  [EThreadsListOptionIds.MY_CONTRIBUTIONS]: {
    label: 'My Contributions',
    value: EChartType.CONTRIBUTION,
  },
  [EThreadsListOptionIds.ALL_THREADS]: {
    label: 'All Threads',
    value: EChartType.ALL_THREADS,
  },
};
