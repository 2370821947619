import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import _mapKeys from 'lodash/mapKeys';
import { EUserWalkthroughs } from './constants';

interface IState {
  completedWalkthroughs: {
    dashboard: boolean;
    threadDetails: boolean;
  };
}

interface IWalkthroughPayload {
  userWalkthroughKey: EUserWalkthroughs;
  value: boolean;
}

export const initialState: IState = {
  completedWalkthroughs: {
    [EUserWalkthroughs.DASHBOARD]: true,
    [EUserWalkthroughs.THREAD_DETAILS]: true,
  },
};

const userWalkthroughSlice = createSlice({
  name: 'userWalkthrough',
  initialState,
  reducers: {
    setWalkthroughState: (
      state,
      { payload: { userWalkthroughKey, value } }: PayloadAction<IWalkthroughPayload>
    ) => {
      state.completedWalkthroughs[userWalkthroughKey] = value;
      return state;
    },

    /** Will simply mark the state values in `completedWalkthroughs` as false (incomplete). */
    markAllUserWalkthroughsAsIncomplete: (state) => {
      state.completedWalkthroughs[EUserWalkthroughs.DASHBOARD] = false;
      state.completedWalkthroughs[EUserWalkthroughs.THREAD_DETAILS] = false;
      return state;
    },
  },
});

export const selectUserWalkthroughs = (state: RootState) =>
  state.userWalkthrough.completedWalkthroughs;

export const { setWalkthroughState, markAllUserWalkthroughsAsIncomplete } =
  userWalkthroughSlice.actions;

export default userWalkthroughSlice.reducer;
