import { useAppDispatch } from 'app/hooks';
import routes from 'constants/routes';
import { Indicator } from 'layout/App';
import { startCase } from 'lodash';
import { useModal } from 'modules/modals/ModalProvider';
import React, { useMemo } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import colors from 'theme/colors';
import { Avatar, Group, Icon, Stack, Text } from 'ui';
import { getEditorText, getFullName, handleApiCall } from 'utils/helpers';
import { deepCamelcaseKeys } from 'utils/mappers';
import { useMarkAsReadMutation } from '../notificationApi';
import { markAsRead } from '../slice';
import { ENotificationTypes, INotificationData, INotificationResponse } from '../types';
import { getAvatarUrl, parseTemplate } from '../utils';
import { Blank, Reply } from './Layout';

const MessageCard: React.FC<INotificationResponse & { closeNotificationModal: () => void }> = ({
  thread,
  createdAt,
  notificationType,
  data,
  isRead,
  id,
  cypressAttribute,
  closeNotificationModal,
}) => {
  const {
    sender,
    messageId,
    initialMessage,
    repliedMessage,
    threadInviteId,
    threadUserInviteId,
    contributor,
    thread: threadData,
    event,
    count,
    reference,
  }: INotificationData = deepCamelcaseKeys(JSON.parse(data));

  const isSummary = notificationType === ENotificationTypes.THREAD_UPDATE_SUMMARY;
  const isPlyableEvent = notificationType === ENotificationTypes.PLYABLE_QUOTE_READY;

  const avatarUrl = sender ? getAvatarUrl(sender.avatar) : getAvatarUrl(contributor?.avatar);
  const title = parseTemplate(notificationType, {
    fullName: sender ? getFullName(sender) : startCase(getFullName(contributor)),
    threadName: threadData?.name,
    eventName: event?.name,
    fileName: reference?.fileName,
    fileVersion: reference?.version,
  });

  const dispatch = useAppDispatch();
  const { open } = useModal();
  const navigate = useNavigate();
  const [markCurrentAsRead] = useMarkAsReadMutation();
  const handleMessageClick = async () => {
    if (!isRead) {
      const result = await markCurrentAsRead(id);
      handleApiCall(
        result,
        () => null,
        () => dispatch(markAsRead(id))
      );
    }
    if (thread && notificationType !== ENotificationTypes.THREAD_TOO_MANY_CONTRIBUTOR_INVITE) {
      navigate(
        messageId
          ? `${routes.thread}/${thread}/messages/${messageId}?referer=notificationsList`
          : `${routes.thread}/${thread}`
      );
      closeNotificationModal();
    }
    if (notificationType === ENotificationTypes.THREAD_TOO_MANY_CONTRIBUTOR_INVITE) {
      open({
        variant: 'center',
        contentLabel: 'action',
        name: 'Invite Action',
        modal: 'contributorInviteActions',
        context: { threadName: threadData?.name, threadInviteId },
      });
      closeNotificationModal();
    }
    if (isPlyableEvent && thread && reference?.id) {
      navigate(`${routes.thread}/${thread}`);
      open({
        variant: 'fullRight',
        contentLabel: 'Edit Reference',
        name: 'Edit Reference',
        modal: 'editReference',
        context: {
          referenceId: reference?.id,
        },
        id: 'modal-edit-reference',
      });
      closeNotificationModal();
    }
    if (notificationType === ENotificationTypes.USER_REQUESTED_TO_JOIN_THREAD) {
      open({
        variant: 'center',
        contentLabel: 'contributor-pending-action',
        name: 'Contributor Pending Action',
        context: { threadData, inviteId: threadUserInviteId },
        modal: 'contributorPendingActions',
      });
    }
  };

  const isOtherNotificationType = !isSummary && !isPlyableEvent;

  const containerAlign = useMemo(() => {
    return isSummary ? 'flex-start' : 'center';
  }, [isSummary]);

  return (
    <div style={{ padding: '18px 10px 18px 0' }} data-cy={cypressAttribute}>
      <Group
        align={containerAlign}
        gap="15px"
        style={{ cursor: 'pointer' }}
        onClick={handleMessageClick}
        data-cy={cypressAttribute && `${cypressAttribute}-group-message`}
      >
        <Indicator
          isActive={!isRead}
          centered={isSummary}
          data-cy={cypressAttribute && `${cypressAttribute}-message-indicator`}
        >
          {isSummary && <Blank />}
          {isPlyableEvent && (
            <div style={{ backgroundColor: '#000', borderRadius: '50%' }}>
              <Icon icon="PlyableLogo" size="large" />
            </div>
          )}
          {isOtherNotificationType && (
            <Avatar
              src={avatarUrl}
              cypressAttribute={cypressAttribute && `${cypressAttribute}-message-avatar`}
            />
          )}
        </Indicator>
        <Stack style={{ flex: 1 }}>
          <Group justify="space-between" align="center">
            <Text
              cypressAttribute={cypressAttribute && `${cypressAttribute}-message-title`}
              style={{ lineHeight: '1.4', maxWidth: '80%' }}
            >
              {title}
            </Text>
            <Stack gap="8px">
              <Text
                cypressAttribute={cypressAttribute && `${cypressAttribute}-message-date`}
                color={colors.gray5}
                align="right"
              >
                <Moment fromNow>{createdAt}</Moment>
              </Text>
            </Stack>
          </Group>
          <Text
            color={colors.gray3}
            cypressAttribute={cypressAttribute && `${cypressAttribute}-message-summary`}
          >
            {notificationType !== ENotificationTypes.THREAD_UPDATE_SUMMARY
              ? getEditorText(initialMessage)
              : `Total updates ${count}`}
          </Text>
          {repliedMessage && (
            <Reply>
              <Text
                cypressAttribute={cypressAttribute && `${cypressAttribute}-message-replied`}
                color={colors.gray2}
              >
                {getEditorText(repliedMessage)}
              </Text>
            </Reply>
          )}
        </Stack>
      </Group>
    </div>
  );
};

export default MessageCard;
