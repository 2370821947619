import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Button, Stack, TextInput } from 'ui';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { validationSchema } from 'utils/validation';
import { useResetPasswordMutation } from 'modules/auth/authApi';
import {
  showErrorMessage,
  showSuccessMessage,
  showUncaughtErrorMessage,
} from 'modules/alert/utils';
import { NOTIFICATION } from 'modules/alert/constants';
import routes from 'constants/routes';

const validation = yup.object({
  password: validationSchema.passwordRequired(),
  password1: validationSchema.confirmPassword(),
});

const initValue = {
  password: '',
  password1: '',
};

const Form = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
    mode: 'onSubmit',
    defaultValues: initValue,
  });

  const [resetPassword, { isSuccess, isError, isLoading }] = useResetPasswordMutation();

  const { userId, token } = useParams();
  const navigate = useNavigate();

  const onSubmit = ({ password, password1 }: FieldValues) => {
    const payload = {
      newPassword1: password,
      newPassword2: password1,
      uid: userId as string,
      token: token as string,
    };
    resetPassword(payload);
  };

  useEffect(() => {
    if (isError) {
      showUncaughtErrorMessage();
    }
    if (isSuccess) {
      showSuccessMessage(NOTIFICATION.PASSWORD_CHANGED);
      navigate(routes.login);
    }
  }, [isLoading]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="30px">
        <TextInput
          error={errors.password}
          register={register}
          type="password"
          name="password"
          label="Type your new Password"
          data-cy="reset-password-new-password-btn"
        />
        <TextInput
          error={errors.password1}
          register={register}
          type="password"
          name="password1"
          label="Confirm Password"
          data-cy="reset-password-confirm-password-btn"
        />
        <Button type="submit" cypressAttribute="reset-password-confirm-btn">
          Confirm
        </Button>
      </Stack>
    </form>
  );
};

export default Form;
