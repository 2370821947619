import {
  AnnotationAvatar,
  AnnotationCommentContent,
  AnnotationCommentDate,
  AnnotationCommentHolder,
  AnnotationCommentLink,
} from 'modules/annotations/components/AnnotationLayout';
import React from 'react';
import Moment from 'react-moment';
import colors from 'theme/colors';
import { IUserMinimize } from 'types/user';
import { Stack, Text } from 'ui';

interface IPlyableAnnotationCommentProps {
  /**
   * Content of the comment.
   */
  content: string;
  /**
   * Date of the comment.
   */
  date: string;
  /**
   * Comment author avatar source.
   */
  avatarSrc: string;
  creator: IUserMinimize | null;
}

const PlyableAnnotationComment: React.FC<IPlyableAnnotationCommentProps> = (props) => {
  const { creator, content, date, avatarSrc } = props;

  return (
    <AnnotationCommentLink target="_blank" rel="noreferrer" data-cy="annotation-comment-link">
      <AnnotationCommentHolder
        justify="space-between"
        align="center"
        gap="16px"
        fluid
        data-cy="annotation-comment-holder"
      >
        <AnnotationCommentContent size="sm" weight="400" data-cy="annotation-comment-content">
          {content}
        </AnnotationCommentContent>
        <Stack gap="3px" justify="flex-start" align="flex-end" data-cy="annotation-comment-stack">
          <AnnotationAvatar size="small" src={avatarSrc} data-cy="annotation-comment-avatar" />
          <Text size="sm">{creator?.firstName}</Text>
          <AnnotationCommentDate
            color={colors.gray3}
            size="sm"
            weight="400"
            noWrap
            data-cy="annotation-comment-date"
          >
            <Moment fromNow date={date} />
          </AnnotationCommentDate>
        </Stack>
      </AnnotationCommentHolder>
    </AnnotationCommentLink>
  );
};

export default PlyableAnnotationComment;
