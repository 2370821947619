import React, { useState } from 'react';
import styled from 'styled-components';
import routes from 'constants/routes';
import * as yup from 'yup';
import devices from 'theme/devices';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { validationSchema } from 'utils/validation';
import {
  Button,
  Group,
  Stack,
  TextInput,
  PhoneInput,
  TextArea,
  FileLoader,
  Spinner,
  Text,
  Checkbox,
} from 'ui';
import useSSO from 'hooks/useSSO';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { handleApiCall, displayErrorMessages } from 'utils/helpers';
import { NOTIFICATION } from 'modules/alert/constants';
import { IErrorMessages } from 'types';
import { userApi } from 'modules/user/userApi';
import { useAppDispatch } from 'app/hooks';
import { useGetSubscriptionsPlansQuery } from 'modules/subscriptionsPlans/subscriptionsPlansApi';
import colors from 'theme/colors';
import { IRegistrationForm } from '../types';
import { useRegisterMutation, useSelfLinkRegisterMutation } from '../signupApi';
import { getSignupType } from '../utils';
import { ESignupTypes } from '../constants';

const validation = yup.object({
  firstName: validationSchema.stringWithNoSpecialCharsRequired(64),
  lastName: validationSchema.stringWithNoSpecialCharsRequired(64),
  phone: validationSchema.phone(),
  password: validationSchema.passwordRequired(),
  repeatedPassword: validationSchema.confirmPassword(),
  acceptedTerms: yup.bool(),
});

const UPLOAD_OPTIONS = {
  SIZE: 5_000_000,
  ACCEPT: 'image/png, image/jpeg',
};

const defaultValues: IRegistrationForm = {
  avatar: null,
  firstName: '',
  lastName: '',
  password: '',
  repeatedPassword: '',
  description: '',
  phone: null,
  country: '',
  acceptedTerms: false,
};

const AdaptiveField = styled(Group)`
  @media screen and ${devices.mobileS} {
    flex-direction: column;
  }
  @media screen and ${devices.tablet} {
    flex-direction: row;
    align-items: center;
  }
`;

const SignupForm: React.FC = () => {
  const [ava, setAva] = useState<File | null>(null);
  const { signUpWithSSO } = useSSO();
  const dispatch = useAppDispatch();

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(validation),
    defaultValues,
    mode: 'onSubmit',
  });
  const [signUp, { isLoading: isSignUpLoading }] = useRegisterMutation();
  const [selfLinkSignUp, { isLoading: isSelfSignUpLoading }] = useSelfLinkRegisterMutation();
  const { data } = useGetSubscriptionsPlansQuery();
  const navigate = useNavigate();
  const [query] = useSearchParams();

  const userEmail = query.get('email') || '';
  const timestamp = query.get('timestamp') || '';
  const siteName = query.get('site_name') || '';
  const selfSignUpToken = query.get('token') || '';

  // check if user has auth method set to sso
  const isAuthMethodSSO = query.get('auth_method') === 'sso';

  // check for subscription plan id
  const subscriptionPriceId = query.get('price_id') || '';

  const subscriptionName = data?.find(
    (subscription) =>
      subscription.year.priceId === subscriptionPriceId ||
      subscription.month.priceId === subscriptionPriceId
  )?.name;

  const isSelfSignUpLinkValid = userEmail || timestamp || siteName || selfSignUpToken;
  const isFormLoading = isSignUpLoading || isSelfSignUpLoading;

  const handleAvatarChange = (file: File) => {
    setAva(file);
  };

  const handleSelfSignUp = async (payload: IRegistrationForm) => {
    if (!isSelfSignUpLinkValid) {
      showErrorMessage(NOTIFICATION.INVALID_INVITE_LINK);
      return;
    }
    if (ava) {
      payload.avatar = ava;
    }
    // TODO: POST the accepted T&C's date here so we get TZ info
    const selfSignUpPayload = {
      ...payload,
      email: userEmail,
      timestamp: Number(timestamp),
      siteName,
      magicToken: selfSignUpToken,
    };

    // check if it is a self sign up or create own workspace(the link will include price id)
    const requestPayload =
      subscriptionPriceId?.length > 0
        ? { ...selfSignUpPayload, priceId: subscriptionPriceId }
        : selfSignUpPayload;

    try {
      const result = await selfLinkSignUp(requestPayload).unwrap();

      // redirect user to stripe checkout page to pay for their subscription
      if (result.redirectUrl) {
        window.open(result.redirectUrl, '_self', 'noopener noreferrer');
      } else {
        await dispatch(userApi.endpoints.getUserInfo.initiate(undefined, { forceRefetch: true }));
      }
    } catch (res) {
      displayErrorMessages((res as any).error.data as IErrorMessages);
    }
  };

  const handleSignUpViaInvitationToken = async (payload: IRegistrationForm) => {
    const token = query.get('invite');
    if (!token) {
      showErrorMessage(NOTIFICATION.INVALID_INVITE_LINK);
      return;
    }

    if (ava) {
      payload.avatar = ava;
    }

    const result = await signUp({
      ...payload,
      token,
    });
    handleApiCall(
      result,
      ({ error }) => {
        displayErrorMessages(error?.data as IErrorMessages);
      },
      () => {
        showSuccessMessage(NOTIFICATION.ACCOUNT_CREATED);
        navigate(routes.login);
      }
    );
  };

  if (isAuthMethodSSO) {
    return (
      <Stack gap="30px">
        <AdaptiveField gap="20px">
          <Button fluid type="button" onClick={signUpWithSSO} cypressAttribute="sign-up-sso-btn">
            <Text weight="700">Sign up with SSO</Text>
          </Button>
        </AdaptiveField>
      </Stack>
    );
  }

  const onSubmit = (values: IRegistrationForm) => {
    const signupType = getSignupType(query);

    if (signupType === ESignupTypes.SELF_SIGN_UP) {
      return handleSelfSignUp(values);
    }

    if (signupType === ESignupTypes.INVITE_SIGN_UP) {
      return handleSignUpViaInvitationToken(values);
    }

    return showErrorMessage(NOTIFICATION.INVALID_INVITE_LINK);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-cy="sign-up-form">
      <Stack gap="30px">
        <AdaptiveField gap="20px">
          <div style={{ flex: 3 }}>
            <TextInput
              error={errors.firstName}
              register={register}
              name="firstName"
              label="First name"
              required
              data-cy="sign-up-first-name"
            />
          </div>
          <div style={{ flex: 3 }}>
            <TextInput
              error={errors.lastName}
              register={register}
              name="lastName"
              label="Last name"
              required
              data-cy="sign-up-last-name"
            />
          </div>
          <div>
            <PhoneInput error={errors.phone} name="phone" label="Phone" control={control} />
          </div>
        </AdaptiveField>
        <TextArea
          label="Description"
          name="description"
          placeholder="Write a short description about yourself"
          resize="none"
          register={register}
          data-cy="sign-up-form-description"
        />
        <FileLoader
          register={register}
          name="avatar"
          label="Upload a profile picture. Max size is 50 MB"
          preview
          accept={UPLOAD_OPTIONS.ACCEPT}
          size={UPLOAD_OPTIONS.SIZE}
          onChange={handleAvatarChange}
        />
        <Group gap="20px">
          <TextInput
            autoComplete="new-password"
            register={register}
            required
            type="password"
            name="password"
            label="Password"
            error={errors.password}
            data-cy="sign-up-form-password"
          />
          <TextInput
            register={register}
            required
            type="password"
            name="repeatedPassword"
            label="Confirm password"
            error={errors.repeatedPassword}
            data-cy="sign-up-form-confirm-password"
          />
        </Group>
        <Group gap="10px">
          <Checkbox
            size="lg"
            name="acceptedTerms"
            checked={getValues('acceptedTerms')}
            onChange={(e) => setValue('acceptedTerms', !getValues('acceptedTerms'))}
          />
          <span>
            I accept the{' '}
            <a
              style={{ color: colors.blue }}
              href={
                subscriptionName === 'Business'
                  ? 'https://www.authentise.com/terms-of-service'
                  : 'https://www.authentise.com/tos-users'
              }
              className="link"
              target="_blank"
              rel="noreferrer"
              data-cy="terms-of-service-link"
            >
              Terms of Service
            </a>
          </span>
        </Group>
        <Group justify="flex-end">
          <Button
            fluid
            type="submit"
            disabled={isFormLoading || !watch('acceptedTerms')}
            cypressAttribute="sign-up-submit-btn"
          >
            {isFormLoading ? (
              <Spinner color="#000" size="small" />
            ) : (
              <Text weight="700">Sign up</Text>
            )}
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default SignupForm;
