import { ESignupTypes } from './constants';

export const getSignupType = (query: URLSearchParams) => {
  const encodedInviteToken = query.get('invite') || '';

  if (['email', 'timestamp', 'site_name', 'token'].every((key) => query.get(key))) {
    return ESignupTypes.SELF_SIGN_UP;
  }

  if (encodedInviteToken) {
    return ESignupTypes.INVITE_SIGN_UP;
  }

  return null;
};
