import { IEvent, IEventResponse } from 'modules/events/types';
import { deepCamelcaseKeys } from 'utils/mappers';
import { ECopilotSuggestionTypes, ICopilotSuggestion } from './types';

export const determineEditModalData = (suggestionType: ECopilotSuggestionTypes | string) => {
  const createEventModal = 'createEvent' as const;

  switch (suggestionType) {
    case ECopilotSuggestionTypes.CREATE:
      return {
        name: 'Create an Event',
        modal: createEventModal,
      };
    case ECopilotSuggestionTypes.MODIFY:
      return {
        name: 'Edit an Event',
        modal: createEventModal,
      };
    default:
      return {
        name: 'Create an Event',
        modal: createEventModal,
      };
  }
};

/**
 * @description Compare IEvent payloads; get field(s) that have been modified.
 */
export const getModifiedEventFields = (
  currentEvent: IEventResponse | undefined,
  copilotSuggestionData: ICopilotSuggestion
) => {
  if (!currentEvent) {
    return [];
  }

  const excludedFields = ['id', 'dueDate'];
  const transformedCopilotSuggestionData = deepCamelcaseKeys(copilotSuggestionData);

  const modifiedFields: string[] = [];

  Object.keys(currentEvent).forEach((key) => {
    if (
      Object.prototype.hasOwnProperty.call(transformedCopilotSuggestionData, key) &&
      transformedCopilotSuggestionData[key] &&
      currentEvent[key as keyof IEvent] !== transformedCopilotSuggestionData[key]
    ) {
      modifiedFields.push(key);
    }
  });

  return modifiedFields.filter((field) => !excludedFields.includes(field));
};
