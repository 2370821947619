import { icons } from 'assets/icons';
import routes from 'constants/routes';
import { Gantt, Task, ViewMode } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import { useGetAllEventsQuery } from 'modules/events/eventsApi';
import { IEvent, IEventResponse } from 'modules/events/types';
import { DEFAULT_LIMIT, GANTT_BAR_WIDTH, GANTT_CELL_WIDTH } from 'modules/threads/constants';
import { useGetGanttQuery } from 'modules/threads/threadsApi';
import {
  EDefaultSortOption,
  EGanttViewType,
  IGanttTasks,
  IThreadView,
} from 'modules/threads/types';
import moment from 'moment';
import React, { CSSProperties, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import colors from 'theme/colors';
import eventColors from 'theme/eventColors';
import { Group, Icon, Spinner, Stack, Switch, Text, Tooltip } from 'ui';
import { EVENTS_KEY_MAP } from 'ui/eventBadge/EventBadge';
import { Td } from '../tbody';
import { TaskListHeader, TaskListTable, ViewGanttSwitcher } from './components';
import { GanttContainer } from './components/Layout';
import { GanttStyles } from './gantStyles';
import './styles.css';
import { createGanttTask } from './utils';

const GanttView: React.FC<IThreadView> = ({ url, title }) => {
  const [lineLinkagesVisible, setLineLinkagesVisible] = useState(true);
  const [ganttViewType, setGanttViewType] = useState(EGanttViewType.GANTT_EVENT_VIEW);
  const [view, setView] = useState(ViewMode.Day);
  const [isChecked, setIsChecked] = useState(true);

  const [infiniteScrollLowerBoundAmount, setInfiniteScrollLowerBoundAmount] = useState(1);
  const [infiniteScrollUpperBoundAmount, setInfiniteScrollUpperBoundAmount] = useState(1);

  const startOfChart = moment().subtract(infiniteScrollLowerBoundAmount, 'month');
  const endOfChart = moment().add(infiniteScrollUpperBoundAmount, 'month');

  let day = startOfChart;
  const days: Date[] = [];

  while (day <= endOfChart) {
    days.push(day.toDate());
    day = day.clone().add(1, 'd');
  }

  let week = startOfChart;
  const weeks: Date[] = [];

  while (week <= endOfChart) {
    weeks.push(week.toDate());
    week = week.clone().add(1, 'week');
  }

  let month = startOfChart;
  const months: Date[] = [];
  while (month <= endOfChart) {
    months.push(month.toDate());
    month = month.clone().add(1, 'M');
  }

  // Return Days/Weeks/Months arrays created above
  const getColumnTimeUnits = (viewMode: ViewMode) => {
    switch (viewMode) {
      case ViewMode.Week:
        return weeks;
      case ViewMode.Month:
        return months;
      default:
        return days;
    }
  };

  // Return either of chars: 'D'/'W'/'M'
  const getTimeComparisonType = (viewMode: ViewMode) => {
    switch (viewMode) {
      case ViewMode.Week:
        return 'W';
      case ViewMode.Month:
        return 'M';
      default:
        return 'D';
    }
  };

  const getHeaderDimensions = (viewMode: ViewMode) => {
    switch (viewMode) {
      case ViewMode.Week:
        return {
          width: 40 * 4,
          height: 60,
        };
      case ViewMode.Month:
        return {
          width: window!.innerWidth / months.length,
          height: 60,
        };
      default:
        return {
          width: 40,
          height: 60,
        };
    }
  };

  const getEventPositionData = (event: IEventResponse, viewMode: ViewMode) => {
    const dayOfTheWeekIndex = moment(event.dueDate).day();
    const dayOftheMonthIndex = moment(event.dueDate).date();

    switch (viewMode) {
      case ViewMode.Week:
        return {
          timeMeasureTotal: 7,
          timeMeasureDayIndex: dayOfTheWeekIndex,
        };
      case ViewMode.Month:
        return {
          timeMeasureTotal: 30,
          timeMeasureDayIndex: dayOftheMonthIndex,
        };
      default:
        // 1 day, for 1 day
        return {
          timeMeasureTotal: 4,
          timeMeasureDayIndex: 0,
        };
    }
  };

  // Remove all lines
  const clearLineLinks = () => {
    window.scrollY = 0;

    const removableElements = [
      ...Array.from(document.querySelectorAll('[id^="linking-line-from"]')),
      ...Array.from(document.querySelectorAll('#horizontal-line-svg-container')),
      ...Array.from(document.querySelectorAll(`[id^="horizontal-line-svg-container-for-thread"]`)),
      ...Array.from(document.querySelectorAll('#line-join')),
      ...Array.from(document.querySelectorAll('#linking-lines-container')),
    ];

    removableElements.forEach((element) => element.remove());
  };

  const {
    data: ganttList,
    isLoading,
    isFetching,
  } = useGetGanttQuery({
    url,
    ordering: EDefaultSortOption.UPDATED_AT_DSC,
    limit: DEFAULT_LIMIT,
    offset: 0,
  });

  const count = ganttList?.count;

  // ganttTasks are all threads
  const ganttTasks = useMemo(() => {
    return ganttList?.results.map((thread) => createGanttTask(thread)) || [];
  }, [isLoading, isFetching]) as IGanttTasks[];

  const { data: eventData } = useGetAllEventsQuery({
    dateRange: [moment(startOfChart).format('YYYY-MM-DD'), moment(endOfChart).format('YYYY-MM-DD')],
  });

  const mainChartWrapper = document.getElementById('main-gantt-wrapper');
  const mainChartElement: HTMLElement | null = document.getElementById('main-gantt');

  const handleInfiniteScroll = () => {
    const hasReachedHorizontalEnd =
      mainChartWrapper &&
      mainChartWrapper.scrollLeft >= mainChartWrapper.scrollWidth - document.body.clientWidth;

    const hasReachedHorizontalStart = mainChartWrapper && mainChartWrapper.scrollLeft <= 0;

    // When scrolling into the future, load infinitely until max 6 months.
    if (hasReachedHorizontalEnd && infiniteScrollUpperBoundAmount < 6) {
      setInfiniteScrollUpperBoundAmount((previous) => previous + 1);
    }

    // When scrolling into the past, load infinitely until max 6 months.
    if (hasReachedHorizontalStart && infiniteScrollLowerBoundAmount < 6) {
      setInfiniteScrollLowerBoundAmount((previous) => previous + 1);
    }
  };

  const handleViewModeChange = (viewMode: ViewMode) => {
    // Reset the infinite scroll bounds
    setInfiniteScrollUpperBoundAmount(1);
    setInfiniteScrollLowerBoundAmount(1);

    // Remove line links to refresh the view
    window.scrollX = 0;
    window.scrollY = 0;

    if (viewMode !== view) {
      clearLineLinks();
      setLineLinkagesVisible(false);
      setView(viewMode);
    }
  };

  /**
   *
   * @description Redirect to related thread.
   * @param event
   * @param thread
   */
  const onGanttTaskClick = (thread?: IGanttTasks) => {
    if (thread) {
      window.open(`${routes.thread}/${thread?.id}`, '_blank');
    }
  };

  // Pure desperation code
  useEffect(() => {
    clearLineLinks();
    setLineLinkagesVisible(false);
    setTimeout(() => {
      clearLineLinks();
      setLineLinkagesVisible(true);
      // 500ms is the sweet-spot for some reason.
    }, 500);
  }, [view, ganttViewType, infiniteScrollLowerBoundAmount, infiniteScrollUpperBoundAmount]);

  useEffect(() => {
    // 🚨 MAIN RENDER FUNCTION OF LINKING LINES 🚨
    // ==========================================
    if (lineLinkagesVisible === false) {
      return;
    }

    // mainChartWrapper?.scrollTo(500, 0);

    // Create svg wrapper for the line links
    // this will hold ALL OTHER JOIN lines (L>)
    // create linking line container
    const linkingLinesContainer: SVGSVGElement = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg'
    );
    linkingLinesContainer.setAttributeNS(null, 'id', 'linking-lines-container');
    linkingLinesContainer.style.width = `${mainChartElement?.clientWidth}px`;
    linkingLinesContainer.style.height = `${mainChartElement?.clientHeight}px`;
    linkingLinesContainer.style.position = 'relative';
    linkingLinesContainer.style.top = `-${mainChartElement?.clientHeight}px`;
    linkingLinesContainer.style.marginBottom = `-${mainChartElement?.clientHeight}px`;

    mainChartElement?.appendChild(linkingLinesContainer);
    // End of creating svg wrapper for line links (L>)

    // ganttTasks means threads
    ganttTasks.forEach((thread) => {
      const currentChartRowElement: HTMLElement | null = document.querySelector(`#t-${thread.id}`);
      const eventsForCurrentRowElements = document.querySelectorAll(
        `[data-thread-id-for-event="${thread.id}"`
      ) as NodeListOf<HTMLElement>;

      // Create the svg wrapper because all lines have to be wrapped in svg
      // this will hold the HORIZONTAL lines (->->)
      const horizontalSvgContainer = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'svg'
      ) as unknown as SVGSVGElement;

      const existingHorizontalSvgContainer: HTMLElement | null = document.getElementById(
        `horizontal-line-svg-container-for-thread-${thread.id}`
      );

      // only append this if it doesn't already exist cos it re-renders twice
      if (!existingHorizontalSvgContainer) {
        // Adjust the svg container
        horizontalSvgContainer.setAttributeNS(
          null,
          'id',
          `horizontal-line-svg-container-for-thread-${thread.id}`
        );
        horizontalSvgContainer.setAttributeNS(null, 'display', 'block');
        horizontalSvgContainer.style.position = 'relative';
        horizontalSvgContainer.style.height = `${currentChartRowElement?.clientHeight}px`;
        horizontalSvgContainer.style.width = `${currentChartRowElement?.clientWidth}px`;
        horizontalSvgContainer.style.top = '0';
        horizontalSvgContainer.style.left = `-${currentChartRowElement?.clientWidth}px`;

        if (mainChartElement) {
          eventsForCurrentRowElements.forEach((event: HTMLElement, index: number) => {
            const eventJSONData: IEvent = JSON.parse(
              event.attributes.getNamedItem('data-event-json')!.value
            );

            // HANDLE MAPPING OF HORIZONTAL ->-> LINES
            // ==========================================

            // Get NEXT -> event after this one
            const nextEvent = eventsForCurrentRowElements[index + 1]
              ? eventsForCurrentRowElements[index + 1]
              : eventsForCurrentRowElements[index];

            // create the horiznotal line
            const horizontalLine = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            horizontalLine.setAttributeNS(null, 'id', 'line-join');
            horizontalLine.setAttributeNS(
              null,
              'd',
              `M ${
                event.getBoundingClientRect().left -
                mainChartElement.getBoundingClientRect().left +
                event.clientWidth / 2
              } ${currentChartRowElement && currentChartRowElement.clientHeight / 2} L ${
                nextEvent.getBoundingClientRect().left -
                mainChartElement.getBoundingClientRect().left +
                event.clientWidth / 2
              } ${currentChartRowElement && currentChartRowElement.clientHeight / 2}`
            );
            horizontalLine.setAttributeNS(null, 'stroke', eventColors[eventJSONData.type]);
            horizontalLine.setAttributeNS(null, 'stroke-linejoin', 'round');
            horizontalLine.setAttributeNS(null, 'stroke-width', '2px');
            horizontalLine.setAttributeNS(null, 'opacity', '1');
            horizontalLine.setAttributeNS(null, 'fill', 'none');

            // append the container to dom
            currentChartRowElement?.appendChild(horizontalSvgContainer);
            horizontalSvgContainer.appendChild(horizontalLine);

            // ==========================================
            // END OF MAPPING OF HORIZONTAL ->-> LINES -|- END

            // HANDLE MAPPING OF THE OTHER JOIN LINES (L>) LINES
            // ==========================================

            /* There must be a related event AND a related thread to make sure deleted
            links disappear */
            if (eventJSONData.relatedEvent && eventJSONData.relatedThread) {
              const relatedEventElement: HTMLElement | null = document.querySelector(
                `[data-event-id-for-event="${eventJSONData.relatedEvent}"]`
              );

              if (!relatedEventElement) {
                return;
              }

              const linkingLine = document.createElementNS('http://www.w3.org/2000/svg', 'path');
              linkingLine.setAttributeNS(
                null,
                'id',
                `linking-line-from-${eventJSONData.id}-to-${eventJSONData.relatedEvent}`
              );

              // start of arrowhead
              const linkingLineDefs = document.createElementNS(
                'http://www.w3.org/2000/svg',
                'defs'
              );
              linkingLineDefs.setAttributeNS(
                null,
                'id',
                `defs-for-linking-line-from-${eventJSONData.id}-to-${eventJSONData.relatedEvent}`
              );

              const linkingLineMarker = document.createElementNS(
                'http://www.w3.org/2000/svg',
                'marker'
              );
              linkingLineMarker.setAttributeNS(null, 'id', `arrow-${eventJSONData.id}`);
              linkingLineMarker.setAttributeNS(null, 'orient', 'auto');

              const linkingLineArrowhead = document.createElementNS(
                'http://www.w3.org/2000/svg',
                'path'
              );
              linkingLineArrowhead.setAttributeNS(null, 'id', 'arrowhead-path');
              linkingLineArrowhead.setAttributeNS(null, 'd', `M0,0 L0,6 L9,3 z`);
              linkingLineArrowhead.setAttributeNS(null, 'fill', `white`);

              // end of arrowhead

              let direction = `M ${
                event.getBoundingClientRect().left -
                mainChartElement.getBoundingClientRect().left +
                event.clientWidth / 2 /*
                getHeaderDimensions(view).width / getDivisibleAmount(view) */
              } ${
                event.getBoundingClientRect().top -
                mainChartElement.getBoundingClientRect().top -
                window.scrollY +
                10
              }`;

              // Vertical modifiers
              if (
                Number(event.getBoundingClientRect().top) <
                Number(relatedEventElement.getBoundingClientRect().top)
              ) {
                console.info(
                  `[info]: TRAVEL DOWN (n)px FROM`,
                  event.id,
                  'TO',
                  relatedEventElement.id
                );

                direction += ` V ${
                  relatedEventElement.getBoundingClientRect().bottom -
                  (mainChartElement.getBoundingClientRect().top + window.scrollY) -
                  10
                }`;
              } else {
                console.info(
                  `[info]: TRAVEL UP (n)px FROM`,
                  event.id,
                  'TO',
                  relatedEventElement.id
                );

                direction += ` V ${
                  relatedEventElement.getBoundingClientRect().bottom -
                  mainChartElement.getBoundingClientRect().top -
                  window.scrollY -
                  10
                }`;
              }
              // --- End of vertical modifiers ---

              // Horizontal modifiers

              if (
                event.getBoundingClientRect().left <
                relatedEventElement.getBoundingClientRect().left
              ) {
                // Travel right
                console.info('[info]: TRAVEL RIGHT FROM', event.id, 'TO', relatedEventElement.id);

                direction += ` H ${
                  relatedEventElement.getBoundingClientRect().left -
                  mainChartElement.getBoundingClientRect().left +
                  window.scrollX
                }`;

                // --- End of travel right ---
              } else if (
                event.getBoundingClientRect().left ===
                relatedEventElement.getBoundingClientRect().left
              ) {
                console.info(
                  '[info]: HORIZONTALLY STAY THE SAME',
                  event.id,
                  'TO',
                  relatedEventElement.id
                );
              } else {
                // Travel left
                console.info('[info]: TRAVEL LEFT FROM', event.id, 'TO', relatedEventElement.id);

                direction += ` H ${
                  // relatedEventElement.getBoundingClientRect().right -
                  // mainChartElement.getBoundingClientRect().right +
                  // event.clientWidth * 2
                  relatedEventElement.getBoundingClientRect().right -
                  (mainChartElement.getBoundingClientRect().left + window.scrollX)
                }`;

                // --- End of travel left ---
              }
              // --- End of horizontal modifiers ---

              linkingLine.setAttributeNS(null, 'stroke', eventColors[eventJSONData.type]);
              linkingLine.setAttributeNS(null, 'stroke-linejoin', 'round');
              linkingLine.setAttributeNS(null, 'stroke-width', '2px');
              linkingLine.setAttributeNS(null, 'opacity', '1');
              linkingLine.setAttributeNS(null, 'fill', 'none');
              linkingLine.setAttributeNS(null, 'd', direction);
              linkingLine.setAttributeNS(null, 'marker-end', `url(#arrow-${eventJSONData.id})`);

              linkingLinesContainer.appendChild(linkingLine);

              // ==========================================
              // END OF MAPPING OF THE OTHER JOIN LINES (L>) LINES
            }
          });
        }
      }
    });
  }, [document.querySelectorAll('td'), lineLinkagesVisible, ganttViewType, eventData]);

  const ganttCellWidth = useMemo(() => {
    if (view === ViewMode.Month)
      return { columnWidth: GANTT_CELL_WIDTH.MONTH, barWidth: GANTT_BAR_WIDTH.MONTH };
    if (view === ViewMode.Week)
      return { columnWidth: GANTT_CELL_WIDTH.WEEK, barWidth: GANTT_BAR_WIDTH.WEEK };
    return { columnWidth: GANTT_CELL_WIDTH.DAY, barWidth: GANTT_BAR_WIDTH.DAY };
  }, [view]);

  if (isLoading) {
    return (
      <Stack align="center" justify="center" fullHeight>
        <Spinner />
      </Stack>
    );
  }

  const bodyCellStyle: CSSProperties = {
    height: 60,
    border: `1px solid #35383F`,
    color: colors.gray1,
  };

  const headerCellStyle: CSSProperties = {
    height: getHeaderDimensions(view).height,
    width: getHeaderDimensions(view).width,
    border: `1px solid #35383F`,
    textAlign: 'center',
    color: colors.gray1,
  };

  return (
    <>
      <Group align="center" justify="space-between" gap="25px" style={{ padding: '16px 0' }}>
        <Text>
          {count} {title}
        </Text>
        <Switch
          leftText="Event View"
          rightText="Bar View"
          checked={ganttViewType === EGanttViewType.GANTT_BAR_VIEW}
          onChange={() =>
            setGanttViewType(
              ganttViewType === EGanttViewType.GANTT_EVENT_VIEW
                ? EGanttViewType.GANTT_BAR_VIEW
                : EGanttViewType.GANTT_EVENT_VIEW
            )
          }
        />
      </Group>

      {ganttTasks?.length ? (
        <GanttContainer gap="15px">
          <ViewGanttSwitcher
            onViewModeChange={handleViewModeChange}
            onViewListChange={() => {
              setLineLinkagesVisible((previous) => !previous);
              if (lineLinkagesVisible) {
                clearLineLinks();
              }
            }}
            isChecked={!lineLinkagesVisible}
            viewMode={view}
            ganttViewType={ganttViewType}
          />

          {ganttViewType === EGanttViewType.GANTT_EVENT_VIEW ? (
            <div
              id="main-gantt-wrapper"
              onScroll={handleInfiniteScroll}
              style={{ width: '100%', overflowX: 'scroll' }}
            >
              <table id="main-gantt">
                <tr>
                  <th style={{ padding: '0 10px', color: colors.gray, width: 200 }}>Thread</th>
                  {view === ViewMode.Month &&
                    months.map((currentMonth) => (
                      <th style={headerCellStyle}>
                        <div>
                          <p>{moment(currentMonth).format('MMMM')}</p>
                        </div>
                      </th>
                    ))}
                  {view === ViewMode.Week &&
                    weeks.map((currentWeek) => (
                      <th style={headerCellStyle}>
                        <div>
                          <p>{moment(currentWeek).startOf('W').date()}</p>
                          <p style={{ fontSize: 12 }}>{moment(currentWeek).format('ddd')}</p>
                        </div>
                      </th>
                    ))}
                  {view === ViewMode.Day &&
                    days.map((currentDay) => (
                      <th style={headerCellStyle}>
                        <div>
                          <p>{moment(currentDay).date()}</p>
                          <p style={{ fontSize: 12 }}>{moment(currentDay).format('ddd')}</p>
                        </div>
                      </th>
                    ))}
                </tr>

                {ganttTasks.map((thread) => {
                  const eventsForCurrentThread = eventData?.results.filter(
                    (event) => event.thread.id === Number(thread.id)
                  );

                  return (
                    <tr id={`t-${thread.id}`}>
                      <Td
                        style={{ backgroundColor: colors.dark1 }}
                        className="thread-title-cell"
                        width={200}
                      >
                        <Link
                          style={{ position: 'relative', zIndex: 5 }}
                          className="link"
                          color={colors.gray}
                          to={`/thread/${thread.id}`}
                        >
                          {thread.name}
                        </Link>
                      </Td>
                      {/* `columnTimeUnits(viewMode)` returns either Days/Weeks/Months */}
                      {getColumnTimeUnits(view).map((timeMeasure, columnIndex) => {
                        const currentEvent = eventsForCurrentThread?.find((event) => {
                          return moment(timeMeasure).isSame(
                            event.dueDate,
                            getTimeComparisonType(view)
                          );
                        });

                        const currentEvents = eventsForCurrentThread?.filter((event) =>
                          moment(timeMeasure).isSame(event.dueDate, getTimeComparisonType(view))
                        );

                        if (!currentEvent) {
                          return (
                            <td id={`t-${thread.id}-col-${columnIndex}`} style={bodyCellStyle} />
                          );
                        }

                        return (
                          <td
                            id={`t-${thread.id}-col-${columnIndex}`}
                            data-thread-id={thread.id}
                            data-event-id={currentEvent.id}
                            data-column={columnIndex}
                            // data-event-json={JSON.stringify(currentEvent)}
                            style={bodyCellStyle}
                          >
                            <div
                              id={`event-wrapper-t-${thread.id}`}
                              style={{
                                position: 'relative',
                                // marginBottom: `-60%`,
                              }}
                            >
                              {currentEvents?.map((event, index) => {
                                return (
                                  <Icon
                                    id={`event-t-${thread.id}-col-${columnIndex}`}
                                    data-thread-id-for-event={thread.id}
                                    data-event-id-for-event={event.id}
                                    data-column={columnIndex}
                                    data-due-date-for-event={event.dueDate}
                                    data-event-json={JSON.stringify(event)}
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      // width: '100%',
                                      top: `-${getHeaderDimensions(view).height / 4}px`,
                                      left:
                                        currentEvents?.length > 1
                                          ? /*
                                          Get the cell width, divide it by (1 day in a day, 7 days in
                                           a week, 30 days in a month)
                                        */
                                            getHeaderDimensions(view).width /
                                              getEventPositionData(event, view).timeMeasureTotal +
                                            /* 
                                          Get the index of the current day in either week or month and
                                           multiply it by a constant of 10
                                           */
                                            getEventPositionData(event, view).timeMeasureDayIndex *
                                              10 +
                                            /* 
                                          Add an addtional offset if the event is on the same day, to 
                                          make it obvious to the user it is a stack of events
                                          */
                                            index * 2
                                          : getHeaderDimensions(view).width / 2,
                                      position: 'absolute',
                                      marginBottom: '100%',
                                      zIndex: 4,
                                    }}
                                    icon={EVENTS_KEY_MAP[event.type] as keyof typeof icons}
                                  />
                                );
                              })}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </table>
            </div>
          ) : (
            <Gantt
              tasks={ganttTasks}
              viewMode={view}
              viewDate={new Date()}
              TooltipContent={Tooltip as FunctionComponent}
              TaskListTable={TaskListTable}
              TaskListHeader={TaskListHeader}
              columnWidth={ganttCellWidth.columnWidth}
              listCellWidth={isChecked ? GANTT_CELL_WIDTH.LIST : ''}
              handleWidth={ganttCellWidth.barWidth}
              onClick={(task: Task) => onGanttTaskClick(task)}
              {...GanttStyles}
            />
          )}
        </GanttContainer>
      ) : (
        <Stack align="center" justify="center" style={{ height: '370px' }}>
          <Text size="lg">No Threads...</Text>
        </Stack>
      )}
    </>
  );
};

export default GanttView;
