import { yupResolver } from '@hookform/resolvers/yup';
import { useAppSelector } from 'app/hooks';
import { Button } from 'components/ui/button';
import { Card, CardContent } from 'components/ui/card';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import {
  showErrorMessage,
  showSuccessMessage,
  showUncaughtErrorMessage,
} from 'modules/alert/utils';
import { useChangePasswordMutation } from 'modules/auth/authApi';
import { selectUserInfo } from 'modules/auth/userSlice';
import React from 'react';
import { useForm } from 'react-hook-form';
import { validationSchema } from 'utils/validation';
import { InferType, object } from 'yup';

const validation = object({
  currentPassword: validationSchema.stringRequired(),
  newPassword: validationSchema.passwordRequired(),
  confirmPassword: validationSchema.confirmPassword('newPassword'),
});

export const Account: React.FC = () => {
  const [changePassword, { isLoading: isChangingPassword }] = useChangePasswordMutation();
  const { email } = useAppSelector(selectUserInfo);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(validation),
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  const onSubmit = async (payload: InferType<typeof validation>) => {
    try {
      await changePassword({
        oldPassword: payload.currentPassword,
        newPassword1: payload.newPassword,
        newPassword2: payload.confirmPassword,
      }).unwrap();
      showSuccessMessage('Password updated');
      reset();
    } catch (error) {
      if ((error as any).status === 400) {
        showErrorMessage('Incorrect password');
        return;
      }
      showUncaughtErrorMessage(error);
    }
  };

  return (
    <div className="flex h-full w-full flex-col gap-6">
      <div className="flex flex-col gap-2">
        <h2 className="text-lg font-semibold">Your Account</h2>
        <span className="text-sm text-neutral-500">Security and privacy settings</span>
        <div className="h-[1px] w-full bg-secondary" />
      </div>
      <form className="flex flex-col gap-4 px-1" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex-1 space-y-2">
          <Label htmlFor="currentPassword">Current Password</Label>
          <Input
            id="currentPassword"
            placeholder="Current Password"
            className="border-solid"
            {...register('currentPassword')}
            type="password"
          />
          {errors.currentPassword && (
            <span className="text-sm text-destructive">{errors.currentPassword.message}</span>
          )}
        </div>
        <div className="flex-1 space-y-2">
          <Label htmlFor="newPassword">New Password</Label>
          <Input
            id="newPassword"
            placeholder="New Password"
            className="border-solid"
            {...register('newPassword')}
            type="password"
          />
          {errors.newPassword && (
            <span className="text-sm text-destructive">{errors.newPassword.message}</span>
          )}
        </div>
        <div className="flex-1 space-y-2">
          <Label htmlFor="confirmPassword">Confirm Password</Label>
          <Input
            id="confirmPassword"
            placeholder="Confirm Password"
            className="border-solid"
            {...register('confirmPassword')}
            type="password"
          />
          {errors.confirmPassword && (
            <span className="text-sm text-destructive">{errors.confirmPassword.message}</span>
          )}
        </div>
        <div>
          <Button disabled={isChangingPassword || !isDirty}>Save</Button>
        </div>
      </form>
      <div className="space-y-4">
        <div className="flex flex-col gap-2">
          <h2 className="text-md font-semibold">Danger Zone</h2>
          <div className="h-[1px] w-full bg-secondary" />
        </div>
        <Card className="border-solid">
          <CardContent className="flex flex-col justify-between gap-2 pt-6 sm:flex-row sm:items-center">
            <div className="flex flex-col space-y-0.5">
              <span className="text-base font-semibold">Delete My Account</span>
              <span className="text-sm text-neutral-500">
                All data associated with your account will be removed
              </span>
            </div>
            <div className="flex flex-row justify-end sm:justify-start">
              <Button variant="destructive" asChild>
                <a
                  // eslint-disable-next-line max-len
                  href={`mailto:support@authentise.zendesk.com?subject=Delete%20all%20personal%20data&body=Hello,%20I%20would%20like%20to%20delete%20all%20my%20personal%20data,%20My%20email%20is%20${email}`}
                  rel="noreferrer"
                >
                  Email Us
                </a>
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
