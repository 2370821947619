import React from 'react';
import CheckCircleIcon from 'assets/icons/check-circle.svg';
import XCircleIcon from 'assets/icons/x-circle.svg';
import GenericErrorToaster from 'modules/toasters/components/GenericErrorToaster';
import { toast } from 'react-toastify';
import colors from 'theme/colors';
import '../styles.css';

export const showSuccessMessage = (msg: string) =>
  toast.success(msg, {
    className: 'success-toaster',
    icon: <img src={CheckCircleIcon} alt="success" />,
    style: {
      color: colors.white,
      backgroundColor: colors.green1,
    },
  });

export const showWarningMessage = (msg: string) =>
  toast.warn(msg, {
    className: 'warn-toaster',
    icon: <img src={XCircleIcon} alt="warn" />,
    autoClose: 4000,
    style: {
      color: colors.white,
      backgroundColor: colors.orange_dark,
    },
  });

export const showErrorMessage = (msg: string) =>
  toast.error(msg, {
    className: 'error-toaster',
    icon: <img src={XCircleIcon} alt="error" />,
    style: {
      color: colors.white,
      backgroundColor: colors.error_red,
    },
  });

export const showPushNotification = (body: JSX.Element) =>
  toast.info(body, {
    theme: 'dark',
    autoClose: 4000,
    pauseOnHover: true,
  });

/**
 * User flow for handling when uncaught/unknown errors occur.
 * Intended to replace vague 'Oops!' messages.
 */
export const showUncaughtErrorMessage = (errorData?: unknown) => {
  toast(<GenericErrorToaster errorData={errorData} />, {
    className: 'error-toaster',
    closeOnClick: false,
    style: {
      color: colors.white,
      backgroundColor: colors.error_red,
    },
  });
};
