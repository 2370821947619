import { EMessageType, ESystemMessageType } from 'modules/chat/types';
import { JsonObject } from 'react-use-websocket/dist/lib/types';
import { IUserMinimize } from 'types/user';

export enum EEventType {
  MESSAGE = 'message',
  MESSAGE_UPDATE = 'message_update',
  MESSAGE_DELETE = 'message_destroy',
  LIKE = 'like',
  NOTIFICATION = 'notification',
  THREADS_3D_MODEL = 'threads_3d_model',
  COPILOT_EVENT_SUGGESTIONS = 'copilot_event_suggestions',
  COPILOT_CHAT_MESSAGE = 'copilot_chat_message',
}

export interface ISocketPayload extends JsonObject {
  type: EEventType;
  context: any;
}
export enum EAction {
  SUBSCRIBE = 'subscribe',
  UNSUBSCRIBE = 'unsubscribe',
}

export interface ISystemMessage {
  id: number;
  text: string;
  creator: IUserMinimize;
  type: ESystemMessageType;
  createdAt: string;
  updatedAt: string;
}
