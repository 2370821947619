import { useReferencesQuery } from 'modules/threads/threadsApi';
import { IReferences } from 'modules/threads/types';
import * as Layout from 'pages/thread/components/Layout';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useInfinitePagination } from 'hooks/usePagination';
import { Button } from 'ui';
import colors from 'theme/colors';
import ReferenceCard from './components/ReferenceCard';

const ReferenceList: React.FC = () => {
  const { id } = useParams<string>();
  const { limit, next } = useInfinitePagination({ pageSize: 10 });
  const { data: references, isLoading } = useReferencesQuery({ id, limit });

  if (isLoading) {
    return <Layout.NoItems>Loading...</Layout.NoItems>;
  }

  if (!references?.count) {
    return <Layout.NoItems>No References...</Layout.NoItems>;
  }
  const canLoadMore = !!references.next;
  return (
    <Layout.TabContentWrap>
      {references.results.map((reference: IReferences) => (
        <ReferenceCard key={reference.id} reference={reference} />
      ))}
      {canLoadMore && (
        <div className="flex flex-col items-center">
          <Button disabled={isLoading} color={colors.orange} variant="filled" onClick={next}>
            Load more
          </Button>
        </div>
      )}
    </Layout.TabContentWrap>
  );
};

export default ReferenceList;
