import { AVAILABLE_INTEGRATIONS } from 'constants/integrations';
import { IFileVersions } from 'modules/threads/types';
import { IUser, IUserMinimize, TUserMinimizeSnakeCase } from 'types/user';
import { IPaginatedResponse } from '../../types';

export enum EChatFilter {
  ALL = 'all',
  COMMENTS = 'user_message',
  LOGS = 'system_message',
  PLAYABLE_EVENT = 'playable_event',
  THREADS_3D_EVENT = 'threads_3d_event',
}

interface IReferenceCreate {
  name: string;
  description?: string;
  url?: string;
  file?: any;
}

export interface IMessageCreate {
  text?: string;
  thread?: number;
  replyTo?: number;
  references: IReferenceCreate[];
  hash?: string;
}

interface IReferenceResponse extends IReferenceCreate {
  id: string;
  icons: [keyof typeof AVAILABLE_INTEGRATIONS];
  fileVersions: IFileVersions[];
  creator: IUser;
  createdAt: string;
  updatedAt: string;
}

export enum EMessageType {
  SYSTEM_MESSAGE = 'SYSTEM_MESSAGE',
  USER_MESSAGE = 'USER_MESSAGE',
  FLOWS_MESSAGE = 'FLOWS_MESSAGE',
  GOOGLE_MESSAGE = 'GOOGLE_MESSAGE',
  PLYABLE_MESSAGE = 'PLYABLE_MESSAGE',
  THREADS_3D_MESSAGE = 'THREADS_3D_MESSAGE',
}

export enum ESystemMessageType {
  EVENT = 'event',
  REFERENCE = 'reference',
  CONTRIBUTOR = 'contributor',
  RESOURCE = 'resource',
}

export interface ISystemMessagePayload {
  message: string;
  message_detail?: string;
  type: ESystemMessageType;
  data: {
    name: string;
    id: string;
    creator: string | TUserMinimizeSnakeCase;
    created_at: string;
    updated_at: string;
    type?: string;
  };
}

export interface IMessageResponse extends Omit<IMessageCreate, 'references'> {
  id: number;
  creator: IUserMinimize | null;
  references: IReferenceResponse[];
  replies: IMessageResponse[];
  createdAt: string;
  updatedAt: string;
  numberOfLikes: number;
  likes: IUserMinimize[];
  type: EMessageType;
  isLoading?: boolean;
  preloadFiles?: File[];
  messageType?: EMessageType;
}

export interface IGetMessageParams {
  limit: number;
  offset: number;
  id: string;
  type: EChatFilter;
  link: string | null;
}

export interface IMessageEdit extends IMessageCreate {
  id: number;
}

export interface IPrependPayload {
  data: IPaginatedResponse<IMessageResponse>;
  index: number;
}

export interface IMessageProps extends IMessageResponse {
  isReplied?: boolean;
  hasReplies?: boolean;
  showReplyBorder?: boolean;
}

export interface IOwner {
  owner: boolean;
}

export interface IMessageLoad {
  id: string;
  msgId?: string;
  filter: EChatFilter;
}

export interface IChatQuery {
  limit?: number;
  offset?: number;
  message_id?: string;
  type?: EChatFilter;
}

export interface IPreloadThunk {
  payload: {
    data: IPaginatedResponse<IMessageResponse>;
    index: number;
  };
}
