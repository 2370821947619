export default {
  orange: '#E96B18',
  orange_dark: '#D57232',
  orange1: '#432918',
  orange2: '#F09D23',
  error_red: '#E33535',
  red: '#D53232',
  red2: '#E33535',
  red3: '#4D2323',
  green: '#59C1AA',
  green1: '#11B667',
  green2: '#026712',
  yellow: '#F5BF44',
  purple: '#F583FF',
  dark: '#1E1E1E',
  dark1: '#1F222A',
  dark2: '#242732',
  dark3: '#40476C',
  dark4: '#737D9B',
  dark5: '#222228',
  dark6: '#212121',
  dark7: '#2A2E3F',
  dark8: '#2F323C',
  blue: '#6B83FF',
  blue2: '#2E385A',
  blue3: '#384569',
  blue4: '#233861',
  blue5: '#2e385a',
  blue6: '#6b83ff',
  blue7: '#96B9FF',
  blue8: '#7C87B5',
  blue9: '#383F5B',
  blue10: '#303658',
  gray: '#BDBDBD',
  gray1: '#8B8B8E',
  gray2: '#DCDCDC',
  gray3: '#9A9A9A',
  gray4: '#7F8391',
  gray5: '#707070',
  purple1: '#8F34BA',
  white: '#ffffff',
  bgSlate50: 'rgba(255, 255, 255, 0.05)',
  neutralBlue100: '#1A1C22',
  brandSecondaryLight: '#62AAFF',
  addBtnBg: '#432918',
  indicator: '#1479F0',
};
