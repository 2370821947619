import { yupResolver } from '@hookform/resolvers/yup';
import { useAppSelector } from 'app/hooks';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { showSuccessMessage, showUncaughtErrorMessage } from 'modules/alert/utils';
import { selectUserInfo } from 'modules/auth/userSlice';
import { useUpdateProfileMutation } from 'modules/user/userApi';
import React from 'react';
import { useForm } from 'react-hook-form';
import { PhoneInput } from 'ui';
import { getPhoneNumber } from 'utils/helpers';
import { compressImage } from 'utils/image';
import { validationSchema } from 'utils/validation';
import { InferType, mixed, object } from 'yup';

const UPLOAD_OPTIONS = {
  SIZE: 5_000_000,
  ACCEPT: 'image/png, image/jpeg',
};

const validation = object({
  firstName: validationSchema.stringWithNoSpecialCharsRequired(64),
  lastName: validationSchema.stringWithNoSpecialCharsRequired(64),
  phone: validationSchema.phone(),
  email: validationSchema.emailRequired(),
  avatar: mixed(),
});

export const Profile: React.FC = () => {
  const { avatar, firstName, lastName, email, phone } = useAppSelector(selectUserInfo);
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(validation),
    defaultValues: {
      firstName,
      lastName,
      email,
      phone,
      avatar,
    },
  });

  const getAvatarFromPayload = async (input: string | File | undefined) => {
    if (!(input instanceof File)) {
      return undefined;
    }
    return compressImage(input);
  };

  const onSubmit = async (payload: InferType<typeof validation>) => {
    try {
      await updateProfile({
        ...payload,
        // Files uploaded as a list
        avatar: await getAvatarFromPayload(payload.avatar[0]),
        phone: payload.phone ? getPhoneNumber(payload.phone) : phone,
      }).unwrap();
      showSuccessMessage('Account details updated');
    } catch (error) {
      showUncaughtErrorMessage(error);
    }
  };

  return (
    <div className="flex h-full w-full flex-col gap-6 pl-1">
      <div className="flex flex-col gap-2">
        <h2 className="text-lg font-semibold">Your Profile</h2>
        <span className="text-sm text-neutral-500">
          How you&apos;ll appear to other Threads users
        </span>
        <div className="h-[1px] w-full bg-secondary" />
      </div>
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex-1 space-y-2">
          <Label htmlFor="firstName">First Name</Label>
          <Input
            id="firstName"
            placeholder="First Name"
            className="border-solid"
            maxLength={64}
            {...register('firstName')}
          />
        </div>
        <div className="flex-1 space-y-2">
          <Label htmlFor="lastName">Last Name</Label>
          <Input
            id="lastName"
            placeholder="Last Name"
            className="border-solid"
            maxLength={64}
            {...register('lastName')}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="avatar">Avatar</Label>
          <Input
            type="file"
            role="button"
            className="border-solid text-foreground"
            {...register('avatar')}
            accept={UPLOAD_OPTIONS.ACCEPT}
            size={UPLOAD_OPTIONS.SIZE}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="phone">Phone Number</Label>
          <PhoneInput name="phone" error={errors.phone} control={control} />
        </div>
        <div className="space-y-2">
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            placeholder="Email"
            disabled
            className="border-solid"
            maxLength={254}
            {...register('email')}
          />
        </div>
        <div>
          <Button disabled={!isValid || isLoading || !isDirty}>Save</Button>
        </div>
      </form>
    </div>
  );
};
