import { INTEGRATION_COLORS } from 'constants/integrations';
import styled from 'styled-components';
import colors from 'theme/colors';
import { Button, Text } from 'ui';

export const ReferenceCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  border-radius: 8px;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.dark2};
`;

export const Description = styled.p`
  max-width: 390px;
  color: ${({ theme }) => theme.colors.gray3};
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
`;

export const ImgBox = styled.div`
  width: 51px;
  height: 51px;

  img {
    object-fit: cover;
    max-height: 100%;
  }
`;

export const IconButton = styled(Button)`
  padding: 0;
`;

export const Name = styled(Text)`
  max-width: 390px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PlayableLogoWrapper = styled.div`
  display: flex;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  background-color: #000;
  border-radius: 4px;
  border: 1px solid #f583ff;
`;

export const Threads3dLogoWrapper = styled.div`
  display: flex;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.dark2};
  border-radius: 4px;
  padding: 5px;
  border: 1px solid ${INTEGRATION_COLORS.threads3d};
`;
