import { useAppSelector } from 'app/hooks';
import { selectUserInfo } from 'modules/auth/userSlice';
import EventCard from 'modules/events/components/EventCard';
import { useGetAllEventsQuery } from 'modules/events/eventsApi';
import { IEventResponse } from 'modules/events/types';
import React, { useState } from 'react';
import colors from 'theme/colors';
import { TReactSelectOption } from 'types';
import { Group, ReactSelect, Spinner, Stack, Text, Title } from 'ui';
import { generateShortUUID } from 'utils/helpers';
import * as Layout from './Layout';

interface IProps {
  cypressAttribute?: string;
}

enum EDashboardEventsValues {
  ALL_EVENTS = 'ALL_EVENTS',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  LATE = 'LATE',
}

/**
 * Smaller component to display list of mapped events.
 */
const DashboardEventsList = ({ data }: { data?: IEventResponse[] }) => {
  if (!data?.length) {
    return (
      <Stack className="max-h-72" fullHeight justify="center" align="center">
        <Group className="max-w-xl">
          <Text size="sm" align="center" color={colors.gray1}>
            Keep an eye out for upcoming events that you are assigned, or why not create one
            yourself track the progress of a Thread you are currently collaborating on!
          </Text>
        </Group>
      </Stack>
    );
  }

  return (
    <>
      {data.map((event: IEventResponse) => (
        <EventCard key={`event-${event.id}`} {...event} showLinkToOriginThread />
      ))}
    </>
  );
};

/**
 * List of events that is intended to be displayed on Dashboard only.
 */
const DashboardEvents: React.FC<IProps> = ({ cypressAttribute }) => {
  const { id: userId } = useAppSelector(selectUserInfo);

  const [selectedOptionValue, setSelectedOptionValue] = useState(EDashboardEventsValues.ALL_EVENTS);
  const { data, isLoading } = useGetAllEventsQuery({
    userId,
    status:
      selectedOptionValue === EDashboardEventsValues.ALL_EVENTS ? undefined : selectedOptionValue,
  });

  const eventsOptions: TReactSelectOption[] = [
    {
      label: 'All Events',
      value: EDashboardEventsValues.ALL_EVENTS,
    },
    {
      label: 'Completed',
      value: EDashboardEventsValues.COMPLETED,
    },
    {
      label: 'In Progress',
      value: EDashboardEventsValues.IN_PROGRESS,
    },
    {
      label: 'Not Started',
      value: EDashboardEventsValues.NOT_STARTED,
    },
    {
      label: 'Late',
      value: EDashboardEventsValues.LATE,
    },
  ];

  return (
    <Layout.DropdownSelector
      id="dashboard-events-container"
      background={colors.dark1}
      data-cy={cypressAttribute && `${cypressAttribute}-data`}
    >
      <Group justify="space-between" align="start">
        <Title id={`${cypressAttribute}-title`} heading="h5">
          Events
        </Title>
        <ReactSelect
          id={cypressAttribute && `${cypressAttribute}-dropdown`}
          isMulti={false}
          defaultValue={eventsOptions[0]}
          isSearchable={false}
          options={eventsOptions}
          onChange={({ value }) => setSelectedOptionValue(value)}
        />
      </Group>
      <Layout.DropdownSelectorContent
        id="dashboard-events-content"
        data-cy={cypressAttribute && `${cypressAttribute}-data-id-${selectedOptionValue}`}
        height="90%"
      >
        {isLoading ? (
          <Group fluid justify="center" align="center">
            <Spinner size="medium" />
          </Group>
        ) : (
          <DashboardEventsList data={data?.results} />
        )}
      </Layout.DropdownSelectorContent>
    </Layout.DropdownSelector>
  );
};

export default DashboardEvents;
