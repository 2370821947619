import { api } from 'app/api';
import { TFeatureFlag } from 'types';

const featureApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFeatures: build.query<TFeatureFlag[], { siteId: string | undefined }>({
      query: () => ({
        url: `/sites/features/`,
      }),
    }),
  }),
});

export const { useGetFeaturesQuery } = featureApi;
