import styled, { css } from 'styled-components';
import colors from 'theme/colors';
import { Stack } from 'ui';

export const Header = styled.header`
  background-color: ${colors.neutralBlue100};
  border-bottom: 1px solid ${colors.dark2};
  padding: 16px 0;
`;

export const SearchBar = styled.div`
  position: relative;
  width: clamp(350px, 417px, 100%);
  background-color: ${colors.dark2};
  border-radius: 4px;
`;

export const TrialBannerContainer = styled.div`
  background-color: ${colors.orange};
  padding: 8px 0;
  margin-bottom: 16px;
`;

export const SearchIcon = styled.i`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
`;

export const Input = styled.input`
  width: 100%;
  background-color: transparent;
  color: white;
  font-size: 12px;
  padding: 12px 14px 12px 50px;
  outline: none;

  &:focus {
    background-color: ${colors.dark2};
  }
`;

export const DropdownMenu = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 101;
  overflow-y: auto;
  width: 100%;
  max-height: 450px;
  margin: 8px 0 0;
  padding: 8px 16px 8px;
  background-color: ${colors.dark2};
  transition: all 0.5s ease-out;
`;

export const MenuItem = styled.li`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  color: #bdbdbd;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const ProfileButton = styled.button`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative;
  background: none;
  border: none;

  img {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
  }
`;

export const ProfileMenu = styled.ul`
  position: absolute;
  top: 150%;
  width: 200px;
  right: 0;
  z-index: 10;
  background-color: ${colors.dark2};
  border-radius: 4px;
  color: white;
`;

export const ProfileItem = styled.li`
  border-radius: 4px;
  &:first-child {
    border-bottom: 1px solid ${colors.blue2};
  }

  &:hover {
    background-color: ${colors.blue2};
  }

  a {
    padding: 15px 10px;
    display: block;
    color: white;
    text-decoration: none;
  }
`;

export const IntegrationsMenuContainer = styled(Stack)`
  width: 100%;
`;

export const SuccessIntegrationsMenuContainer = styled(Stack)`
  width: 100%;
  background-color: ${colors.dark2};
  border-radius: 8px;
  padding: 25px 16px;
`;

export const ListContainer = styled(Stack)`
  height: 255px;
`;

export const IntegrationsList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 30px;
  padding: 16px;
`;

export const IntegrationItem = styled.li<{ connected: boolean }>`
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: ${colors.dark2};
  border-radius: 8px;
  padding: 20px 10px;
  transition: background-color 0.2s ease-out;
  cursor: default;

  ${(props) =>
    !props.connected &&
    css`
      cursor: pointer;
      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }

      &:active {
        opacity: 0.6;
      }
    `}
`;

export const IntegrationItemButton = styled.button<{ connected: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: default;
  gap: 12px;
  width: 100%;
  padding: 8px;
  background: none;
  border: none;
  border-radius: 4px;

  ${(props) =>
    !props.connected &&
    css`
      cursor: pointer;
    `}
`;

export const UpgradeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  border-radius: 20px;
  background-color: ${colors.yellow};
  color: ${colors.white};
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
  &:hover {
    background-color: rgba(245, 191, 68, 0.8);
  }
`;
