import { useAppDispatch, useAppSelector } from 'app/hooks';
import { resetPlayableInfo, selectPlayable, updatePlayableInfo } from '../store/playableSlice';
import { IPlayablePing, IPlayableState } from '../types';

interface IUsePlayableStatus
  extends Omit<IPlayableState, 'isIntegrationEnabled' | 'isPlayableAvailable'> {
  /**
   * Setter function for updating Playable info.
   * @param updateInfo - Playable info to update.
   */
  updatePlayableInfo: (updateInfo: IPlayablePing) => void;
  /**
   * Resets Plyable info to initial state.
   */
  resetPlayableInfo: () => void;
}

/**
 * Hook for getting Playable status and setter function for updating Playable info.
 * @returns Playable status and setter function for updating Playable info.
 */

export const usePlayableStatus = (): IUsePlayableStatus => {
  const { isIntegrationEnabled, isPlayableAvailable } = useAppSelector(selectPlayable);
  const dispatch = useAppDispatch();

  const isPlayableFunctionalAvailable = isIntegrationEnabled && isPlayableAvailable;

  return {
    isPlayableFunctionalAvailable,
    updatePlayableInfo: (updateInfo: IPlayablePing) => {
      dispatch(updatePlayableInfo(updateInfo));
    },
    resetPlayableInfo: () => {
      dispatch(resetPlayableInfo());
    },
  };
};
