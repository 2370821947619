import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import SubscriptionsPlans from './pages/subscriptionsPlans';
import routes from './constants/routes';
import CheckEmail from './pages/checkEmail';
import { CreateWorkspace } from './pages/createWorkspace';
import ForgotPassword from './pages/forgotPassword';
import Login from './pages/login';
import ResetPassword from './pages/resetPassword';
import SignupPage from './pages/signup';

export const UnauthenticatedRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={routes.login} element={<Login />} />
      <Route path={routes.signup} element={<SignupPage />} />
      <Route path={routes.forgotPassword} element={<ForgotPassword />} />
      <Route path={routes.checkEmail} element={<CheckEmail />} />
      <Route path={`${routes.resetPassword}/:userId/:token`} element={<ResetPassword />} />
      <Route
        path={`${routes.subscriptionsPlans}/auth`}
        element={<SubscriptionsPlans isAuthFlow />}
      />
      <Route path={`${routes.createWorkspace}`} element={<CreateWorkspace />} />
      <Route
        path="/subscriptions-plans/dashboard"
        element={<Navigate to="/subscriptions-plans/auth" />}
      />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};
