import React, { CSSProperties, ReactElement } from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import { IIconWrapperProps } from 'ui/icon/IconWrapper';
import Icon from '../icon';

const StyledButton = styled.button<{
  color?: string;
}>`
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  color: ${({ color }) => color || colors.orange};
  background: transparent;
  padding: 6px 0;
  margin: 0;
  border: none;
  outline: none;
  border-radius: 50%;
`;

interface IAddButton {
  onClick?: () => void;
  children?: React.ReactNode;
  style?: CSSProperties;
  icon?: ReactElement;
  cypressAttribute?: string;
  color?: string;
}

const TextButton: React.FC<IAddButton> = ({
  onClick,
  children,
  style,
  icon,
  cypressAttribute,
  color,
}) => {
  return (
    <StyledButton style={style} onClick={onClick} data-cy={cypressAttribute} color={color}>
      {children}
      {icon}
    </StyledButton>
  );
};

export default TextButton;
