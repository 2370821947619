import { INTEGRATION_COLORS } from 'constants/integrations';
import styled from 'styled-components';
import colors from 'theme/colors';

export const PlyableFileContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: ${colors.purple};
  font-size: 14px;
  line-height: 1.4;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  border-radius: 4px;
  padding: 4px 8px;
  background: rgba(245, 131, 255, 0.1);
`;

export const PlyableMessageLink = styled.button`
  background-color: ${colors.purple};
  border-radius: 4px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-left: auto;
`;

export const PlyableLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
`;

export const FileLink = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: ${INTEGRATION_COLORS.threads3d};
  font-size: 14px;
  line-height: 1.4;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  border-radius: 4px;
  padding: 4px 8px;
  background: ${INTEGRATION_COLORS.threads3d}33;
`;

export const Threads3DLink = styled.a`
  background-color: ${INTEGRATION_COLORS.threads3d};
  border-radius: 4px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-left: auto;
`;

export const GoogleFileLink = styled.a`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: ${colors.blue};
  font-size: 14px;
  line-height: 1.4;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  border-radius: 4px;
  padding: 4px 8px;
  background: rgba(20, 121, 240, 0.2);
`;
