import { yupResolver } from '@hookform/resolvers/yup';
import { useAppSelector } from 'app/hooks';
import {
  THREAD_OPTIONS_PRIORITY,
  THREAD_OPTIONS_STATUSES_EDIT,
  THREAD_VISIBILITY_NAMES,
  THREAD_TAG_MAX_LENGTH,
} from 'constants/index';
import useSubscriptionPermissions from 'hooks/useSubscriptionPermissions';
import { NOTIFICATION } from 'modules/alert/constants';
import { showSuccessMessage } from 'modules/alert/utils';
import { selectUserInfo } from 'modules/auth/userSlice';
import { useModal } from 'modules/modals/ModalProvider';
import {
  useGetThreadQuery,
  useGetThreadTagsQuery,
  useUpdateThreadMutation,
} from 'modules/threads/threadsApi';
import { isThreadOwner } from 'modules/threads/threadsSlice';
import { ICreateThreadMutation } from 'modules/threads/types';
import * as Layout from 'pages/thread/components/Layout';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import colors from 'theme/colors';
import {
  EThreadParams,
  EThreadPriority,
  EThreadStatuses,
  EThreadVisibility,
  IThreadMOdalsDefaultProps,
  TReactSelectOption,
} from 'types';
import { Button, Dropdown, Group, ReactSelect, Stack, TextArea, TextInput } from 'ui';
import { createOption, isAdmin } from 'utils/helpers';
import { validationSchema } from 'utils/validation';
import * as yup from 'yup';

const validation = yup
  .object({
    name: validationSchema.stringRequired(),
    description: validationSchema.textArea(),
    status: validationSchema.stringRequired(),
    priority: validationSchema.stringRequired(),
    tags: yup.array().notRequired(),
  })
  .required();

const EditThread: React.FC<IThreadMOdalsDefaultProps> = ({ threadId }) => {
  const { open, close } = useModal();
  const { id, userRole } = useAppSelector(selectUserInfo);
  const isCurrentUserThreadOwner = useAppSelector(isThreadOwner(threadId as string, id));
  const { data: thread, isLoading, isFetching } = useGetThreadQuery({ id: threadId as string });
  const { data: tagOptions, isLoading: isTagsFetching } = useGetThreadTagsQuery('');
  const [updateThread, { isLoading: isUpdating }] = useUpdateThreadMutation();

  const { handleSubscriptionPermissionErrors } = useSubscriptionPermissions();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ICreateThreadMutation>({
    defaultValues: thread,
    mode: 'onSubmit',
    shouldFocusError: false,
    resolver: yupResolver(validation),
  });

  const isAbleToEditThreadVisibility = useMemo(() => {
    return isCurrentUserThreadOwner || isAdmin(userRole);
  }, [isCurrentUserThreadOwner, userRole]);

  const threadTags: TReactSelectOption[] = useMemo(() => {
    return thread?.tags?.map((el: string) => createOption(el)) || [];
  }, [thread, isFetching]);

  const threadTagOptions = useMemo(() => {
    return tagOptions?.map((tag: string) => createOption(tag)) || [];
  }, [thread, isTagsFetching]);

  const threadPriority = useMemo(() => {
    return THREAD_OPTIONS_PRIORITY.find((priority) => priority.value === thread?.priority);
  }, [thread, isFetching]);

  const threadStatus = useMemo(() => {
    return THREAD_OPTIONS_STATUSES_EDIT.find((status) => status.value === thread?.status);
  }, [thread, isFetching]);

  const onSubmit = async (payload: ICreateThreadMutation) => {
    const { status } = payload;

    if (EThreadStatuses.ARCHIVE === status) {
      open({
        variant: 'center',
        contentLabel: 'Archive thread warning dialog',
        name: 'Archive Thread',
        modal: 'archiveThread',
        context: {
          id: threadId as string,
          ...payload,
        },
        id: 'modal-archive-thread',
      });
      return;
    }
    try {
      await updateThread({ id: threadId as string, ...payload }).unwrap();
      showSuccessMessage(NOTIFICATION.THREAD_UPDATED);
    } catch (responseError) {
      handleSubscriptionPermissionErrors(
        responseError,
        'An error occurred while updating thread. Please try again.'
      );
    } finally {
      close();
    }
  };

  const setStatus = (value: string) => {
    setValue(EThreadParams.STATUS, value as EThreadStatuses);
  };

  const setPriority = (value: string) => {
    setValue(EThreadParams.PRIORITY, value as EThreadPriority);
  };

  const setTags = (options: TReactSelectOption[]) => {
    const tags = options?.map((option: TReactSelectOption) => option.value);
    setValue(EThreadParams.TAGS, tags);
  };

  const setVisibility = (option: TReactSelectOption) => {
    if (option.value === EThreadVisibility.PUBLIC) {
      return setValue(EThreadParams.IS_PRIVATE, false);
    }
    return setValue(EThreadParams.IS_PRIVATE, true);
  };

  useEffect(() => {
    if (!thread) return;
    setValue(EThreadParams.STATUS, thread.status);
    setValue(EThreadParams.PRIORITY, thread.priority);
    setValue(EThreadParams.TAGS, thread.tags ? thread.tags : []);
  }, [isFetching, isUpdating, thread]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!thread) {
    return <div>Thread not found...</div>;
  }

  return (
    <Layout.Form onSubmit={handleSubmit(onSubmit)} data-cy="modal-edit-thread-form">
      <Stack fluid gap="25px" style={{ height: '100%' }}>
        <TextInput
          label="Name"
          name="name"
          placeholder="Name"
          type="text"
          defaultValue={thread.name}
          register={register}
          required
          error={errors.name}
          data-cy="modal-edit-thread-name"
        />
        <TextArea
          label="Description"
          name="description"
          placeholder="Description"
          resize="none"
          defaultValue={thread.description}
          register={register}
          error={errors.description}
          data-cy="modal-edit-thread-description"
        />
        <ReactSelect
          label="Tags"
          id="edit-thread-select"
          placeholder="Select tags"
          isLoading={isTagsFetching}
          defaultValue={threadTags}
          options={threadTagOptions}
          isSearchable
          onChange={setTags}
          isValidNewOption={(inputValue) =>
            !!inputValue && inputValue?.length < THREAD_TAG_MAX_LENGTH
          }
        />
        <Dropdown
          label="Status"
          name="status"
          badge="status"
          placeholder="Choose status"
          defaultValue={threadStatus}
          options={THREAD_OPTIONS_STATUSES_EDIT}
          bg={colors.dark2}
          onClick={setStatus}
          error={errors.status}
          cypressAttribute="modal-edit-thread-status"
        />
        <Dropdown
          label="Priority"
          name="priority"
          badge="priority"
          placeholder="Choose priority"
          defaultValue={threadPriority}
          options={THREAD_OPTIONS_PRIORITY}
          bg={colors.dark2}
          onClick={setPriority}
          error={errors.priority}
          cypressAttribute="modal-edit-thread-priority"
        />
        {isAbleToEditThreadVisibility && (
          <ReactSelect
            label="Visibility"
            id="create-thread-select-visibility"
            isLoading={isLoading}
            required
            defaultValue={{
              label: thread.isPrivate ? 'Private' : 'Public',
              value: thread.isPrivate ? EThreadVisibility.PRIVATE : EThreadVisibility.PUBLIC,
            }}
            options={THREAD_VISIBILITY_NAMES}
            isCreatable={false}
            isSearchable={false}
            isMulti={false}
            placeholder="Select Visibility"
            onChange={setVisibility}
          />
        )}
      </Stack>
      <Group justify="end" gap="15px" style={{ marginTop: 'auto' }}>
        <Button
          color={colors.dark2}
          onClick={close}
          cypressAttribute="modal-edit-thread-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          disabled={isUpdating}
          type="submit"
          cypressAttribute="modal-create-thread-update-btn"
        >
          {isUpdating ? 'Updating...' : 'Update Thread'}{' '}
        </Button>
      </Group>
    </Layout.Form>
  );
};

export default EditThread;
