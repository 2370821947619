import { showUncaughtErrorMessage } from 'modules/alert/utils';
import * as ChatLayout from 'modules/chat/components/Layout';
import {
  PlyableFileContainer,
  PlyableLogoWrapper,
  PlyableMessageLink,
} from 'modules/chat/components/integrations/Layout';
import type { IMessageResponse } from 'modules/chat/types';
import { usePlyableAccessLinkMutation } from 'modules/playable/api';
import React, { useMemo } from 'react';
import Moment from 'react-moment';
import colors from 'theme/colors';
import { Group, Icon, Stack, Text } from 'ui';
import { getFullName } from 'utils/helpers';

interface IMessageData {
  message: string;
  linkUrl: string | null;
  fileName: string | null;
  fileUrl: string | null;
  fileId: string | null;
}

export const PlyableMessage: React.FC<{ message: IMessageResponse }> = ({ message }) => {
  const { creator, createdAt, text = '' } = message;
  const [getPlyableLink] = usePlyableAccessLinkMutation();

  const messageData: IMessageData = useMemo(() => {
    const { blocks = [] } = JSON.parse(text as string) || {};
    const [parsed] = blocks;
    return {
      message: parsed?.text || '',
      linkUrl: parsed?.plyable_url || null,
      fileName: parsed?.reference_versioned_file?.name || null,
      fileUrl: parsed?.reference_versioned_file?.url || null,
      fileId: parsed?.reference_versioned_file?.id || null,
    };
  }, [text]);

  const navigateToPlyable = async () => {
    if (messageData.fileId) {
      try {
        const response = await getPlyableLink({ referenceId: messageData.fileId }).unwrap();
        window.open(response.link);
      } catch (error) {
        if ((error as { status_code: number }).status_code === 400) {
          return;
        }
        showUncaughtErrorMessage(error);
      }
    } else if (messageData?.linkUrl) {
      window.open(messageData.linkUrl, '_blank');
    }
  };

  return (
    <ChatLayout.MessageContent
      isPlyableMessage
      owner={false}
      isCopiedMessage={false}
      data-cy="chat-layout-plyable-content"
    >
      <ChatLayout.MessageHeader owner={false}>
        <Group>
          <Stack data-cy="chat-layout-message-content-stack">
            <Text
              align="left"
              size="sm"
              color={colors.orange}
              cypressAttribute="chat-layout-message-content-full-name"
            >
              {creator && getFullName(creator)}
            </Text>
          </Stack>
        </Group>
        <Text size="xs" color={colors.gray1} cypressAttribute="chat-layout-message-content-time">
          <Moment fromNow date={createdAt} />
        </Text>
      </ChatLayout.MessageHeader>
      <Text
        align="left"
        color={colors.white}
        size="sm"
        cypressAttribute="chat-layout-message-content-message"
      >
        {messageData.message}
      </Text>
      <Group justify="space-between" gap="24px" align="center">
        {messageData.fileName && messageData.fileUrl && (
          <PlyableFileContainer data-cy="chat-layout-message-content-file-link">
            <Icon
              icon="AttachIcon"
              size="small"
              path={colors.purple}
              style={{ width: '18px', height: '18px' }}
            />
            {messageData.fileName}
          </PlyableFileContainer>
        )}
        {messageData.linkUrl && (
          <PlyableMessageLink
            onClick={navigateToPlyable}
            data-cy="chat-layout-message-content-encube-link"
          >
            <PlyableLogoWrapper>
              <Icon icon="PlyableLogo" size="medium" />
            </PlyableLogoWrapper>

            <Icon icon="ArrowRightIcon" size="small" />
          </PlyableMessageLink>
        )}
      </Group>
    </ChatLayout.MessageContent>
  );
};
