/* eslint-disable */
import { IUser, IUserMinimize } from '../types/user';
import { getFullName } from './helpers';

type TMapper = (arg: any) => any;

export const toCamelcase = (str: string) =>
  ` ${str}`
    .replace(/(?:[^A-Z0-9]+(\w))/gi, (_, m) => m.toUpperCase())
    .replace(/^[A-Z]+/, (m) => m.toLowerCase());

export const toUnderscoreCase = (str: string) =>
  str.replace(/\.?([A-Z]+)/g, (x, y) => `_${y.toLowerCase()}`).replace(/^_/, '');

const isObj = (obj: any) => obj && typeof obj === 'object';

const mapObject = (obj: any, key: string, mapper: TMapper) =>
  // eslint-disable-next-line no-nested-ternary
  Array.isArray(obj[key])
    ? obj[key].map((item: any) => mapper(item))
    : isObj(obj[key])
      ? mapper(obj[key])
      : obj[key];

const objectReducer = (obj: any, mapper: TMapper) => (res: any, key: string) => ({
  ...res,
  [key]: mapObject(obj, key, mapper),
});

export const camelcaseKeys = (obj: any) =>
  obj &&
  Object.keys(obj).reduce(
    (newObj, key) =>
      Object.assign(newObj, {
        [toCamelcase(key)]: obj[key],
      }),
    {}
  );

export const deepCamelcaseKeys = (obj: any, camelCased = camelcaseKeys(obj)): any =>
  // eslint-disable-next-line no-nested-ternary
  Array.isArray(obj)
    ? obj.map((o) => deepCamelcaseKeys(o))
    : isObj(obj)
      ? Object.keys(camelCased).reduce(objectReducer(camelCased, deepCamelcaseKeys), {})
      : obj;

export const underscoreCaseKeys = (obj: any) =>
  isObj(obj)
    ? Object.keys(obj).reduce(
        (newObj, key) =>
          Object.assign(newObj, {
            [toUnderscoreCase(key)]: obj[key],
          }),
        {}
      )
    : obj;

export const deepUnderscoreKeys = (obj: any, underscored = underscoreCaseKeys(obj)): any =>
  // eslint-disable-next-line no-nested-ternary
  Array.isArray(obj)
    ? obj.map((o) => deepUnderscoreKeys(o))
    : isObj(obj) && !(obj instanceof Date) && !(obj instanceof File) && !(obj instanceof FormData)
      ? Object.keys(underscored).reduce(objectReducer(underscored, deepUnderscoreKeys), {})
      : obj;

export const getMention = (user: IUser | IUserMinimize) => ({
  name: getFullName(user),
  id: user.id,
  avatar: user.avatar ?? undefined,
});
