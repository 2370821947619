import { IUserMinimize } from 'types/user';

/**
 * The regular expression is matching a string that contains a forward slash / and capturing
 * all characters after the last forward slash that are not a question mark ?.
 */
const MATCH_FILENAME_REGEX = /.*\/([^?]+)/;

/**
 * Extracts the file name from a URL.
 * @param url a string that represents the URL to extract the file name from.
 */
export const getFileNameFromUrl = (url: string): string => {
  const match = url.match(MATCH_FILENAME_REGEX);
  return match ? match[1] : '';
};

/**
 * Indicates interval in milliseconds to refetch data from the server.
 */
export const REFETCH_INTERVAL = 1000 * 60 * 0.5; // 30 seconds

/**
 * Indicates the number of skeleton components to render while loading data from the server.
 */
export const SKELETON_COUNT = 2;

export const DEFAULT_THREADS_3D_CREATOR: IUserMinimize = {
  avatar: '/images/threads-3d-avatar.png',
  firstName: 'Threads 3D',
  lastName: 'Comments',
  id: 'DEFAULT_THREADS_3D_CREATOR',
  email: 'admin@threads3d.com',
  group: 'Threads3D System',
};

export const DEFAULT_PLYABLE_CREATOR: IUserMinimize = {
  avatar: '/images/plyable-logo.png',
  firstName: 'Plyable',
  lastName: 'Comments',
  id: 'DEFAULT_PLYABLE_CREATOR',
  email: 'admin@plyable.com',
  group: 'Plyable System',
};
