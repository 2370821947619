export interface ICopilotSuggestion {
  suggestionType: string;
  eventUuid: string | null;
  name: string;
  type: string;
  description: string;
  status: string;
  dueDate: Date;
  id: number;
}

export enum ECopilotSuggestionTypes {
  CREATE = 'CREATE',
  MODIFY = 'MODIFY',
}

export enum SuggestionAction {
  ACCEPT = 'ACCEPT',
  EDIT = 'EDIT',
  DISMISS = 'DISMISS',
}
