import { useAppSelector } from 'app/hooks';
import { ReactComponent as SuccIcon } from 'assets/icons/success-icon.svg';
import routes from 'constants/routes';
import { useWindowSize } from 'hooks/useWindowSize';
import _isEmpty from 'lodash/isEmpty';
import { selectUserInfo } from 'modules/auth/userSlice';
import { useGetSubscriptionsPlansQuery } from 'modules/subscriptionsPlans/subscriptionsPlansApi';
import { isCurrentCustomPaidSubscriptionPlan } from 'modules/subscriptionsPlans/subscriptionsPlansSlice';
import { ISubscriptionPlansResponse, ISubscriptionPrice } from 'modules/subscriptionsPlans/types';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import colors from 'theme/colors';
import { Button, Group, Spinner, Stack, Text, Title } from 'ui';
import { SubscriptionCard } from './components/SubscriptionCard';
import * as Layout from './Layout';

const SubscriptionPlansLoadFailureView: FC = () => (
  <>
    <Title heading="h1">Something went wrong...</Title>
    <Text color={colors.gray1}>
      We&apos;re sorry, but the subscription plans could not be loaded properly. Please try again
      later.
    </Text>
  </>
);

interface IProps {
  /**
   * Indicates if this screen in auth flow or in app navigation flow.
   */
  isAuthFlow?: boolean;
}

const SubscriptionsPlans: FC<IProps> = ({ isAuthFlow = false }) => {
  const { width } = useWindowSize();
  const navigate = useNavigate();

  const { isSiteOwner, id: userId } = useAppSelector(selectUserInfo);
  const isCustomPaidSubscription = useAppSelector(isCurrentCustomPaidSubscriptionPlan);
  const {
    data,
    isLoading,
    isSuccess: isSubscriptionFetchSuccessful,
  } = useGetSubscriptionsPlansQuery();

  const [activeSubscriptionPeriod, setActiveSubscriptionPeriod] = React.useState<'month' | 'year'>(
    'month'
  );
  const [hasUpgraded, setHasUpgraded] = useState(false);

  /**
   * This function finds the current subscription plan based on the `isCurrent` property.
   * @param subscriptionPlans - The subscription plans array.
   * @returns The current subscription plan period.
   */
  const findCurrentSubscription = useCallback(
    (subscriptionPlans: ISubscriptionPlansResponse[] | undefined): 'month' | 'year' => {
      if (!subscriptionPlans) return 'month';
      const currentPlan = subscriptionPlans.find((plan) =>
        Object.keys(plan).find(
          (key) => (plan[key as keyof typeof plan] as ISubscriptionPrice).isCurrent
        )
      );

      return currentPlan
        ? (Object.keys(currentPlan).find(
            (key) => (currentPlan[key as keyof typeof currentPlan] as ISubscriptionPrice).isCurrent
          ) as 'month' | 'year')
        : 'month';
    },
    [data]
  );

  const monthlyButtonVariant = useMemo(
    () => (activeSubscriptionPeriod === 'month' ? 'filled' : 'plain'),
    [activeSubscriptionPeriod]
  );
  const yearlyButtonVariant = useMemo(
    () => (activeSubscriptionPeriod === 'year' ? 'filled' : 'plain'),
    [activeSubscriptionPeriod]
  );

  const defaultSubscriptionButtonText = useMemo(
    () => (isAuthFlow ? 'CHOOSE' : 'UPGRADE'),
    [isAuthFlow]
  );

  const headerText = useMemo(
    () => (hasUpgraded ? 'CONGRATULATIONS!' : 'CHOOSE YOUR PLAN'),
    [hasUpgraded]
  );

  const isMobile = useMemo(() => width <= 1015, [width]);

  const isCTAButtonDisabled = useMemo(() => {
    // If user is authenticated, but they are a site owner, they cannot see subscription plans.
    if (!_isEmpty(userId) && !isSiteOwner) {
      return true;
    }
    // If user is authenticated and are on a custom plan, they cannot see subscription plans.
    if (!_isEmpty(userId) && isCustomPaidSubscription) {
      return true;
    }

    // User can view subscription plans if none of the above conditions are met.
    return false;
  }, [userId, isSiteOwner, isCustomPaidSubscription]);

  const handleMonthlyClick = () => {
    if (activeSubscriptionPeriod === 'month') return;
    setActiveSubscriptionPeriod('month');
  };

  const handleYearlyClick = () => {
    if (activeSubscriptionPeriod === 'year') return;
    setActiveSubscriptionPeriod('year');
  };

  const handleBackToLoginClick = useCallback(() => {
    navigate(routes.login);
  }, []);

  const handleBackToDashboardClick = useCallback(() => {
    navigate(routes.dashboard);
  }, []);

  useEffect(() => {
    if (!data) return;
    const currentSubscriptionPeriod = findCurrentSubscription(data);
    setActiveSubscriptionPeriod(currentSubscriptionPeriod);
  }, [data]);

  if (isLoading) {
    return (
      <Stack align="center" justify="center" style={{ height: 'calc(100vh - 140px)' }}>
        <Spinner />
      </Stack>
    );
  }

  return (
    <Layout.SubscriptionMainContainer
      fluid
      align="center"
      // eslint-disable-next-line no-nested-ternary
      style={{ height: isMobile ? 'auto' : isAuthFlow ? '100vh' : 'calc(100vh - 140px)' }}
      justify="center"
      fullHeight
      gap="40px"
    >
      {data ? (
        <Layout.SubscriptionHeader>
          <Text weight="600" style={{ fontSize: 24, lineHeight: 'normal' }}>
            {headerText}
          </Text>
          <Layout.Divider />
          {hasUpgraded ? (
            <Stack gap="30px">
              <SuccIcon style={{ alignSelf: 'center' }} />
              <Text weight="400" style={{ fontSize: 18, lineHeight: '1.5' }} align="center">
                You have successfully upgraded your subscription plan. This operation may take a few
                minutes to be reflected in your account.
              </Text>
              <Button onClick={handleBackToDashboardClick}>Go to Dashboard</Button>
            </Stack>
          ) : (
            <Group>
              <Button
                variant={monthlyButtonVariant}
                onClick={handleMonthlyClick}
                style={{ padding: '12px 24px' }}
              >
                Monthly
              </Button>
              <Button
                variant={yearlyButtonVariant}
                style={{ padding: '12px 24px' }}
                onClick={handleYearlyClick}
              >
                Yearly
              </Button>
            </Group>
          )}
        </Layout.SubscriptionHeader>
      ) : (
        <Stack align="center" gap="15px">
          <SubscriptionPlansLoadFailureView />
          <Button asChild className="mt-5 max-w-lg">
            <Link to={isAuthFlow ? routes.login : '/'}>
              {isAuthFlow ? 'Back to login' : 'Back to dashboard'}
            </Link>
          </Button>
        </Stack>
      )}
      {!hasUpgraded && (
        <Layout.SubscriptionsMainCardsContainer>
          {data?.map((subscriptionPlan, index) => (
            <SubscriptionCard
              // eslint-disable-next-line react/no-array-index-key
              key={`subscription-plan-${index}`}
              subscriptionPlanId={subscriptionPlan[activeSubscriptionPeriod].priceId}
              subscriptionPlanName={subscriptionPlan.name}
              subscriptionPlanPrice={subscriptionPlan[activeSubscriptionPeriod].price}
              subscriptionButtonText={defaultSubscriptionButtonText}
              onSuccessUpgrade={setHasUpgraded}
              subscriptionPlanFeatures={subscriptionPlan.features}
              isSelectable={subscriptionPlan[activeSubscriptionPeriod].isSelectable}
              isSubscribed={subscriptionPlan[activeSubscriptionPeriod].isCurrent}
              mailToLink={subscriptionPlan[activeSubscriptionPeriod].mailto}
              isAuthFlow={isAuthFlow}
              isCTAButtonDisabled={isCTAButtonDisabled}
            />
          ))}
        </Layout.SubscriptionsMainCardsContainer>
      )}
      {isSubscriptionFetchSuccessful && isAuthFlow && (
        <Button type="button" variant="plain" onClick={handleBackToLoginClick}>
          <Text weight="700">Back to Login</Text>
        </Button>
      )}
    </Layout.SubscriptionMainContainer>
  );
};

export default SubscriptionsPlans;
