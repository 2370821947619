import React from 'react';
import Moment from 'react-moment';
import colors from 'theme/colors';
import { Stack } from 'ui';
import {
  AnnotationAvatar,
  AnnotationCommentContent,
  AnnotationCommentDate,
  AnnotationCommentHolder,
} from './AnnotationLayout';

interface IAnnotationCommentProps {
  content: string;
  date: string;
  avatarSrc: string;
  referenceId: number;
}

export const AnnotationComment: React.FC<IAnnotationCommentProps> = ({
  content,
  date,
  avatarSrc,
  referenceId,
}) => {
  return (
    <AnnotationCommentHolder
      justify="space-between"
      align="center"
      gap="16px"
      fluid
      data-cy="annotation-comment-holder"
    >
      <AnnotationCommentContent size="sm" weight="400" data-cy="annotation-comment-content">
        {content}
      </AnnotationCommentContent>
      <Stack gap="3px" justify="flex-start" align="flex-end" data-cy="annotation-comment-stack">
        <AnnotationAvatar size="small" src={avatarSrc} data-cy="annotation-comment-avatar" />
        <AnnotationCommentDate
          color={colors.gray3}
          size="sm"
          weight="400"
          noWrap
          data-cy="annotation-comment-date"
        >
          <Moment fromNow date={date} />
        </AnnotationCommentDate>
      </Stack>
    </AnnotationCommentHolder>
  );
};
