import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { CUSTOM_PAID_SUBSCRIPTION_PLANS } from 'constants/subscription';
import { integrationApi } from 'modules/integrations/integrationsApi';

interface ISubscriptionPlansState {
  subscriptionPriceId: string;
  subscriptionName: string;
  subscriptionInfo: {
    isActive: boolean;
    subscriptionRenewalInfo: string | null;
  };
}

export const initialState: ISubscriptionPlansState = {
  subscriptionPriceId: '',
  subscriptionName: '',
  subscriptionInfo: {
    isActive: false,
    subscriptionRenewalInfo: null,
  },
};

const subscriptionsPlansSlice = createSlice({
  name: 'subscriptionsPlans',
  initialState,
  reducers: {
    updateSubscriptionPriceId: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      subscriptionPriceId: payload,
    }),
    updateSubscriptionName: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      subscriptionName: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      integrationApi.endpoints.getCurrentSiteSubscription.matchFulfilled,
      (state, { payload }) => {
        state.subscriptionName = payload.plan;
        state.subscriptionInfo.isActive = payload.isActive;
        state.subscriptionInfo.subscriptionRenewalInfo = payload.subscriptionRenewalInfo;
      }
    );
  },
});

export const selectSubscriptionPriceId = (state: RootState) =>
  state.subscriptionPlans.subscriptionPriceId;

export const selectSubscriptionName = (state: RootState) =>
  state.subscriptionPlans.subscriptionName;
export const selectSubscriptionInfo = (state: RootState) =>
  state.subscriptionPlans.subscriptionInfo;

export const isCurrentCustomPaidSubscriptionPlan = (state: RootState) => {
  const subscriptionName = state.subscriptionPlans?.subscriptionName;
  const { CUSTOM_PAID, CUSTOM_TRIAL } = CUSTOM_PAID_SUBSCRIPTION_PLANS;
  return subscriptionName?.includes(CUSTOM_PAID) || subscriptionName?.includes(CUSTOM_TRIAL);
};

export const { updateSubscriptionPriceId, updateSubscriptionName } =
  subscriptionsPlansSlice.actions;

export default subscriptionsPlansSlice.reducer;
