import React from 'react';
import styled from 'styled-components';
import { Avatar, Button, Group, Stack, Text } from 'ui';
import { useModal } from 'modules/modals/ModalProvider';
import { useDeleteUserMutation } from 'modules/user/userApi';
import {
  showErrorMessage,
  showSuccessMessage,
  showUncaughtErrorMessage,
} from 'modules/alert/utils';
import { NOTIFICATION } from 'modules/alert/constants';
import { handleApiCall } from 'utils/helpers';
import * as Layout from 'pages/members/components/Layout';
import colors from 'theme/colors';
import { IUser } from 'types/user';

const Form = styled.form`
  height: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

interface DeleteMemberProps {
  member: IUser;
}

const DeleteMember: React.FC<DeleteMemberProps> = ({ member }) => {
  const [deleteUser, { isLoading }] = useDeleteUserMutation();
  const { close } = useModal();
  const { id, firstName, lastName, email, avatar } = member;

  const handleDeleteMember = async () => {
    const res = await deleteUser(id);
    handleApiCall(
      res,
      () => showUncaughtErrorMessage(),
      () => {
        showSuccessMessage(NOTIFICATION.USER_INVITE_DELETED);
        close();
      }
    );
  };

  return (
    <Form>
      <Layout.CardUserContainer align="center">
        <Avatar src={avatar || undefined} />
        <Stack gap="10px" data-cy="modal-delete-member-user-info">
          <>
            <Text weight="500">
              {firstName} {lastName}
            </Text>
            <Text color={colors.gray3}>{email}</Text>
          </>
        </Stack>
      </Layout.CardUserContainer>
      <Group justify="end" gap="15px">
        <Button
          color={colors.dark2}
          onClick={close}
          cypressAttribute="modal-delete-member-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          color={colors.red2}
          disabled={isLoading}
          onClick={handleDeleteMember}
          cypressAttribute="modal-delete-member-remove-btn"
        >
          {isLoading ? 'Removing...' : 'Remove'}
        </Button>
      </Group>
    </Form>
  );
};

export default DeleteMember;
