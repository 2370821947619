import AccessControl from 'app/permissions/AccessControl';
import { EPermissions } from 'app/permissions/constants';
import { FormattedDate } from 'components/dates';
import { REFERENCE_INTEGRATION_ICONS } from 'constants/integrations';
import _capitalize from 'lodash/capitalize';
import { useModal } from 'modules/modals/ModalProvider';
import { IReferences, REFERENCE_TYPE } from 'modules/threads/types';
import { ModelLink } from 'modules/threads3d/Link';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import colors from 'theme/colors';
import { DropDownAction, Group, Icon, Stack, Text } from 'ui';
import Tooltip from 'components/ui/tooltip';
import {
  convertCentsToDollars,
  getReferenceIconName,
  getUserNameAndFirstCharacterSurname,
  handleDownloadFile,
  isImage,
  truncateRawString,
} from 'utils/helpers';
import * as Layout from '../Layout';

interface IReference {
  reference: IReferences;
}

const ReferenceCard: React.FC<IReference> = ({ reference }) => {
  const { name, description, url, fileVersions, createdAt, icons } = reference;
  const latestFile = fileVersions.length ? fileVersions[0] : null;

  const { open } = useModal();

  const handleRemove = () => {
    open({
      variant: 'center',
      contentLabel: 'Remove Reference',
      name: 'Remove Reference',
      modal: 'removeReference',
      context: {
        id: reference.id,
        name: reference.name,
        url: reference.url,
      },
      id: 'modal-remove-reference',
    });
  };

  const handleEdit = () => {
    open({
      variant: 'fullRight',
      contentLabel: 'Edit Reference',
      name: 'Edit Reference',
      modal: 'editReference',
      context: {
        reference,
      },
      id: 'modal-edit-reference',
    });
  };

  const actions = useMemo(
    () => [
      {
        id: 'edit',
        title: 'Edit',
        action: handleEdit,
        icon: <Icon size="small" icon="PencilIcon" />,
        permissions: [EPermissions.EDIT_REFERENCES],
      },
      {
        id: 'delete',
        title: 'Delete',
        action: handleRemove,
        icon: <Icon size="small" icon="BinIcon" />,
        permissions: [EPermissions.DELETE_REFERENCES],
      },
    ],
    [reference]
  );
  const { id: threadId } = useParams();
  const isImg = isImage(latestFile?.file);
  const iconName = getReferenceIconName(reference.referenceType, isImg, reference.url);
  const creatorName = getUserNameAndFirstCharacterSurname(reference.creator);

  const isPlayableIntegrationFile = useMemo(() => {
    return reference.referenceType === REFERENCE_TYPE.PLAYABLE;
  }, [reference.referenceType]);

  const isFile = useMemo(() => {
    return reference.referenceType === REFERENCE_TYPE.FILE;
  }, [reference.referenceType]);

  const isFileWithActiveIntegrations = useMemo(() => {
    return ([REFERENCE_TYPE.PLAYABLE, REFERENCE_TYPE.THREADS_3D] as ReadonlyArray<string>).includes(
      reference.referenceType
    );
  }, [reference.referenceType]);

  const hasIcons = useMemo(() => {
    return icons && icons.length > 0;
  }, [icons]);

  const shouldShowTooltip = useMemo(() => {
    return name.length > 20;
  }, [name]);

  return (
    <Layout.ReferenceCard data-cy="reference-card">
      <Group align="center" justify="space-between">
        <Group align="center" gap="10px">
          <Icon icon={iconName} stroke="none" />
          {hasIcons && icons?.map((icon) => REFERENCE_INTEGRATION_ICONS[icon])}
          <Tooltip
            content={truncateRawString(name, 60)}
            side="bottom"
            isVisible={shouldShowTooltip}
            cypressAttribute="reference-card-tooltip"
          >
            <Layout.Name size="sm" data-cy="reference-card-name">
              {truncateRawString(name, 28)}
            </Layout.Name>
          </Tooltip>
        </Group>
        <Group gap="10px" align="center">
          {latestFile ? (
            <a
              href={latestFile.file}
              title={name}
              download={name}
              rel="noreferrer"
              onClick={handleDownloadFile(name, latestFile.file)}
              data-cy="reference-card-latest-file"
            >
              <Icon icon="DownloadIcon" style={{ width: 20, height: 20 }} />
            </a>
          ) : (
            <a
              href={url}
              title={name}
              target="_blank"
              rel="noreferrer"
              data-cy="reference-card-external-link"
            >
              <Icon stroke="#6E7A9A" icon="ExternalLinkIcon" style={{ width: 20, height: 20 }} />
            </a>
          )}
          <AccessControl
            permissions={[EPermissions.EDIT_REFERENCES, EPermissions.DELETE_REFERENCES]}
            threadId={threadId as string}
            strict={false}
          >
            <Layout.IconButton onClick={() => null} color={colors.dark2}>
              <DropDownAction actions={actions} cypressAttribute="reference-card-actions" />
            </Layout.IconButton>
          </AccessControl>
        </Group>
      </Group>
      <Group justify="space-between" align="flex-end" gap="25px">
        <Stack fluid>
          {description &&
            (description.length > 130 ? (
              <Tooltip
                content={description}
                side="bottom"
                cypressAttribute="reference-card-description-tooltip"
              >
                <Layout.Description>{reference.description}</Layout.Description>
              </Tooltip>
            ) : (
              <Layout.Description>{reference.description}</Layout.Description>
            ))}
          <Group justify="space-between" fluid>
            <Group gap="15px">
              <Group align="center" gap="5px">
                <Icon icon="CalendarIcon" stroke="none" size="normal" />
                <Text
                  color={colors.gray3}
                  size="sm"
                  weight="500"
                  cypressAttribute="reference-card-date-text"
                >
                  <FormattedDate date={createdAt} />
                </Text>
              </Group>
              <Group align="center" gap="5px">
                <Icon icon="UserIcon" stroke={colors.gray3} size="normal" />
                <Text
                  color={colors.gray3}
                  weight="400"
                  size="sm"
                  cypressAttribute="reference-card-creator-name-text"
                >
                  {creatorName}
                </Text>
              </Group>
              {latestFile && (
                <Group align="center" gap="5px">
                  <Icon icon="AttachIcon" size="normal" stroke={colors.gray3} />
                  <Text
                    color={colors.gray3}
                    size="sm"
                    cypressAttribute="reference-card-file-size-text"
                  >
                    {latestFile.fileSize}
                  </Text>
                </Group>
              )}
            </Group>
            <Group gap="8px" align="center">
              {latestFile?.isPlyableDfmCheckFailed && (
                <Tooltip
                  content={
                    <Text size="sm">Your mold design has failed one or more DFM checks.</Text>
                  }
                  side="left"
                >
                  <Icon icon="InfoIcon" path={colors.red} size="medium" />
                </Tooltip>
              )}
              {isFileWithActiveIntegrations && (
                <Icon icon="ReferenceIntegrationsIcon" path="#E96B18" />
              )}
              {isFile && !isImg && <Icon icon="ReferenceIntegrationsIcon" />}
              {isPlayableIntegrationFile && (
                <Layout.PlayableLogoWrapper>
                  <Tooltip
                    side="left"
                    content={
                      <Stack gap="8px">
                        <Text size="sm" color={colors.gray3}>
                          Quote total:{' '}
                          {convertCentsToDollars(
                            latestFile?.plyablePriceInCents,
                            latestFile?.plyablePriceCurrency
                          ) || 'N/A'}
                        </Text>
                        <Text size="sm" color={colors.gray3}>
                          Order Status: {_capitalize(latestFile?.plyableOrderStatus) || 'N/A'}
                        </Text>
                        <Text size="sm" color={colors.gray3}>
                          Est. delivery date:{' '}
                          <FormattedDate date={latestFile?.plyableEstimatedDeliveryDate} />
                        </Text>
                      </Stack>
                    }
                  >
                    <Icon icon="PlyableLogo" />
                  </Tooltip>
                </Layout.PlayableLogoWrapper>
              )}
              {reference.referenceType === REFERENCE_TYPE.THREADS_3D && latestFile && (
                <ModelLink referenceId={latestFile.id} status={latestFile.modelStatus} />
              )}
            </Group>
          </Group>
        </Stack>
        {isImg && (
          <Layout.ImgBox data-cy="reference-card-img-box">
            <a
              href={latestFile?.file as string}
              download
              target="_blank"
              rel="noreferrer"
              data-cy="reference-card-img-box-link"
            >
              <img
                src={latestFile?.file as string}
                alt={name}
                data-cy="reference-card-img-box-img"
              />
            </a>
          </Layout.ImgBox>
        )}
      </Group>
    </Layout.ReferenceCard>
  );
};

export default ReferenceCard;
