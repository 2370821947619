import { PopoverContent } from '@radix-ui/react-popover';
import { useAppSelector } from 'app/hooks';
import { Button } from 'components/ui/button';
import { Popover, PopoverTrigger } from 'components/ui/popover';
import { Bell } from 'lucide-react';
import React, { useState } from 'react';
import View from './components/View';
import { useGetNotificationsQuery } from './notificationApi';
import { LIMIT, selectUnreadCount } from './slice';

const NotificationToggle: React.FC = () => {
  const count = useAppSelector(selectUnreadCount);
  useGetNotificationsQuery({ offset: 0, limit: LIMIT });
  const [open, setOpen] = useState(false);
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger>
        <Button asChild variant="ghost" size="icon" onClick={() => setOpen((prev) => !prev)}>
          <div className="relative">
            <Bell size={20} />
            {count > 0 && (
              <div className="absolute bottom-0 right-0 h-4 w-4 rounded-full bg-red-500 text-xs">
                {count}
              </div>
            )}
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" className="z-[999]">
        <View closeNotificationModal={() => setOpen(false)} />
      </PopoverContent>
    </Popover>
  );
};

export default NotificationToggle;
