import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { showSuccessMessage, showUncaughtErrorMessage } from 'modules/alert/utils';
import { useUpdateMemberRoleMutation } from 'modules/user/userApi';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { EUserRole, IUser } from 'types/user';
import { InferType, object, string } from 'yup';

const validation = object({
  role: string().oneOf(['user', 'admin']).required(),
});

export const ChangeRoleDialog: React.FC<{ user: IUser }> = ({ user }) => {
  const [open, setOpen] = useState(false);
  const [updateRole, { isLoading }] = useUpdateMemberRoleMutation();
  const form = useForm({
    defaultValues: { role: 'user' },
    resolver: yupResolver(validation),
  });

  const onSubmit = async (value: InferType<typeof validation>) => {
    try {
      await updateRole({ id: user.id, type: value.role as EUserRole }).unwrap();
      showSuccessMessage('Invite sent');
      setOpen(false);
    } catch (error) {
      showUncaughtErrorMessage(error);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>Change Role</DialogTrigger>
      <DialogContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <DialogHeader>
              <DialogTitle>Change User Role</DialogTitle>
            </DialogHeader>

            <FormField
              control={form.control}
              name="role"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Role</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a role" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="user">User</SelectItem>
                      <SelectItem value="admin">Admin</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button disabled={isLoading}>Save</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
