import path from 'constants/path';
import Table from 'modules/threads';
import { GanttView as Gantt } from 'modules/threads/components';
import { TITLES } from 'pages/dashboard/constants';
import React from 'react';

interface Props {
  showGantt: boolean;
}

export const MyContributions: React.FC<Props> = ({ showGantt }) => {
  return (
    <div>
      {showGantt ? (
        <Gantt url={path.contributionsGanttList} title={TITLES.CONTRIBUTOR} />
      ) : (
        <Table url={path.contributionsList} title={TITLES.CONTRIBUTOR} />
      )}
    </div>
  );
};

export default MyContributions;
