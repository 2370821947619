import React, { Ref, createContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAppSelector } from 'app/hooks';
import { find } from 'lodash';
import { selectUserInfo } from 'modules/auth/userSlice';
import TextEditor from '@draft-js-plugins/editor';
import { findByMsgIndexById } from 'modules/chat/utils';
import { IMessageResponse } from '../../types';
import Message from './Message';
import ReplyHistory from './ReplyHistory';
import { useFocus } from '../FocusContext';

type TReplyState = {
  replyModeEnabled: boolean;
  replyingToMessage: IMessageResponse | null;
};

interface IContextStore {
  enableReplyMode(replyingToMessageId: number): void;
  disableReplyMode(): void;
  replyMode: boolean;
  parentId?: number;
  isOwner: boolean;
  messageId: number | null;
  creator: object | null;
  replyState: TReplyState;
  replyInputRef: Ref<TextEditor> | null;
}

export const MessageContext = createContext<IContextStore>({
  enableReplyMode: () => undefined,
  disableReplyMode: () => undefined,
  replyMode: false,
  isOwner: false,
  messageId: null,
  creator: null,
  replyState: { replyModeEnabled: false, replyingToMessage: null },
  replyInputRef: null,
});

const ChatMessage = (props: IMessageResponse & { messages: IMessageResponse[] }) => {
  // Ref for the reply input box
  const replyInputRef = useRef<TextEditor | null>(null);
  const { focus } = useFocus();
  const { creator, replies, id: messageId, replyTo, messages } = props;

  const [replyState, setReplyState] = useState<TReplyState>({
    replyModeEnabled: false,
    replyingToMessage: null,
  });

  /**
   * @description When user clicks reply in actions, scroll to reply input field.
   */
  useEffect(() => {
    if (!replyState.replyModeEnabled || !replyInputRef.current) return;
    const index = findByMsgIndexById(messages, String(messageId));
    // focus(replyInputRef.current, index);
  }, [replyState.replyModeEnabled, replyInputRef]);

  /**
   * @description Get message object we are currently replying to by its id.
   */
  const getReplyingToMessage = (replyingToMessageId: number) => {
    if (replyingToMessageId === messageId) {
      return props;
    }

    return find(replies, { id: replyingToMessageId }) || null;
  };

  /**
   * @description Display reply input field.
   */
  const enableReplyMode = (replyingToMessageId: number) =>
    setReplyState({
      ...replyState,
      replyingToMessage: getReplyingToMessage(replyingToMessageId),
      replyModeEnabled: true,
    });

  const disableReplyMode = () => setReplyState({ ...replyState, replyModeEnabled: false });

  const { id: userId } = useAppSelector(selectUserInfo);
  const isOwner = creator?.id === userId;

  const data = useMemo(
    () => ({
      enableReplyMode,
      replyMode: replyState.replyModeEnabled,
      disableReplyMode,
      replyState,
      parentId: replyTo || messageId,
      isOwner,
      messageId,
      replies,
      creator,
      replyInputRef,
    }),
    [replyState]
  );

  return (
    <MessageContext.Provider value={data}>
      <Message hasReplies={replyState.replyModeEnabled || !!replies.length} {...props} />
      <ReplyHistory messages={replies} />
    </MessageContext.Provider>
  );
};
export default ChatMessage;
