import { INTEGRATION_COLORS, INTEGRATION_LOGOS } from 'constants/integrations';
import _capitalize from 'lodash/capitalize';
import { EModelStatus, IFileVersions } from 'modules/threads/types';
import { ModelLink } from 'modules/threads3d/Link';
import React from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import { Group, Stack, TagBadge, Text } from 'ui';

const Container = styled(Stack)`
  overflow: hidden;
  padding: 15px;
  border: 2px solid ${INTEGRATION_COLORS.threads3d}};
  border-radius: 10px;
`;

const STATUS_COLOURS: Record<EModelStatus | 'unknown', string> = {
  uploaded: colors.blue2,
  pending: colors.blue2,
  processed: colors.green1,
  new: colors.blue,
  unknown: colors.red,
  error: colors.red,
};

interface Props {
  file: IFileVersions;
}

export const Threads3dIntegrationDetails: React.FC<Props> = ({ file }) => {
  const { modelStatus } = file;
  const status = modelStatus ?? 'unknown';

  const statusText = () => {
    if (status === EModelStatus.NEW) return 'Processing';
    return _capitalize(status);
  };

  return (
    <Container>
      <Stack gap="1rem">
        <Group justify="space-between" align="center">
          <Group gap="10px" justify="between" align="center">
            {INTEGRATION_LOGOS.threads3d}
            <Text size="lg">Threads3D</Text>
          </Group>
        </Group>
        <Group align="end" justify="space-between">
          <Group>
            <TagBadge color={STATUS_COLOURS[status]}>{statusText()}</TagBadge>
            <TagBadge>Version {file.version}</TagBadge>
          </Group>
          <ModelLink referenceId={file.id} status={modelStatus} />
        </Group>
      </Stack>
    </Container>
  );
};
