import React from 'react';
import colors from 'theme/colors';
import { Icon, Text } from 'ui';
import * as Layout from './Layout';
import { ICheckBoxProps } from './types';

const CheckBox = React.forwardRef<HTMLInputElement, ICheckBoxProps>(
  ({ name, checked, value, size, onChange, label, cypressAttribute }, ref) => {
    return (
      <Layout.Label data-cy={cypressAttribute && `${cypressAttribute}-checkbox-label`}>
        <Layout.Input
          type="checkbox"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          data-cy={cypressAttribute && `${cypressAttribute}-checkbox-input`}
          ref={ref}
        />
        <Layout.Check
          size={size}
          data-cy={cypressAttribute && `${cypressAttribute}-checkbox-check`}
        >
          <Icon fill={colors.blue} icon="CheckIcon" size="normal" />
        </Layout.Check>
        {label && (
          <Text
            color={colors.gray}
            size="sm"
            cypressAttribute={cypressAttribute && `${cypressAttribute}-checkbox-text`}
          >
            {label}
          </Text>
        )}
      </Layout.Label>
    );
  }
);

export default CheckBox;
