'use client';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import * as React from 'react';

import { cn } from 'lib/utils';

interface ITooltipProps
  extends React.PropsWithChildren<
    React.ComponentProps<typeof TooltipTrigger> & TooltipPrimitive.TooltipProps
  > {
  content: React.ReactNode;
  tooltipContentClassName?: string;
  delayDuration?: number;
  cypressAttribute?: string;
  isVisible?: boolean;
  side?: "top" | "right" | "bottom" | "left";
}

const TooltipProvider = TooltipPrimitive.Provider;

const BaseTooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      `z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md
      animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0
      data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2
      data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2`,
      className
    )}
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

const Tooltip = ({
  children,
  content,
  tooltipContentClassName,
  delayDuration = 0,
  cypressAttribute,
  isVisible = true,
  side,
  ...props
}: ITooltipProps): JSX.Element => {
  if (!isVisible) {
    return <>{children}</>;
  }
  
  return (
    <BaseTooltip data-cy={cypressAttribute} delayDuration={0} {...props}>
      <TooltipTrigger asChild>
        <span>{children}</span>
      </TooltipTrigger>
      <TooltipContent side={side} className={`border-solid border-card-border ${tooltipContentClassName}`}>
        {content}
      </TooltipContent>
    </BaseTooltip>
  );
};

export { TooltipProvider };
export default Tooltip;
