import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardContent } from 'components/ui/card';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from 'components/ui/form';
import { Switch } from 'components/ui/switch';
import { Loader2 } from 'lucide-react';
import { showSuccessMessage, showUncaughtErrorMessage } from 'modules/alert/utils';
import {
  useGetUserProfileNotificationsQuery,
  useUpdateUserProfileNotificationsMutation,
} from 'modules/user/userApi';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Button } from 'ui';
import { InferType, bool, number, object } from 'yup';

const NotificationCard: React.FC<{
  preference: Preference;
}> = ({ preference: { label, name, description } }) => {
  const { control } = useFormContext();
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <Card className=" border-solid ">
            <CardContent className="flex flex-row items-center justify-between pt-6">
              <div className="space-y-0.5">
                <FormLabel className="text-base">{label}</FormLabel>
                <FormDescription>{description}</FormDescription>
              </div>
              <FormControl>
                <Switch checked={field.value} onCheckedChange={field.onChange} />
              </FormControl>
            </CardContent>
          </Card>
        </FormItem>
      )}
    />
  );
};

const schema = object({
  userMentionedEnabled: bool().required(),
  threadStatusChangedEnabled: bool().required(),
  eventStatusChangedEnabled: bool().required(),
  newEventInThreadEnabled: bool().required(),
  eventAssignedEnabled: bool().required(),
  id: number().required(),
});

type NotificationName =
  | 'userMentionedEnabled'
  | 'threadStatusChangedEnabled'
  | 'eventStatusChangedEnabled'
  | 'newEventInThreadEnabled'
  | 'eventAssignedEnabled';

interface Preference {
  label: string;
  description: string;
  name: NotificationName;
}

const PREFERENCES: Preference[] = [
  {
    name: 'userMentionedEnabled',
    label: 'Mentions',
    description: 'Whenever a user mentions you in any thread',
  },
  {
    name: 'threadStatusChangedEnabled',
    label: 'Thread Status',
    description: 'Whenever the status of a thread you follow changes',
  },
  {
    name: 'eventStatusChangedEnabled',
    label: 'Event Status',
    description: 'Whenever the status of an event in a thread you follow changes',
  },

  {
    name: 'newEventInThreadEnabled',
    label: 'New Events',
    description: 'Whenever an event is created in a thread you follow',
  },
  {
    name: 'eventAssignedEnabled',
    label: 'Assigments',
    description: 'Whenever an event in a thread you follow is assigned to someone',
  },
];

export const Notifications: React.FC = () => {
  const { data: preferences } = useGetUserProfileNotificationsQuery();
  const [updatePreferences, { isLoading }] = useUpdateUserProfileNotificationsMutation();
  const form = useForm<InferType<typeof schema>>({
    values: preferences,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (payload: InferType<typeof schema>) => {
    try {
      await updatePreferences(payload).unwrap();
      showSuccessMessage('Notification preferences updated');
    } catch (error) {
      showUncaughtErrorMessage(error);
    }
  };

  return (
    <FormProvider {...form}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
          <div className="space-y-4">
            <div className="flex flex-col gap-2">
              <h2 className="text-lg font-semibold">Email Notifications</h2>
              <span className="text-sm text-neutral-500">
                Choose which notifications you&apos;d like to receive
              </span>
              <div className="h-[1px] w-full bg-secondary" />
            </div>
            {PREFERENCES.map((preference) => (
              <React.Fragment key={preference.name}>
                <NotificationCard preference={preference} />
              </React.Fragment>
            ))}
          </div>
          <Button type="submit" disabled={isLoading || !form.formState.isDirty}>
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Saving
              </>
            ) : (
              'Save'
            )}
          </Button>
        </form>
      </Form>
    </FormProvider>
  );
};
