import { api } from 'app/api';
import { AppThunk } from 'app/store';
import { deepCamelcaseKeys } from 'utils/mappers';
// @ts-ignore ts don't have types for audio file
import notificationSound from 'assets/audio/notification.mp3';
import { eventsApi } from 'modules/events/eventsApi';
import { INotificationResponse } from 'modules/notification/types';
import { isContributorNotification, safeJSONParse } from 'utils/helpers';
import path from '../../constants/path';
import { selectUserInfo } from '../auth/userSlice';
import { deleteMessage, pushMessage, selectFilter, updateMessage } from '../chat/chatSlice';
import {
  EMessageType,
  ESystemMessageType,
  IMessageResponse,
  ISystemMessagePayload,
} from '../chat/types';
import { checkFiltration } from '../chat/utils';
import { addMessage } from '../notification/slice';
import { selectFilter as selectThreadsFilter } from '../threads/filterSlice';
import { getThreadsListQueryParams } from '../threads/hooks';
import { threadsListApi } from '../threads/threadsApi';
import { EEventType, ISocketPayload, ISystemMessage } from './types';

// Specifically ignores FLOWS_MESSAGES
const isOwner = (ownerId: string, { type, creator }: IMessageResponse) =>
  type === EMessageType.USER_MESSAGE && creator?.id === ownerId;

export const chatMessageHandler =
  (context: IMessageResponse): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const { id } = selectUserInfo(state);
    const filter = selectFilter(state);
    if (isOwner(id, context) || !checkFiltration(context.type, filter)) {
      return;
    }
    dispatch(pushMessage(context));
  };

const handleSystemMessage = (dispatch: (action: unknown) => void, data: ISystemMessage) => {
  const messageText = safeJSONParse(data.text) as ISystemMessagePayload;
  switch (messageText.type) {
    case ESystemMessageType.EVENT: {
      // :tech-debt: Fix to allow individual thread parameters to be passed in.
      dispatch(eventsApi.util.invalidateTags(['events']));
      break;
    }
    default:
      break;
  }
};

export const eventHandler =
  (payload: ISocketPayload): AppThunk =>
  (dispatch, getState) => {
    const { type, context } = payload;
    const data = deepCamelcaseKeys(context);
    switch (type) {
      case EEventType.MESSAGE: {
        dispatch(chatMessageHandler(data));
        if (context.type === EMessageType.SYSTEM_MESSAGE) {
          handleSystemMessage(dispatch, data);
        }
        if (context.type === EMessageType.THREADS_3D_MESSAGE) {
          dispatch(api.util.invalidateTags(['threads3dAnnotations']));
        }
        break;
      }
      case EEventType.MESSAGE_DELETE: {
        dispatch(deleteMessage(context));
        break;
      }
      case EEventType.MESSAGE_UPDATE: {
        dispatch(updateMessage(data));
        break;
      }
      case EEventType.LIKE: {
        dispatch(updateMessage(data));
        break;
      }
      case EEventType.THREADS_3D_MODEL: {
        dispatch(api.util.invalidateTags(['references']));
        break;
      }
      case EEventType.NOTIFICATION: {
        // TODO: Add validation here
        const notification = data as INotificationResponse;
        const state = getState();
        const { id: userId } = selectUserInfo(state);
        if (isContributorNotification(userId, notification)) {
          const threadsFilter = selectThreadsFilter(state);
          dispatch(
            threadsListApi.endpoints.getThreadsList.initiate(
              getThreadsListQueryParams(path.contributionsList, threadsFilter),
              { forceRefetch: true }
            )
          );
        }
        dispatch(addMessage(notification));
        if (document.hasFocus()) {
          const audio = new Audio(notificationSound);
          audio.play();
        }
        break;
      }
      default:
        break;
    }
  };
