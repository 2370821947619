export enum EUserRole {
  ADMIN = 'admin',
  USER = 'user',
  EXTERNAL_USER = 'external_user',
}
export interface IUserMinimize {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string | null;
  email: string;
  group: string;
}

/**
 * When backend sends serialized user with (snake_cased) keys.
 */
export type TUserMinimizeSnakeCase = Omit<IUserMinimize, 'firstName' | 'lastName'> & {
  first_name: string;
  last_name: string;
};

export interface IUser extends IUserMinimize {
  siteId: string;
  phone?: string | null;
  description: string;
  userRole: EUserRole;
  termsAndConditions: Date | null;
  isTrialExpired: boolean;
  isImpersonate: boolean;
  impersonatorId: string | null;
  isSiteOwner: boolean;
  canImpersonate: boolean;
}
