/**
 * Slice for general app state.
 */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { appModals, modals } from 'modules/modals/modals';
import { loadStateFromLocalStorage } from 'utils/localStorage';
import { EThreadsListOptionIds } from 'pages/dashboard/constants';
import { SAVED_STATE } from '../../constants';

type TModalKey = keyof typeof modals | keyof typeof appModals;

interface IModalPayload {
  modal: TModalKey;
  show: boolean;
  context?: object | null;
}

interface IAppState {
  debugMode: boolean;
  showSupportModal: boolean;
  dashboardThreadsFilterOptionId: string | number; // Selected filter option for threads list on dashboard.
  modalState: IModalPayload;
}

export const initialState: IAppState = {
  debugMode: loadStateFromLocalStorage(localStorage.getItem(SAVED_STATE))?.app?.debugMode || false,
  showSupportModal: false,
  dashboardThreadsFilterOptionId: EThreadsListOptionIds.ALL_THREADS,
  modalState: {
    modal: 'customerSupport' as TModalKey,
    show: false,
    context: null,
  },
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    /** Set debug mode state */
    setDebugMode: (state, { payload }) => {
      state.debugMode = payload;
      return state;
    },
    /** Set display for support modal. */
    setShowSupportModal: (state, { payload }) => {
      return { ...state, showSupportModal: payload };
    },

    /** Set filter option for threads list on dashboard. */
    setDashboardThreadsFilterOptionId: (state, { payload }: PayloadAction<string | number>) => {
      return { ...state, dashboardThreadsFilterOptionId: payload };
    },

    setModalState: (state, { payload }: PayloadAction<IModalPayload>) => {
      return {
        ...state,
        modalState: {
          ...payload,
          context: payload.context || null,
        },
      };
    },
  },
});

export const {
  setShowSupportModal,
  setDebugMode,
  setDashboardThreadsFilterOptionId,
  setModalState,
} = appSlice.actions;

export const selectAppState = (state: RootState) => state.app;
export const selectDebugModeEnabled = (state: RootState) => state.app.debugMode;
export const selectDashboardThreadsFilterOptionId = (state: RootState) =>
  state.app.dashboardThreadsFilterOptionId;
export const selectAppModalState = (state: RootState) => state.app.modalState;

export default appSlice.reducer;
