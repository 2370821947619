import { yupResolver } from '@hookform/resolvers/yup';
import {
  THREAD_TAG_MAX_LENGTH,
  THREAD_OPTIONS_PRIORITY,
  THREAD_OPTIONS_STATUSES,
  THREAD_VISIBILITY_NAMES,
} from 'constants/index';
import routes from 'constants/routes';
import useSubscriptionPermissions from 'hooks/useSubscriptionPermissions';
import { NOTIFICATION } from 'modules/alert/constants';
import { showSuccessMessage } from 'modules/alert/utils';
import { useModal } from 'modules/modals/ModalProvider';
import { useCreateThreadMutation, useGetThreadTagsQuery } from 'modules/threads/threadsApi';
import { ICreateThreadMutation } from 'modules/threads/types';
import * as Layout from 'pages/thread/components/Layout';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import colors from 'theme/colors';
import {
  EThreadParams,
  EThreadPriority,
  EThreadStatuses,
  EThreadVisibility,
  TReactSelectOption,
} from 'types';
import { Button, Dropdown, Group, ReactSelect, Spinner, Stack, TextArea, TextInput } from 'ui';
import { createOption } from 'utils/helpers';
import { validationSchema } from 'utils/validation';
import * as yup from 'yup';

const initialValues = {
  name: '',
  description: '',
  status: EThreadStatuses.ON_TARGET,
  priority: EThreadPriority.LOW,
  tags: [],
  isPrivate: false,
};

const validation = yup
  .object({
    name: validationSchema.stringRequired(),
    description: validationSchema.textArea(),
    status: validationSchema.stringRequired(),
    priority: validationSchema.stringRequired(),
    tags: yup.array().notRequired(),
  })
  .required();

const CreateThread = () => {
  const { close } = useModal();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ICreateThreadMutation>({
    defaultValues: initialValues,
    mode: 'onSubmit',
    shouldFocusError: false,
    resolver: yupResolver(validation),
  });

  const { handleSubscriptionPermissionErrors } = useSubscriptionPermissions();

  const navigate = useNavigate();

  const [createThread, { isLoading: isCreateThreadLoading }] = useCreateThreadMutation();
  const { data: threadTags, isLoading, isFetching } = useGetThreadTagsQuery('');

  const onSubmit = async (payload: ICreateThreadMutation) => {
    try {
      const result = await createThread(payload).unwrap();
      showSuccessMessage(NOTIFICATION.THREAD_CREATED);
      close();

      if (result) {
        navigate(`${routes.thread}/${result.id}`);
      }
    } catch (error) {
      handleSubscriptionPermissionErrors(error, NOTIFICATION.INVALID_THREAD);
    }
  };

  const setStatus = (value: string) => {
    setValue(EThreadParams.STATUS, value as EThreadStatuses);
  };

  const setPriority = (value: string) => {
    setValue(EThreadParams.PRIORITY, value as EThreadPriority);
  };

  const setTags = (options: TReactSelectOption[]) => {
    const tags = options.map((option: TReactSelectOption) => option.value);
    setValue(EThreadParams.TAGS, tags);
  };

  const setVisibility = (option: TReactSelectOption) => {
    if (option.value === EThreadVisibility.PUBLIC) {
      return setValue(EThreadParams.IS_PRIVATE, false);
    }
    return setValue(EThreadParams.IS_PRIVATE, true);
  };

  const tagOptions: TReactSelectOption[] = useMemo(() => {
    return threadTags?.map((tag: string) => createOption(tag)) || [];
  }, [isFetching, threadTags]);

  return (
    <Layout.Form onSubmit={handleSubmit(onSubmit)} data-cy="modal-create-thread-form">
      <Stack fluid gap="25px" style={{ height: '100%' }}>
        <TextInput
          focusOnMount
          register={register}
          name="name"
          placeholder="Name"
          type="text"
          label="Name"
          required
          error={errors.name}
          data-cy="modal-create-thread-name"
        />
        <TextArea
          register={register}
          name="description"
          placeholder="Description"
          label="Description"
          resize="none"
          error={errors.description}
          maxLength={450}
          data-cy="modal-create-thread-description"
        />
        <ReactSelect
          label="Tags"
          id="create-thread-select"
          isLoading={isLoading}
          options={tagOptions}
          isSearchable
          placeholder="Select tags"
          onChange={setTags}
          isValidNewOption={(inputValue) =>
            !!inputValue && inputValue?.length < THREAD_TAG_MAX_LENGTH
          }
        />
        <Dropdown
          label="Status"
          name="status"
          badge="status"
          placeholder="Choose status"
          required
          bg={colors.dark2}
          defaultValue={THREAD_OPTIONS_STATUSES[0]}
          options={THREAD_OPTIONS_STATUSES}
          onClick={setStatus}
          error={errors.status}
          cypressAttribute="modal-create-thread-status"
        />
        <Dropdown
          label="Priority"
          name="priority"
          badge="priority"
          placeholder="Choose priority"
          required
          bg={colors.dark2}
          defaultValue={THREAD_OPTIONS_PRIORITY[0]}
          options={THREAD_OPTIONS_PRIORITY}
          onClick={setPriority}
          error={errors.priority}
          cypressAttribute="modal-create-thread-priority"
        />
        <ReactSelect
          label="Visibility"
          id="create-thread-select-visibility"
          isLoading={isLoading}
          required
          options={THREAD_VISIBILITY_NAMES}
          defaultValue={THREAD_VISIBILITY_NAMES[0]}
          isCreatable={false}
          isSearchable={false}
          isMulti={false}
          placeholder="Select Visibility"
          onChange={setVisibility}
        />
      </Stack>
      <Group justify="end" gap="15px" style={{ marginTop: 'auto' }}>
        <Button
          color={colors.dark2}
          onClick={close}
          cypressAttribute="modal-create-thread-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          disabled={isCreateThreadLoading}
          type="submit"
          cypressAttribute="modal-create-thread-create-btn"
        >
          {isCreateThreadLoading ? <Spinner size="small" color="white" /> : 'Create Thread'}
        </Button>
      </Group>
    </Layout.Form>
  );
};

export default CreateThread;
