import { yupResolver } from '@hookform/resolvers/yup';
import { useAppSelector } from 'app/hooks';
import _ from 'lodash';
import { NOTIFICATION } from 'modules/alert/constants';
import { useChangePasswordMutation } from 'modules/auth/authApi';
import { IChangePasswordMutation } from 'modules/auth/types';
import { selectUserInfo } from 'modules/auth/userSlice';
import { useWorkspaceQuery } from 'modules/workspace/workspaceApi';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'theme/colors';
import devices from 'theme/devices';
import { TReactSelectOption } from 'types';
import {
  Button,
  Divider,
  FileLoader,
  Group,
  Icon,
  PhoneInput,
  ReactSelect,
  Spinner,
  Stack,
  TextArea,
  TextInput,
  Title,
} from 'ui';
import { createOption, getPhoneNumber, handleApiCall } from 'utils/helpers';
import { compressImage } from 'utils/image';
import { MESSAGE, SPECIAL_CHARACTERS_REGEX, validationSchema } from 'utils/validation';
import * as yup from 'yup';
import { showSuccessMessage, showUncaughtErrorMessage } from '../../alert/utils';
import { onChangePassError } from '../helpers';
import { IUserProfileMutation } from '../types';
import { useGetUserGroupsQuery, useUpdateProfileMutation } from '../userApi';
import UserProfileNotificationForm from './UserProfileNotificationForm';

const UPLOAD_OPTIONS = {
  SIZE: 5_000_000,
  ACCEPT: 'image/png, image/jpeg',
};

interface IProfileForm extends Omit<IUserProfileMutation, 'avatar'>, IChangePasswordMutation {
  avatar: File | string | null;
  country: string;
}

const ResponsiveField = styled(Stack)`
  @media screen and ${devices.mobileL} {
    flex-direction: row;
  }
`;

const UploadAvatarFileContainer = styled.div`
  width: 100%;
  max-width: 460px;
`;

const validation = yup.object({
  firstName: validationSchema.stringWithNoSpecialCharsRequired(64),
  lastName: validationSchema.stringWithNoSpecialCharsRequired(64),
  description: validationSchema
    .string()
    .nullable()
    .max(1000, 'Description must not exceed 1000 characters'),
  newPassword1: validationSchema.password(),
  newPassword2: validationSchema.confirmPassword('newPassword1').nullable(),
  phone: validationSchema.phone(),
  email: validationSchema.emailRequired(),
  group: validationSchema.string().max(64, 'Group name must not exceed 64 characters').nullable(),
});

const ProfileForm: React.FC = () => {
  const { siteId, ...personalInfo } = useAppSelector(selectUserInfo);
  const defaultValues = useMemo(
    () => ({
      ...personalInfo,
      oldPassword: '',
      newPassword1: '',
      newPassword2: '',
    }),
    [personalInfo]
  );
  const {
    register,
    setValue,
    formState: { errors, isDirty },
    handleSubmit,
    getValues,
    setError,
    reset,
    control,
  } = useForm<IProfileForm>({
    defaultValues,
    resolver: yupResolver(validation),
  });
  const [changePassword, { isLoading: isPasswordChanging }] = useChangePasswordMutation();
  const [updateProfile, { isLoading: isUpdatingProfile }] = useUpdateProfileMutation();
  const { data: availableGroups } = useGetUserGroupsQuery();
  const { data: workspace } = useWorkspaceQuery();

  const isLoading = isPasswordChanging || isUpdatingProfile;

  const navigate = useNavigate();
  const handleAvatarChange = (avatar: File) => setValue('avatar', avatar, { shouldDirty: true });

  const handleProfileChange = async ({ phone, ...payload }: IUserProfileMutation) => {
    // phone is null if user has not changed it so we use the default value
    const userPhoneNumber = phone ? getPhoneNumber(phone) : personalInfo.phone;

    const response = await updateProfile({
      ...payload,
      phone: userPhoneNumber,
    });
    handleApiCall<IUserProfileMutation>(
      response,
      (error) => showUncaughtErrorMessage(error),
      (successfulResponse) => {
        showSuccessMessage(NOTIFICATION.PROFILE_UPDATED);
        reset({ ...defaultValues, ...successfulResponse.data });
      }
    );
  };

  const onSubmit = async (values: IProfileForm) => {
    const { oldPassword, newPassword1, newPassword2, avatar, ...restProfile } = values;
    // Do not send avatar if not changed or not set
    const payload =
      typeof avatar === 'string' || avatar === null
        ? restProfile
        : { avatar: await compressImage(avatar as File), ...restProfile };

    // Old password and new password must be present; plus additional equality check
    if (
      _.every([oldPassword, newPassword1, newPassword2], Boolean) &&
      newPassword1 === newPassword2
    ) {
      const passwordChangeResponse = await changePassword({
        oldPassword,
        newPassword1,
        newPassword2,
      });

      handleApiCall(passwordChangeResponse, onChangePassError, () => handleProfileChange(payload));
      return;
    }

    handleProfileChange(payload);
  };

  const groupsOptions = useMemo(
    () => availableGroups?.groups.map((group) => createOption(group)),
    [availableGroups]
  );

  const onGroupChange = useCallback(
    (options: TReactSelectOption) => setValue('group', options?.value, { shouldDirty: true }),
    []
  );

  const currentGroup = createOption(personalInfo.group);

  return (
    <form className="flex-1" onSubmit={handleSubmit(onSubmit)} data-cy="profile-form">
      <Stack fluid gap="30px">
        <Group align="center" justify="space-between">
          <Title heading="h5" weight="600">
            Personal info
          </Title>
          <Button
            variant="outlined"
            color={colors.red}
            leftIcon={<Icon icon="BinIcon" size="small" />}
            size="sm"
          >
            <a
              // eslint-disable-next-line max-len
              href={`mailto:support@authentise.zendesk.com?subject=Delete%20all%20personal%20data&body=Hello,%20I%20would%20like%20to%20delete%20all%20my%20personal%20data,%20My%20email%20is%20${personalInfo.email}`}
              rel="noreferrer"
            >
              Delete all my data
            </a>
          </Button>
        </Group>

        <UploadAvatarFileContainer>
          <FileLoader
            name="avatar"
            label="Upload a profile picture."
            text="Max size is 5MB"
            preview
            accept={UPLOAD_OPTIONS.ACCEPT}
            size={UPLOAD_OPTIONS.SIZE}
            onChange={handleAvatarChange}
            value={personalInfo.avatar ?? undefined}
          />
        </UploadAvatarFileContainer>
        <ResponsiveField gap="20px">
          <TextInput
            error={errors.firstName}
            name="firstName"
            register={register}
            label="First name"
            required
            data-cy="profile-form-name"
            maxLength={64}
          />
          <TextInput
            error={errors.lastName}
            name="lastName"
            register={register}
            label="Last name"
            required
            data-cy="profile-form-surname"
            maxLength={64}
          />
        </ResponsiveField>
        <ResponsiveField gap="20px">
          <PhoneInput label="Phone" error={errors.phone} name="phone" control={control} />
          <TextInput
            readOnly
            disabled
            name="email"
            register={register}
            label="Email"
            required
            data-cy="profile-form-email"
          />
        </ResponsiveField>
        <TextArea
          error={errors.description}
          name="description"
          label="Description"
          resize="none"
          register={register}
          data-cy="profile-form-description"
          maxLength={1000}
        />
        <Divider />
        <Title heading="h6" cypressAttribute="profile-form-group-title">
          Workspace
        </Title>
        <TextInput
          readOnly
          disabled
          name="workspace"
          label="Workspace Name"
          value={workspace?.name}
        />
        <ReactSelect
          error={errors.group}
          label="Current User Group"
          id="user-group-select"
          isSearchable
          defaultValue={currentGroup}
          options={groupsOptions}
          isMulti={false}
          onChange={onGroupChange}
        />
        <Divider />
        <UserProfileNotificationForm />
        <Divider />
        <Title heading="h6" cypressAttribute="profile-form-change-password-title">
          Change password
        </Title>
        <TextInput
          autoComplete="new-password"
          error={errors.oldPassword}
          name="oldPassword"
          label="Current Password"
          required={getValues('oldPassword').length > 0}
          register={register}
          type="password"
          data-cy="profile-form-current-password"
        />
        <ResponsiveField gap="20px">
          <TextInput
            error={errors.newPassword1}
            name="newPassword1"
            register={register}
            label="New Password"
            required={getValues('oldPassword').length > 0}
            type="password"
            data-cy="profile-form-new-password"
          />
          <TextInput
            error={errors.newPassword2}
            name="newPassword2"
            register={register}
            label="Confirm New Password"
            required={getValues('oldPassword').length > 0}
            type="password"
            data-cy="profile-form-confirm-new-password"
          />
        </ResponsiveField>
        <Group fluid gap="20px" justify="flex-end">
          <Button
            onClick={() => navigate(-1)}
            color={colors.dark2}
            cypressAttribute="profile-form-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isLoading || !isDirty}
            cypressAttribute="profile-form-save-btn"
          >
            {isLoading ? <Spinner size="small" color="#000" /> : 'Save'}
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default ProfileForm;
