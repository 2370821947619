import {
  AnnotationCommentList,
  AnnotationCommentListScroller,
} from 'modules/annotations/components/AnnotationLayout';
import { DEFAULT_PLYABLE_CREATOR } from 'modules/annotations/helpers';
import type { IPlyableReply } from 'modules/playable/types';
import React from 'react';
import { PlyableAnnotationContentHolder } from './Layout';
import PlyableAnnotationComment from './PlyableAnnotationComment';
import PlyableAnnotationContentSidebar from './PlyableAnnotationContentSidebar';

interface IPlyableAnnotationContentProps {
  /**
   * Unique identifier of the annotation.
   */
  id: string;
  /**
   * Annotation author name.
   */
  authorName: string;
  /**
   * Date of the annotation creation. ISO 8601 format.
   * @example "2021-01-01T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * Unique identifier of the file that the annotation is attached to.
   */
  fileId: number;
  /**
   * Name of the file that the annotation is attached to.
   */
  fileName: string;
  /**
   * Version of the file that the annotation is attached to.
   * @example "1.0.0"
   */
  fileVersion: string;
  /**
   * URL of the file that the annotation is attached to.
   */
  fileUrl: string;
  /**
   * List of comments for the annotation.
   */
  comments: IPlyableReply[];
  /**
   * Callback that is called when the refresh button is clicked.
   * @param id - Unique identifier of the annotation.
   */
  onRefreshClick?: (id: string) => void;
  isRefreshing: boolean;
}

const PlyableAnnotationContent: React.FC<IPlyableAnnotationContentProps> = (props) => {
  const {
    id,
    authorName,
    createdAt,
    fileId,
    fileName,
    fileVersion,
    fileUrl,
    comments = [],
    onRefreshClick,
    isRefreshing = false,
  } = props;

  return (
    <PlyableAnnotationContentHolder
      fluid
      justify="space-between"
      align="stretch"
      gap="10px"
      data-cy="annotation-content-holder"
    >
      <AnnotationCommentListScroller
        fullHeight
        justify="flex-start"
        data-cy="annotation-comments-list-scroller"
      >
        <AnnotationCommentList
          fluid
          fullHeight
          gap="8px"
          justify="flex-start"
          align="flex-start"
          data-cy="annotation-comments-list"
        >
          {comments.map((comment) => (
            <PlyableAnnotationComment
              key={comment.plyableId}
              creator={comment.creator || DEFAULT_PLYABLE_CREATOR}
              avatarSrc={comment.creator?.avatar ?? DEFAULT_PLYABLE_CREATOR.avatar!}
              content={comment.message}
              date={comment.createdAt}
            />
          ))}
        </AnnotationCommentList>
      </AnnotationCommentListScroller>
      <PlyableAnnotationContentSidebar
        author={authorName}
        date={createdAt}
        fileId={fileId}
        fileName={fileName}
        fileVersion={fileVersion}
        id={id}
        fileUrl={fileUrl}
        onRefreshClick={onRefreshClick}
        isRefreshing={isRefreshing}
      />
    </PlyableAnnotationContentHolder>
  );
};

export default PlyableAnnotationContent;
