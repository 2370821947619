/**
 * Wait for element to be mounted to DOM using MutationObserver.
 * https://stackoverflow.com/questions/5525071/how-to-wait-until-an-element-exists
 */
export const waitForElement = (selector: string) => {
  // eslint-disable-next-line consistent-return
  return new Promise((resolve) => {
    if (document.getElementById(selector)) {
      // eslint-disable-next-line no-promise-executor-return
      return resolve(document.getElementById(selector));
    }

    const observer = new MutationObserver((mutations) => {
      if (document.getElementById(selector)) {
        observer.disconnect();
        resolve(document.getElementById(selector));
      }
    });

    // If you get "parameter 1 is not of type 'Node'" error, see https://stackoverflow.com/a/77855838/492336
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};
