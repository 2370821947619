import { useAppSelector } from 'app/hooks';
import { Button } from 'components/ui/button';
import { Card, CardContent, CardHeader } from 'components/ui/card';
import { useSubscription } from 'hooks/useSubscription';
import { selectUserInfo } from 'modules/auth/userSlice';
import { useGetViewabilityOfThreadsQuery } from 'modules/threads/threadsApi';
import { useGetUsersQuery } from 'modules/user/userApi';
import { useWorkspaceQuery } from 'modules/workspace/workspaceApi';
import { GoogleIntegrationCard } from 'pages/settings/tabs/workspace/components/googleIntegration';
import { WorkspaceInvitesTable } from 'pages/settings/tabs/workspace/components/inviteTable';
import {
  ROLE_NAME_LOOKUP,
  WorkspaceMembersTable,
} from 'pages/settings/tabs/workspace/components/memberTable';
import { EditWorkspaceDialog } from 'pages/settings/tabs/workspace/dialogs/editWorkspace';
import { SendInviteDialog } from 'pages/settings/tabs/workspace/dialogs/sendInvite';
import React from 'react';

const WorkspaceDetailRow: React.FC<{ title: string; value?: string }> = ({ title, value }) => {
  return (
    <div className="flex flex-col justify-between sm:flex-row">
      <span className="text-sm text-neutral-500 sm:text-base  sm:text-foreground">{title}</span>
      <span className="text-base font-semibold">{value}</span>
    </div>
  );
};

export const Workspace: React.FC = () => {
  const { userRole, email, isSiteOwner } = useAppSelector(selectUserInfo);
  const { data: workspace } = useWorkspaceQuery();
  const { data: subscription } = useSubscription();
  const { data: guestViewability } = useGetViewabilityOfThreadsQuery();
  const { data: users } = useGetUsersQuery({});

  return (
    <div className="flex h-full w-full flex-col gap-6">
      <div className="flex flex-col gap-2">
        <h2 className="text-lg font-semibold">Your Workspace</h2>
        <span className="text-sm text-neutral-500">
          Your workspace&apos;s members, invites and integrations
        </span>
        <div className="h-[1px] w-full bg-secondary" />
      </div>
      <Card className="border-solid">
        <CardHeader className="flex flex-row items-center justify-between">
          <h3 className="text-md font-semibold">Workspace Details</h3>
          {isSiteOwner && (
            <EditWorkspaceDialog
              visibility={guestViewability?.guestsCanViewPublicThreads ?? false}
            />
          )}
        </CardHeader>
        <CardContent>
          <div className="space-y-2">
            <WorkspaceDetailRow title="Workspace name" value={workspace?.name} />
            <div className="h-[1px] w-full bg-secondary" />
            <WorkspaceDetailRow title="Your role" value={ROLE_NAME_LOOKUP[userRole]} />
            <div className="h-[1px] w-full bg-secondary" />
            <WorkspaceDetailRow title="Number of members" value={`${users?.count}`} />
            <div className="h-[1px] w-full bg-secondary" />
            <WorkspaceDetailRow title="Plan" value={subscription?.plan ?? 'No Plan'} />
            <div className="h-[1px] w-full bg-secondary" />
            <WorkspaceDetailRow
              title="Guest Visibility"
              value={
                guestViewability?.guestsCanViewPublicThreads
                  ? 'All Public Threads'
                  : 'Invited Threads'
              }
            />
          </div>
        </CardContent>
      </Card>
      <Card className="border-solid">
        <CardHeader className="flex flex-row items-center justify-between">
          <h3 className="text-md font-semibold">Workspace Members</h3>
        </CardHeader>
        <CardContent>
          <WorkspaceMembersTable />
        </CardContent>
      </Card>
      <Card className="border-solid">
        <CardHeader className="flex flex-row items-center justify-between">
          <h3 className="text-md font-semibold">Workspace Invites</h3>
          <SendInviteDialog />
        </CardHeader>
        <CardContent>
          <WorkspaceInvitesTable />
        </CardContent>
      </Card>
      <Card className="border-solid">
        <CardHeader className="flex flex-row items-center justify-between">
          <h3 className="text-md font-semibold">Workspace Integrations</h3>
        </CardHeader>
        <GoogleIntegrationCard />
      </Card>
      {isSiteOwner && (
        <div className="space-y-4">
          <div className="flex flex-col gap-2">
            <h2 className="text-md font-semibold">Danger Zone</h2>
            <div className="h-[1px] w-full bg-secondary" />
          </div>
          <Card className="border-solid">
            <CardContent className="flex flex-col justify-between gap-2 pt-6 sm:flex-row sm:items-center">
              <div className="flex flex-col space-y-0.5">
                <span className="text-base font-semibold">Transfer Ownership</span>
                <span className="text-sm text-neutral-500">
                  Transfer workspace ownership to another user
                </span>
              </div>
              <div className="flex flex-row justify-end sm:justify-start">
                <Button variant="destructive" asChild>
                  <a
                    // eslint-disable-next-line max-len
                    href={`mailto:support@authentise.zendesk.com?subject=Change%20workspace%20ownership&body=Hello,%20I%20would%20like%20to%20change%20the%20owner%20of%20the%20workspace,%20my%20email%20is%20${email}`}
                    rel="noreferrer"
                  >
                    Email Us
                  </a>
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      )}
    </div>
  );
};
