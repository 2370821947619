import useSubscriptionPermissions from 'hooks/useSubscriptionPermissions';
import { showSuccessMessage } from 'modules/alert/utils';
import {
  useRequestToJoinThreadMutation,
  useRevokeRequestToJoinThreadMutation,
} from 'modules/contributors/contributorsApi';
import React from 'react';
import { useParams } from 'react-router-dom';
import colors from 'theme/colors';
import { Icon } from 'ui';
import Tooltip from 'components/ui/tooltip';
import { Button } from 'components/ui/button';

interface Props {
  hasRequested: boolean;
}

export const RequestToJoinButton: React.FC<Props> = ({ hasRequested }) => {
  const { id } = useParams();
  const { handleSubscriptionPermissionErrors } = useSubscriptionPermissions();
  const [requestToJoinThreadAsContributor, { isLoading: isJoinRequestFetching }] =
    useRequestToJoinThreadMutation();
  const [cancelJoinThread, { isLoading: isCancelJoinLoading }] =
    useRevokeRequestToJoinThreadMutation();
  const isLoading = isJoinRequestFetching || isCancelJoinLoading;

  const handleRequestToJoinThread = async () => {
    if (!id) return;
    try {
      await requestToJoinThreadAsContributor({ thread: id }).unwrap();
      showSuccessMessage('Request to join thread as contributor sent successfully.');
    } catch (resError) {
      handleSubscriptionPermissionErrors(
        resError,
        'An error occurred while requesting to join thread as contributor.'
      );
    }
  };

  const handleCancelJoinThread = async () => {
    if (!id) return;
    const thread = parseInt(id, 10);
    if (Number.isNaN(thread)) return;
    try {
      await cancelJoinThread({ thread }).unwrap();
      showSuccessMessage('Request to join thread cancelled successfully.');
    } catch (resError) {
      handleSubscriptionPermissionErrors(
        resError,
        'An error occurred while cancelling request to join thread.'
      );
    }
  };

  return (
    <Tooltip
      content="You have already requested to join this thread."
      side="left"
      cypressAttribute="chat-message-content-date-tooltip"
      isVisible={hasRequested}
    >
      <Button
        variant="secondary"
        color={colors.dark2}
        onClick={hasRequested ? handleCancelJoinThread : handleRequestToJoinThread}
        disabled={isLoading}
      >
        {hasRequested ? (
          <>
            <Icon className="mr-2" icon="PendingRequestIcon" size="small" />
            Cancel Join Request
          </>
        ) : (
          <>
            <Icon className="mr-2" size="small" icon="UserWithPlus" stroke="white" />
            Request to Join
          </>
        )}
      </Button>
    </Tooltip>
  );
};
