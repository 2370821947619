import { showErrorMessage, showWarningMessage } from 'modules/alert/utils';
import { useModal } from 'modules/modals/ModalProvider';
import { TLimitValidationError } from 'types/error';

const useSubscriptionPermissions = () => {
  const { open } = useModal();
  /**
   * @description
   * Handle errors case-by-case based on status code and error type.
   * ---
   * @param error
   * Error object that comes from BE
   * @param fallBackErrorMessage
   * A fallback error message in case the error is not a subscription error
   */
  const handleSubscriptionPermissionErrors = (
    error: unknown,
    fallBackErrorMessage = 'An error occurred. Please try again.'
  ) => {
    const limitValidationError = error as TLimitValidationError;
    if (limitValidationError.status === 403 && limitValidationError.data.subscription_limit_error) {
      showWarningMessage('You have reached your subscription limit.');
      open({
        variant: 'center',
        contentLabel: 'subscriptionLimits',
        name: 'Subscription Limits',
        modal: 'subscriptionLimits',
        context: {
          subscriptionErrorKey: limitValidationError.data.subscription_limit_error,
          redirectUrl: limitValidationError.data.redirect_url,
        },
      });
      return;
    }
    showErrorMessage(fallBackErrorMessage);
  };

  return { handleSubscriptionPermissionErrors };
};

export default useSubscriptionPermissions;
