import { EEventStatus, EEvents } from 'constants/events';
import { NOTIFICATION } from 'modules/alert/constants';
import { showErrorMessage } from 'modules/alert/utils';
import { useActionSuggestionMutation } from 'modules/copilot/copilotApi';
import { setShowSuggestions } from 'modules/copilot/copilotSlice';
import {
  ECopilotSuggestionTypes,
  ICopilotSuggestion,
  SuggestionAction,
} from 'modules/copilot/types';
import { determineEditModalData } from 'modules/copilot/utils';
import { useCreateEventMutation, useEditEventMutation } from 'modules/events/eventsApi';
import { useModal } from 'modules/modals/ModalProvider';
import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import colors from 'theme/colors';
import { Group, Icon, Text } from 'ui';
import CopilotSuggestionMessageContent from '../CopilotMessageContent';
import {
  CopilotAction,
  CopilotActionsContainer,
  CopilotMessageContainer,
  CopilotMessageContent,
  CopilotSuggestionPaginationContainer,
} from './Layout';

interface CopilotSuggestionProps {
  suggestions: ICopilotSuggestion[];
}

const CopilotSuggestion: React.FC<CopilotSuggestionProps> = ({ suggestions }) => {
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);
  const currentCopilotSuggestion = suggestions[selectedSuggestionIndex];
  const [actionSuggestion] = useActionSuggestionMutation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { open } = useModal();
  const [createEvent] = useCreateEventMutation();
  const [editEvent] = useEditEventMutation();

  // Guard the destructuring below to prevent breakage if current suggestion is falsy.
  if (!currentCopilotSuggestion) {
    return null;
  }

  const {
    id: suggestionId,
    dueDate,
    status,
    description,
    type,
    name,
    eventUuid,
    suggestionType,
  } = currentCopilotSuggestion;

  const onSuggestionActioned = async (action: SuggestionAction, event?: string) => {
    await actionSuggestion({
      suggestionId,
      payload: { event, action },
    });
    dispatch(setShowSuggestions(false));
  };

  const message = () => (
    <CopilotSuggestionMessageContent
      copilotSuggestion={currentCopilotSuggestion}
      threadId={id as string}
    />
  );

  const handleEditBeforeSavingCopilotSuggestion = async () => {
    if (!id) return;
    const thread = parseInt(id, 10);
    if (Number.isNaN(thread)) return;

    const modalData = determineEditModalData(suggestionType);

    open({
      variant: 'fullRight',
      contentLabel: 'events',
      name: modalData.name,
      modal: modalData.modal,
      context: {
        // Not passing in `eventUuid`, as its values are force-set to current values.
        threadId: id,
        name,
        description,
        type: type as EEvents,
        status: (status as EEventStatus) ?? EEventStatus.NOT_STARTED,
        assignees: [],
        dueDate,
        copilotSuggestion: currentCopilotSuggestion,
      },
      id: 'modal-create-event',
    });
    dispatch(setShowSuggestions(false));
  };

  const handleAcceptCopilotSuggestion = async () => {
    if (!id) return;
    const thread = parseInt(id, 10);
    if (Number.isNaN(thread)) return;

    const eventPayload = {
      type: type as EEvents,
      name,
      description,
      thread,
      status: (status as EEventStatus) ?? EEventStatus.NOT_STARTED,
      assignees: [],
      dueDate: dueDate ?? new Date(),
      relatedEvent: null,
      relatedThread: null,
    };

    try {
      // Event id from suggestion - only passed for MODIFY actions
      let eventId = eventUuid ?? undefined;
      switch (suggestionType) {
        case ECopilotSuggestionTypes.CREATE: {
          const event = await createEvent(eventPayload).unwrap();
          eventId = `${event.id}`;
          break;
        }
        case ECopilotSuggestionTypes.MODIFY: {
          await editEvent({ ...eventPayload, id: Number(eventUuid) }).unwrap();
          break;
        }
        default:
          break;
      }
      onSuggestionActioned(SuggestionAction.ACCEPT, eventId);
    } catch {
      showErrorMessage(NOTIFICATION.SOMETHING_WRONG);
    }
  };

  return (
    <CopilotMessageContainer>
      <CopilotMessageContent justify="space-between" align="center" gap="10px">
        <Group align="center">
          <Text style={{ lineHeight: 1.2 }}>{message()}</Text>
        </Group>
        {suggestions && (
          <CopilotSuggestionPaginationContainer id="copilotSuggestionsPaginationContainer">
            {selectedSuggestionIndex + 1 > 1 && (
              <FaChevronLeft
                onClick={() => setSelectedSuggestionIndex((previous) => previous - 1)}
                role="button"
                color={colors.gray}
                className="spacer-right"
                size={12}
              />
            )}
            <Text size="xs" color={colors.gray}>
              Response {selectedSuggestionIndex + 1} of {suggestions.length}
            </Text>
            {selectedSuggestionIndex + 1 < suggestions.length && (
              <FaChevronRight
                onClick={() => setSelectedSuggestionIndex((previous) => previous + 1)}
                role="button"
                color={colors.gray}
                className="spacer-left"
                size={12}
              />
            )}
          </CopilotSuggestionPaginationContainer>
        )}
      </CopilotMessageContent>
      <CopilotActionsContainer>
        <CopilotAction onClick={handleAcceptCopilotSuggestion}>
          Accept
          <Icon icon="CheckIcon" size="small" stroke="white" fill="white" />
        </CopilotAction>
        <CopilotAction onClick={handleEditBeforeSavingCopilotSuggestion}>
          Edit
          <Icon icon="PencilIcon" size="small" stroke="white" />
        </CopilotAction>
        <CopilotAction onClick={() => onSuggestionActioned(SuggestionAction.DISMISS)}>
          Dismiss
          <Icon icon="CloseIcon" size="small" path="white" />
        </CopilotAction>
      </CopilotActionsContainer>
    </CopilotMessageContainer>
  );
};

export default CopilotSuggestion;
