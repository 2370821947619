import React from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { GlobalStyles } from 'GlobalStyles';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import App from 'App';
import { store } from 'app/store';
import Notification from 'modules/alert';
import theme from 'theme';
import { ENV, PRODUCTION } from './constants/env';
import 'global.css';

if (ENV.NODE_ENV === PRODUCTION) {
  const DSN = ENV.REACT_APP_SENTRY_DSN;
  const environment = ENV.REACT_APP_ENVIRONMENT || PRODUCTION;
  Sentry.init({
    dsn: DSN,
    integrations: [new BrowserTracing()],
    environment,
    tracesSampleRate: 1.0,
  });
}

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <App />
      <Notification />
    </ThemeProvider>
  </Provider>
);
