import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

// https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
const StyledContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    right: 0;
  }
  .Toastify__toast {
    border-radius: 32px 0 0 32px;
    min-height: auto;
    max-width: 500px;
    padding: 10px;
    margin: 0;
  }
  .Toastify__toast-body {
    max-width: 500px;
    padding: 0;
  }
  .Toastify__progress-bar {
    visibility: hidden;
  }
`;

const Notification = () => {
  return <StyledContainer closeButton={false} />;
};

export default Notification;
