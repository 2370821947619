import colors from 'theme/colors';
import { EPosition } from './types';

export enum EUserWalkthroughs {
  DASHBOARD = 'dashboard',
  THREAD_DETAILS = 'threadDetails',
}

export const ARROW_STYLE_MAP = {
  [EPosition.LEFT]: {
    left: 'unset',
    right: '-15px',
    borderLeft: `15px solid ${colors.orange}`,
    borderRight: 'none',
  },
  [EPosition.RIGHT]: {
    left: '-15px',
    right: 'unset',
    borderLeft: 'none',
    borderRight: `15px solid ${colors.orange}`,
  },
  [EPosition.TOP]: {
    left: '15px',
    top: 'auto',
    bottom: '-15px',
    borderTop: `15px solid ${colors.orange}`,
    borderRight: '15px solid transparent',
    borderLeft: '15px solid transparent',
    borderBottom: 'none',
  },
  [EPosition.BOTTOM]: {
    right: '15px',
    top: '-15px',
    borderTop: 'none',
    borderRight: '15px solid transparent',
    borderLeft: '15px solid transparent',
    borderBottom: `15px solid ${colors.orange}`,
  },
};
