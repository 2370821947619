import path from 'constants/path';
import Table from 'modules/threads';
import { GanttView as Gantt } from 'modules/threads/components';
import { TITLES } from 'pages/dashboard/constants';
import React from 'react';

interface Props {
  showGantt: boolean;
}

export const MyThreads: React.FC<Props> = ({ showGantt }) => {
  return (
    <div>
      {showGantt ? (
        <Gantt url={path.ganttList} title={TITLES.OWNER} />
      ) : (
        <Table url={path.threadsList} title={TITLES.OWNER} cypressAttribute="my-threads-table" />
      )}
    </div>
  );
};

export default MyThreads;
