import React, { useEffect } from 'react';
import Form from 'pages/login/components/Form';
import { Title } from 'ui';
import colors from 'theme/colors';
import LoginLayout from 'layout/Login';
import { api } from 'app/api';
import useLocalStorage from 'hooks/useLocalStorage';
import { RESET_EMAIL } from '../../constants';

const Login = () => {
  const [_, setResetEmail] = useLocalStorage(RESET_EMAIL);

  useEffect(() => {
    // remove email that used for reset password
    setResetEmail(null);
    // reset all cached data
    api.util.resetApiState();
  }, []);

  return (
    <LoginLayout>
      <>
        <div>
          <Title heading="h1" style={{ marginBottom: '30px' }}>
            Login
          </Title>
          <Title heading="h5" color={colors.gray1}>
            Agile Collaboration & Decision Making
          </Title>
        </div>
        <Form />
      </>
    </LoginLayout>
  );
};

export default Login;
