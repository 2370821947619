import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { usePingPlayableQuery } from '../api';
import { IPlayablePing } from '../types';

interface IUsePlyablePing {
  data: IPlayablePing | null;
  isLoading: boolean;
  isError: boolean;
  error: FetchBaseQueryError | SerializedError | null;
}

// 10 minutes
const PLYABLE_PING_INTERVAL = 10 * 60 * 1000;
export const usePlayablePing = (interval = PLYABLE_PING_INTERVAL): IUsePlyablePing => {
  const {
    data = null,
    isLoading,
    isError,
    error = null,
  } = usePingPlayableQuery(null, {
    pollingInterval: interval,
  });

  return { data, isLoading, isError, error };
};
