import TextEditor from '@draft-js-plugins/editor';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import AccessControl from 'app/permissions/AccessControl';
import { EPermissions } from 'app/permissions/constants';
import { usePageInteraction } from 'hooks';
import _clone from 'lodash/clone';
import { DEFAULT_PLYABLE_CREATOR } from 'modules/annotations/helpers';
import { GoogleMessage } from 'modules/chat/components/integrations/GoogleMessage';
import { IntegrationMessage } from 'modules/chat/components/integrations/IntegrationMessage';
import { PlyableMessage } from 'modules/chat/components/integrations/PlyableMessage';
import { Threads3DMessage } from 'modules/chat/components/integrations/Threads3DMessage';
import CopilotSuggestion from 'modules/copilot/components/CopilotSuggestion';
import { selectShowSuggestions } from 'modules/copilot/copilotSlice';
import { ICopilotSuggestion } from 'modules/copilot/types';
import { useThreadSuggestions } from 'modules/copilot/useThreadSuggestions';
import React, { Context, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ListProps, Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import colors from 'theme/colors';
import { Spinner, Stack, Text } from 'ui';
import Tooltip from 'components/ui/tooltip';
import {
  loadMessages,
  loadNextMessagesByLink,
  loadPrevMessagesByLink,
  selectChat,
  selectFilter,
} from './chatSlice';
import { useFocus } from './components/FocusContext';
import Input from './components/Input';
import * as ChatLayout from './components/Layout';
import ChatMessage from './components/Message';
import SystemMessage from './components/SystemMessage';
import { MESSAGE_PER_PAGE } from './constants';
import { EMessageType, IMessageResponse } from './types';
import { findByMsgIndexById } from './utils';

const ChatLoading = () => (
  <Stack align="center" justify="center" style={{ paddingTop: '12px' }}>
    <Text color={colors.gray2}>Loading...</Text>
  </Stack>
);

/**
 * Custom virtuoso component to render the list that encloses chat messages.
 */
const ChatItemsList = React.forwardRef<HTMLDivElement, ListProps & { context?: Context<unknown> }>(
  (props, ref) => {
    return (
      <div id="chat-items-list" style={props.style} data-test-id="virtuoso-item-list" ref={ref}>
        {props.children}
      </div>
    );
  }
);

/**
 * Schema that will be appended to end of virtuoso messages.
 */
type TVirtuosoCopilotSuggestionPayload = {
  type: string;
  list: ICopilotSuggestion[];
};

interface IChatProps {
  isArchived: boolean;
}

const Chat = ({ isArchived }: IChatProps) => {
  const { id, msgId } = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const referer = searchParams.get('referer');
  const navigate = useNavigate();

  const { count, prevLink, nextLink, currentOffset, isLoading, messages } =
    useAppSelector(selectChat);
  const { initiate: initiateSuggestions, data: suggestions } = useThreadSuggestions(id);
  const { focus, focusedInputRef } = useFocus();

  // Fetch copilot suggestions on page load
  useEffect(() => {
    if (id) {
      initiateSuggestions();
    }
  }, [id]);

  const [copilotSuggestionsPayload, setCopilotSuggestionsPayload] = useState<
    TVirtuosoCopilotSuggestionPayload[] | []
  >([]);

  // ⚠️ Hack to hide suggestions once dismissed
  // ⚠️ TODO: Replace this with an API call and invalidation
  const showSuggestions = useAppSelector(selectShowSuggestions);

  const dispatch = useAppDispatch();
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const chatFilter = useAppSelector(selectFilter);

  const hasInteractedWithPage = usePageInteraction(isLoading);

  // Focus the 'main' (i.e non-reply) input on mount (input is hidden until finished loading)
  useEffect(() => {
    if (!isLoading) {
      focus(focusedInputRef.current, undefined);
    }
  }, [isLoading]);

  const loadPrev = useCallback(() => {
    if (prevLink !== 'None') {
      const newLink = prevLink?.replace(/limit=\d+/, `limit=${currentOffset}`);
      dispatch(
        loadPrevMessagesByLink({
          link: Number(currentOffset) < MESSAGE_PER_PAGE ? (newLink as string) : prevLink,
        })
      );
    }
  }, [prevLink, currentOffset]);

  const loadNext = useCallback(() => {
    if (nextLink && nextLink !== 'None') {
      dispatch(loadNextMessagesByLink({ link: nextLink }));
    }
  }, [nextLink]);

  const renderCopilotSuggestions = (suggestions_: ICopilotSuggestion[]) => {
    return <CopilotSuggestion suggestions={suggestions_} />;
  };

  const renderMessageContent = (messageData: IMessageResponse): React.ReactNode => {
    switch (messageData?.type) {
      case EMessageType.USER_MESSAGE: // fallthrough
      case EMessageType.FLOWS_MESSAGE:
        return <ChatMessage key={messageData.id} messages={messages} {...messageData} />;

      case EMessageType.SYSTEM_MESSAGE:
        return <SystemMessage key={messageData.id} {...messageData} />;

      case EMessageType.THREADS_3D_MESSAGE:
        return (
          <IntegrationMessage
            message={messageData}
            renderMessage={(message) => <Threads3DMessage message={message} />}
          />
        );

      case EMessageType.GOOGLE_MESSAGE:
        return (
          <IntegrationMessage
            message={messageData}
            renderMessage={(message) => <GoogleMessage message={message} />}
          />
        );

      case EMessageType.PLYABLE_MESSAGE:
        return (
          <IntegrationMessage
            message={messageData}
            renderMessage={(message) => <PlyableMessage message={message} />}
          />
        );

      default:
        return null;
    }
  };

  /**
   * If URL contains thread/{id}/messages/{msgId}/ scroll to the highlighted comment
   * whose msgId matches.
   */
  useEffect(() => {
    if (msgId) {
      const highlightedMessageIndex = findByMsgIndexById(messages, msgId);

      // `?referer=notificationsList`
      if (referer === 'notificationsList') {
        // Skip checks and scroll to index.
        virtuosoRef.current?.scrollToIndex(highlightedMessageIndex);
        // Pop off the `?referer=` query param straightaway.
        navigate(`${location.pathname}${location.hash}`);
      } else if (!hasInteractedWithPage) {
        /* If no referer, scroll only if the page hasn't been interacted (clicked). This is to prevent
        annoying jumps (to highlighted comment) when the user is performing regular tasks on this page. */
        virtuosoRef.current?.scrollToIndex(highlightedMessageIndex);
      }
    }
  }, [virtuosoRef.current]);

  useEffect(() => {
    const onInitialize = async () => {
      // Load messages first, then load copilot data.
      await dispatch(loadMessages({ id: id as string, filter: chatFilter, msgId }));
    };
    onInitialize();
  }, [chatFilter, id]);

  const handleRenderItemContent = (_: unknown, data: unknown) => {
    const suggestionsPayload = data as TVirtuosoCopilotSuggestionPayload;
    const messagePayload = _clone(data) as IMessageResponse;

    if (suggestionsPayload.type === 'COPILOT_SUGGESTIONS_PAYLOAD') {
      if (!suggestionsPayload.list.length) {
        return null;
      }

      return renderCopilotSuggestions(suggestionsPayload.list as ICopilotSuggestion[]);
    }

    if (messagePayload.type === EMessageType.PLYABLE_MESSAGE) {
      if (!messagePayload.creator) messagePayload.creator = DEFAULT_PLYABLE_CREATOR;
    }

    return renderMessageContent(messagePayload);
  };

  /**
   * Append payload to show suggestions when user clicks bulb.
   */
  const appendCopilotSuggestions = () => {
    if (suggestions?.suggestionsAvailable && showSuggestions) {
      setCopilotSuggestionsPayload([
        {
          type: 'COPILOT_SUGGESTIONS_PAYLOAD',
          list: suggestions.suggestions,
        },
      ]);
    } else {
      setCopilotSuggestionsPayload([]);
    }
  };

  useEffect(() => {
    appendCopilotSuggestions();

    if (showSuggestions) {
      setTimeout(() => {
        const bottomIndex = [...messages, ...copilotSuggestionsPayload].length;
        virtuosoRef.current?.scrollToIndex(bottomIndex);
      }, 100);
    }
  }, [showSuggestions]);

  return (
    <ChatLayout.Wrapper id="chat-wrapper" data-cy="chat-wrapper">
      <div style={{ flex: 1 }}>
        {messages.length > 0 && (
          <Virtuoso
            id="chat-virtuoso"
            className="h-full"
            data={[...messages, ...copilotSuggestionsPayload]}
            ref={virtuosoRef}
            firstItemIndex={count && messages ? count - messages.length : 0}
            followOutput={false}
            startReached={loadPrev}
            endReached={loadNext}
            initialTopMostItemIndex={
              msgId ? findByMsgIndexById(messages, msgId as string) : messages.length - 1
            }
            itemContent={handleRenderItemContent}
            components={{
              // :tech-debt: to fix UI padding at the top, commenting out to fix copilot flickering issues.
              // List: ChatItemsList,
              /* eslint-disable-next-line react/no-unstable-nested-components */
              Footer: () => (isLoading ? <ChatLoading /> : null),
              /* eslint-disable-next-line react/no-unstable-nested-components */
              Header: () => (isLoading ? <ChatLoading /> : <div className="h-4" />),
            }}
          />
        )}
      </div>

      {!isLoading && !isArchived && (
        <AccessControl permissions={[EPermissions.SEND_CHAT_MESSAGE]} threadId={id as string}>
          <Input ref={focusedInputRef as RefObject<TextEditor>} />
        </AccessControl>
      )}

      {!messages.length && (
        <ChatLayout.Placeholder data-cy="chat-wrapper-no-message">
          {isLoading ? (
            <Stack align="center">
              Loading messages...
              <Spinner size="medium" />
            </Stack>
          ) : (
            'No messages'
          )}
        </ChatLayout.Placeholder>
      )}
    </ChatLayout.Wrapper>
  );
};

export default Chat;
