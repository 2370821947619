import { useContributors } from 'modules/contributors/useContributors';
import { useParams } from 'react-router-dom';
import { selectUserInfo } from '../../modules/auth/userSlice';
import { selectOwner } from '../../modules/threads/threadsSlice';
import { useAppSelector } from '../hooks';
import { ERoles } from './constants';
import { getRole } from './utils';

export const useCanContribute = () => {
  const { id: threadId } = useParams();
  const { id, userRole } = useAppSelector(selectUserInfo);
  const { id: ownerId } = useAppSelector(selectOwner(threadId as string));
  const { isCurrentUserContributor } = useContributors(threadId as string);
  const role = getRole(userRole, ownerId === id, isCurrentUserContributor);
  return [ERoles.OWNER, ERoles.CONTRIBUTOR].includes(role);
};
