import { api } from 'app/api';
import {
  ICheckInviteContributorMutation,
  IContributor,
  IContributorCreate,
  IContributorInvite,
  IContributorInviteMutation,
  IExternalContributorsCreate,
  IThreadJoinRequest,
  TGetContributorParams,
} from 'modules/contributors/types';
import { TGetInviteParams } from 'modules/inviteUsers/types';
import { DEFAULT_LIMIT } from 'modules/threads/constants';

import { IPaginatedResponse } from 'types';

export const contributorsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getContributors: build.query<IPaginatedResponse<IContributor>, TGetContributorParams>({
      query: ({ id, limit = DEFAULT_LIMIT }) => ({
        url: `/threads/${id}/contributors/?limit=${limit}`,
      }),
      providesTags: ['contributorsList'],
    }),

    getInvites: build.query<IPaginatedResponse<IContributorInvite>, TGetInviteParams>({
      query: ({ id, limit = DEFAULT_LIMIT }) => ({
        url: `/threads/${id}/invites/?limit=${limit}`,
      }),
      providesTags: ['contributorsInvites'],
    }),

    addContributors: build.mutation<IContributor[], IContributorCreate[]>({
      query: (payload) => ({
        url: '/contributors/',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['contributorsList'],
    }),
    checkInviteContributorPermission: build.mutation<void, ICheckInviteContributorMutation>({
      query: (payload) => ({
        url: '/contributors/check-invited-limit/',
        method: 'POST',
        body: payload,
      }),
    }),

    addExternalContributors: build.mutation<IContributor, IExternalContributorsCreate[]>({
      query: (payload) => ({
        url: '/invite-tokens/invite-external-user/',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['contributorsInvites'],
    }),

    contributorInviteUpdate: build.mutation<IContributorInvite, IContributorInviteMutation>({
      query: (payload) => ({
        url: `/contributors/thread-invite-respond/`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['joinRequestsList', 'contributorsList'],
    }),

    checkInviteAcceptance: build.mutation<{ canAccept: boolean }, { threadInviteId: number }>({
      query: (payload) => ({
        url: `/contributors/can-accept-invite/`,
        method: 'POST',
        body: payload,
      }),
    }),

    removeContributor: build.mutation<void, string>({
      query: (id) => ({
        url: `/contributors/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['contributorsList'],
    }),

    contributorLeaveThread: build.mutation<void, { threadId: string }>({
      query: (body) => ({
        url: `/contributors/leave-thread/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['contributorsList', 'threadList'],
    }),
    getThreadJoinRequests: build.query<
      IPaginatedResponse<IThreadJoinRequest>,
      { threadId: string }
    >({
      query: ({ threadId }) => ({
        url: `/threads/${threadId}/join-requests/`,
      }),
      providesTags: ['joinRequestsList'],
    }),
    getThreadJoinRequestById: build.query<
      IPaginatedResponse<IThreadJoinRequest>,
      { threadId: string; id: string }
    >({
      query: ({ threadId, id }) => ({
        url: `/threads/${threadId}/join-requests/?id=${id}`,
      }),
    }),
    requestToJoinThread: build.mutation<void, { thread: string }>({
      query: (body) => ({
        url: `/contributors/thread-request-join/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['threadList'],
    }),
    revokeRequestToJoinThread: build.mutation<void, { thread: number }>({
      query: (body) => ({
        url: `/contributors/thread-invite-cancel/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['threadList'],
    }),
  }),
});

export const {
  useGetContributorsQuery,
  useAddContributorsMutation,
  useCheckInviteContributorPermissionMutation,
  useAddExternalContributorsMutation,
  useRemoveContributorMutation,
  useGetInvitesQuery,
  useContributorInviteUpdateMutation,
  useCheckInviteAcceptanceMutation,
  useContributorLeaveThreadMutation,
  useRequestToJoinThreadMutation,
  useGetThreadJoinRequestsQuery,
  useGetThreadJoinRequestByIdQuery,
  useRevokeRequestToJoinThreadMutation,
} = contributorsApi;
