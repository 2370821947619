import React from 'react';
import { Button } from 'components/ui/button';
import { useNavigate } from 'react-router-dom';
import colors from 'theme/colors';
import { Group, Stack, Text, Title } from 'ui';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { IoHelpBuoyOutline } from 'react-icons/io5';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Stack padding="15px" align="center">
      <Title heading="h1">Not Found</Title>
      <div className="mt-5 max-w-lg">
        <Text color={colors.gray} size="sm">
          The requested page could not be found. You may have misspelt the URL or the location may
          have been changed.
        </Text>
      </div>
      <div className="mt-5 flex gap-5">
        <Button onClick={() => navigate('/')}>
          <IoMdArrowRoundBack className="mr-2" />
          Back to Home
        </Button>
        <Button asChild variant="secondary">
          <a
            href="https://authentise.zendesk.com/hc/en-us/categories/18378893436820-Threads"
            target="_blank"
            rel="noreferrer"
          >
            Help
          </a>
        </Button>
      </div>
    </Stack>
  );
};

export default NotFound;
