import { useAppSelector } from 'app/hooks';
import AccessControl from 'app/permissions/AccessControl';
import { EPermissions } from 'app/permissions/constants';
import { FormattedDate } from 'components/dates';
import { useTreadCreator } from 'hooks/useThreadCreator';
import { NOTIFICATION } from 'modules/alert/constants';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { selectUserInfo } from 'modules/auth/userSlice';
import { useGetContributorsQuery } from 'modules/contributors/contributorsApi';
import { useModal } from 'modules/modals/ModalProvider';
import { selectThread } from 'modules/threads/threadsSlice';
import { RequestToJoinButton } from 'pages/thread/components/RequestToJoinButton';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'theme/colors';
import devices from 'theme/devices';
import { EThreadStatuses } from 'types';
import {
  AddButton,
  Avatar,
  Button,
  DropDownAction,
  Group,
  Icon,
  MemberList,
  PriorityBadge,
  Stack,
  StatusBadge,
  Text,
  Title,
} from 'ui';
import Tooltip from 'components/ui/tooltip';
import { getFullName, truncateRawString } from 'utils/helpers';
import PrivateThreadIndicator from './privateThreadIndicator';

const IconButton = styled(Button)`
  padding: 8px;
`;

const AdaptiveWrapper = styled(Stack)`
  position: relative;
  z-index: 10;
  @media screen and ${devices.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

interface IProps {
  isAdmin?: boolean;
}

const Header: React.FC<IProps> = ({ isAdmin = false }) => {
  const { open } = useModal();
  const { id } = useParams();
  const {
    status,
    priority,
    creator,
    name,
    updatedAt,
    isRequestedToJoin,
    isContributor,
    isPrivate,
  } = useAppSelector(selectThread(id as string));
  const { id: currentUserId } = useAppSelector(selectUserInfo);
  const cypressAttribute = 'thread-header-details';
  const { data: contributors } = useGetContributorsQuery({ id: id as string });
  const isUserContributor = contributors?.results.some(
    (contributor) => contributor.user.id === currentUserId
  );
  const memberList = useMemo(() => {
    return (
      contributors?.results.map((member) => {
        const { firstName, lastName, avatar } = member.user;
        return { firstName, lastName, avatar, id: member.id };
      }) ?? []
    );
  }, [contributors]);

  const { isUserThreadCreator } = useTreadCreator();

  const toggleDeleteModal = () => {
    open({
      variant: 'center',
      contentLabel: 'Delete thread',
      name: 'Delete Thread',
      modal: 'deleteThread',
      context: { threadId: id },
      id: 'modal-remove-thread',
    });
  };

  const toggleEditModal = () => {
    open({
      variant: 'fullRight',
      contentLabel: 'edit thread',
      name: 'Edit Thread',
      modal: 'editThread',
      context: { threadId: id },
      id: 'modal-edit-thread',
    });
  };

  const addContributor = () => {
    open({
      variant: 'fullRight',
      contentLabel: 'add contributor',
      name: 'Add Contributors',
      modal: 'addContributors',
      context: {
        threadId: id,
      },
      id: 'modal-add-contributor',
    });
  };

  const handleLeaveThread = () => {
    open({
      variant: 'center',
      contentLabel: 'Leave thread',
      name: 'Leave thread',
      modal: 'leaveThread',
      context: { threadName: name, threadId: id },
    });
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(`${window.location.origin}/thread/${id}`);
      showSuccessMessage('Thread link copied');
    } catch (error) {
      showErrorMessage(NOTIFICATION.CLIPBOARD_COPY_FAILURE);
    }
  };

  const actions = [
    {
      id: 'copy thread link',
      title: 'Copy link',
      action: handleCopyLink,
      icon: <Icon size="small" icon="CopyLinkIcon" />,
    },
    {
      id: 'edit',
      title: 'Edit',
      action: toggleEditModal,
      icon: <Icon size="small" icon="PencilIcon" />,
    },
    {
      id: 'delete',
      title: 'Delete',
      action: toggleDeleteModal,
      icon: <Icon size="small" icon="BinIcon" />,
    },
  ];

  const isArchived = EThreadStatuses.ARCHIVE === status;
  const creatorName = getFullName(creator);
  const threadCreatedByThisUser = creator.id === currentUserId;

  return (
    <Group
      style={{ paddingBottom: 10 }}
      fluid
      justify="space-between"
      align="center"
      data-cy="thread-header-group"
    >
      <AdaptiveWrapper gap="15px">
        <AdaptiveWrapper gap="15px">
          <Stack>
            <Group>
              <Title
                weight="700"
                heading="h5"
                data-cy={cypressAttribute && `${cypressAttribute}-heading`}
              >
                {name}
              </Title>
            </Group>
            <Group align="center" data-cy={cypressAttribute && `${cypressAttribute}-update-group`}>
              <Text size="xs" color={colors.gray1}>
                Last updated on
              </Text>
              <Text
                size="xs"
                color={colors.gray1}
                cypressAttribute={cypressAttribute && `${cypressAttribute}-update-date`}
              >
                <FormattedDate date={updatedAt} />
              </Text>
            </Group>
          </Stack>

          {isPrivate && (
            <Tooltip content={<PrivateThreadIndicator isPrivate={isPrivate} />}>
              <Icon icon="StrikeEyeIcon" size="medium" stroke="none" />
            </Tooltip>
          )}

          <Group data-cy={cypressAttribute && `${cypressAttribute}-members-group`}>
            <MemberList list={memberList} />
            {!isArchived && (
              <AccessControl permissions={[EPermissions.ADD_CONTRIBUTORS]} threadId={id as string}>
                <AddButton
                  id="add-thread-member-btn"
                  onClick={addContributor}
                  cypressAttribute="add-thread-member-btn"
                />
              </AccessControl>
            )}
          </Group>
        </AdaptiveWrapper>
      </AdaptiveWrapper>

      <Group gap="16px" align="center">
        <Group gap="16px" align="center">
          <StatusBadge status={status} cypressAttribute="thread-header-status" />
          <PriorityBadge priority={priority} cypressAttribute="thread-header-priority" />
          <Button
            leftIcon={<Avatar src={creator?.avatar} size="small" />}
            size="sm"
            color={colors.dark6}
            cypressAttribute="thread-header-creator-btn"
          >
            {truncateRawString(creatorName, 30)}
          </Button>
        </Group>

        {!threadCreatedByThisUser && !isAdmin && !isContributor && (
          <RequestToJoinButton hasRequested={isRequestedToJoin ?? false} />
        )}

        <AccessControl
          permissions={[EPermissions.DELETE_THREAD, EPermissions.EDIT_THREAD]}
          threadId={id as string}
        >
          <IconButton onClick={() => null} color={colors.dark2}>
            <DropDownAction actions={actions} cypressAttribute="thread-dropdown" />
          </IconButton>
        </AccessControl>
        {!isUserThreadCreator() && isUserContributor && (
          <Button
            variant="plain"
            leftIcon={<Icon icon="ExitIcon" style={{ width: 18, height: 18 }} path={colors.red} />}
            onClick={handleLeaveThread}
          >
            <Text weight="400" size="sm">
              Leave thread
            </Text>
          </Button>
        )}
      </Group>
    </Group>
  );
};

export default Header;
