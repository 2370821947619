import _ from 'lodash';
import {
  MembersWrapper,
  ReplyContent,
  ReplyWrapper,
  WithLine,
} from 'modules/chat/components/Layout';
import { MessageContainer } from 'modules/chat/components/integrations/MessageContainer';
import { IMessageResponse } from 'modules/chat/types';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import colors from 'theme/colors';
import { IUserMinimize } from 'types/user';
import { Button, Group, Icon, MemberList } from 'ui';

interface IntegrationMessageRepliesProps {
  messages: IMessageResponse[];
  renderReply: (reply: IMessageResponse) => React.ReactNode;
}

export const IntegrationMessageReplies: React.FC<IntegrationMessageRepliesProps> = ({
  messages,
  renderReply,
}) => {
  const { msgId } = useParams();
  const [isListChanged, setIsListChanged] = useState(false);
  const [initialMessageCount, setInitialMessageCount] = useState(messages.length);
  const [isOpen, setIsOpen] = useState(false);
  // Memoized list of messages received before page loads
  const initialMessages = useMemo(() => messages, []);

  // List of messages received after page loads
  const newMessages = messages.slice(initialMessages.length);
  const users = initialMessages
    .map((message) => message.creator)
    .filter(Boolean) as IUserMinimize[];

  useEffect(() => {
    if (messages.length > initialMessageCount && !isOpen) {
      return;
    }
    setIsListChanged(!isListChanged);
    setInitialMessageCount(messages.length);
  }, [messages]);

  const replyIncludesCopiedId = !!_.find(initialMessages, { id: Number(msgId) });

  useEffect(() => {
    setIsOpen(replyIncludesCopiedId);
  }, [msgId]);

  return (
    <ReplyWrapper owner={false}>
      <ReplyContent owner={false}>
        {initialMessages.length > 0 &&
          (isOpen ? (
            <>
              <MembersWrapper owner={false} withBorder>
                <Button
                  onClick={() => setIsOpen(false)}
                  leftIcon={
                    <Icon
                      icon="ChevronDownIcon"
                      size="extraSmall"
                      rotate="180deg"
                      stroke={colors.blue8}
                    />
                  }
                  textColor={colors.blue8}
                  variant="plain"
                >
                  Hide Replies
                </Button>
              </MembersWrapper>
              {initialMessages.map((message, index) => (
                <MessageContainer
                  key={message.id}
                  isReply
                  showReplyBorder={messages.length - 1 !== index}
                  creator={message.creator}
                >
                  {renderReply(message)}
                </MessageContainer>
              ))}
            </>
          ) : (
            <MembersWrapper owner={false}>
              <WithLine owner={false}>
                <Group align="center" gap="10px">
                  <MemberList disabled list={users} />
                  <Button textColor={colors.blue8} variant="plain" onClick={() => setIsOpen(true)}>
                    View {initialMessages.length} More Replies
                  </Button>
                </Group>
              </WithLine>
            </MembersWrapper>
          ))}
        {newMessages.map((message, index) => (
          <MessageContainer
            key={message.id}
            isReply
            showReplyBorder={newMessages.length - 1 !== index}
            creator={message.creator}
          >
            {renderReply(message)}
          </MessageContainer>
        ))}
      </ReplyContent>
    </ReplyWrapper>
  );
};
