import { EFeatureFlags } from 'constants/features';
import { useIsFeatureEnabled } from 'hooks/useFeatures';
import useSubscriptionPermissions from 'hooks/useSubscriptionPermissions';
import _filter from 'lodash/filter';
import _uniqBy from 'lodash/uniqBy';
import { NOTIFICATION } from 'modules/alert/constants';
import {
  showErrorMessage,
  showSuccessMessage,
  showUncaughtErrorMessage,
} from 'modules/alert/utils';
import {
  useAddContributorsMutation,
  useAddExternalContributorsMutation,
  useCheckInviteContributorPermissionMutation,
  useGetContributorsQuery,
} from 'modules/contributors/contributorsApi';
import {
  ICheckInviteContributorMutation,
  IContributor,
  IContributorCreate,
  IExternalContributorsCreate,
} from 'modules/contributors/types';
import { useModal } from 'modules/modals/ModalProvider';
import { useGetUsersQuery } from 'modules/user/userApi';
import * as Layout from 'pages/thread/components/Layout';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import colors from 'theme/colors';
import { TReactSelectOption } from 'types';
import { IUser } from 'types/user';
import { Button, Group, Icon, ReactSelect, Spinner, Stack, Text } from 'ui';
import { extractErrorMessagesFromResponse } from 'utils/helpers';
import { EMAIL_REGEX, MESSAGE } from 'utils/validation';
import { useGetThreadQuery } from 'modules/threads/threadsApi';

const GET_USERS_PARAMS = {
  limit: 500,
  offset: 0,
};

interface IProps {
  threadId: number;
}

interface IValidationError {
  status: number;
  data: {
    non_field_errors: string[];
  }[];
}

const AddContributors: React.FC<IProps> = ({ threadId }) => {
  const [addContributors, { isLoading }] = useAddContributorsMutation();
  const isNautilusIntegration = useIsFeatureEnabled(EFeatureFlags.NAUTILUS_INTEGRATION);
  const [checkInviteContributorsPermission, { isLoading: isChecking }] =
    useCheckInviteContributorPermissionMutation();
  const [addExternalContributors] = useAddExternalContributorsMutation();

  const { handleSubscriptionPermissionErrors } = useSubscriptionPermissions();
  const { close } = useModal();

  const { data: existingContributors } = useGetContributorsQuery({ id: String(threadId) });
  const { data: threadData } = useGetThreadQuery({ id: threadId });
  const [error, setError] = useState<boolean>(false);
  const { data, isLoading: isUsersLoading, isFetching } = useGetUsersQuery(GET_USERS_PARAMS);
  const users = data?.results || [];
  const [contributorsToInvite, setContributorsToInvite] = useState<TReactSelectOption[]>([]);
  const [contributorsOptions, setContributorsOptions] = useState<TReactSelectOption[]>([]);

  const { handleSubmit } = useForm<IContributorCreate[]>({
    mode: 'onSubmit',
    shouldFocusError: false,
  });

  const createUserOption = (
    email: string,
    userId: string,
    firstName: string,
    lastName: string
  ) => ({
    label: `${firstName} ${lastName} (${email})`,
    value: email,
    user: userId,
    parts: [firstName, lastName, email],
  });

  const allContributorOptions: TReactSelectOption[] = useMemo(() => {
    const existingThreadContributorIds =
      existingContributors?.results.map((contributor) => contributor.user.id) ?? [];

    const uninvitedUsers = _filter(
      users,
      (user) => ![...existingThreadContributorIds, threadData?.creator.id].includes(user.id)
    );

    return (
      uninvitedUsers?.map((user: IUser) =>
        createUserOption(user.email, user.id, user.firstName, user.lastName)
      ) || []
    );
  }, [JSON.stringify(users), threadData?.id]);

  useEffect(() => {
    setContributorsOptions(allContributorOptions);
  }, [allContributorOptions, isFetching]);

  const externalUsers: IExternalContributorsCreate[] = useMemo(() => {
    return (
      contributorsToInvite
        /* eslint-disable-next-line no-underscore-dangle */
        .filter((contributor) => contributor.__isNew__)
        .map((contributor) => ({ email: contributor.value, thread: [threadId] }))
    );
  }, [contributorsToInvite]);

  const existingUsers: IContributorCreate[] = useMemo(() => {
    return contributorsToInvite
      .filter((contributor) => contributor.user)
      .map((contributor) => ({ user: contributor.user, thread: threadId }));
  }, [contributorsToInvite]);

  const handleChange = useCallback((options: TReactSelectOption[]) => {
    setError(false);
    const isValid = options.every((option) => option.value.trim().match(EMAIL_REGEX));
    if (!isValid) {
      setError(true);
    }
    setContributorsToInvite(options);
  }, []);

  const handleInputChange = useCallback(
    (inputValue: string) => {
      if (!inputValue?.trim().length) {
        setContributorsOptions(allContributorOptions);
        return;
      }
      const numberOfParts = allContributorOptions[0]?.parts?.length ?? 1;
      const groupedOptions: TReactSelectOption[][] = Array(numberOfParts).fill([]);
      const searchTerm = inputValue.trim().toLowerCase();
      allContributorOptions.forEach((option) => {
        const parts = (option.parts || []) as string[];
        parts.forEach((part, index) => {
          if (part.toLowerCase().startsWith(searchTerm)) {
            groupedOptions[index].push(option);
          }
        });
      });
      const newOptions: TReactSelectOption[] = _uniqBy(groupedOptions.flat(), 'value');
      setContributorsOptions(newOptions);
    },
    [allContributorOptions]
  );

  const handleContributorInvitePermissionCheck = async (contributorsData: IContributor[]) => {
    const invitedContributorsUserId: ICheckInviteContributorMutation = {
      userIds: contributorsData.map((contributor) => contributor.user.id),
      threadId,
    };

    try {
      await checkInviteContributorsPermission(invitedContributorsUserId).unwrap();
      showSuccessMessage(NOTIFICATION.CONTRIBUTOR_CREATED);
      close();
    } catch (responseError) {
      handleSubscriptionPermissionErrors(
        responseError,
        'Can not check invite contributors permission. Please try again.'
      );
    }
  };

  const onSubmit = async () => {
    if (existingUsers.length) {
      try {
        const existingUsersRes = await addContributors(existingUsers).unwrap();
        handleContributorInvitePermissionCheck(existingUsersRes);
      } catch (addContributorError) {
        (addContributorError as IValidationError).data.forEach((errorData) => {
          errorData.non_field_errors.forEach((nonFieldError: string) => {
            showErrorMessage(nonFieldError);
          });
        });
      }
    }
    if (externalUsers.length) {
      try {
        await addExternalContributors(externalUsers).unwrap();
        showSuccessMessage('External contributors added successfully');
        close();
      } catch (errorData) {
        const errorMessages = extractErrorMessagesFromResponse(errorData);
        if (errorMessages.length) {
          errorMessages.forEach((message) => {
            showErrorMessage(message);
          });
        } else {
          showUncaughtErrorMessage(errorData);
        }
      }
    }
  };

  if (isUsersLoading) {
    return (
      <Stack align="center" justify="center">
        <Spinner size="medium" />
      </Stack>
    );
  }

  const placeholder = isNautilusIntegration ? 'Search' : 'Search | invite users by email';

  return (
    <Layout.ContributorForm onSubmit={handleSubmit(onSubmit)} data-cy="modal-contributor-form">
      <Stack>
        <Layout.InputWrap data-cy="modal-contributor-search-wrapper">
          <Layout.SearchWrap aria-hidden="true">
            <Icon fill={colors.gray1} icon="SearchIcon" size="normal" />
          </Layout.SearchWrap>
          <ReactSelect
            id="contributors-select"
            placeholder={placeholder}
            isSearchable
            autoFocus
            isCreatable={!isNautilusIntegration}
            options={contributorsOptions}
            onChange={handleChange}
            onInputChange={handleInputChange}
          />
        </Layout.InputWrap>
        {error && <Text color={colors.red}>{MESSAGE.INVALID_EMAIL}</Text>}
      </Stack>
      <Group justify="end" gap="15px" style={{ marginTop: 'auto' }}>
        <Button color={colors.dark2} onClick={close} data-cy="modal-contributor-close-btn">
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={error || isLoading || !contributorsToInvite.length || isChecking}
          cypressAttribute="modal-contributor-save-btn"
        >
          Save
        </Button>
      </Group>
    </Layout.ContributorForm>
  );
};

export default AddContributors;
