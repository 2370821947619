import { useGoogleLogin } from '@react-oauth/google';
import { useAppSelector } from 'app/hooks';
import { ReactComponent as GoogleDriveIcon } from 'assets/icons/google-drive.svg';
import { Button } from 'components/ui/button';
import { Card, CardContent } from 'components/ui/card';
import Tooltip from 'components/ui/tooltip';
import { useFeatureFlags } from 'hooks';
import { Info } from 'lucide-react';
import { showErrorMessage } from 'modules/alert/utils';
import {
  useGetUserAvailableIntegrationsQuery,
  useSendGoogleAccessTokenMutation,
} from 'modules/integrations/integrationsApi';
import { selectPlayable } from 'modules/playable';
import React, { useMemo } from 'react';
import { BsLightbulb } from 'react-icons/bs';
import { Icon } from 'ui';

enum GoogleIntegrationState {
  disabled = 'disabled',
  enabled = 'enabled',
  partiallyConnected = 'partiallyConnected',
  connected = 'connected',
}

const InfoTooltip: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Tooltip content={children}>
      <Button variant="ghost" size="icon">
        <Info className="h-4 w-4" />
      </Button>
    </Tooltip>
  );
};

const IntegrationCard: React.FC<{
  name: string;
  description: string;
  icon: React.ReactNode;
  children: React.ReactNode;
}> = ({ name, description, icon, children }) => {
  return (
    <Card className="border-solid">
      <CardContent className="items:start flex flex-col justify-between gap-2 pt-6 sm:flex-row sm:items-center">
        <div className="flex flex-row items-center space-x-2">
          <div
            className={`flex h-10 w-10 flex-shrink-0 flex-col items-center justify-center 
          rounded-full bg-secondary p-2`}
          >
            {icon}
          </div>
          <div className="flex flex-col space-y-0.5">
            <span className="text-base font-semibold">{name}</span>
            <span className="text-sm text-neutral-500">{description}</span>
          </div>
        </div>
        <div className="flex flex-row justify-end gap-2 sm:justify-start">{children}</div>
      </CardContent>
    </Card>
  );
};

const GoogleIntegrationCard: React.FC = () => {
  const { data: integrations, isFetching } = useGetUserAvailableIntegrationsQuery();
  const [sendGoogleAuthCode, { isLoading }] = useSendGoogleAccessTokenMutation();

  const connectToGoogle = useGoogleLogin({
    scope: `https://www.googleapis.com/auth/documents https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/presentations https://www.googleapis.com/auth/drive`,
    flow: 'auth-code',
    redirect_uri: 'postmessage',
    onSuccess: async (codeResponse) => {
      try {
        await sendGoogleAuthCode(codeResponse.code).unwrap();
      } catch {
        showErrorMessage('Could not connect to Google');
      }
    },
    onError: (errorResponse) =>
      showErrorMessage(errorResponse?.error || 'Could not connect to Google'),
  });

  // State machine representing whether all google integrations (docs, drive etc.) are connected
  const integrationState = useMemo(() => {
    if (!integrations) return GoogleIntegrationState.disabled;
    if (!integrations.isGoogleEnabled) return GoogleIntegrationState.disabled;
    if (!integrations.integrations.some((integration) => integration.connected))
      return GoogleIntegrationState.enabled;
    if (!integrations.integrations.every((integration) => integration.connected))
      return GoogleIntegrationState.partiallyConnected;
    return GoogleIntegrationState.connected;
  }, [integrations]);

  const tooltip = () => {
    if (integrationState === 'enabled' || integrationState === 'connected') return null;
    return (
      <Tooltip
        content={
          integrationState === 'disabled' ? (
            <span>Google Workspace integration is not enabled for your workspace</span>
          ) : (
            <span>
              Some Google services are not connected. <br />
              Please select all Google services when you connect this integration.
            </span>
          )
        }
      >
        <Button variant="ghost" size="icon">
          <Info className="h-4 w-4" />
        </Button>
      </Tooltip>
    );
  };

  return (
    <IntegrationCard
      name="Google Workspace"
      description="Connect to Google Docs, Sheets, Slides and Drive"
      icon={<GoogleDriveIcon />}
    >
      {tooltip()}
      <Button
        onClick={connectToGoogle}
        variant={integrationState === 'disabled' ? 'secondary' : 'default'}
        disabled={
          isFetching ||
          isLoading ||
          integrationState === 'disabled' ||
          integrationState === 'connected'
        }
      >
        {integrationState === 'disabled' && 'Disabled'}
        {(integrationState === 'enabled' || integrationState === 'partiallyConnected') && 'Connect'}
        {integrationState === 'connected' && 'Connected'}
      </Button>
    </IntegrationCard>
  );
};

export const Integrations: React.FC = () => {
  const { features } = useFeatureFlags();
  const threads3dEnabled = features['threads-3d'];
  const copilotEnabled = features.copilot;
  const { isIntegrationEnabled: plyableEnabled } = useAppSelector(selectPlayable);

  return (
    <div className="space-y-4">
      <div className="flex flex-col gap-2">
        <h2 className="text-lg font-semibold">Your Integrations</h2>
        <span className="text-sm text-neutral-500">
          Connect your account with external integrations
        </span>
        <div className="h-[1px] w-full bg-secondary" />
      </div>
      <GoogleIntegrationCard />
      <IntegrationCard
        name="Threads3D"
        description="Collaborative 3D Model Viewer"
        icon={<Icon icon="Threads3dIcon" aria-label="Threads 3d logo" />}
      >
        {!threads3dEnabled && (
          <InfoTooltip>Contact your workspace owner to enable this integration</InfoTooltip>
        )}
        <Button variant={threads3dEnabled ? 'default' : 'secondary'} disabled>
          {threads3dEnabled ? 'Enabled' : 'Disabled'}
        </Button>
      </IntegrationCard>
      <IntegrationCard
        name="Plyable"
        description="Additive Manufacturing Printing Provider"
        icon={<Icon icon="PlyableLogo" stroke="none" aria-label="Plyable logo" />}
      >
        {!plyableEnabled && (
          <InfoTooltip>Contact your workspace owner to enable this integration</InfoTooltip>
        )}
        <Button variant={plyableEnabled ? 'default' : 'secondary'} disabled>
          {plyableEnabled ? 'Enabled' : 'Disabled'}
        </Button>
      </IntegrationCard>
      <IntegrationCard
        name="ThreadsBot"
        description="Integrated AI Assistant"
        icon={<BsLightbulb />}
      >
        {!copilotEnabled && (
          <InfoTooltip>Contact your workspace owner to enable this integration</InfoTooltip>
        )}
        <Button variant={copilotEnabled ? 'default' : 'secondary'} disabled>
          {copilotEnabled ? 'Enabled' : 'Disabled'}
        </Button>
      </IntegrationCard>
    </div>
  );
};
