import styled from 'styled-components';
import React, { FC, useCallback, useState } from 'react';
import { Checkbox, Text, Stack, Title } from 'ui';
import { debounce } from 'lodash';
import { handleApiCall } from 'utils/helpers';
import {
  showErrorMessage,
  showSuccessMessage,
  showUncaughtErrorMessage,
} from 'modules/alert/utils';
import { NOTIFICATION } from 'modules/alert/constants';
import { IUserProfileNotifications } from '../types';
import {
  useGetUserProfileNotificationsQuery,
  useUpdateUserProfileNotificationsMutation,
} from '../userApi';

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.dark2};
}

;`;

type TNotificationPermission = Omit<IUserProfileNotifications, 'id'>;

const UserProfileNotificationForm: FC = () => {
  // api call to get user notifications permissions
  const { data: userNotificationsPermissions, isLoading } = useGetUserProfileNotificationsQuery();
  // api call to update user notifications permissions
  const [updateUserProfileNotificationPermission] = useUpdateUserProfileNotificationsMutation();
  const [formData, setFormData] = useState<TNotificationPermission>({
    userMentionedEnabled: false,
    threadStatusChangedEnabled: false,
    eventStatusChangedEnabled: false,
    newEventInThreadEnabled: false,
    eventAssignedEnabled: false,
  } as TNotificationPermission);

  const debounceSave = debounce(async (data: TNotificationPermission) => {
    const res = await updateUserProfileNotificationPermission({
      ...data,
      id: userNotificationsPermissions?.id as number,
    });
    handleApiCall(
      res,
      () => showUncaughtErrorMessage(),
      () => showSuccessMessage(NOTIFICATION.EMAIL_NOTIFICATION_PERMISSION)
    );
  }, 1000);

  const onSubmit = useCallback(async (data: TNotificationPermission) => {
    setFormData(data);
    debounceSave(data);
  }, []);

  React.useEffect(() => {
    setFormData({
      userMentionedEnabled: userNotificationsPermissions?.userMentionedEnabled || false,
      threadStatusChangedEnabled: userNotificationsPermissions?.threadStatusChangedEnabled || false,
      eventStatusChangedEnabled: userNotificationsPermissions?.eventStatusChangedEnabled || false,
      newEventInThreadEnabled: userNotificationsPermissions?.newEventInThreadEnabled || false,
      eventAssignedEnabled: userNotificationsPermissions?.eventAssignedEnabled || false,
    });
  }, [isLoading]);

  return (
    <Stack gap="20px">
      <Title heading="h6" cypressAttribute="user-profile-email-notifications-title">
        Email Notifications
      </Title>
      <MainContainer data-cy="user-profile-mentions-container">
        <Text weight="500">Mentions</Text>
        <Checkbox
          checked={formData.userMentionedEnabled}
          name="userMentionedEnabled"
          size="lg"
          onChange={() =>
            onSubmit({
              ...formData,
              userMentionedEnabled: !formData.userMentionedEnabled,
            })
          }
        />
      </MainContainer>
      <MainContainer data-cy="user-profile-thread-status-container">
        <Text weight="500">Thread status change</Text>
        <Checkbox
          checked={formData.threadStatusChangedEnabled}
          name="userMentionedEnabled"
          size="lg"
          onChange={() =>
            onSubmit({
              ...formData,
              threadStatusChangedEnabled: !formData.threadStatusChangedEnabled,
            })
          }
        />
      </MainContainer>
      <MainContainer data-cy="user-profile-event-status-container">
        <Text weight="500">Event status change</Text>
        <Checkbox
          checked={formData.eventStatusChangedEnabled}
          name="userMentionedEnabled"
          size="lg"
          onChange={() =>
            onSubmit({
              ...formData,
              eventStatusChangedEnabled: !formData.eventStatusChangedEnabled,
            })
          }
        />
      </MainContainer>
      <MainContainer data-cy="user-profile-new-thread-event-container">
        <Text weight="500">New event in thread</Text>
        <Checkbox
          checked={formData.newEventInThreadEnabled}
          name="userMentionedEnabled"
          size="lg"
          onChange={() =>
            onSubmit({ ...formData, newEventInThreadEnabled: !formData.newEventInThreadEnabled })
          }
        />
      </MainContainer>
      <MainContainer data-cy="user-profile-new-asignment-event-container">
        <Text weight="500">New assignment in event</Text>
        <Checkbox
          checked={formData.eventAssignedEnabled}
          name="userMentionedEnabled"
          size="lg"
          onChange={() =>
            onSubmit({ ...formData, eventAssignedEnabled: !formData.eventAssignedEnabled })
          }
        />
      </MainContainer>
    </Stack>
  );
};

export default UserProfileNotificationForm;
