import { ENotificationTypes } from './types';

export const NOTIFICATION_TITLE_MAP = {
  [ENotificationTypes.EVENT_CREATED]:
    '{fullName} created a new event "{eventName}" in "{threadName}"',
  [ENotificationTypes.USER_EVENT_CREATED]:
    '{fullName} created an event {eventName} from your message in "{threadName}"',
  [ENotificationTypes.CONTRIBUTOR_ADDED]: '{fullName} added you as a contributor to "{threadName}"',
  [ENotificationTypes.CONTRIBUTOR_REMOVED]:
    '{fullName} deleted you as a contributor from "{threadName}"',
  [ENotificationTypes.MENTION]: '{fullName} mentioned you in a message in "{threadName}"',
  [ENotificationTypes.REPLY]: '{fullName} replied to your message in "{threadName}"',
  [ENotificationTypes.REACTION]: '{fullName} reacted to your message in "{threadName}"',
  [ENotificationTypes.THREAD_ARCHIVED]: '{fullName} archived thread "{threadName}"',
  [ENotificationTypes.THREAD_DELETED]: '{fullName} deleted thread "{threadName}"',
  [ENotificationTypes.THREAD_UPDATE_SUMMARY]: 'Summary report for thread "{threadName}"',
  [ENotificationTypes.THREAD_CONTRIBUTOR_INVITE_SUCCESS]:
    'Contributor "{fullName}" joined your thread "{threadName}"',
  [ENotificationTypes.THREAD_CONTRIBUTOR_INVITE_FAILURE]: `
  Sorry, contributor "{fullName}" isn't able to join your thread "{threadName}"`,
  [ENotificationTypes.THREAD_TOO_MANY_CONTRIBUTOR_INVITE]: `You've been invited to too many threads, take action now`,
  [ENotificationTypes.CONTRIBUTOR_LEAVE_THREAD]: 'You left thread "{threadName}"',
  [ENotificationTypes.COPILOT_EVENT_SUGGESTIONS]: 'Copilot suggestion',
  [ENotificationTypes.PLYABLE_QUOTE_READY]:
    'Your composite mold quote for "{fileName}" (Version {fileVersion}) is ready',
  [ENotificationTypes.USER_REQUESTED_TO_JOIN_THREAD]:
    '{fullName} would like to join thread "{threadName}"',
  [ENotificationTypes.USER_JOIN_REQUEST_APPROVED]:
    'Your request to join {threadName} has been approved by {fullName}',
  [ENotificationTypes.USER_JOIN_REQUEST_REJECTED]:
    '{fullName} has denied your request to join {threadName}',
};
