import { useAppDispatch } from 'app/hooks';
import _find from 'lodash/find';
import { setDashboardThreadsFilterOptionId } from 'modules/app/appSlice';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { CSSProperties } from 'styled-components';
import { TReactSelectOption } from 'types';
import { Group, ReactSelect, Title } from 'ui';
import { TDropdownSelectorOption } from '../types';
import * as Layout from './Layout';

interface IProps {
  id?: string;
  title?: string;
  options: TDropdownSelectorOption[];
  defaultValueId?: number | string;
  cypressAttribute: string;
  height?: CSSProperties['height'];
  children?: ReactElement | ReactElement[];
  /** An optional function called when an active dropdown option is changed. */
  onChangeActiveOptionEffect?: (optionId: string) => void;
}

/**
 * Wraps the component in a selectable dropdown in which options can be passed into.
 * Indended to be used on Dashboard page only.
 */
const WrappedDropdownSelector = ({
  id,
  options,
  cypressAttribute,
  defaultValueId,
  height,
  title,
  children,
  onChangeActiveOptionEffect,
}: IProps) => {
  const dispatch = useAppDispatch();

  const [activeOptionId, setActiveOptionId] = useState<number | string>(
    defaultValueId || options[0].id
  );

  const transformedOptions: TReactSelectOption[] = useMemo(
    () =>
      options.map((option) => ({
        label: option.name,
        value: String(option.id),
      })),
    [options]
  );

  const activeOption = useMemo(
    () =>
      options.find(
        (option: TDropdownSelectorOption) => String(option.id) === String(activeOptionId)
      ),
    [activeOptionId, options]
  );

  const handleChangeActiveOption = useCallback(
    (option: TReactSelectOption) => {
      setActiveOptionId(option.value);

      if (onChangeActiveOptionEffect) onChangeActiveOptionEffect(option.value);

      // If this WrappedDropdownSelector corresponds to Threads list only
      if (id === 'threads-options') {
        dispatch(setDashboardThreadsFilterOptionId(option.value));
      }
    },
    [id]
  );

  return (
    <Layout.DropdownSelector
      id={id}
      height="auto"
      data-cy={cypressAttribute && `${cypressAttribute}-data`}
    >
      <Group justify="space-between">
        {title && (
          <Title id={`${cypressAttribute}-title`} heading="h5">
            {title}
          </Title>
        )}
        <ReactSelect
          id={cypressAttribute && `${cypressAttribute}-dropdown`}
          isMulti={false}
          defaultValue={
            defaultValueId
              ? _find(transformedOptions, { value: String(defaultValueId) })
              : transformedOptions[0]
          }
          isSearchable={false}
          options={transformedOptions}
          onChange={handleChangeActiveOption}
        />
        {children}
      </Group>
      <Layout.DropdownSelectorContent
        data-cy={cypressAttribute && `${cypressAttribute}-data-id-${activeOptionId}`}
      >
        {activeOption &&
          React.isValidElement(activeOption.content) &&
          React.cloneElement(
            activeOption.content as ReactElement<{ activeOptionId: number | string }>,
            {
              activeOptionId,
            }
          )}
      </Layout.DropdownSelectorContent>
    </Layout.DropdownSelector>
  );
};

export default WrappedDropdownSelector;
