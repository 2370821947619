import React, { FC, useEffect } from 'react';
import LoginLayout from 'layout/Login';
import routes from 'constants/routes';
import { useAppSelector } from 'app/hooks';
import { useNavigate } from 'react-router-dom';
import {
  selectSubscriptionName,
  selectSubscriptionPriceId,
} from 'modules/subscriptionsPlans/subscriptionsPlansSlice';
import { CreateWorkspaceForm } from './components/CreateWorkspaceForm';

export const CreateWorkspace: FC = () => {
  const subscriptionPriceId = useAppSelector(selectSubscriptionPriceId);
  const subscriptionName = useAppSelector(selectSubscriptionName);
  const navigate = useNavigate();
  // redirect user to subscription plans page if he is does not have selected plan
  useEffect(() => {
    if (!subscriptionPriceId.length) {
      navigate(`${routes.subscriptionsPlans}/auth`);
    }
  }, [subscriptionPriceId]);
  return (
    <LoginLayout>
      <CreateWorkspaceForm subscriptionName={subscriptionName} />
    </LoginLayout>
  );
};
