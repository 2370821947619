import { useAppSelector } from 'app/hooks';
import { ENV } from 'constants/env';
import { selectChannels } from 'modules/stream/streamSlice';
import { EAction, ISocketPayload } from 'modules/stream/types';
import { useEffect } from 'react';
import useWebSocket from 'react-use-websocket';

export const useStream = (handler: (message: ISocketPayload) => void) => {
  const channels = useAppSelector(selectChannels);

  const { lastJsonMessage, sendMessage } = useWebSocket<ISocketPayload>(
    ENV.REACT_APP_WEBSOCKET_URL,
    {
      share: true,
      onOpen: () => console.info('connected'),
      onClose: () => console.info('close'),
      retryOnError: true,
    }
  );

  const getActionPayload = (action: EAction) =>
    JSON.stringify({
      action,
      data: { channels },
    });

  useEffect(() => {
    if (lastJsonMessage) {
      handler(lastJsonMessage);
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    sendMessage(getActionPayload(channels.length ? EAction.SUBSCRIBE : EAction.UNSUBSCRIBE));
  }, [channels]);

  return { sendMessage };
};
