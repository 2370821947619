import React from 'react';
import useSSO from 'hooks/useSSO';
import Button from 'ui/button';
import Text from 'ui/text';

export const SSOForm: React.FC = () => {
  const { loginWithSSO } = useSSO();
  return (
    <Button fluid type="button" onClick={loginWithSSO} cypressAttribute="sign-in-with-sso">
      <Text weight="700" color="#000">
        Sign in with SSO
      </Text>
    </Button>
  );
};
