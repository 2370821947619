import { Button } from 'components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table';
import { ChevronLeft, ChevronRight, EllipsisVertical } from 'lucide-react';
import { useGetUserInvitesListQuery } from 'modules/inviteUsers/inviteUsersApi';
import { QUERY_PARAMS } from 'pages/members/constants';
import { RemoveInviteDialog } from 'pages/settings/tabs/workspace/dialogs/removeInvite';
import React, { useState } from 'react';

export const WorkspaceInvitesTable: React.FC = () => {
  const [page, setPage] = useState(0);
  const { data: invites } = useGetUserInvitesListQuery({
    ordering: QUERY_PARAMS.ORDERING,
    limit: QUERY_PARAMS.USERS_LIMIT,
    offset: page * QUERY_PARAMS.USERS_LIMIT,
  });
  if (invites?.results.length === 0) {
    return (
      <div className="flex w-full justify-center">
        <span className="text-sm text-neutral-500">No invites</span>
      </div>
    );
  }
  return (
    <div className="space-y-2">
      <Table className="rounded border border-solid border-card-border">
        <TableHeader className="block">
          <TableRow className="table border-solid border-card-border">
            <TableHead>Email</TableHead>
            <TableHead className="w-16 text-right md:w-24">Action</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody className="block max-h-96 w-full overflow-auto">
          {invites?.results.map((user) => (
            <TableRow key={user.id} className="table table-fixed border-solid border-card-border">
              <TableCell>{user.email}</TableCell>
              <TableCell className="w-16 text-right md:w-24">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button size="icon" variant="secondary">
                      <EllipsisVertical className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="border-solid border-card-border">
                    {/* preventDefault stops the dropdown unmounting and unmounting the dialog */}
                    <DropdownMenuItem onClick={(e) => e.preventDefault()}>
                      <RemoveInviteDialog user={user} />
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="flex flex-row justify-end gap-2">
        <Button
          disabled={!invites?.previous}
          onClick={() => setPage((prev) => prev - 1)}
          size="icon"
          variant="secondary"
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        <Button
          disabled={!invites?.next}
          onClick={() => setPage((prev) => prev + 1)}
          size="icon"
          variant="secondary"
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};
