import React from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import { Icon } from 'ui';
import StatusBadgeWithCircleIcon from 'modules/threads/components/badge/StatusBadgeWithCircleIcon';

import { AVOID_SORTING_OPTIONS } from 'constants/sorting';
import { EDefaultSortOption } from 'modules/threads/types';
import { EThreadParams, EThreadStatuses } from 'types/index';

type TName = 'name' | 'status' | 'description' | 'group' | 'tags' | 'dropdownMenu';

interface ITableRow {
  name?: TName;
  disabled?: boolean;
}

enum EThead {
  NAME = 'name',
  STATUS = 'status',
  GROUP = 'group',
  DESCRIPTION = 'description',
  TAGS = 'tags',
  IS_PRIVATE = 'isPrivate',
  UPDATED_AT = 'updatedAt',
}

const WIDTH_MAP = {
  [EThead.STATUS]: '60px',
  [EThead.NAME]: '100%',
  [EThead.GROUP]: '100%',
  [EThead.DESCRIPTION]: '100%',
  [EThead.TAGS]: '100%',
  [EThead.IS_PRIVATE]: '60px',
  [EThead.UPDATED_AT]: '60%',
  dropdownMenu: '50%',
};

export const Th = styled.th<ITableRow>`
  position: relative;
  padding: 16px 16px 16px 5px;
  width: ${({ name }) => (name ? WIDTH_MAP[name as EThead] : 'auto')};
  min-width: 20px;
  ${({ disabled }) => disabled && `pointer-events: none;`};

  & > div {
    display: inline-table;
    vertical-align: middle;
    margin-top: -2px;
  }

  &:last-child {
    padding-right: 5px;
    text-align: right;
  }

  button {
    display: flex;
    font-size: 12px;
    color: ${colors.gray};
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
`;

interface IRows {
  rows: Array<EThreadParams | string>;
  onSort: (val: EThreadParams | string) => React.MouseEventHandler | void;
  cypressAttribute?: string;
}

enum EActivityLabel {
  LAST_ACTIVITY = 'last activity',
}

const THead: React.FC<IRows> = ({ rows, onSort, cypressAttribute }) => {
  const formattedRows = [...rows, 'dropdownMenu'];

  const formatUtilityColumnLabel = (item: EThreadParams) => {
    if (item === EThreadParams.IS_PRIVATE) {
      return '';
    }

    if (item === EThreadParams.UPDATED_AT) {
      return EActivityLabel.LAST_ACTIVITY;
    }
    return item;
  };

  return (
    <thead data-cy={cypressAttribute}>
      <tr>
        {formattedRows.map((item: EThreadParams | string) => {
          const avoidSort = AVOID_SORTING_OPTIONS.includes(item as EThreadParams);
          const sortOption =
            item === EThreadParams.UPDATED_AT ? EDefaultSortOption.UPDATED_AT : item;

          if (item === EThreadParams.STATUS) {
            return (
              <Th
                role="button"
                onClick={() => onSort(sortOption)}
                key={item}
                disabled={avoidSort}
                name={item}
              >
                <StatusBadgeWithCircleIcon
                  radius={String(8)}
                  text={false}
                  status={EThreadStatuses.ON_TARGET}
                />
                {!avoidSort && <Icon size="small" icon="SortingChevronIcon" />}
              </Th>
            );
          }

          if (item === 'dropdownMenu') {
            return <Th key={item} disabled={avoidSort} name={item} />;
          }

          return (
            <Th key={item} disabled={avoidSort} name={item as TName}>
              <button className="flex items-center gap-2" onClick={() => onSort(sortOption)}>
                {formatUtilityColumnLabel(item as EThreadParams)}
                {!avoidSort && <Icon size="small" icon="SortingChevronIcon" />}
              </button>
            </Th>
          );
        })}
      </tr>
    </thead>
  );
};

export default THead;
