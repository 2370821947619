import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectAppState, setModalState } from 'modules/app/appSlice';
import { appModals, modals } from '../modals';

/** @description Component to display modals accessible across the application. */
const GlobalModalContainer = () => {
  const dispatch = useAppDispatch();

  const appState = useAppSelector(selectAppState);
  const { modalState } = appState;

  const handleClose = () => dispatch(setModalState({ ...modalState, show: false, context: null }));

  const ModalComponent = modals[modalState.modal as keyof typeof appModals];

  return <ModalComponent isOpen={modalState.show} onRequestClose={handleClose} />;
};

export default GlobalModalContainer;
