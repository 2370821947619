import { GoogleOAuthProvider } from '@react-oauth/google';
import routes from 'constants/routes';
import { useFeatureFlags } from 'hooks/useFeatures';
import WithHeader from 'layout/WithHeader';
import GlobalModalContainer from 'modules/modals/components/GlobalModalContainer';
import { usePlayablePing } from 'modules/playable';
import ThreadsBot from 'modules/threadsBot/components';
import About from 'pages/about';
import Account from 'pages/account';
import Dashboard from 'pages/dashboard';
import Members from 'pages/members';
import { Settings } from 'pages/settings';
import SubscriptionsPlans from 'pages/subscriptionsPlans';
import Thread from 'pages/thread';
import usePushNotifications from 'hooks/usePushNotifications';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import NotFound from 'pages/NotFound';
import { UserWalkthroughProvider } from 'modules/userWalkthrough/UserWalkthroughProvider';
import { TermsOfServiceProvider } from 'modules/termsOfService/provider';
import { ENV } from './constants/env';
import ModalProvider from './modules/modals/ModalProvider';
import Stream from './modules/stream/Stream';

export const AuthenticatedRoutes: React.FC = () => {
  const { features } = useFeatureFlags();

  usePushNotifications();
  usePlayablePing();
  return (
    <UserWalkthroughProvider>
      <TermsOfServiceProvider>
        <GoogleOAuthProvider clientId={ENV.REACT_APP_GOOGLE_CLIENT_ID as string}>
          <ModalProvider>
            {features.copilot && <ThreadsBot />}
            <GlobalModalContainer />

            <WithHeader>
              <Stream>
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  {features['ui-library'] && <Route path="/settings" element={<Settings />} />}
                  <Route path={routes.account} element={<Account />} />
                  <Route path={routes.members} element={<Members />} />
                  <Route path={`${routes.thread}/:id`} element={<Thread />} />
                  <Route path={`${routes.thread}/:id/messages/:msgId`} element={<Thread />} />
                  <Route path={`${routes.thread}/:id/event/:eventId`} element={<Thread />} />
                  <Route path={`${routes.new_thread}`} element={<Dashboard />} />
                  <Route
                    path={`${routes.subscriptionsPlans}/dashboard`}
                    element={<SubscriptionsPlans />}
                  />
                  <Route path={`${routes.about}`} element={<About />} />
                  <Route path="*" element={<NotFound />} />
                  <Route path="/login" element={<Navigate to="/" />} />
                  <Route path="/sign-up" element={<Navigate to="/" />} />
                  <Route
                    path="/subscriptions-plans/auth"
                    element={<Navigate to="/subscriptions-plans/dashboard" />}
                  />
                </Routes>
              </Stream>
            </WithHeader>
          </ModalProvider>
        </GoogleOAuthProvider>
      </TermsOfServiceProvider>
    </UserWalkthroughProvider>
  );
};
