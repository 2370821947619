import { useAppSelector } from 'app/hooks';
import AccessControl from 'app/permissions/AccessControl';
import { EPermissions } from 'app/permissions/constants';
import { useOnClickOutside } from 'hooks';
import { useWindowSize } from 'hooks/useWindowSize';
import { selectThread } from 'modules/threads/threadsSlice';
import { AddTagsModal } from 'pages/thread/modals';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'theme/colors';
import devices, { mediaSize } from 'theme/devices';
import { AddButton, Group, Stack, Tags, Text } from 'ui';
import TextButton from 'ui/textButton';
import { truncateRawString } from 'utils/helpers';
import ChatFilter from './chatFilter';

const AdaptiveWrapper = styled(Stack)`
  position: relative;
  z-index: 10;
  @media screen and ${devices.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const MobileAdaptive = styled(Stack)`
  gap: 15px;
  @media screen and ${devices.mobileL} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

interface ITitleProps {
  isArchived: boolean;
  cypressAttribute?: string;
}

const ThreadTitle: React.FC<ITitleProps> = ({ isArchived, cypressAttribute }) => {
  const [showAddTagsModal, setShowAddTagsModal] = useState<boolean>(false);
  const [unsavedTags, setUnsavedTags] = useState<string[] | []>([]);
  const [showFullDescription, setShowFullDescription] = useState<boolean>(false);
  const { id: threadId } = useParams();
  const { width } = useWindowSize();
  const { tags, description } = useAppSelector(selectThread(threadId as string));
  const isDescriptionTruncated = description?.length > 150;

  const addTagsModalRef = useRef<HTMLElement>(null);
  useOnClickOutside(addTagsModalRef, () => setShowAddTagsModal(false));

  const toggleTagsModal = () => {
    setShowAddTagsModal((prev) => !prev);
  };

  return (
    <Stack gap="10px" style={{ position: 'relative', zIndex: 10, paddingTop: 5 }}>
      <Text color={colors.gray1} cypressAttribute="thread-description-container-text">
        {isDescriptionTruncated ? (
          <span data-cy="thread-description-full">
            {showFullDescription ? description : truncateRawString(description, 150)}
            <TextButton
              style={{ marginLeft: 5 }}
              onClick={() => setShowFullDescription((prev) => !prev)}
              cypressAttribute="thread-description-text-btn"
            >
              {showFullDescription ? 'hide full description' : 'show full description'}
            </TextButton>
          </span>
        ) : (
          <span data-cy="thread-description">{description}</span>
        )}
      </Text>
      <MobileAdaptive fluid>
        <ChatFilter />
        <Group align="center" data-cy={cypressAttribute && `${cypressAttribute}-tags-group`}>
          <Text>Tags:</Text>
          <Tags
            tags={tags}
            offset={width > mediaSize.laptop ? 5 : 2}
            cypressAttribute="thread-tags"
          />
          {!isArchived && (
            <AccessControl
              permissions={[EPermissions.ADD_TAG_FROM_THREAD_PAGE]}
              threadId={threadId as string}
            >
              <AddButton onClick={toggleTagsModal} cypressAttribute="toggle-tags-modal-btn" />
            </AccessControl>
          )}

          {showAddTagsModal && (
            <AddTagsModal
              ref={addTagsModalRef}
              forwardedState={{ unsavedTags, setUnsavedTags }}
              closeModal={() => setShowAddTagsModal(false)}
            />
          )}
        </Group>
      </MobileAdaptive>
    </Stack>
  );
};

export default ThreadTitle;
