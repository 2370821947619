/* eslint-disable no-underscore-dangle */
export const PRODUCTION = 'production';
export const DEVELOPMENT = 'development';

window._env_ = window._env_ || process.env;

// TODO: Add zod validation
export const ENV = {
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_API_URL: window._env_.REACT_APP_API_URL,
  REACT_APP_API_URL_IMAGES: window._env_.REACT_APP_API_URL_IMAGES,
  REACT_APP_ENVIRONMENT: window._env_.REACT_APP_ENVIRONMENT,
  REACT_APP_WEBSOCKET_URL: window._env_.REACT_APP_WEBSOCKET_URL,
  REACT_APP_GOOGLE_CLIENT_ID: window._env_.REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_FIREBASE_API_KEY: window._env_.REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN: window._env_.REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID: window._env_.REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET: window._env_.REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: window._env_.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID: window._env_.REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID: window._env_.REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_VAPID_KEY: window._env_.REACT_APP_FIREBASE_VAPID_KEY,
  REACT_APP_KEYCLOAK_CLIENT_ID: window._env_.REACT_APP_KEYCLOAK_CLIENT_ID,
  REACT_APP_KEYCLOAK_BASE_URL: window._env_.REACT_APP_KEYCLOAK_BASE_URL,
  REACT_APP_KEYCLOAK_REALM: window._env_.REACT_APP_KEYCLOAK_REALM,
  REACT_APP_SENTRY_DSN: window._env_.REACT_APP_SENTRY_DSN,
  REACT_APP_MICROSOFT_CLARITY_APP_KEY: window._env_.REACT_APP_MICROSOFT_CLARITY_APP_KEY,
} as const;
