import { useAppSelector } from 'app/hooks';
import { Button } from 'components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table';
import { ChevronLeft, ChevronRight, EllipsisVertical } from 'lucide-react';
import { selectUserInfo } from 'modules/auth/userSlice';
import { useGetUsersQuery } from 'modules/user/userApi';
import { useWorkspaceQuery } from 'modules/workspace/workspaceApi';
import { QUERY_PARAMS } from 'pages/members/constants';
import { ChangeRoleDialog } from 'pages/settings/tabs/workspace/dialogs/changeRole';
import { RemoveMemberDialog } from 'pages/settings/tabs/workspace/dialogs/removeMember';
import React, { useState } from 'react';
import { EUserRole } from 'types/user';

export const ROLE_NAME_LOOKUP: Record<EUserRole, string> = {
  [EUserRole.ADMIN]: 'Admin',
  [EUserRole.EXTERNAL_USER]: 'Guest',
  [EUserRole.USER]: 'User',
} as const;

export const WorkspaceMembersTable: React.FC = () => {
  const [page, setPage] = useState(0);
  const { data: users } = useGetUsersQuery({
    limit: QUERY_PARAMS.USERS_LIMIT,
    offset: QUERY_PARAMS.USERS_LIMIT * page,
  });
  const { id: userId } = useAppSelector(selectUserInfo);

  const { data: workspace } = useWorkspaceQuery();

  if (users?.results.length === 1) {
    // Only the owner
    return (
      <div className="flex w-full justify-center">
        <span className="text-sm text-neutral-500">No other members</span>
      </div>
    );
  }

  return (
    <div className="space-y-2">
      <Table className="rounded border border-solid border-card-border">
        <TableHeader className="block">
          <TableRow className="table border-solid border-card-border">
            <TableHead>Email</TableHead>
            <TableHead className="w-16 md:w-24">Role</TableHead>
            <TableHead className="w-16 text-right md:w-24">Action</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody className="block max-h-96 w-full overflow-auto">
          {users?.results
            .filter((user) => user.id !== userId)
            .map((user) => (
              <TableRow key={user.id} className="table table-fixed border-solid border-card-border">
                <TableCell className="overflow-hidden text-ellipsis">{user.email}</TableCell>
                <TableCell className="w-16 md:w-24">
                  {user.id === workspace?.owner ? 'Owner' : ROLE_NAME_LOOKUP[user.userRole]}
                </TableCell>
                <TableCell className="w-16 text-right md:w-24">
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        disabled={user.id === workspace?.owner}
                        size="icon"
                        variant="secondary"
                      >
                        <EllipsisVertical className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="border-solid border-card-border">
                      {/* preventDefault stops the dropdown unmounting and unmounting the dialog */}
                      <DropdownMenuItem onClick={(e) => e.preventDefault()}>
                        <ChangeRoleDialog user={user} />
                      </DropdownMenuItem>
                      {/* preventDefault stops the dropdown unmounting and unmounting the dialog */}
                      <DropdownMenuItem onClick={(e) => e.preventDefault()}>
                        <RemoveMemberDialog user={user} />
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <div className="flex flex-row justify-end gap-2">
        <Button
          disabled={!users?.previous}
          onClick={() => setPage((prev) => prev - 1)}
          size="icon"
          variant="secondary"
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        <Button
          disabled={!users?.next}
          onClick={() => setPage((prev) => prev + 1)}
          size="icon"
          variant="secondary"
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};
