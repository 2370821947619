import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog';
import { showSuccessMessage, showUncaughtErrorMessage } from 'modules/alert/utils';
import { useDeleteUserMutation } from 'modules/user/userApi';
import React from 'react';
import { IUser } from 'types/user';

export const RemoveMemberDialog: React.FC<{ user: IUser }> = ({ user }) => {
  const [removeMember] = useDeleteUserMutation();

  const handleRemoveMember = async () => {
    try {
      await removeMember(user.id).unwrap();
      showSuccessMessage('User removed from workspace');
    } catch (error) {
      showUncaughtErrorMessage(error);
    }
  };
  return (
    <AlertDialog>
      <AlertDialogTrigger className="w-full text-start">Remove</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Remove {user.firstName} {user.lastName} from workspace?
          </AlertDialogTitle>
          <AlertDialogDescription>This will delete the user&apos;s account</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handleRemoveMember}>Remove</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
