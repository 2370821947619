import { api } from 'app/api';
import {
  ISubscriptionPlansResponse,
  IUpdateSubscriptionPlanMutation,
  IUpdateSubscriptionPlanResponse,
} from './types';

export const subscriptionsPlansApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSubscriptionsPlans: build.query<ISubscriptionPlansResponse[], void>({
      query: () => ({
        url: `payments/subscriptions-plans/`,
      }),
      keepUnusedDataFor: 1,
      providesTags: ['subscriptionsPlans'],
    }),
    getStripeDashboardLink: build.query<{ redirectUrl: string | null }, void>({
      query: () => ({
        url: `payments/subscriptions/customer-portal/`,
      }),
    }),
    updateSubscriptionPlan: build.mutation<
      IUpdateSubscriptionPlanResponse,
      IUpdateSubscriptionPlanMutation
    >({
      query: (body) => ({
        url: `payments/subscriptions/update/`,
        method: 'PATCH',
        body,
      }),
      // invalidatesTags: ['subscriptionsPlans'],
    }),
    getSubscriptionRenewalLink: build.query<{ redirectUrl: string | null }, void>({
      query: () => ({
        url: `payments/subscriptions/renewal-link/`,
      }),
    }),
  }),
});

export const {
  useGetSubscriptionsPlansQuery,
  useUpdateSubscriptionPlanMutation,
  useLazyGetStripeDashboardLinkQuery,
  useGetStripeDashboardLinkQuery,
  useLazyGetSubscriptionRenewalLinkQuery,
} = subscriptionsPlansApi;
