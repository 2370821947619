import { useAppSelector } from 'app/hooks';
import _has from 'lodash/has';
import React from 'react';
import { useForm } from 'react-hook-form';
import colors from 'theme/colors';
import { IError } from 'types';
import { IUser } from 'types/user';
import { Button, Group, Modal, Stack, TextArea } from 'ui';
import { selectAppModalState } from '../appSlice';

interface IProps {
  onRequestClose: () => void;
  isOpen: boolean;
}

/**
 * Modal to allow users to report uncaught errors to our team.
 */
const ReportErrorModal: React.FC<IProps> = ({ onRequestClose, isOpen }) => {
  const modalState = useAppSelector(selectAppModalState);

  const { handleSubmit, register } = useForm<{
    userMessage: string;
  }>();

  type TContext = {
    errorData: unknown | null;
    userInfo: IUser;
    timestamp: Date;
  };

  const { errorData, userInfo, timestamp } = (modalState.context as TContext) || {};

  const extractErrorDetails = (showErrorStack = false) => {
    let result = typeof errorData === 'string' ? JSON.stringify(errorData) : '';

    if (_has(errorData, 'error')) {
      result = JSON.stringify((errorData as IError)?.error);
    }

    if (_has(errorData, 'message')) {
      result = (errorData as Error)?.message;
    }

    if (showErrorStack && _has(errorData, 'stack')) {
      result += `\n${(errorData as Error)?.stack}`;
    }

    return result;
  };

  const handleSubmitReportError = async (payload: { userMessage: string }) => {
    const email = 'support@authentise.zendesk.com';
    const subject = `Threads - Reported Error`;
    const body = `
    User ID: ${userInfo.id}
    User Email: ${userInfo.email}
    User agent: ${window.navigator.userAgent.toString()}
    Message: ${payload.userMessage}
    Timestamp: ${timestamp.toISOString()}

    Error message: ${extractErrorDetails(true)}
    `;

    window.location.href = `mailto:"${email}"?subject="${subject}"&body="${encodeURIComponent(
      body
    )}`;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      variant="center"
      contentLabel="reportError"
      name="Report Error"
    >
      <form onSubmit={handleSubmit(handleSubmitReportError)}>
        <Group margin="0 0 20px 0" gap="15px">
          We appreciate your dedication to improving Threads. If you have encountered an error or
          issue, please take a moment to report it to our team. Your feedback helps us enhance the
          user experience and ensure the smooth functioning of our software.
        </Group>

        <Stack gap="15px" margin="20px 0">
          <span>
            <strong>Error</strong>: &ldquo;
            {!!errorData && (
              <code>
                {modalState.context ? extractErrorDetails() : 'Error could not be shown.'}
              </code>
            )}
            &rdquo;
          </span>

          <span>
            <strong>Browser Details</strong>: {window.navigator.userAgent.toString()}
          </span>

          <div>
            <strong>Message</strong>:
            <TextArea
              register={register}
              resize="vertical"
              style={{ marginTop: 5 }}
              name="userMessage"
              placeholder="Additional details or steps to reproduce..."
            />
          </div>
        </Stack>

        <Group gap="15px" justify="end">
          <Button onClick={onRequestClose} color={colors.red}>
            Close
          </Button>
          <Button type="submit">Submit</Button>
        </Group>
      </form>
    </Modal>
  );
};

export default ReportErrorModal;
