import { INTEGRATION_COLORS } from 'constants/integrations';
import { showErrorMessage } from 'modules/alert/utils';
import { EModelStatus } from 'modules/threads/types';
import { useThreads3dAccessLinkMutation } from 'modules/threads3d/api';
import React from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import { Button, Icon, Spinner, Text } from 'ui';
import Tooltip from 'components/ui/tooltip';

const LinkWrapper = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  padding: 0.25rem;
  background-color: ${INTEGRATION_COLORS.threads3d}};
  border-radius: 0.25rem;
`;

const Threads3dLogo = styled.div`
  background: ${colors.dark1};
  padding: 0.25rem;
  border-radius: 0.25rem;
`;

interface Props {
  referenceId: number;
  status: EModelStatus | null;
}

export const ModelLink: React.FC<Props> = ({ status, referenceId }) => {
  const [retrieveModelLink, { isLoading }] = useThreads3dAccessLinkMutation();

  const navigateToModel = async () => {
    try {
      const res = await retrieveModelLink({ referenceId }).unwrap();
      if (res.location) {
        window.open(res.location, '_blank');
      } else {
        showErrorMessage('Failed to generate Threads3D link');
      }
    } catch {
      showErrorMessage('Failed to generate Threads3D link');
    }
  };

  if (!status || status === EModelStatus.ERROR) {
    return (
      <Tooltip content={<Text size="sm">Failed to process model</Text>} side="left">
        <LinkWrapper>
          <Threads3dLogo>
            <Icon icon="Threads3dIcon" size="normal" />
          </Threads3dLogo>
          <Icon icon="InfoIcon" size="medium" stroke="white" />
        </LinkWrapper>
      </Tooltip>
    );
  }

  if (status !== EModelStatus.PROCESSED) {
    return (
      <Tooltip content={<Text size="sm">Processing model...</Text>} side="left">
        <LinkWrapper>
          <Threads3dLogo>
            <Icon icon="Threads3dIcon" size="normal" />
          </Threads3dLogo>
          <div style={{ padding: '0.2rem' }}>
            <Spinner size="small" color="white" width="20" strokeWidth="3" />
          </div>
        </LinkWrapper>
      </Tooltip>
    );
  }

  return (
    <LinkWrapper onClick={navigateToModel} disabled={isLoading}>
      <Threads3dLogo>
        <Icon icon="Threads3dIcon" size="normal" />
      </Threads3dLogo>
      <Icon icon="ArrowRightIcon" size="medium" />
    </LinkWrapper>
  );
};
