import AccessControl from 'app/permissions/AccessControl';
import { EPermissions } from 'app/permissions/constants';
import Tooltip from 'components/ui/tooltip';
// import { BaseTooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip';
import { useModal } from 'modules/modals/ModalProvider';
import { Card } from 'modules/relatedThreads/components/Layout';
import { IRelatedThread } from 'modules/relatedThreads/types';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import colors from 'theme/colors';
import { DropDownAction, Group, Icon, Stack, StatusBadge, Text } from 'ui';
import { getFullName } from 'utils/helpers';

const ThreadCard: React.FC<IRelatedThread> = ({ thread, creator }) => {
  const { open } = useModal();
  const { id: threadId } = useParams();

  const captionText = thread.description.length
    ? thread.description
    : `Created by ${getFullName(thread.creator)}`;

  const handleRemove = () => {
    open({
      variant: 'center',
      contentLabel: 'remove related thread',
      name: 'Remove Related Thread',
      modal: 'deleteRelatedThread',
      context: {
        relatedThreadId: thread.id,
        threadId,
      },
      id: 'modal-delete-related-thread',
    });
  };

  const actions = [
    {
      id: 'delete',
      title: 'Delete',
      action: handleRemove,
      icon: <Icon size="small" icon="BinIcon" />,
      permissions: [EPermissions.DELETE_RELATED_THREADS],
    },
  ];

  return (
    <Card data-cy="thread-card">
      <Group gap="5px" align="center">
        <Stack fluid gap="16px">
          <Link to={`/thread/${thread.id}`} data-cy="thread-card-link">
            <Stack gap="0px">
              <Group align="center">
                <Text weight="500" size="md" className="flex" color={colors.blue7}>
                  {thread.name}
                </Text>
              </Group>
              <Text
                size="sm"
                color={colors.gray}
                style={{
                  fontStyle: 'italic',
                  overflow: 'hidden',
                  maxHeight: '20px',
                }}
              >
                {captionText}
              </Text>
            </Stack>
          </Link>
        </Stack>
        <Group align="center">
          <Tooltip
            delayDuration={0}
            content={<span>Relation added by {getFullName(creator)}</span>}
            tooltipContentClassName="max-w-80"
          >
            <Text size="xs" color={colors.gray1}>
              <Icon className="mr-1" size="small" icon="InfoIcon" stroke={colors.gray} />
            </Text>
          </Tooltip>
          <StatusBadge status={thread.status} />
          <AccessControl
            permissions={[EPermissions.DELETE_RELATED_THREADS]}
            threadId={threadId as string}
          >
            <DropDownAction
              position="vertical"
              actions={actions}
              cypressAttribute="thread-card-dropdown-actions"
            />
          </AccessControl>
        </Group>
      </Group>
    </Card>
  );
};

export default ThreadCard;
