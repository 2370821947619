import { useAppSelector } from 'app/hooks';
import PlyableAnnotations from 'modules/PlyableAnnotations/components/PlyableAnnotations';
import { Threads3dAnnotations } from 'modules/annotations/components/Threads3dAnnotations';
import { selectFilter } from 'modules/chat/chatSlice';
import { EChatFilter } from 'modules/chat/types';
import React, { useCallback } from 'react';

interface IProps {
  annotationType: EChatFilter;
}

export const Annotations: React.FC<IProps> = ({ annotationType }) => {
  const chatFilter = useAppSelector(selectFilter);

  const renderAnnotations = useCallback(() => {
    switch (chatFilter) {
      case EChatFilter.PLAYABLE_EVENT:
        return <PlyableAnnotations />;
      case EChatFilter.THREADS_3D_EVENT:
        return <Threads3dAnnotations />;

      default:
        return null;
    }
  }, [chatFilter]);

  return renderAnnotations();
};
