import { fixedBottomRightStyle } from 'modules/threadsBot/constants';
import React from 'react';
import Card from 'ui/card';
import Logo from 'ui/logo';

interface Props {
  toggle: () => void;
}

/**
 * @description Floating action card for collapsed ThreadsBot, clicking will
 * expand the full UI.
 */
const ThreadsBotCollapsedFloatingCard: React.FC<Props> = ({ toggle }) => {
  return (
    <Card
      cursorPointer
      borderRadius="20px 25px"
      width="fit-content"
      style={fixedBottomRightStyle}
      onClick={toggle}
      padding="15px 20px"
      height="fit-content"
    >
      <Logo />
    </Card>
  );
};

export default ThreadsBotCollapsedFloatingCard;
