import { IntegrationMessageReplies } from 'modules/chat/components/integrations/IntegrationMessageReplies';
import { MessageContainer } from 'modules/chat/components/integrations/MessageContainer';
import { IMessageResponse } from 'modules/chat/types';
import React from 'react';

interface Props {
  message: IMessageResponse;
  renderMessage: (message: IMessageResponse) => React.ReactNode;
}

export const IntegrationMessage: React.FC<Props> = ({ message, renderMessage }) => {
  return (
    <>
      <MessageContainer hasReplies={message.replies.length > 0} creator={message.creator}>
        {renderMessage(message)}
      </MessageContainer>
      <IntegrationMessageReplies messages={message.replies} renderReply={renderMessage} />
    </>
  );
};
