import styled from 'styled-components';
import colors from 'theme/colors';

interface ITabProps {
  active?: boolean;
  height?: string;
}

export const Tabs = styled.div`
  position: relative;
  height: 100%;
`;

export const TabsList = styled.ul`
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  list-style-type: none;

  &:before {
    content: '';
    height: 1px;
    width: 100%;
    background-color: ${colors.blue2};
    position: absolute;
    bottom: -17px;
    border-radius: 8px;
  }
`;

export const TabNavItem = styled.li<ITabProps>`
  position: relative;
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 18px;
  color: ${colors.dark4};
  transition: 0.4s ease-in-out;

  &:hover {
    color: ${colors.orange};
  }

  &:after {
    content: '';
    height: 2px;
    width: 100%;
    background-color: ${colors.orange};
    position: absolute;
    bottom: -17px;
    border-radius: 8px;
    opacity: 0;
    visibility: hidden;
    transition:
      visibility 0s,
      opacity 0.4s ease-in-out;
  }

  button {
    padding: 0 12px;
  }

  ${({ active }: ITabProps) =>
    active &&
    `
      color: ${colors.orange};
      &:after {
        opacity: 1;
        visibility: visible;
        transition: visibility 0s, opacity 0.4s ease-in-out;
      }`}
`;

export const TabContent = styled.div<ITabProps>`
  position: relative;
  margin: 25px 0;
  padding-top: 5px;
  height: ${({ height }: ITabProps) => height};
`;
