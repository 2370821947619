import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog';
import { showSuccessMessage, showUncaughtErrorMessage } from 'modules/alert/utils';
import { useDeleteMemberMutation } from 'modules/inviteUsers/inviteUsersApi';
import { IUserInvites } from 'modules/inviteUsers/types';
import React from 'react';

export const RemoveInviteDialog: React.FC<{ user: IUserInvites }> = ({ user }) => {
  const [removeInvite] = useDeleteMemberMutation();

  const handleDeleteInvite = async () => {
    try {
      await removeInvite(user.id).unwrap();
      showSuccessMessage('Invite deleted');
    } catch (error) {
      showUncaughtErrorMessage(error);
    }
  };
  return (
    <AlertDialog>
      <AlertDialogTrigger>Delete</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete invite for {user.email}?</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handleDeleteInvite}>Delete</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
