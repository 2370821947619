import { api } from 'app/api';
import type { IPlayableAnnotationRaw } from 'modules/playable/types';
import type { IId, IPaginatedResponse } from 'types';

export const plyableAnnotationApi = api.injectEndpoints({
  endpoints: (build) => ({
    plyableAnnotations: build.query<
      IPaginatedResponse<IPlayableAnnotationRaw>,
      { id: string; limit: number; offset: number }
    >({
      query: ({ id, limit, offset }) => ({
        url: `/threads/${id}/plyable-comments/`,
        params: {
          limit,
          offset,
        },
      }),
      providesTags: ['plyableAnnotations'],
    }),
    plyableAnnotationById: build.query<IPlayableAnnotationRaw, IId>({
      query: ({ id }) => `/plyable/comments/${id}/`,
      providesTags: ['plyableAnnotations'],
    }),
  }),
});

export const { usePlyableAnnotationsQuery, useLazyPlyableAnnotationByIdQuery } =
  plyableAnnotationApi;
