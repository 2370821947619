import { createAction, Dispatch } from '@reduxjs/toolkit';
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  retry,
} from '@reduxjs/toolkit/query/react';
import { FETCH_ERRORS } from 'modules/threads/constants';
import { handleApiCall } from 'utils/helpers';
import { deepCamelcaseKeys, deepUnderscoreKeys } from 'utils/mappers';
import { ENV } from '../constants/env';
import { IError } from '../types';
import { RootState } from './store';

export const resetAll = createAction('reset/all');

const handleUnauthorizedRequest =
  ({ error }: IError) =>
  (dispatch: Dispatch) => {
    // @ts-ignore
    if (error.status === 401 && error.data?.detail === FETCH_ERRORS.INVALID_TOKEN) {
      dispatch(resetAll());
    }
  };

const BASE_API = ENV.REACT_APP_API_URL || '';
const EXCLUDE_ENDPOINTS = ['getStatistic'];

const getCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()!.split(';').shift();
  return undefined;
};
const baseQuery = fetchBaseQuery({
  baseUrl: BASE_API,
  prepareHeaders: (headers, { getState }) => {
    const {
      userInfo: { siteId },
    } = (getState() as RootState).user;

    headers.set('site-id', siteId);
    const csrf = getCookie('csrftoken');
    if (csrf) headers.set('X-CSRFToken', csrf);
    return headers;
  },
  credentials: 'include',
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });
const baseQueryWithCamelize: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions = {}
) => {
  const result = await baseQueryWithRetry(deepUnderscoreKeys(args), api, extraOptions);
  handleApiCall(result, (error) => api.dispatch(handleUnauthorizedRequest(error)));
  if (result.data && !EXCLUDE_ENDPOINTS.includes(api.endpoint)) {
    result.data = deepCamelcaseKeys(result.data);
  }
  return result;
};
export const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: 'splitApi',
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseQueryWithCamelize,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [
    'threadList',
    'user',
    'users',
    'chatMessages',
    'userInvites',
    'contributorsList',
    'contributorsInvites',
    'events',
    'resources',
    'relatedThreads',
    'searchRelatedThreads',
    'user-info',
    'user-groups',
    'statistic',
    'notifications',
    'threadGantt',
    'user-notification-settings',
    'currentSiteSubscription',
    'userNonActiveIntegrations',
    'subscriptionsPlans',
    'userAvailableIntegrations',
    'playablePing',
    'plyableAnnotations',
    'joinRequestsList',
    'threads3dAnnotations',
    'references',
    'copilotSuggestions',
    'workspace',
    'threadViewability',
  ],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
});
