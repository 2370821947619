import { useAppSelector } from 'app/hooks';
import useSubscriptionPermissions from 'hooks/useSubscriptionPermissions';
import _difference from 'lodash/difference';
import _isEqual from 'lodash/isEqual';
import _uniq from 'lodash/uniq';
import { NOTIFICATION } from 'modules/alert/constants';
import { showSuccessMessage } from 'modules/alert/utils';
import { useGetThreadTagsQuery, useUpdateThreadMutation } from 'modules/threads/threadsApi';
import { selectThread } from 'modules/threads/threadsSlice';
import { ICreateThreadMutation } from 'modules/threads/types';
import * as Layout from 'pages/thread/components/Layout';
import React, { MutableRefObject, forwardRef, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { EThreadParams, IModalDefaultProps, TReactSelectOption } from 'types';
import { Button, ReactSelect } from 'ui';
import { createOption } from 'utils/helpers';
import { THREAD_TAG_MAX_LENGTH } from 'constants/index';

type TAddTagsModalForwardedState = {
  unsavedTags: string[] | [];
  setUnsavedTags: React.Dispatch<React.SetStateAction<[] | string[]>>;
};

export const AddTagsModal = forwardRef<
  HTMLElement,
  IModalDefaultProps<TAddTagsModalForwardedState>
>(({ closeModal, forwardedState }, forwardedRef) => {
  const { unsavedTags, setUnsavedTags } = forwardedState || {};

  const { id } = useParams();
  const { tags, name } = useAppSelector(selectThread(id as string));
  const { data: tagOptions, isLoading: isTagsFetching } = useGetThreadTagsQuery('');
  const [updateThread] = useUpdateThreadMutation();

  const { handleSubscriptionPermissionErrors } = useSubscriptionPermissions();

  const { handleSubmit, setValue, getValues } = useForm<ICreateThreadMutation>({
    defaultValues: { name, tags },
    mode: 'onSubmit',
    shouldFocusError: false,
  });

  const threadTagOptions = useMemo(() => {
    return tagOptions?.map((tag: string) => createOption(tag)) || [];
  }, [isTagsFetching]);

  const threadTags = useMemo(() => {
    if (tags?.length) {
      const mappableData = [...tags, ...(unsavedTags ?? [])];
      return mappableData.map((tag: string) => createOption(tag)) || [];
    }
    return [];
  }, [tags]);

  // Combination of the current state array, and the array of unsaved tags.
  const currentAndUnsavedTags = useMemo(
    () => _uniq([...(getValues().tags ?? []), ...(unsavedTags ?? [])]),
    [tags, unsavedTags]
  );

  const onSubmit = async (payload: ICreateThreadMutation) => {
    try {
      if (setUnsavedTags) {
        setUnsavedTags([]);
      }

      await updateThread({ id: id as string, ...payload }).unwrap();
      showSuccessMessage(NOTIFICATION.ADD_TAGS);
      closeModal();
    } catch (error) {
      handleSubscriptionPermissionErrors(
        error,
        'An error occurred while updating tags. Please try again.'
      );
    }
  };

  const handleChange = (options: TReactSelectOption[]) => {
    const newTags = options?.map((option: TReactSelectOption) => option.value);
    setValue(EThreadParams.TAGS, newTags);

    if (setUnsavedTags && tags) {
      setUnsavedTags(_difference(newTags, tags));
    }
  };

  return (
    <Layout.AddTagsModal
      ref={forwardedRef as MutableRefObject<HTMLFormElement>}
      onSubmit={handleSubmit(onSubmit)}
      data-cy="modal-add-tags"
    >
      <div style={{ flexGrow: 1, maxWidth: 'calc(100% - 80px)' }}>
        <ReactSelect
          placeholder="Add Tags"
          isSearchable
          defaultValue={threadTags}
          options={threadTagOptions}
          onChange={handleChange}
          id="add-tags-dropdown"
          menuPortalTarget={null}
          isValidNewOption={(inputValue) =>
            !!inputValue && inputValue?.length < THREAD_TAG_MAX_LENGTH
          }
        />
      </div>
      <Button disabled={_isEqual(tags, currentAndUnsavedTags)} type="submit">
        Save
      </Button>
    </Layout.AddTagsModal>
  );
});

export default AddTagsModal;
