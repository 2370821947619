import React from 'react';
import AccessControl from 'app/permissions/AccessControl';
import { useParams } from 'react-router-dom';
import { useModal } from 'modules/modals/ModalProvider';
import { Avatar, Group, Stack, Text, Button, Icon } from 'ui';
import { IContributorInvite } from 'modules/contributors/types';
import { EPermissions } from 'app/permissions/constants';
import * as Layout from './Layout';

const ContributorInviteCard: React.FC<IContributorInvite> = (props) => {
  const { email } = props;

  const { id: threadId } = useParams();
  const { open } = useModal();

  const handleRemove = () => {
    open({
      variant: 'center',
      contentLabel: 'delete invite',
      name: 'Delete Invite',
      modal: 'deleteInvite',
      context: {
        invite: props,
      },
      id: 'modal-delete-invite',
    });
  };

  return (
    <Layout.Card align="center" justify="space-between" data-cy="contributor-invite-card">
      <Group gap="12px">
        <Avatar src={null} cypressAttribute="contributor-invite-card-avatar" />
        <Stack align="center" justify="center">
          <Text cypressAttribute="contributor-invite-card-email">{email}</Text>
        </Stack>
      </Group>
      <AccessControl permissions={[EPermissions.DELETE_CONTRIBUTORS]} threadId={threadId as string}>
        <Layout.RemoveButton
          variant="plain"
          onClick={handleRemove}
          data-cy="contributor-invite-card-remove-btn"
        >
          <Icon icon="RemoveUserIcon" data-cy="contributor-invite-card-remove-icon" />
        </Layout.RemoveButton>
      </AccessControl>
    </Layout.Card>
  );
};

export default ContributorInviteCard;
