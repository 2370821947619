import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { IHeading } from 'theme';

interface ITitleProps {
  id?: string;
  heading: keyof IHeading;
  weight?: '400' | '600' | '700';
  align?: CSSProperties['textAlign'];
  color?: CSSProperties['color'];
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  cypressAttribute?: string;
}

const StyledTitle = styled.h1<ITitleProps>`
  text-align: ${({ align }) => align || 'left'};
  color: ${({ color }) => color || 'white'};
  font-weight: ${({ weight }) => weight || '400'};
  ${({ heading, theme }) => theme.heading[heading]};
`;

const Title: FC<ITitleProps> = ({ id, children, cypressAttribute, ...props }) => {
  return (
    <StyledTitle id={id} data-cy={cypressAttribute} as={props.heading} {...props}>
      {children}
    </StyledTitle>
  );
};

export default Title;
