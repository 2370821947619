import { useAppSelector } from 'app/hooks';
import { EFeatureFlags, FEATURE_FLAGS } from 'constants/features';
import { useGetFeaturesQuery } from 'modules/app/featuresApi';
import { selectUserInfo } from 'modules/auth/userSlice';
import { useMemo } from 'react';

export const useFeatureFlags = () => {
  const userInfo = useAppSelector(selectUserInfo);
  // TODO: Site id should be initialised as part of the auth step. We shouldn't be doing this here.
  const { data: featureList } = useGetFeaturesQuery(
    { siteId: userInfo.siteId },
    { skip: !userInfo.siteId }
  );
  const features = useMemo(
    () =>
      FEATURE_FLAGS.reduce(
        (acc, curr) => {
          // Reduce list of all Feature Flags to object of whether they are enabled or not
          acc[curr] = featureList?.find((feature) => feature.name === curr)?.isEnabled ?? false;
          return acc;
        },
        {} as Record<EFeatureFlags, boolean>
      ),
    [featureList]
  );

  const isFeatureEnabled = (feature: EFeatureFlags) => features[feature];

  return { features, isFeatureEnabled };
};

/**
 * Shorthand for `isFeatureEnabled`
 */
export const useIsFeatureEnabled = (flag: EFeatureFlags): boolean => {
  const { isFeatureEnabled } = useFeatureFlags();
  return isFeatureEnabled(flag);
};
