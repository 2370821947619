import { useDebounce } from 'hooks';
import { NOTIFICATION } from 'modules/alert/constants';
import { showSuccessMessage, showUncaughtErrorMessage } from 'modules/alert/utils';
import { useModal } from 'modules/modals/ModalProvider';
import * as Layout from 'modules/relatedThreads/components/Layout';
import RelatedThread from 'modules/relatedThreads/components/RelatedThread';
import {
  useCreateRelatedThreadMutation,
  useSearchRelatedThreadsQuery,
} from 'modules/relatedThreads/relatedThreadsApi';
import { ICreateThreadMutation } from 'modules/relatedThreads/types';
import { useGetUserInfoQuery } from 'modules/user/userApi';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import colors from 'theme/colors';
import { Button, Divider, Group, Icon, Stack, TextInput, Title } from 'ui';
import { getThreadIdFromUrl, isValidUrl } from 'utils/helpers';

interface IProps {
  threadId: string;
}

interface FormState {
  threadIds: string[];
}

const PAGINATE_BY = 25;

const AddRelatedThread = ({ threadId }: IProps) => {
  const [search, setSearch] = useState<string>('');
  const [relatedThreadId, setRelatedThreadId] = useState<string>('');
  const [pagination, setPagination] = useState<number>(PAGINATE_BY);
  const { close } = useModal();
  const { data: user } = useGetUserInfoQuery();

  const deferredSearch = useDebounce(search, 500);

  const [createRelatedThread, { isLoading }] = useCreateRelatedThreadMutation();
  const { data } = useSearchRelatedThreadsQuery({
    id: threadId,
    name: deferredSearch,
    relatedThreadId,
    limit: pagination,
    offset: 0,
  });

  const results = data?.results ?? [];

  const onFetchMore = useCallback(() => {
    setPagination((prevState) => prevState + PAGINATE_BY);
  }, [setPagination]);

  const { handleSubmit, register, watch } = useForm<FormState>({
    defaultValues: {
      threadIds: [],
    },
    mode: 'onSubmit',
    shouldFocusError: false,
  });

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const isUrl = isValidUrl(value);
    if (isUrl) {
      setSearch('');
      setRelatedThreadId(getThreadIdFromUrl(value));
      return;
    }
    setSearch(value.toLocaleLowerCase());
    setRelatedThreadId('');
  };

  const onSubmit = async (state: FormState) => {
    if (!user) return;
    const payloads: ICreateThreadMutation[] = state.threadIds.map((id) => ({
      relatedThread: id,
      creator: user?.id,
    }));

    try {
      await Promise.all(
        payloads.map((payload) => createRelatedThread({ payload, threadId }).unwrap())
      );
      showSuccessMessage(NOTIFICATION.RELATED_THREAD_CREATED);
      close();
    } catch (error) {
      showUncaughtErrorMessage();
    }
  };

  const selectedThreads = useMemo(
    () => results?.filter((thread) => watch('threadIds').includes(`${thread.id}`)),
    [results, watch('threadIds')]
  );

  const unselectedThreads = useMemo(
    () => results?.filter((thread) => !watch('threadIds').includes(`${thread.id}`)),
    [results, watch('threadIds')]
  );
  return (
    <Stack gap="24px" fullHeight data-cy="modal-add-related-thread-stack">
      <Title heading="h6" color={colors.gray2} cypressAttribute="modal-add-related-thread-title">
        Search Threads
      </Title>
      <Layout.SearchBar data-cy="modal-add-related-thread-search">
        <Layout.SearchIcon aria-hidden="true">
          <Icon fill={colors.gray1} icon="SearchIcon" size="small" />
        </Layout.SearchIcon>
        <TextInput
          autoFocus
          type="text"
          name="search-threads"
          placeholder="Search thread by name or paste url"
          onChange={handleSearchChange}
          style={{ paddingLeft: '44px' }}
          data-cy="modal-add-related-thread-search-input"
        />
      </Layout.SearchBar>
      <Divider color={colors.blue3} />
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '75%',
          gap: '24px',
          display: 'flex',
          overflow: 'hidden',
          flexDirection: 'column',
        }}
        data-cy="modal-add-related-thread-form"
      >
        <Stack gap="24px" style={{ overflow: 'auto', height: '100%' }}>
          {selectedThreads.length > 0 && (
            <>
              <Stack gap="12px" data-cy="modal-add-related-thread-stack">
                <Title
                  heading="h6"
                  color={colors.gray2}
                  cypressAttribute="modal-add-related-thread-form-title"
                >
                  Selected Threads
                </Title>
                {selectedThreads.map((thread) => (
                  <RelatedThread
                    key={thread.id}
                    {...register('threadIds')}
                    creator={thread.creator}
                    value={thread.id}
                    label={thread.name}
                  />
                ))}
              </Stack>
              <Divider color={colors.blue3} />
            </>
          )}

          <Stack gap="12px">
            {unselectedThreads.map((thread) => (
              <RelatedThread
                key={thread.id}
                {...register('threadIds')}
                creator={thread.creator}
                value={thread.id}
                label={thread.name}
              />
            ))}
          </Stack>
          {!!data?.count && results.length !== data?.count && (
            <Group fluid justify="center" margin="10px 0">
              <Button color={colors.dark2} variant="filled" onClick={onFetchMore}>
                Load more
              </Button>
            </Group>
          )}
          <Group justify="flex-end" gap="15px" style={{ marginTop: 'auto' }}>
            <Button
              onClick={close}
              color={colors.dark2}
              cypressAttribute="modal-add-related-thread-close-btn"
            >
              Close
            </Button>
            <Button
              type="submit"
              disabled={!watch('threadIds').length || isLoading}
              cypressAttribute="modal-add-related-thread-save-btn"
            >
              Save
            </Button>
          </Group>
        </Stack>
      </form>
    </Stack>
  );
};

export default AddRelatedThread;
