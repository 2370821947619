import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Group, Icon, Spinner, Stack, Text } from 'ui';
import colors from 'theme/colors';
import { CUSTOM_SUBSCRIPTION_PLANS } from 'constants/subscription';
import { showErrorMessage } from 'modules/alert/utils';
import { handleApiCall } from 'utils/helpers';
import { useAppDispatch } from 'app/hooks';
import routes from 'constants/routes';
import {
  updateSubscriptionPriceId,
  updateSubscriptionName,
} from 'modules/subscriptionsPlans/subscriptionsPlansSlice';
import { useUpdateSubscriptionPlanMutation } from 'modules/subscriptionsPlans/subscriptionsPlansApi';
import Tooltip from 'components/ui/tooltip';
import * as Layout from '../Layout';

interface IProps {
  /**
   * Subscription plan name.
   */
  subscriptionPlanName: string;
  /**
   * Subscription plan price. Could be per month / year or custom label.
   */
  subscriptionPlanPrice: number | string;
  /**
   * Subscription plan id. Id of the product inside stripe dashboard.
   */
  subscriptionPlanId: string;
  /**
   * Subscription feature list.
   */
  subscriptionPlanFeatures: string[];
  /**
   * A text that displays on subscription card button. Default value is 'UPGRADE'.
   */
  subscriptionButtonText?: string;
  /**
   * If this subscription plan is active. False by default.
   */
  isSubscribed: boolean;
  /**
   * Indicates if this subscription plan is allowed to be selected. True by default.
   */
  isSelectable: boolean;
  /**
   * Link to mailto. Used for enterprise subscription plan.
   */
  mailToLink: string | null;
  /**
   * Indicates if this screen in auth flow or in app navigation flow.
   */
  isAuthFlow: boolean;
  /**
   * Callback that fires when subscription plan is upgraded only when upgrading from paid subscription to paid.
   */
  onSuccessUpgrade?: Dispatch<SetStateAction<boolean>>;
  isCTAButtonDisabled?: boolean;
}

export const SubscriptionCard: FC<IProps> = (props) => {
  const {
    subscriptionPlanName,
    subscriptionPlanPrice,
    subscriptionPlanFeatures,
    subscriptionPlanId,
    subscriptionButtonText = 'UPGRADE',
    isSubscribed = false,
    isSelectable = true,
    mailToLink = null,
    isAuthFlow = false,
    onSuccessUpgrade,
    isCTAButtonDisabled = false,
  } = props;

  const [updateSubscriptionPlan, { isLoading }] = useUpdateSubscriptionPlanMutation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isSubscribeAvailable = !isSubscribed && isSelectable;
  const buttonText = useMemo(
    () => (isSubscribed ? 'YOUR PLAN' : subscriptionButtonText),
    [isSubscribed]
  );

  // eslint-disable-next-line no-nested-ternary
  const buttonBgColor = isSelectable ? colors.orange : isSubscribed ? colors.green1 : colors.gray1;

  const isSubscriptionPlanEnterprise =
    subscriptionPlanName === CUSTOM_SUBSCRIPTION_PLANS.Enterprise;

  const subscriptionCardPrice = isSubscriptionPlanEnterprise
    ? 'CUSTOM PRICING'
    : `$${subscriptionPlanPrice}`;

  const handleSubscriptionButtonClick = async () => {
    if (!subscriptionPlanId.length) return;
    if (isAuthFlow) {
      dispatch(updateSubscriptionPriceId(subscriptionPlanId));
      dispatch(updateSubscriptionName(subscriptionPlanName));
      navigate(routes.createWorkspace);
    } else if (!isSubscriptionPlanEnterprise) {
      const response = await updateSubscriptionPlan({ priceId: subscriptionPlanId });
      handleApiCall(
        response,
        () => showErrorMessage('Could not update subscription plan'),
        ({ data: responseData }) => {
          if (responseData.redirectUrl) {
            window.open(responseData.redirectUrl, '_self', 'noopener,noreferrer');
            return;
          }
          onSuccessUpgrade?.(true);
        }
      );
    }
  };
  return (
    <Layout.SubscriptionsCardContainer>
      <Stack style={{ marginBottom: '10px' }} fluid>
        <Text weight="600" align="center">
          {subscriptionPlanName}
        </Text>
        <Stack
          style={{ height: '50px', margin: '20px 0px 10px 0px' }}
          align="center"
          justify="center"
        >
          <Text
            weight="600"
            color={colors.orange}
            align="center"
            style={{ fontSize: 24, lineHeight: '100%' }}
          >
            {subscriptionCardPrice}
          </Text>
        </Stack>
        <Layout.Divider />
      </Stack>
      <Stack gap="10px" style={{ height: '160px', marginBottom: '20px' }}>
        {subscriptionPlanFeatures.map((feature, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Group align="center" key={`${feature}-${index}`}>
            <div>
              <Icon icon="CheckIcon" path={colors.orange} style={{ width: 14, height: 14 }} />
            </div>
            <Text key={feature} style={{ lineHeight: 'normal' }}>
              {feature}
            </Text>
          </Group>
        ))}
      </Stack>
      <Tooltip
        delayDuration={0}
        content="You must be a workspace owner on a regular (non-custom)
       subscription plan to change your subscription"
        tooltipContentClassName="max-w-sm"
        isVisible={isCTAButtonDisabled}
      >
        <Button
          type="button"
          variant="filled"
          color={buttonBgColor}
          disabled={isCTAButtonDisabled || !isSubscribeAvailable || isLoading}
          style={{ width: '130px' }}
          onClick={handleSubscriptionButtonClick}
        >
          {/* eslint-disable-next-line max-len */}
          {/* if subscription plan is enterprise, we want to show mailto link to give ability to user to contact us and discuss the plan */}
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoading ? (
            <Spinner size="small" color={colors.white} />
          ) : isSubscriptionPlanEnterprise && mailToLink ? (
            <a href={mailToLink}>
              <Text weight="700">{`LET'S TALK!`}</Text>
            </a>
          ) : (
            <Text weight="700">{buttonText}</Text>
          )}
        </Button>
      </Tooltip>
    </Layout.SubscriptionsCardContainer>
  );
};
