import { api } from 'app/api';
import { IGetUsersParams } from 'modules/user/types';
import { IPaginatedResponse } from 'types';
import { IUser } from 'types/user';

export type Workspace = {
  name: string;
  // UUID of owner user
  owner: string;
  id: number;
};

export const workspaceApi = api.injectEndpoints({
  endpoints: (build) => ({
    workspace: build.query<Workspace, void>({
      query: () => ({
        url: `/sites/`,
      }),
      providesTags: ['workspace'],
    }),
    workspaceMembers: build.query<IPaginatedResponse<IUser>, IGetUsersParams>({
      query: ({ limit, offset, search }) => ({
        url: '/users/',
        params: {
          search,
          offset,
          limit,
        },
      }),
      providesTags: ['users'],
    }),
  }),
});
export const { useWorkspaceQuery } = workspaceApi;
