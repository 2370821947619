import { Card } from 'modules/relatedThreads/components/Layout';
import React from 'react';
import colors from 'theme/colors';
import { IUserMinimize } from 'types/user';
import { Checkbox, Group, Stack, Text } from 'ui';
import { ICheckBoxProps } from 'ui/checkbox/types';
import { getFullName } from 'utils/helpers';

interface IRelatedThreadProps extends ICheckBoxProps {
  cypressAttribute?: string;
  creator: IUserMinimize;
}

const RelatedThread = React.forwardRef<HTMLInputElement, IRelatedThreadProps>(
  ({ name, creator, label, onChange, value, cypressAttribute }, ref) => {
    const creatorName = getFullName(creator);

    return (
      <Card data-cy={cypressAttribute && `${cypressAttribute}-card`}>
        <Group align="center" gap="15px" data-cy={cypressAttribute && `${cypressAttribute}-group`}>
          <Checkbox
            name={name}
            value={value}
            size="xl"
            onChange={onChange}
            cypressAttribute={cypressAttribute}
            ref={ref}
          />

          <Stack gap="0px">
            <Text
              cypressAttribute={cypressAttribute && `${cypressAttribute}-name`}
              weight="500"
              size="md"
            >
              {label}
            </Text>
            <Text size="sm" color={colors.gray}>
              <em style={{ fontStyle: 'italic' }}>Created by {creatorName}</em>
            </Text>
          </Stack>
        </Group>
      </Card>
    );
  }
);

export default RelatedThread;
