import * as ChatLayout from 'modules/chat/components/Layout';
import React from 'react';
import { IUserMinimize } from 'types/user';
import { Avatar, Stack } from 'ui';

interface IMessageContainerProps {
  hasReplies?: boolean;
  isReply?: boolean;
  showReplyBorder?: boolean;
  creator: IUserMinimize | null;
  children: React.ReactNode;
}

export const MessageContainer: React.FC<IMessageContainerProps> = ({
  creator,
  showReplyBorder = false,
  hasReplies = false,
  isReply = false,
  children,
}) => (
  <ChatLayout.Message owner={false} isRootOwner={false} showBorder={showReplyBorder}>
    <ChatLayout.ReplayLine hasReplies={hasReplies}>
      <Stack style={{ position: 'relative', zIndex: 5 }} justify="center">
        {isReply ? (
          <ChatLayout.WithLine owner={false}>
            <Avatar src={creator?.avatar} cypressAttribute="message-container-avatar-with-line" />
          </ChatLayout.WithLine>
        ) : (
          <Avatar src={creator?.avatar} cypressAttribute="message-container-avatar" />
        )}
      </Stack>
    </ChatLayout.ReplayLine>
    {children}
  </ChatLayout.Message>
);
