import { ReactComponent as GoogleDocsIcon } from 'assets/icons/google-docs.svg';
import { ReactComponent as GoogleDriveIcon } from 'assets/icons/google-drive.svg';
import { ReactComponent as GoogleSheetsIcon } from 'assets/icons/google-sheets.svg';
import { ReactComponent as GoogleSlidesIcon } from 'assets/icons/google-slides.svg';
import React from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import { IUserMinimize } from 'types/user';
import { Icon } from 'ui';

export const AVAILABLE_INTEGRATIONS = {
  spreadsheets: 'spreadsheets',
  document: 'documents',
  presentation: 'presentations',
  drive: 'drive',
} as const;

export const INTEGRATION_LIST = {
  spreadsheets: {
    title: 'Google Sheets',
    icon: <GoogleSheetsIcon width={52} height={72} />,
  },
  document: {
    title: 'Google Docs',
    icon: <GoogleDocsIcon width={52} height={72} />,
  },
  presentation: {
    title: 'Google Slides',
    icon: <GoogleSlidesIcon width={52} height={72} />,
  },
  drive: {
    title: 'Google Drive',
    icon: <GoogleDriveIcon width={52} height={72} />,
  },
};

const IntegrationIconContainer = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: ${({ color }) => color || 'rgba(0, 134, 249, 0.2)'};
`;

export const REFERENCE_INTEGRATION_ICONS = {
  spreadsheets: (
    <IntegrationIconContainer color="#21A4644D">
      <GoogleSheetsIcon width={10} height={15} />
    </IntegrationIconContainer>
  ),
  document: (
    <IntegrationIconContainer>
      <GoogleDocsIcon width={10} height={15} />
    </IntegrationIconContainer>
  ),
  presentation: (
    <IntegrationIconContainer color="#F5BA154D">
      <GoogleSlidesIcon width={10} height={15} />
    </IntegrationIconContainer>
  ),
  drive: (
    <IntegrationIconContainer>
      <GoogleDriveIcon width={10} height={15} />
    </IntegrationIconContainer>
  ),
};

export const DEFAULT_GOOGLE_CREATOR: IUserMinimize = {
  avatar: '/images/google-logo.svg',
  firstName: 'Google',
  lastName: 'Comments',
  id: 'DEFAULT_GOOGLE_CREATOR',
  email: 'admin@google.com',
  group: 'Google Comments',
};

export enum EReferenceIntegrations {
  plyable = 'plyable',
  threads3d = 'threads3d',
}

export const INTEGRATION_COLORS: { [key in EReferenceIntegrations]: string } = {
  [EReferenceIntegrations.plyable]: '#F583FF',
  [EReferenceIntegrations.threads3d]: colors.orange2,
};

export const INTEGRATION_LOGOS: { [key in EReferenceIntegrations]: React.ReactNode } = {
  [EReferenceIntegrations.plyable]: (
    <div style={{ backgroundColor: '#000', borderRadius: '50%', width: 30, height: 30 }}>
      <Icon
        icon="PlyableLogo"
        stroke="none"
        style={{ width: '30px', height: '30px' }}
        aria-label="Plyable logo"
      />
    </div>
  ),
  [EReferenceIntegrations.threads3d]: (
    <div style={{ backgroundColor: colors.dark8, borderRadius: '50%', padding: '0.4rem' }}>
      <Icon icon="Threads3dIcon" aria-label="Threads 3d logo" size="normal" />
    </div>
  ),
};

export const PLAYABLE_QUOTE_FOR = {
  TOOL: 'Tool manufacture',
  COMPONENT: 'Component manufacture',
} as const;

export const PLAYABLE_MOLD_TYPES_OPTIONS = [
  { value: 'COMPONENT', label: 'Component manufacture' },
  { value: 'TOOL', label: 'Tool manufacture' },
];
