import path from 'constants/path';
import Table from 'modules/threads';
import { GanttView as Gantt } from 'modules/threads/components';
import { TITLES } from 'pages/dashboard/constants';
import React from 'react';

interface Props {
  showGantt: boolean;
}

export const AllThreads: React.FC<Props> = ({ showGantt }) => {
  return (
    <div>
      {showGantt ? (
        <Gantt url={path.allThreadsGanttList} title={TITLES.ALL} />
      ) : (
        <Table
          url={path.allThreadsList}
          title={TITLES.ALL}
          cypressAttribute="all-threads-table"
          isAllThreads
        />
      )}
    </div>
  );
};

export default AllThreads;
