import React, { useRef, useState } from 'react';
import { useAppSelector } from 'app/hooks';
import { useUserWalkthrough } from 'hooks/useUserWalkthrough';
import { selectUserWalkthroughs } from 'modules/userWalkthrough/userWalkthroughSlice';
import { Button, Title } from 'ui';
import { EPosition } from 'modules/userWalkthrough/types';
import colors from 'theme/colors';
import { EUserWalkthroughs } from 'modules/userWalkthrough/constants';
import { useWindowSize } from 'hooks/useWindowSize';
import { mediaSize } from 'theme/devices';
import { DarkOverlay, UserWalkthroughBox, UserWalkthroughDialog } from '../Layout';

interface IDashboardUserWalkthroughProps {
  id?: string;
}

enum EUserWalkthroughIds {
  DIALOG = 'user-walkthrough-dismissable-dialog',
  STATISTICS = 'user-walkthrough-statistics-box',
  EVENTS = 'user-walkthrough-events-box',
  THREADS = 'user-walkthrough-threads-box',
}

/**
 * User walk-through for onboarding first-time users.
 * Walk-through for user dashboard.
 */
const DashboardUserWalkthrough = ({ id }: IDashboardUserWalkthroughProps) => {
  const { markWalkthroughAsCompleted } = useUserWalkthrough(EUserWalkthroughs.DASHBOARD);
  const { width, height } = useWindowSize();
  const isViewportWidthTabletOrBelow = width <= mediaSize.tablet;
  const isViewportHeightSmall = height <= 600;

  const statisticsCard = document.getElementById('statistic-options-title');
  const dashboardEvents = document.getElementById('dashboard-events-title');
  const threadsList = document.getElementById('right-flex-container');

  const [focusedDialog, setFocusedDialog] = useState(EUserWalkthroughIds.DIALOG);
  const handleFocusDialog = (componentId: EUserWalkthroughIds): void =>
    setFocusedDialog(componentId);

  return (
    <>
      <DarkOverlay height={height} id="dark-overlay" />
      <UserWalkthroughDialog.Body
        id={EUserWalkthroughIds.DIALOG}
        onClick={() => handleFocusDialog(EUserWalkthroughIds.DIALOG)}
        focused={focusedDialog === EUserWalkthroughIds.DIALOG}
      >
        <Title weight="700" heading="h6">
          Welcome to Threads!
        </Title>
        Here is your home dashboard where you can view high-level information about your workspace.
        <UserWalkthroughDialog.Footer id="user-walkthrough-dialog-footer">
          <Button onClick={markWalkthroughAsCompleted} color={colors.orange2}>
            Got it!
          </Button>
        </UserWalkthroughDialog.Footer>
      </UserWalkthroughDialog.Body>
      <UserWalkthroughBox
        id={EUserWalkthroughIds.STATISTICS}
        onClick={() => handleFocusDialog(EUserWalkthroughIds.STATISTICS)}
        focused={focusedDialog === EUserWalkthroughIds.STATISTICS}
        topOffset={-60}
        position={EPosition.RIGHT}
        anchoredComponent={statisticsCard}
        width={isViewportWidthTabletOrBelow ? 200 : 300}
      >
        <Title weight="700" heading="h6">
          Statistics
        </Title>
        View the status of the Threads you’ve worked on in the last week and month.
      </UserWalkthroughBox>
      <UserWalkthroughBox
        id={EUserWalkthroughIds.EVENTS}
        onClick={() => handleFocusDialog(EUserWalkthroughIds.EVENTS)}
        focused={focusedDialog === EUserWalkthroughIds.EVENTS}
        leftOffset={isViewportWidthTabletOrBelow || isViewportHeightSmall ? -30 : 0}
        topOffset={isViewportWidthTabletOrBelow || isViewportHeightSmall ? -200 : -60}
        position={
          isViewportWidthTabletOrBelow || isViewportHeightSmall ? EPosition.TOP : EPosition.RIGHT
        }
        anchoredComponent={dashboardEvents}
        width={isViewportWidthTabletOrBelow ? 200 : 300}
      >
        <Title weight="600" heading="h6">
          Events
        </Title>
        Events are any meaningful moment that occurs within a Thread. Your dashboard will contain
        any events assigned to you, organized by deadline.
      </UserWalkthroughBox>
      <UserWalkthroughBox
        id={EUserWalkthroughIds.THREADS}
        onClick={() => handleFocusDialog(EUserWalkthroughIds.THREADS)}
        focused={focusedDialog === EUserWalkthroughIds.THREADS}
        leftOffset={-20}
        topOffset={-20}
        position={EPosition.TOP}
        anchoredComponent={threadsList}
        width={isViewportWidthTabletOrBelow ? 200 : 300}
      >
        <Title weight="600" heading="h6">
          Threads
        </Title>
        View your list of Threads. Check out the Welcome Thread to see how they work!
      </UserWalkthroughBox>
    </>
  );
};

export default DashboardUserWalkthrough;
