import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import routes from 'constants/routes';
import useSubscriptionPermissions from 'hooks/useSubscriptionPermissions';
import { showSuccessMessage } from 'modules/alert/utils';
import {
  useContributorInviteUpdateMutation,
  useGetThreadJoinRequestByIdQuery,
} from 'modules/contributors/contributorsApi';
import { useModal } from 'modules/modals/ModalProvider';
import { IThreadsResult } from 'modules/threads/types';
import React, { useCallback } from 'react';
import colors from 'theme/colors';
import { Button, Group, Spinner, Stack, Text } from 'ui';
import AnchorLink from 'ui/anchorLink';
import { getFullName, handleApiCall } from 'utils/helpers';
import ModalErrorBoundary from '../components/ModalErrorBoundary';

interface IProps {
  threadData: IThreadsResult;
  inviteId: string;
}

const ContributorPendingActions: React.FC<IProps> = ({ threadData, inviteId }) => {
  const { id, name: threadName } = threadData;
  const threadId = String(id);

  const { close } = useModal();

  const {
    data,
    isLoading,
    error: requestError,
  } = useGetThreadJoinRequestByIdQuery(
    {
      threadId,
      id: inviteId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { handleSubscriptionPermissionErrors } = useSubscriptionPermissions();
  const [contributorMutation, { isLoading: isUpdating }] = useContributorInviteUpdateMutation();

  const handleRejectRequest = useCallback(async () => {
    const response = await contributorMutation({
      id: Number(inviteId),
      accept: false,
    });
    handleApiCall(
      response,
      (error) => {
        handleSubscriptionPermissionErrors(error, 'Error occurred while rejecting request.');
      },
      () => {
        showSuccessMessage('Request rejected.');
        close();
      }
    );
  }, [inviteId]);

  const handleApproveRequest = useCallback(async () => {
    try {
      await contributorMutation({
        id: Number(inviteId),
        accept: true,
      }).unwrap();
      showSuccessMessage('Request approved.');
      close();
    } catch (error) {
      handleSubscriptionPermissionErrors(error, 'Error occurred while approving request.');
    }
  }, [inviteId]);

  const isButtonDisabled = isLoading || isUpdating;
  const user = data?.results[0]?.user;

  if (isLoading) {
    return (
      <Stack align="center" justify="center" style={{ padding: '5rem' }}>
        <Spinner />
      </Stack>
    );
  }

  return (
    <ModalErrorBoundary error={requestError as FetchBaseQueryError}>
      <Stack gap="30px">
        <Text align="center" size="lg" style={{ lineHeight: 1.3 }}>
          <Text size="lg" weight="600" color={colors.orange} component="span">
            {getFullName(
              user && {
                firstName: user.first_name,
                lastName: user.last_name,
                ...user,
              }
            )}
          </Text>
          {user ? ` (${user.email}) ` : ' '}
          has requested to be a contributor to thread{' '}
          <AnchorLink href={`${routes.thread}/${threadId}`}>
            {threadName} ({threadId})
          </AnchorLink>
          .
        </Text>
        <Group gap="15px" fluid>
          <Button onClick={close} fluid color={colors.dark2}>
            Cancel
          </Button>
          <Button onClick={handleRejectRequest} fluid color="#B73131" disabled={isButtonDisabled}>
            {isUpdating ? <Spinner size="small" /> : 'Deny'}
          </Button>
          <Button onClick={handleApproveRequest} fluid disabled={isButtonDisabled}>
            {isUpdating ? <Spinner size="small" /> : 'Add'}
          </Button>
        </Group>
      </Stack>
    </ModalErrorBoundary>
  );
};

export default ContributorPendingActions;
