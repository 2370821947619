import { useState } from 'react';

const LIMIT = 25;

interface Props {
  limit?: number;
  initialOffset?: number;
}

export const usePagination = ({ initialOffset = 0, limit = LIMIT }: Props) => {
  const [offset, setOffset] = useState(initialOffset);

  const next = () => {
    setOffset((prev) => prev + limit);
  };

  const previous = () => {
    setOffset((prev) => {
      if (prev - limit < 0) {
        return 0;
      }
      return prev - limit;
    });
  };

  return {
    offset,
    limit,
    next,
    previous,
    page: Math.floor(offset / limit),
  };
};

interface InfiniteProps {
  pageSize?: number;
}
/**
 * Pagination via increasing limit rather than offset.
 * On changing pages, this will refetch already fetched data, so is wasteful
 * But this allows us to keep all records in a single list (infinite scroll -> nicer UX)
 */
export const useInfinitePagination = ({ pageSize = LIMIT }: InfiniteProps) => {
  const [limit, setLimit] = useState(pageSize);

  const next = () => {
    setLimit((prev) => prev + pageSize);
  };

  return {
    offset: 0,
    limit,
    next,
    page: Math.floor(limit / pageSize),
  };
};
