import { api } from 'app/api';
import { ICopilotSuggestion, SuggestionAction } from 'modules/copilot/types';

export const copilotApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCopilotSuggestions: build.mutation<
      { suggestionsReady: boolean },
      { threadId: string | number }
    >({
      query: ({ threadId }) => ({
        url: `/copilot/${threadId}/`,
        method: 'POST',
      }),
    }),
    copilotSuggestions: build.query<
      { suggestions: ICopilotSuggestion[]; suggestionsAvailable: boolean },
      { threadId: string | undefined }
    >({
      query: ({ threadId }) => ({
        url: `/copilot/${threadId}/`,
      }),
      providesTags: ['copilotSuggestions'],
    }),
    actionSuggestion: build.mutation<
      void,
      { suggestionId: number; payload: { event?: string; action: SuggestionAction } }
    >({
      query: ({ suggestionId, payload }) => ({
        url: `/copilot/suggestion/${suggestionId}/action/`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['copilotSuggestions'],
    }),
    sendCopilotChatMessage: build.mutation<void, { message: string; chatHistory: string }>({
      query: ({ message, chatHistory }) => ({
        url: 'copilot/chat/',
        method: 'POST',
        body: {
          q: message,
          preChatHistory: chatHistory,
        },
      }),
    }),
  }),
});

export const {
  useGetCopilotSuggestionsMutation,
  useCopilotSuggestionsQuery,
  useSendCopilotChatMessageMutation,
  useActionSuggestionMutation,
} = copilotApi;
