import {
  DEFAULT_PLYABLE_CREATOR,
  REFETCH_INTERVAL,
  SKELETON_COUNT,
} from 'modules/annotations/helpers';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Icon } from 'ui';

import { useInfinitePagination } from 'hooks/usePagination';
import { usePlyableAnnotationsQuery } from 'modules/PlyableAnnotations/api';
import { AnnotationsEmpty } from 'modules/annotations/components/AnnotationsEmpty';
import { AnnotationsSkeleton } from 'modules/annotations/components/AnnotationsSkeleton';
import {
  AnnotationListScroller,
  AnnotationRefreshButton,
  AnnotationsContainer,
  LoadButtonContainer,
} from '../../annotations/components/AnnotationLayout';
import PlyableAnnotationItem from './PlyableAnnotationItem';

const PlyableAnnotations: React.FC = () => {
  const { id } = useParams();
  const [isRefetchLoading, setIsRefetchLoading] = useState<boolean>(false);
  const { offset, limit, next } = useInfinitePagination({ pageSize: 10 });

  const { data, isLoading, isFetching, refetch } = usePlyableAnnotationsQuery(
    { id: id as string, limit, offset },
    {
      refetchOnMountOrArgChange: false,
      pollingInterval: REFETCH_INTERVAL,
    }
  );

  const hasNextLink = !!data?.next;

  const refreshAnnotationsClickHandler = () => {
    setIsRefetchLoading(true);
    refetch().then(() => setIsRefetchLoading(false));
  };

  return (
    <AnnotationListScroller fullHeight justify="flex-start" data-cy="annotation-list-scroller">
      <AnnotationsContainer gap="35px" fluid fullHeight data-cy="annotations-container">
        {
          isLoading &&
            new Array(SKELETON_COUNT)
              .fill(null)
              .map((_, index) => <AnnotationsSkeleton key={index} />) // eslint-disable-line react/no-array-index-key
        }
        {data?.results.length === 0 && !isLoading && (
          <AnnotationsEmpty
            isRefreshing={isRefetchLoading}
            onRefreshClick={refreshAnnotationsClickHandler}
          />
        )}
        {data?.results &&
          !isLoading &&
          data?.results.map((annotation) => (
            <PlyableAnnotationItem
              key={annotation.id}
              id={annotation.id.toString()}
              author={annotation.creator ?? DEFAULT_PLYABLE_CREATOR}
              createdAt={annotation.createdAt}
              fileId={annotation.versionedFile.id}
              fileName={annotation.versionedFile.file}
              fileVersion={annotation.versionedFile.version.toString()}
              fileUrl={annotation.versionedFile.plyableUrl}
              comments={annotation.replies}
            />
          ))}
        <LoadButtonContainer isBottomButton data-cy="annotation-load-btn-container">
          {hasNextLink && !isLoading && (
            <AnnotationRefreshButton
              type="button"
              rightIcon={<Icon spin={isFetching} icon="RefreshAnnotationIcon" size="small" />}
              onClick={next}
              data-cy="annotation-load-more-btn"
            >
              {isFetching ? 'Loading...' : 'Load More'}
            </AnnotationRefreshButton>
          )}
        </LoadButtonContainer>
      </AnnotationsContainer>
    </AnnotationListScroller>
  );
};

export default PlyableAnnotations;
