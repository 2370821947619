export enum EEvents {
  ISSUE = 'ISSUE',
  QUESTION = 'QUESTION',
  INSIGHT = 'INSIGHT',
  DECISION = 'DECISION',
  RESOLUTION = 'RESOLUTION',
  ACTION = 'ACTION',
  STOPPED_STALLED = 'STOPPED_STALLED',
  END = 'END',
}

export const EVENTS_NAME_MAP = {
  [EEvents.ISSUE]: 'Issue',
  [EEvents.QUESTION]: 'Question',
  [EEvents.INSIGHT]: 'Insight',
  [EEvents.DECISION]: 'Decision',
  [EEvents.RESOLUTION]: 'Resolution',
  [EEvents.ACTION]: 'Action',
  [EEvents.STOPPED_STALLED]: 'Stopped/Stalled',
  [EEvents.END]: 'End',
};

export const EVENT_CARD_TOOLTIP_OMITTED_KEYS = [
  'createdAt',
  'updatedAt',
  'assignees',
  'thread',
  'showLinkToOriginThread',
];
