import React, { useEffect } from 'react';
import LoginLayout from 'layout/Login';
import { Button, Group, Icon, Stack, Text, Title } from 'ui';
import colors from 'theme/colors';
import { Link, useNavigate } from 'react-router-dom';
import routes from 'constants/routes';
import styled from 'styled-components';
import { useResetConfirmEmailMutation } from 'modules/auth/authApi';
import {
  showErrorMessage,
  showSuccessMessage,
  showUncaughtErrorMessage,
} from 'modules/alert/utils';
import { NOTIFICATION } from 'modules/alert/constants';
import useLocalStorage from 'hooks/useLocalStorage';
import { RESET_EMAIL } from '../../constants';

const Mail = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.dark2};
  border-radius: 50%;
`;

const CheckEmail = () => {
  const [confirmEmail, { isSuccess, isLoading, isError }] = useResetConfirmEmailMutation();
  const [email] = useLocalStorage(RESET_EMAIL);
  const resendEmail = () => confirmEmail({ email: email as string });
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      showSuccessMessage(NOTIFICATION.REQUEST_SENT);
    }
    if (isError) {
      showUncaughtErrorMessage();
    }
  }, [isLoading]);

  useEffect(() => {
    if (!email) {
      navigate(routes.login);
    }
  }, [email]);

  return (
    <LoginLayout>
      <>
        <Stack gap="30px">
          <Mail>
            <Icon size="large" icon="MailIcon" />
          </Mail>
          <Title heading="h1">Check your email</Title>
          <Title style={{ fontSize: '16px' }} heading="h5" color={colors.gray1}>
            Please check your email and follow the link to create a new password.
          </Title>
        </Stack>
        <Stack gap="24px">
          <Link to={routes.login}>
            <Button fluid cypressAttribute="check-email-back-to-login-btn">
              Back to Login
            </Button>
          </Link>
          <Group align="center">
            <Text color={colors.gray1}>Didn&apos;t receive the email?</Text>
            <Button
              cypressAttribute="check-email-click-to-resend-btn"
              onClick={resendEmail}
              type="button"
              textColor={colors.orange}
              variant="plain"
            >
              Click to resend
            </Button>
          </Group>
        </Stack>
      </>
    </LoginLayout>
  );
};

export default CheckEmail;
