import { api } from 'app/api';
import { useAppDispatch } from 'app/hooks';
import {
  useCopilotSuggestionsQuery,
  useGetCopilotSuggestionsMutation,
} from 'modules/copilot/copilotApi';
import { EEventType, ISocketPayload } from 'modules/stream/types';
import { useStream } from 'modules/stream/useStream';
import { useState } from 'react';
import { deepCamelcaseKeys } from 'utils/mappers';

export const useThreadSuggestions = (threadId: string | undefined) => {
  const [waitingToFetch, setWaitingToFetch] = useState(true);
  const { data, isFetching, error } = useCopilotSuggestionsQuery(
    { threadId },
    { skip: waitingToFetch || !threadId }
  );
  const [generateSuggestions] = useGetCopilotSuggestionsMutation();
  const dispatch = useAppDispatch();

  // On receiving a copilot suggestion notification, refetch from api
  const handler = (message: ISocketPayload) => {
    if (message.type === EEventType.COPILOT_EVENT_SUGGESTIONS) {
      const notification = deepCamelcaseKeys(message.context);
      if (threadId === notification.threadId) {
        dispatch(api.util.invalidateTags(['copilotSuggestions']));
        setWaitingToFetch(false);
      }
    }
  };

  const initiate = async () => {
    if (!threadId) return;
    try {
      // Ask the BE to start generating suggestions.
      // If it has some ready, we fetch with the query above
      const { suggestionsReady } = await generateSuggestions({ threadId }).unwrap();
      if (suggestionsReady) {
        setWaitingToFetch(false);
      }
    } catch (fetchError) {
      console.error(fetchError);
    }
  };

  useStream(handler);

  return { initiate, data, isLoading: isFetching || data === undefined, error };
};
