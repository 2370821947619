import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import routes from 'constants/routes';
import { NOTIFICATION } from 'modules/alert/constants';
import { showErrorMessage, showUncaughtErrorMessage } from 'modules/alert/utils';
import {
  useCheckAuthMethodMutation,
  useLoginMutation,
  useNautilusLoginMutation,
} from 'modules/auth/authApi';
import { AuthMethod, ILoginMutation } from 'modules/auth/types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import colors from 'theme/colors';
import { Spinner } from 'ui';
import { validationSchema } from 'utils/validation';
import * as yup from 'yup';
import { userApi } from 'modules/user/userApi';
import { useAppDispatch } from 'app/hooks';
import { EmailPasswordForm } from './EmailPasswordForm';
import { SSOForm } from './SSOForm';

const checkAuthMethodValidation = yup.object({
  email: validationSchema.emailRequired(),
});

const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const [authMethod, setAuthMethod] = useState<AuthMethod | undefined>(undefined);

  const dispatch = useAppDispatch();
  const [login, { isLoading: isLoggingIn }] = useLoginMutation();
  const [nautilusLogin, { isLoading: isNautilusLoggingIn }] = useNautilusLoginMutation();
  const isSubmitting = isLoggingIn || isNautilusLoggingIn;
  const [checkAuthMethod, { isLoading: isCheckingAuthMethod }] = useCheckAuthMethodMutation();

  const form = useForm<ILoginMutation>({
    defaultValues: { email: '' },
    resolver: yupResolver(checkAuthMethodValidation),
    shouldFocusError: false,
  });

  const onSubmit = async (values: ILoginMutation) => {
    if (authMethod === 'email') {
      try {
        await login(values).unwrap();

        dispatch(userApi.endpoints.getUserInfo.initiate(undefined, { forceRefetch: true }));
      } catch (error) {
        console.log(error);
        if ((error as any).status === 400) {
          showErrorMessage(NOTIFICATION.INVALID_LOGIN);
        } else {
          showUncaughtErrorMessage();
        }
      }
    } else if (authMethod === 'nautilus-managed') {
      try {
        await nautilusLogin(values).unwrap();
        dispatch(userApi.endpoints.getUserInfo.initiate(undefined, { forceRefetch: true }));
      } catch (error) {
        if ((error as any).status === 400) {
          showErrorMessage(NOTIFICATION.INVALID_LOGIN);
        } else {
          showUncaughtErrorMessage();
        }
      }
    }
  };

  const handleCheckAuthMethod = async (values: { email: string }) => {
    try {
      const { method, redirectUrl } = await checkAuthMethod(values).unwrap();
      setAuthMethod(method);
      if (redirectUrl) navigate(redirectUrl);
    } catch (error) {
      const errorData = error as any;
      if (errorData?.data?.email?.length) {
        showErrorMessage(errorData.data.email[0]);
      } else {
        showUncaughtErrorMessage();
      }
    }
  };

  const clean = () => {
    form.reset();
    setAuthMethod(undefined);
  };

  if (authMethod === 'sso') {
    return <SSOForm />;
  }

  if (authMethod === 'email' || authMethod === 'nautilus-managed') {
    return (
      <EmailPasswordForm
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        handleCancel={clean}
        email={form.getValues('email')}
      />
    );
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleCheckAuthMethod)}>
        <div className="flex flex-col gap-12">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input
                    className="border-solid"
                    autoFocus
                    disabled={isCheckingAuthMethod}
                    placeholder="Enter Email"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex w-full flex-col items-center gap-4">
            <Button
              disabled={isSubmitting}
              type="submit"
              className="w-full"
              data-cy="sign-in-submit-btn"
            >
              {isCheckingAuthMethod ? (
                <Spinner size="small" color={colors.dark} />
              ) : (
                <span className="font-semibold">Proceed</span>
              )}
            </Button>
            <span>or</span>
            <Link to={`${routes.subscriptionsPlans}/auth`} className="w-full">
              <Button variant="outline" asChild className="w-full border-solid">
                <span className="font-semibold">Create workspace</span>
              </Button>
            </Link>
          </div>
        </div>
      </form>
    </Form>
  );
};

export default LoginForm;
