import useSubscriptionPermissions from 'hooks/useSubscriptionPermissions';
import { NOTIFICATION } from 'modules/alert/constants';
import { showSuccessMessage } from 'modules/alert/utils';
import { useModal } from 'modules/modals/ModalProvider';
import { useUpdateThreadMutation } from 'modules/threads/threadsApi';
import { IUpdateThreadMutation } from 'modules/threads/types';
import React from 'react';
import colors from 'theme/colors';
import { Button, Group, Stack, Text } from 'ui';

const ArchiveThread: React.FC<IUpdateThreadMutation> = (args) => {
  const { close } = useModal();
  const { handleSubscriptionPermissionErrors } = useSubscriptionPermissions();

  const [updateThread, { isLoading }] = useUpdateThreadMutation();

  const handleSubmit = async () => {
    try {
      await updateThread({ ...args }).unwrap();
      showSuccessMessage(NOTIFICATION.THREAD_ARCHIVED);
      close();
    } catch (error) {
      handleSubscriptionPermissionErrors(error, 'Could not archive thread. Please try again.');
    }
  };

  return (
    <Stack gap="20px">
      <Text size="lg" color={colors.gray2}>
        Are you sure you want to archive this thread? Other users won&apos;t be able to contribute
        to it and won&apos;t see it on threads listing
      </Text>
      <Group justify="end" gap="15px">
        <Button
          color={colors.dark2}
          onClick={close}
          cypressAttribute="modal-archive-thread-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          color={colors.orange}
          onClick={handleSubmit}
          cypressAttribute="modal-archive-thread-archive-btn"
        >
          {isLoading ? 'Archiving...' : 'Archive Thread'}
        </Button>
      </Group>
    </Stack>
  );
};

export default ArchiveThread;
