import { NOTIFICATION } from 'modules/alert/constants';
import { showSuccessMessage, showUncaughtErrorMessage } from 'modules/alert/utils';
import { useModal } from 'modules/modals/ModalProvider';
import { useDeleteReferenceMutation } from 'modules/threads/threadsApi';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import { Button, Group, Icon, Stack, Text } from 'ui';
import { handleApiCall } from 'utils/helpers';

interface IRemoveReference {
  id: string | number;
  name: string;
  url: string | null;
}

const ReferenceItem = styled(Group)`
  min-height: 56px;
  border: 1px solid;
  background-color: ${({ theme }) => theme.colors.dark2};
  border-color: ${({ theme }) => theme.colors.blue2};
  border-radius: 8px;
  padding: 12px;
`;

const RemoveReference: React.FC<IRemoveReference> = ({ id, name, url }) => {
  const [deleteReference, { isLoading, isSuccess, isError }] = useDeleteReferenceMutation();
  const { close } = useModal();

  useEffect(() => {
    if (isSuccess) {
      showSuccessMessage(NOTIFICATION.REFERENCE_DELETED);
      close();
    }

    if (isError) {
      showUncaughtErrorMessage();
    }
  }, [isLoading]);

  const handleDeleteReference = async () => {
    const res = await deleteReference(id.toString());
    handleApiCall(
      res,
      () => showUncaughtErrorMessage(),
      () => {
        close();
      }
    );
  };

  return (
    <Stack gap="24px">
      <Text size="lg" color={colors.gray2}>
        Are you sure you want to remove this Reference?
      </Text>
      <ReferenceItem gap="10px" align="center">
        <Icon
          icon={url ? 'ReferenceLinkIcon' : 'ReferenceDocumentIcon'}
          size="large"
          stroke="none"
        />
        {name}
      </ReferenceItem>

      <Group justify="end" gap="15px">
        <Button
          color={colors.dark2}
          onClick={close}
          cypressAttribute="modal-remove-reference-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          color={colors.red2}
          onClick={handleDeleteReference}
          cypressAttribute="modal-remove-reference-remove-btn"
        >
          {isLoading ? 'Removing...' : 'Remove'}
        </Button>
      </Group>
    </Stack>
  );
};

export default RemoveReference;
