import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectChat } from 'modules/chat/chatSlice';
import { useCopilotSuggestionsQuery } from 'modules/copilot/copilotApi';
import { setShowSuggestions } from 'modules/copilot/copilotSlice';
import React from 'react';
import { BsArrowRepeat, BsLightbulb, BsLightbulbFill } from 'react-icons/bs';
import colors from 'theme/colors';
import Tooltip from 'components/ui/tooltip';

interface Props {
  threadId: string;
}

export const Suggestions: React.FC<Props> = ({ threadId }) => {
  const { data: suggestions, isFetching } = useCopilotSuggestionsQuery(
    { threadId },
    { skip: !threadId }
  );
  const dispatch = useAppDispatch();
  const handleShowSuggestions = () => dispatch(setShowSuggestions(true));

  const { messages } = useAppSelector(selectChat);

  // Commenting out to prevent potential infinite spinner when suggestions fail to load.
  // :tech-debt: Re-enable once reviewing state machine.
  //   if (isFetching || !suggestions?.suggestionsAvailable) {
  //   return (
  //     <Tooltip data="Processing...">
  //       <BsArrowRepeat className="animate-spin" size={19} strokeWidth={0.7} color={colors.gray1} />
  //     </Tooltip>
  //   );
  // }

  if (!suggestions || suggestions.suggestions.length === 0 || messages?.length === 0) {
    return (
      <Tooltip content="No ThreadsBot suggestions">
        <BsLightbulb size={19} color={colors.gray1} strokeWidth={0.7} />
      </Tooltip>
    );
  }
  return (
    <Tooltip content="Click to view suggestion">
      <BsLightbulbFill
        role="button"
        onClick={handleShowSuggestions}
        size={19}
        color={colors.yellow}
      />
    </Tooltip>
  );
};
