import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Text, Button } from 'ui';
import { useModal } from 'modules/modals/ModalProvider';
import { SUBSCRIPTION_ERROR_KEYS } from 'constants/subscription';
import routes from 'constants/routes';
import colors from 'theme/colors';

interface IProps {
  subscriptionErrorKey: keyof typeof SUBSCRIPTION_ERROR_KEYS;
  redirectUrl: string | null;
}

const SubscriptionLimits: FC<IProps> = ({ subscriptionErrorKey, redirectUrl }) => {
  const navigate = useNavigate();
  const { close } = useModal();

  const handleUpgradeNowClick = useCallback(() => {
    navigate(`${routes.subscriptionsPlans}/dashboard`);
    close();
  }, []);

  const handleOptionalLinkClick = useCallback(() => {
    if (redirectUrl) {
      window.open(redirectUrl, '_self', 'noopener noreferrer');
      return;
    }
    navigate(routes.dashboard);
    close();
  }, [redirectUrl]);

  if (subscriptionErrorKey === SUBSCRIPTION_ERROR_KEYS.THREAD_LIMIT_EXCEEDED) {
    return (
      <Stack gap="20px" align="center">
        <Stack gap="10px">
          <Text weight="400" align="center" size="lg">
            You have reached the maximum capacity of active threads.
          </Text>
          <Text weight="400" align="center" size="lg">
            Upgrade now to get unlimited threads!
          </Text>
        </Stack>
        <Button onClick={handleUpgradeNowClick}>Upgrade now</Button>
        <Text>
          Alternatively, you can
          <button
            style={{ background: 'transparent', border: 'none' }}
            onClick={handleOptionalLinkClick}
          >
            <Text color={colors.orange}>archive or delete</Text>
          </button>
          one of your active threads.
        </Text>
      </Stack>
    );
  }

  if (subscriptionErrorKey === SUBSCRIPTION_ERROR_KEYS.USER_LIMIT_EXCEEDED) {
    return (
      <Stack gap="20px" align="center">
        <Stack gap="10px">
          <Text weight="400" align="center" size="lg">
            You have reached the maximum capacity of site users.
          </Text>
          <Text weight="400" align="center" size="lg">
            Upgrade now to invite more users!
          </Text>
        </Stack>
        <Button onClick={handleUpgradeNowClick}>Upgrade now</Button>
        <Text>
          Or purchase just 1 more user{' '}
          <button
            style={{ background: 'transparent', border: 'none' }}
            onClick={handleOptionalLinkClick}
          >
            <Text color={colors.orange}>here</Text>
          </button>
          . Alternatively, you can remove another site user.
        </Text>
      </Stack>
    );
  }
  return (
    <Stack gap="16px">
      <Text weight="400" align="center" size="lg">
        Warning!
      </Text>
      <Text weight="400" align="center" size="lg">
        The contributor has achieved their maximum threads capacity. They have been alerted and we
        will notify you if they join your thread.
      </Text>
      <Text weight="400" align="center" size="lg">
        Thank you for your patience!
      </Text>
    </Stack>
  );
};

export default SubscriptionLimits;
