import { EPermissions } from 'app/permissions/constants';
import { FormattedDate, FormattedDateTime } from 'components/dates';
import { EEventStatus, EEvents, EVENTS_NAME_MAP, EVENT_STATUS_NAME_MAP } from 'constants/events';
import routes from 'constants/routes';
import { isEmpty } from 'lodash';
import _capitalize from 'lodash/capitalize';
import { NOTIFICATION } from 'modules/alert/constants';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { useModal } from 'modules/modals/ModalProvider';
import React, { useCallback, useMemo, useState } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import Moment from 'react-moment';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'theme/colors';
import {
  Badge,
  Button,
  DropDownAction,
  Group,
  Icon,
  MemberList,
  Stack,
  StatusBadge,
  Text,
} from 'ui';
import Tooltip from 'components/ui/tooltip';
import { EVENTS_ICON_MAP } from 'ui/eventBadge/EventBadge';
import { getFullName } from 'utils/helpers';
import { FaArrowRight } from 'react-icons/fa6';
import { EVENT_CARD_TOOLTIP_OMITTED_KEYS } from '../constants';
import { IEventResponse } from '../types';

interface IProps extends IEventResponse {
  showLinkToOriginThread?: boolean;
}

const ICON_MAP = {
  [EEvents.END]: <Icon size="large" icon="EndEventIcon" />,
  [EEvents.ISSUE]: <Icon size="large" icon="IssueEventIcon" />,
  [EEvents.QUESTION]: <Icon size="large" icon="QuestionEventIcon" />,
  [EEvents.INSIGHT]: <Icon size="large" icon="InsightEventIcon" />,
  [EEvents.ACTION]: <Icon size="large" icon="ActionEventIcon" />,
  [EEvents.DECISION]: <Icon size="large" icon="DecisionEventIcon" />,
  [EEvents.RESOLUTION]: <Icon size="large" icon="ResolutionEventIcon" />,
  [EEvents.STOPPED_STALLED]: <Icon size="large" icon="StoppedEventIcon" />,
};

const Card = styled.div<{ isCopied: boolean }>`
  padding: 16px 16px;
  border-radius: 8px;
  background-color: ${({ theme, isCopied }) =>
    isCopied ? theme.colors.blue10 : theme.colors.dark2};
  border: ${({ theme, isCopied }) =>
    isCopied ? `1px solid ${theme.colors.blue}` : `1px solid ${theme.colors.dark2}`};
  margin-bottom: 16px;
  transition: background-color 0.3s ease-in-out;
`;

const CardWrapper = styled(Group)``;

const AssignedToContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 8px;
  flex-wrap: wrap;
`;

const EventCard: React.FC<IProps> = (props) => {
  const {
    type,
    name,
    status,
    creator,
    createdAt,
    dueDate,
    thread,
    id,
    relatedEvent,
    relatedThread,
    assignees,
    showLinkToOriginThread = false,
  } = props;
  const { open } = useModal();
  const { eventId } = useParams();
  const { id: propsEventId } = props;

  const handleEditEvent = () => {
    open({
      variant: 'fullRight',
      contentLabel: 'event-type',
      name: 'Edit Event',
      modal: 'createEvent',
      context: {
        eventId: propsEventId,
        threadId: thread.id,
        editModeInitialState: false,
      },
      id: 'modal-edit-event',
    });
  };

  const handleDelete = () => {
    if (relatedThread) {
      open({
        variant: 'center',
        contentLabel: 'warning message',
        name: 'Warning',
        modal: 'relateEventWarning',
        id: 'modal-relate-event-warning',
      });
      return;
    }
    open({
      variant: 'center',
      contentLabel: 'delete event',
      name: 'Delete an Event',
      modal: 'deleteEvent',
      context: {
        id,
      },
      id: 'modal-delete-event',
    });
  };

  const isCurrentEventCopied = Number(eventId) === id;

  const [isCopiedEvent, setIsCopiedEvent] = useState(isCurrentEventCopied);

  const handleCopyEventLink = async () => {
    const { id: eventID, thread: propsThread } = props;
    try {
      await navigator.clipboard.writeText(
        `${window.location.origin}/thread/${propsThread.id}/event/${eventID}`
      );
      showSuccessMessage('Event link copied');
    } catch (error) {
      showErrorMessage(NOTIFICATION.CLIPBOARD_COPY_FAILURE);
    }
  };

  const unHighlightEvent = useCallback(() => {
    if (isCurrentEventCopied && isCopiedEvent) {
      setIsCopiedEvent(false);
    }
  }, [isCurrentEventCopied, isCopiedEvent]);

  const assigneesList = useMemo(() => {
    return assignees.map((assignee) => {
      const { firstName, lastName, avatar } = assignee;
      return { firstName, lastName, avatar, id: assignee.id };
    });
  }, [assignees]);

  const actions = useMemo(
    () => [
      {
        id: '0',
        title: 'Copy link',
        action: handleCopyEventLink,
        icon: <Icon size="small" icon="CopyLinkIcon" />,
      },
      {
        id: '1',
        title: 'Edit',
        action: handleEditEvent,
        icon: <Icon size="small" icon="PencilIcon" stroke="white" />,
        permissions: [EPermissions.EDIT_EVENT],
      },
      {
        id: '2',
        title: 'Delete',
        action: handleDelete,
        icon: <Icon size="small" icon="BinIcon" />,
        permissions: [EPermissions.DELETE_EVENT],
      },
    ],
    [props]
  );

  const isRelation = useMemo(() => relatedThread && relatedEvent, [relatedThread, relatedEvent]);
  const isBrokenRelation = useMemo(
    () => (relatedThread && !relatedEvent) || (relatedEvent && !relatedThread),
    [relatedThread, relatedEvent]
  );
  const tooltipText = useMemo(
    () =>
      // eslint-disable-next-line max-len
      `Duplicated event: ${name} was deleted from related thread. The link from related thread is broken`,
    [name, relatedThread]
  );

  const creatorName = getFullName(creator);

  /**
   *
   * @description formats dynamic keys and value for event hover tooltip.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatEventTooltipData = (eventKey: string, eventValue: any) => {
    switch (eventKey) {
      case 'creator':
        return { key: _capitalize(eventKey), value: eventValue.email };
      case 'status':
        return {
          key: _capitalize(eventKey),
          value: EVENT_STATUS_NAME_MAP[eventValue as EEventStatus],
        };
      case 'type':
        return { key: _capitalize(eventKey), value: EVENTS_NAME_MAP[eventValue as EEvents] };
      case 'dueDate':
        return { key: 'Due Date', value: <FormattedDate date={eventValue} /> };
      default:
        return { key: _capitalize(eventKey), value: eventValue };
    }
  };

  const eventTooltipData = (
    <>
      <div className="flex items-center" data-cy="event-card-tooltip-status-text">
        {EVENTS_ICON_MAP[type as EEvents]}{' '}
        <Text size="lg" style={{ marginLeft: 5 }}>
          {EVENTS_NAME_MAP[type as EEvents]}
        </Text>
      </div>
      <br />
      <table data-cy="event-card-tooltip-table">
        <tbody>
          {Object.entries(props).map(([eventKey, eventValue]) => {
            if (EVENT_CARD_TOOLTIP_OMITTED_KEYS.includes(eventKey) || eventValue === null) {
              return null;
            }

            const { key: formattedKey, value: formattedValue } = formatEventTooltipData(
              eventKey,
              eventValue
            );

            return (
              <tr data-cy={`event-card-table-tr-${eventKey}`} key={`${eventKey}-row`}>
                <td className="p-1" data-cy="event-card-table-td-formatted-key">
                  <Text
                    color={colors.gray1}
                    size="sm"
                    weight="700"
                    cypressAttribute="event-card-table-td-formatted-key-text"
                  >
                    {formattedKey}
                  </Text>
                </td>
                <td className="p-1" data-cy="event-card-table-td-formatted-value">
                  <Text cypressAttribute="event-card-table-td-formatted-value-text">
                    {formattedValue}
                  </Text>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );

  return (
    <Card isCopied={isCopiedEvent} onMouseLeave={unHighlightEvent} data-cy="event-card">
      <CardWrapper justify="space-between">
        <Group className="min-w-0" fluid gap="12px" margin="0 15px 0 0">
          <Tooltip
            content={eventTooltipData}
            tooltipContentClassName="max-w-80"
            cypressAttribute="event-card-tooltip"
          >
            <div data-cy="event-card-type-icon">{ICON_MAP[type]}</div>
          </Tooltip>
          <Stack className="mr-4 min-w-0" fluid>
            <Group fluid gap="5px" align="center" style={{ flexShrink: 0 }}>
              {isRelation && <Icon size="small" icon="LinkIcon" />}
              {isBrokenRelation && (
                <Tooltip content={tooltipText}>
                  <Icon size="small" icon="BrokenLink" />
                </Tooltip>
              )}
              <Group overflow="hidden" align="center">
                <Button textColor={colors.blue7} variant="plain" onClick={handleEditEvent}>
                  <Text
                    size="md"
                    color={colors.blue7}
                    cut={30}
                    noWrap={false}
                    cypressAttribute="event-card-name-text"
                  >
                    {name}
                  </Text>
                </Button>
              </Group>
            </Group>
            <Group align="center" justify="start">
              <Text
                className="max-h-[40px] w-fit truncate"
                cut={33}
                color={colors.gray}
                cypressAttribute="event-card-creator-and-date-text"
              >
                {`Created by ${creatorName}`}
              </Text>
              <Text color={colors.gray}>
                (<Moment fromNow>{createdAt}</Moment>)
              </Text>
            </Group>
          </Stack>
        </Group>

        <Group align="start">
          <StatusBadge
            status={status}
            text={<FormattedDate date={dueDate} />}
            cypressAttribute="event-card-status-badge"
          />
          <DropDownAction
            position="vertical"
            actions={actions}
            cypressAttribute="event-card-dropdown-actions"
          />
        </Group>
      </CardWrapper>

      {showLinkToOriginThread && (
        <Tooltip content={thread?.name}>
          <Link to={`${routes.thread}/${thread.id}`}>
            <Badge
              role="button"
              className="self-start"
              backgroundColor={colors.blue9}
              id="origin-thread-link"
              margin="10px 0 0"
            >
              <Group align="center">
                <Text cut={50} size="xs">
                  {thread?.name}
                </Text>
                <FaArrowRight size={10} />
              </Group>
            </Badge>
          </Link>
        </Tooltip>
      )}

      {!isEmpty(assignees) && (
        <AssignedToContainer data-cy="event-card-assigned-to-container">
          <Text>Assigned to: </Text>
          <MemberList list={assigneesList} />
        </AssignedToContainer>
      )}
    </Card>
  );
};

export default EventCard;
