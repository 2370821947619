import React from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import _map from 'lodash/map';
import { ChevronDown } from 'lucide-react';
import styled from 'styled-components';
import colors from 'theme/colors';
import { AddButton, Stack } from 'ui';
import { ITab } from 'ui/tabs/types';
import AccessControl from 'app/permissions/AccessControl';
import { useParams } from 'react-router-dom';

const SidePanelContainer = styled(Stack)`
  padding: 20px;
  width: 100%;
  height: 100%;
  background: ${colors.dark1};
  border-radius: 8px;
  position: relative;
  flex: 1;
  overflow: scroll;
`;

const Action = styled.div`
  position: absolute;
  top: 15px;
  right: 20px;
`;

interface IAction {
  [key: string | number]: () => void;
}

interface IProps {
  actions?: IAction;
  tabs: ITab[];
  cypressAttribute?: string;
  isArchived?: boolean;
}

const ThreadSidePanel = ({ tabs, actions, cypressAttribute, isArchived }: IProps) => {
  const { id } = useParams();

  return (
    <SidePanelContainer>
      {_map(tabs, (tab) => {
        return (
          <div
            key={tab.id}
            style={{ background: colors.dark2 }}
            className="mb-2 flex w-full items-start justify-between rounded-md bg-black px-2"
          >
            <Accordion className="w-full" type="single" collapsible>
              <AccordionItem value="item-1">
                <div className="flex w-full items-center justify-between">
                  <AccordionTrigger
                    id="accordion-trigger"
                    className="flex w-full justify-between px-3"
                  >
                    <div className="flex items-center gap-1">
                      {tab.icon}
                      {tab.name}
                    </div>
                    <ChevronDown
                      className="h-4 w-4 shrink-0 transform text-white transition-transform 
                    duration-200 duration-300  group-data-[state=open]:rotate-180 group-data-[state=open]:text-blue-500"
                    />
                  </AccordionTrigger>
                  {actions && !isArchived && (
                    <AccessControl permissions={tab.permissions} threadId={id as string}>
                      <AddButton
                        onClick={actions && actions[tab.id]}
                        data-cy={cypressAttribute && `${cypressAttribute}-action-btn`}
                      />
                    </AccessControl>
                  )}
                </div>
                <AccordionContent>
                  <div style={{ background: colors.dark1 }} className="w-full rounded-md p-2">
                    {tab.content}
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        );
      })}
    </SidePanelContainer>
  );
};

export default ThreadSidePanel;
