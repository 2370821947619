import { useAppSelector } from 'app/hooks';
import { useState } from 'react';
import { EThreadParams } from 'types';
import { formatDate, getNextDay } from 'utils/helpers';
import { DEFAULT_LIMIT } from './constants';
import { selectFilter } from './filterSlice';
import { useGetThreadsListQuery } from './threadsApi';
import { EDefaultSortOption, IFilterState, IGetThreadsParams } from './types';

export const getThreadsListQueryParams = (
  url: string,
  threadsFilter: IFilterState,
  limit: number = DEFAULT_LIMIT,
  ordering: EThreadParams | string = EDefaultSortOption.UPDATED_AT_DSC
): IGetThreadsParams => {
  const { search, status, group, tags, date } = threadsFilter;
  const dateEnd = getNextDay(date[1]);
  const updatedAt_Gte = formatDate(date[0]);
  const updatedAt_Lte = formatDate(dateEnd);

  return {
    url,
    ordering,
    limit,
    offset: 0,
    search,
    status,
    group,
    tags,
    updatedAt_Gte,
    updatedAt_Lte,
  };
};

interface IUseThreadsListParams {
  url: string;
  ordering?: EThreadParams | string;
  shouldSkipRequest?: boolean;
  shouldRefetchOnMountOrArgChange?: boolean;
  limit?: number;
  resultsPerRequestLimit?: number;
}

export const useThreadsList = ({
  url,
  limit,
  ordering = EDefaultSortOption.UPDATED_AT_DSC,
  shouldSkipRequest = false,
  shouldRefetchOnMountOrArgChange = false,
  resultsPerRequestLimit,
}: IUseThreadsListParams) => {
  const [pagination, setPagination] = useState(limit || DEFAULT_LIMIT);

  const onFetchMore = () => {
    if (resultsPerRequestLimit) {
      setPagination((previous) => previous + resultsPerRequestLimit);
    }
  };

  const threadsFilter = useAppSelector(selectFilter);
  return {
    ...useGetThreadsListQuery(getThreadsListQueryParams(url, threadsFilter, pagination, ordering), {
      refetchOnMountOrArgChange: shouldRefetchOnMountOrArgChange,
      skip: shouldSkipRequest,
    }),
    onFetchMore,
  };
};
