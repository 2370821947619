import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useAppSelector } from 'app/hooks';
import { EFeatureFlags } from 'constants/features';
import { useIsFeatureEnabled } from 'hooks/useFeatures';
import useSubscriptionPermissions from 'hooks/useSubscriptionPermissions';
import _capitalize from 'lodash/capitalize';
import { NOTIFICATION } from 'modules/alert/constants';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { selectUser } from 'modules/auth/userSlice';
import { useModal } from 'modules/modals/ModalProvider';
import { useUpdateMemberRoleMutation } from 'modules/user/userApi';
import * as Layout from 'pages/members/components/Layout';
import React, { useMemo, useState } from 'react';
import colors from 'theme/colors';
import { EUserRole, IUser } from 'types/user';
import { Avatar, Group, Icon, ReactSelect, Stack, Text } from 'ui';

const roleLabel = (role: EUserRole): string => {
  switch (role) {
    case EUserRole.ADMIN:
      return 'Admin';
    case EUserRole.USER:
      return 'User';
    case EUserRole.EXTERNAL_USER:
      return 'Guest';
    default:
      return 'Unknown';
  }
};

interface MemberCardProps {
  member: IUser;
}

export const MemberCard: React.FC<MemberCardProps> = ({ member }) => {
  const { open } = useModal();
  const [updateMemberRole] = useUpdateMemberRoleMutation();
  const { handleSubscriptionPermissionErrors } = useSubscriptionPermissions();
  const [memberUserRole, setMemberUserRole] = useState(member.userRole);
  const isNautilusIntegration = useIsFeatureEnabled(EFeatureFlags.NAUTILUS_INTEGRATION);

  const {
    userInfo: { id: currentUserId, userRole: currentUserRole },
  } = useAppSelector(selectUser);

  const shouldDisplaySelectRole = useMemo(() => {
    // Only admins can change roles
    if (currentUserRole !== EUserRole.ADMIN) return false;
    // So that a site owner cannot be downgraded to a user
    if (member.isSiteOwner) return false;
    // Can't change own role
    return currentUserId !== member.id;
  }, [currentUserRole, memberUserRole, member.isSiteOwner]);

  const handleRemove = () => {
    open({
      variant: 'center',
      contentLabel: 'delete member',
      name: 'Delete Member',
      modal: 'deleteMember',
      context: {
        member,
      },
      id: 'modal-delete-member',
    });
  };

  const availableUserRoles = useMemo(() => {
    return [EUserRole.ADMIN, EUserRole.USER].map((option) => {
      return { label: _capitalize(option), value: option };
    });
  }, []);

  const handleSelectRole = async (event: { label: string; value: EUserRole }) => {
    try {
      await updateMemberRole({
        id: member.id,
        type: event.value,
      }).unwrap();
      setMemberUserRole(event.value);
      showSuccessMessage(NOTIFICATION.UPDATE_MEMBER_ROLE);
    } catch (error) {
      const data = (error as FetchBaseQueryError).data;
      if (Object.hasOwnProperty.call(data, 'type')) {
        const inputError = data as { type: string[] };
        showErrorMessage(inputError?.type[0]);
        return;
      }
      handleSubscriptionPermissionErrors(error, 'Could not update member role');
    }
  };

  return (
    <Layout.CardUser data-cy="member-card-user">
      <Group align="center" gap="12px">
        <Avatar src={member.avatar} cypressAttribute="member-card-avatar" />
        <Stack gap="10px">
          <Layout.UserNamesContainer data-cy="member-card-user-names-container">
            <Text weight="500" cypressAttribute="member-card-user-first-name">
              {_capitalize(member.firstName)}
            </Text>
            <Text weight="700" cypressAttribute="member-card-user-last-name">
              {_capitalize(member.lastName)}
            </Text>
          </Layout.UserNamesContainer>
          <Text size="xs" color={colors.gray1}>
            {member.email}
          </Text>
        </Stack>
      </Group>

      <Layout.ListControls>
        {shouldDisplaySelectRole && (
          <Layout.UserRoleSelect data-cy="member-card-user-role-select">
            <ReactSelect
              id="user-invite-role-select"
              value={{ label: roleLabel(memberUserRole), value: memberUserRole }}
              defaultValue={{ label: roleLabel(member.userRole), value: member.userRole }}
              options={availableUserRoles}
              isMulti={false}
              isCreatable={false}
              isSearchable={false}
              onChange={handleSelectRole}
            />
          </Layout.UserRoleSelect>
        )}
        {!isNautilusIntegration && (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {currentUserId !== member.id ? (
              <Layout.RemoveButton
                variant="plain"
                onClick={handleRemove}
                data-cy="member-card-user-remove-btn"
              >
                <Icon icon="CloseIcon" size="medium" path="#fff" />
              </Layout.RemoveButton>
            ) : (
              <Text weight="500" size="lg">
                You
              </Text>
            )}
          </>
        )}
      </Layout.ListControls>
    </Layout.CardUser>
  );
};
