import React from 'react';
import colors from 'theme/colors';
import Icon from 'ui/icon';
import Tooltip from 'ui/tooltip';

interface IMessageDetail {
  description: string;
  cypressAttribute?: string;
}

const MessageDetail: React.FC<IMessageDetail> = ({ description, cypressAttribute }) => {
  return (
    <Tooltip
      data={description}
      mode="dark"
      position="left"
      width="full"
      alignment="start"
      cypressAttribute={cypressAttribute}
    >
      <Icon size="small" icon="InfoIcon" stroke={colors.gray} />
    </Tooltip>
  );
};

export default MessageDetail;
