import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { Input } from 'components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import {
  showErrorMessage,
  showSuccessMessage,
  showUncaughtErrorMessage,
} from 'modules/alert/utils';
import { useInviteUsersMutation } from 'modules/inviteUsers/inviteUsersApi';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { EUserRole } from 'types/user';
import { validationSchema } from 'utils/validation';
import { InferType, object, string } from 'yup';

const validation = object({
  email: validationSchema.emailRequired(),
  role: string().oneOf(['user', 'admin']).required(),
});

export const SendInviteDialog: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [sendInvite, { isLoading }] = useInviteUsersMutation();
  const form = useForm({
    defaultValues: { email: '', role: 'user' },
    resolver: yupResolver(validation),
  });

  const onSubmit = async (value: InferType<typeof validation>) => {
    try {
      await sendInvite([{ email: value.email, userRole: value.role as EUserRole }]).unwrap();
      showSuccessMessage('Invite sent');
      setOpen(false);
    } catch (error) {
      if ((error as any).status === 400) {
        showErrorMessage('A user with this email address already exists');
      } else {
        showUncaughtErrorMessage(error);
      }
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>Invite</Button>
      </DialogTrigger>
      <DialogContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <DialogHeader>
              <DialogTitle>Send Workspace Invite</DialogTitle>
            </DialogHeader>

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input className="border-solid" placeholder="Email" type="email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="role"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Role</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a role" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="user">User</SelectItem>
                      <SelectItem value="admin">Admin</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button disabled={isLoading}>Send</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
