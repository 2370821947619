import { INTEGRATION_COLORS, INTEGRATION_LOGOS } from 'constants/integrations';
import React from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import { Group, Icon, Stack, Text } from 'ui';

const Container = styled(Stack)<{
  active: boolean;
}>`
  overflow: hidden;
  padding: 15px;
  border: ${({ active }) =>
    `${active ? 2 : 1}px solid ${active ? INTEGRATION_COLORS.threads3d : colors.dark4}`};
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
`;

interface IProps {
  isSelected: boolean;
  toggleSelection: () => void;
}

export const Threads3dIntegrationToggle: React.FC<IProps> = ({ isSelected, toggleSelection }) => {
  return (
    <Container active={isSelected} onClick={toggleSelection}>
      <Group justify="space-between" align="center">
        <Group gap="10px" justify="between" align="center">
          {INTEGRATION_LOGOS.threads3d}
          <Text size="lg">Threads3D</Text>
        </Group>
        {isSelected && <Icon icon="CheckCircle" />}
      </Group>
    </Container>
  );
};
